import React, { useEffect, useState, useContext } from "react";
import LineSplitDetails from "../../components/LineSplitDetails";
import ScreenHeader from "../../components/ScreenHeader";
import API from "../../utils/API";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import Loader from "../../components/Loader";
import './style.css'
import Status from "../../components/Status";

function GetTag() {

    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [tag, setTag] = useState(null);
    const [error, setError] = useState(false);
    const [pages, setPages] = useState([]);
    const location = useLocation();
    const [fileNames, setFileNames] = useState("");

    useEffect(() => {
        try {
            const cancelToken = API.cancelToken();
            setLoading(true)
            API.getTag(cancelToken, id).then(res => {
                setTag(res.data);
                var pags = res.data.pages.split(",");
                setPages(pags);
                setLoading(false)
            })
        } catch (err) {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Unable to load Tag. Please try again later or contact support',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }
    }, []);


    return (
        <div className="items-stretch bg-slate-950 flex flex-col tag-form mb-5">
            <div className="flex flex-row items-center justify-between">
                <div className="w-8/12 ml-11  text-white text-2xl font-medium leading-10 whitespace-nowrap  mt-11 self-start max-md:ml-2.5 max-md:mt-10">
                    TAG MANAGER
                </div>
                {
                    tag && 
                    <div className="text-white text-right justify-end text-2xl font-medium leading-10 whitespace-nowrap  mt-11 self-start mr-11   max-md:mt-10">
                        <Status
                        key={tag.request_id + 'tagStatus'}
                        id={tag.status}
                        currentStatus={tag.status}
                        clickable="true" />
                    </div>
                }
            </div>
            {
                !tag && loading ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div> :
                    <div className="justify-center bg-slate-800 flex flex-col mt-4 mx-11 p-8 items-start max-md:max-w-full max-md:mr-2.5 max-md:px-5">
                        <div className="items-stretch self-stretch flex justify-between gap-4 mt-4 max-md:max-w-full max-md:flex-wrap">
                            <div className="items-stretch flex flex-col flex-1 max-md:max-w-full">
                                <div className="text-white text-base font-light leading-5">
                                    Tag name
                                </div>
                                <input className="items-start rounded bg-gray-900 max-md:max-w-full shrink-0 max-w-full h-10 flex-col mt-1 pl-3" value={tag && tag.tag_name} />
                            </div>
                            <div className="items-stretch flex flex-col flex-1 max-md:max-w-full">
                                <div className="text-white text-base font-light leading-5">
                                    JIRA Id
                                </div>
                                <input className="items-start rounded bg-gray-900 flex max-md:max-w-full shrink-0 max-w-full h-10 flex-col mt-1 pl-3" value={tag && tag.jira_id} />
                            </div>
                        </div>
                        <div className="items-stretch self-stretch flex justify-between gap-4 mt-4 max-md:max-w-full max-md:flex-wrap">
                            <div className="items-stretch flex flex-col flex-1 max-md:max-w-full">
                                <div className="text-white text-base font-light leading-5 max-md:max-w-full">
                                    Request name
                                </div>
                                <input className="items-start rounded bg-gray-900 flex shrink-0 h-10 flex-col mt-1 max-md:max-w-full" value={tag && tag.requester_name} />
                            </div>
                            <div className="items-stretch flex flex-col flex-1 max-md:max-w-full">
                                <div className="text-white text-base font-light leading-5 max-md:max-w-full">
                                    Request e-mail
                                </div>
                                <input className="items-start rounded bg-gray-900 flex shrink-0 h-10 flex-col mt-1 max-md:max-w-full" value={tag && tag.requester_email} />
                            </div>
                        </div>
                        <div className="text-white text-base font-light leading-5 mt-4">
                            Tag head
                        </div>
                        <input className="items-start rounded bg-gray-900 flex w-full shrink-0 max-w-full h-10 flex-col mt-2" value={tag && tag.tag_header} />
                        <div className="self-stretch mt-4 max-md:max-w-full">
                            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                <div className="flex flex-col items-stretch w-[67%] max-md:w-full max-md:ml-0">
                                    <div className="items-stretch flex grow flex-col max-md:max-w-full max-md:mt-4">
                                        <div className="text-white text-base font-light leading-5 max-md:max-w-full">
                                            Tag body
                                        </div>
                                        <textarea className="items-start rounded bg-gray-900 flex shrink-0 h-[200px] flex-col mt-2 max-md:max-w-full" value={tag && tag.tag_body} />
                                    </div>
                                </div>
                                <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                    <div className="justify-center items-stretch flex flex-col max-md:max-w-full max-md:mt-4">
                                        <div className="text-white text-base font-light leading-5 max-md:max-w-full">
                                            Pages
                                        </div>
                                        {
                                            pages && pages.length > 0 &&
                                            pages.map((page, index) => {
                                                return (
                                                    <div className="items-stretch flex justify-between gap-3 mt-2 max-md:max-w-full max-md:flex-wrap">
                                                        <div className="text-white text-base my-auto">
                                                            {index + 1}.
                                                        </div>
                                                        <input className="text-white text-base leading-5 items-stretch rounded bg-gray-900 grow justify-center" value={page} readOnly />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default GetTag; 