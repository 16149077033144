import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import GlobalSVG from '../../utils/GlobalSVG';
import * as XLSX from 'xlsx'

function Orderbook() {
    const [listOfOrder, setListOfOrder] = useState(null);
    const [listOfSorting, setListOfSorting] = useState(null);
    const [listOfFilteredOrder, setListOfFilteredOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [force, setForce] = useState(false);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("")
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [isSearch, setIsSearch] = useState(false);
    const [reset, setReset] = useState(false)
    const [downloadableData, setDownloadableData] = useState([]);


    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[2]}/${date[1]}/${date[0]}`;
    }

    useEffect(() => {
        if (downloadableData.length > 0) {
            console.log("GET DATA")
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(downloadableData);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, `report.xlsx`);
        }
    }, [downloadableData]);

    const handleDelete = (skf_order_id, line, split_id) => {
        let cancelToken = API.cancelToken();
        Swal.fire({
            title: 'Delete ID',
            text: `Are you sure you want to discard this line?`,
            showCancelButton: true,
            confirmButtonText: 'YES',
            confirmButtonColor: "#131921",
            cancelButtonText: `NO`,
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                API.discardCancelled(cancelToken, skf_order_id, line, split_id).then(res => {
                    setForce(!force)
                }).catch(err => {
                    setLoading(false)
                    console.log(err.response && err.response)
                    if (err.message === 'cancelling') return
                    if (err.response && err.response.status >= 500) {
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    } else {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                })
            }
        })
    }

    useEffect(async () => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if (type === "") return
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setMobile(window.innerWidth <= 768);
        setLoading(true)

        cancelToken = API.cancelToken();

        try {
            let res = null
            switch (type) {
                case "inv-check":

                    setListOfSorting(
                        { "skf_customer_id": "desc", "order_id": "", "billing_date": "", "nf_number": "", "nf_key": "", "error": "" }
                    )

                    res = await API.getInvoiceMonitoring(cancelToken)
                    break

                case "ent-stat": case "bil-delay": case "del-delay": case "rbk-stat": case "ship-delay":
                    if (type == "ent-stat") {
                        setListOfSorting({ "skf_customer_id": "desc", "order_id": "", "coh_registration_date": "", "line": "", "split_id": "", "skf_order_id": "", "SKU": "", "quantity": "", "status": "" })
                    }
                    if (type == "bil-delay") {
                        setListOfSorting({ "skf_customer_id": "desc", "order_id": "", "coh_registration_date": "", "last_accepted_date": "", "line": "", "split_id": "", "skf_order_id": "", "SKU": "", "quantity": "", "status": "", "delta_days": "", "impo_cd_ref_externa": "", "impo_st_skf": "", "impo_dt_ship_date": "" })
                    }
                    if (type == "del-delay") {
                        setListOfSorting({ "skf_customer_id": "desc", "order_id": "", "coh_registration_date": "", "billing_date": "", "nf_number": "", "line": "", "split_id": "", "skf_order_id": "", "SKU": "", "quantity": "", "status": "" })
                    }
                    if (type == "rbk-stat") {
                        setListOfSorting({ "skf_customer_id": "desc", "order_id": "", "last_accepted_date": "", "line": "", "split_id": "", "skf_order_id": "", "SKU": "", "quantity": "", "status": "", "delta_days": "" })
                    }
                    res = await API.getOrderMonitoring(cancelToken, type)
                    break

                case "rej-stat": case "can-stat":
                    if (type == "can-stat") {
                        setListOfSorting({ "skf_customer_id": "desc", "order_id": "", "coh_registration_date": "", "line": "", "split_id": "", "skf_order_id": "", "SKU": "", "status": "" })
                    }
                    if (type == "rej-stat") {
                        setListOfSorting({ "skf_customer_id": "desc", "order_id": "", "coh_registration_date": "", "line": "", "split_id": "", "SKU": "", "status": "" })
                    }
                    res = await API.getCancelledMonitoring(cancelToken, type)
                    break

                case "product": case "customer": case "no-image": case "no-register":
                    if (type == "product") {
                        setListOfSorting({ "id": "desc", "pricelist": "" })
                    }
                    if (type == "customer") {
                        setListOfSorting({ "id": "desc" })
                    }
                    if (type == "no-image") {
                        setListOfSorting({ "skf_product_id": "desc", "pricelist": "", "catalog": "" })
                    }
                    if (type == "no-image") {
                        setListOfSorting({ "skf_product_id": "desc", "pricelist": "" })
                    }
                    res = await API.getProdCustMonitoring(cancelToken, type)
                    break
            }
            // setMaxPages(res.data.pagination.total_pages)
            console.log(res)
            if (res.data) {
                setListOfOrder(res.data)
                setListOfFilteredOrder(res.data)
            } else {
                setListOfOrder([])
                setListOfFilteredOrder([])
            }
        } catch (err) {
            setListOfOrder([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        } finally {
            setLoading(false)
        }

        return () => {
            API.cancel(cancelToken);
        }
    }, [type, force]);

    const handleTypeChange = (e) => {
        let val = e.currentTarget.value
        setType(val)
    }

    const filterReport = (e) => {
        const { name, value } = e.target
        const filteredData = listOfOrder.filter(item =>
            Object.values(item).some(row_value =>
                String(row_value).toLowerCase().includes(value.toLowerCase())
            )
        );
        setListOfFilteredOrder(filteredData)
    }

    const sortReport = (value) => {
        console.log(`VALUE ${listOfSorting[value]}`)

        if (listOfSorting[value] == "asc" || listOfSorting[value] == "") {
            Object.keys(listOfSorting).forEach(key => {
                listOfSorting[key] = "";
            });
            listOfSorting[value] = "desc"
            setListOfSorting(listOfSorting)
            var sorted = [...listOfFilteredOrder].sort((a, b) => {
                if (typeof a[value] === 'string') {
                    return a[value].localeCompare(b[value]);
                } else if (typeof a[value] === 'number') {
                    return a[value] - b[value];
                } else {
                    return 0;  // If neither a string nor a number, do not change order.
                }
            });
        } else {
            Object.keys(listOfSorting).forEach(key => {
                listOfSorting[key] = "";
            });
            listOfSorting[value] = "asc"
            setListOfSorting(listOfSorting)
            var sorted = [...listOfFilteredOrder].sort((a, b) => {
                if (typeof a[value] === 'string') {
                    return b[value].localeCompare(a[value]);
                } else if (typeof a[value] === 'number') {
                    return b[value] - a[value];
                } else {
                    return 0;  // If neither a string nor a number, do not change order.
                }
            });
        }

        setListOfFilteredOrder(sorted);
    }

    const downloadData = () => {
        console.log("Somethitg")
        console.log(type)
        Swal.fire({
            title: 'Creating report...',
            showConfirmButton: false,
            allowOutsideClick: false
        })
        if (type == "no-image") {
            var arr = [['SKU', 'Price List', 'Catalog']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['skf_product_id'], product['pricelist'], product['catalog']])
            });
        }
        if (type == "no-register") {
            var arr = [['SKU', 'Price List']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['skf_product_id'], product['pricelist']])
            });
        }
        if (type == "product") {
            var arr = [['SKU', 'Price List', 'Errors']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['id'], product['pricelist'], product['errors']])
            });
        }
        if (type == "customer") {
            var arr = [['SKU', 'Errors']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['id'], product['errors']])
            });
        }
        if (type == "bil-delay" || type == "del-delay") {
            var arr = [['COH COH Customer ID', 'COH Order ID', 'Created at', 'Invoice Date', 'Invoice Number', 'Order Line', 'Order Split', 'SKU', 'Quantity', 'Order Status']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['skf_customer_id'], product['skf_order_id'], product['coh_registration_date'], product['billing_date'], product['nf_number'], product['line'], product['split_id'], product['SKU'], product['quantity'], product['status'], product['delta_days'], product['impo_cd_ref_externa'], product['impo_st_skf'], product['impo_dt_ship_date'], product['impo_ds_pos']])
            });
        }
        if (type == "rej-stat") {
            var arr = [['COH COH Customer ID', 'COH Order ID', 'Created at', 'Order Line', 'Order Split', 'SKU', 'Order Status']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['skf_customer_id'], product['skf_order_id'], product['coh_registration_date'], product['line'], product['split_id'], product['SKU'], product['status']])
            });
        }
        if (type == "ship-delay") {
            var arr = [['COH COH Customer ID', 'COH Order ID', 'Created at', 'COH Last Accepted Date', 'Order Line', 'Order Split', 'SKU', 'Quantity', 'DPC', 'Order Status', 'COH Shipment Date']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['skf_customer_id'], product['skf_order_id'], product['coh_registration_date'], product['last_accepted_date'], product['line'], product['split_id'], product['SKU'], product['quantity'], product['DPC'], product['status'], product['coh_shipment_date']])
            });
        }
        if (type == "rbk-stat") {
            var arr = [['COH COH Customer ID', 'COH Order ID', 'COH Last Accepted Date', 'Order Line', 'Order Split', 'SKU', 'Quantity', 'Order Status']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['skf_customer_id'], product['skf_order_id'], product['last_accepted_date'], product['line'], product['split_id'], product['SKU'], product['quantity'], product['status'], product['delta_days']])
            });
        }
        if (type == "can-stat" || type == "ent-stat") {
            var arr = [['COH COH Customer ID', 'COH Order ID', 'Created at', 'Order Line', 'Order Split', 'SKU', 'Quantity', 'Order Status']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['skf_customer_id'], product['skf_order_id'], product['coh_registration_date'], product['line'], product['split_id'], product['SKU'], product['quantity'], product['status']])
            });
        }
        if (type == "inv-check") {
            var arr = [['COH COH Customer ID', 'COH Order ID', 'Invoice Date', 'Invoice Number', 'Invoice Access Key', 'Error']]
            listOfFilteredOrder.forEach(product => {
                arr.push([product['skf_customer_id'], product['skf_order_id'], product['billing_date'], product['nf_number'], product['nf_key'], product['error']])
            });
        }
        setDownloadableData(arr)
    }


    return (
        <div className="flex-col md:p-10 p-5 orderbook-page height-limiter">
            {/* <div className="flex flex-row mb-1 gap-x-2 justify-end">

                <div className={`${type !== 'no-image' && type !== 'no-register' ? 'hide' : ''} bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2`} onClick={() => { downloadData() }}>
                    <p className="px-2">Download Data</p>
                </div>

            </div> */}

            <ScreenHeader
                title="Order Book Monitoring"
                link=""
                buttonDescription="Add Order"
                svg="orders"
                hasButton="false" />



            <div id="searchBar" className="bg-gray-200 h-30 my-6 p-6 rounded-md">
                <div className="flex-row wrap-override mb-2">
                    <div className="w-5/6 md:mr-2">
                        <select name="values"
                            className={`appearance-none bg-blue col-span-2 lg:text-base placeholder-white px-5 py-2 rounded-md text-xs w-full box-border border-0`}
                            onChange={handleTypeChange}             >
                            <option value="">Select a report</option>
                            <option value="inv-check">Invoice Checker</option>
                            <option value="ent-stat">ENT Status</option>
                            <option value="bil-delay">Delay in Billing</option>
                            <option value="del-delay">Delay in Order Delivery</option>
                            <option value="rbk-stat">RBK Status</option>
                            <option value="ship-delay">Shipment Delay</option>
                            <option value="rej-stat">REJ Status</option>
                            <option value="can-stat">CAN Status</option>
                            <option value="product">Products</option>
                            <option value="customer">Customers</option>
                            <option value="no-image">No Image</option>
                            <option value="no-register">No Register</option>
                        </select>
                    </div>
                    <div className="w-1/6  mt-2 md:mt-0">
                        <button className="bg-blue cursor-pointer flex items-center justify-center lg:px-5 lg:text-base placeholder-white py-2 rounded-md text-xs w-full"
                            // disabled={props.clicked}
                            onClick={downloadData}
                        >
                            {/* {props.clicked ? "Searching..." : "Search"} */}
                            {"Download Report"}

                        </button>
                    </div>
                </div>
                <div className="flex-row wrap-override">
                    <input type="text"
                        className={`bg-blue col-span-3 lg:text-base placeholder-white px-5 py-2 rounded-md w-full text-xs  box-border border-0`}
                        placeholder="Type to search..."
                        onChange={filterReport}
                    />
                </div>
            </div>

            <div className="overflow-auto">
                {type && type === "inv-check" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                COH Customer ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_customer_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_customer_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>
                                Order ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("billing_date")}>
                                Invoice Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['billing_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['billing_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("nf_number")}>
                                Invoice Number
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['nf_number'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['nf_number'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-3/12 flex-row justify-center text-center" onClick={() => sortReport("nf_key")}>
                                Invoice Access Key
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['nf_key'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['nf_key'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("error")}>
                                Error
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['error'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['error'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                {type && type === "ent-stat" &&

                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                COH COH Customer ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_customer_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_customer_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>COH Order ID<span className="arrow-up ml-2">{listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("coh_registration_date")}>Created at<span className="arrow-up ml-2">{listOfSorting && listOfSorting['coh_registration_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['coh_registration_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("line")}>Order Line<span className="arrow-up ml-2">{listOfSorting && listOfSorting['line'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['line'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("split_id")}>Order Split<span className="arrow-up ml-2">{listOfSorting && listOfSorting['split_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['split_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("SKU")}>SKU<span className="arrow-up ml-2">{listOfSorting && listOfSorting['SKU'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['SKU'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>Quantity<span className="arrow-up ml-2">{listOfSorting && listOfSorting['quantity'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['quantity'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("status")}>Order Status<span className="arrow-up ml-2">{listOfSorting && listOfSorting['status'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['status'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("delta_days")}>Days in ENT<span className="arrow-up ml-2">{listOfSorting && listOfSorting['delta_days'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['delta_days'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                        </div>
                    </div>
                }
                {type && type === "bil-delay" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                COH Customer ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_customer_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_customer_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>
                                Order ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("coh_registration_date")}>
                                Registration Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['coh_registration_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['coh_registration_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("last_accepted_date")}>
                                Last Accepted Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['last_accepted_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['last_accepted_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("line")}>
                                Order Line
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['line'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['line'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("split_id")}>
                                Order Line Split
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['split_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['split_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("SKU")}>
                                SKU
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['SKU'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['SKU'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                Quantity
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['quantity'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['quantity'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("status")}>
                                Status
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['status'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['status'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                Billing Delay Time
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['delta_days'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['delta_days'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                SKF Process Number
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['impo_cd_ref_externa'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['impo_cd_ref_externa'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                Process Status
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['impo_st_skf'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['impo_st_skf'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                Expected Date Arrival at SKF
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['impo_dt_ship_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['impo_dt_ship_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                {type && type === "del-delay" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                COH Customer ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_customer_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_customer_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>
                                Order ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("coh_registration_date")}>
                                Registration Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['coh_registration_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['coh_registration_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("billing_date")}>
                                Invoice Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['billing_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['billing_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("nf_number")}>
                                Invoice Number
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['nf_number'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['nf_number'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("line")}>
                                Order Line
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['line'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['line'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("split_id")}>
                                Order Line Split
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['split_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['split_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("SKU")}>
                                SKU
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['SKU'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['SKU'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                Quantity
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['quantity'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['quantity'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("status")}>
                                Status
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['status'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['status'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/11 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                Delivery Delay time
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['quantity'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['quantity'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>


                        </div>
                    </div>
                }
                {type && type === "rbk-stat" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                AZPartes Order ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_customer_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_customer_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                COH Customer ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_customer_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_customer_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>
                                Order ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("last_accepted_date")}>
                                Last Accepted Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['last_accepted_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['last_accepted_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("last_accepted_date")}>
                                Created at
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['last_accepted_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['last_accepted_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("last_accepted_date")}>
                                COH Penultimate Accepted Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['last_accepted_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['last_accepted_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("line")}>
                                Order Line
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['line'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['line'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("split_id")}>
                                Order Line Split
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['split_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['split_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("SKU")}>
                                SKU
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['SKU'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['SKU'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                Quantity
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['quantity'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['quantity'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("status")}>
                                Status
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['status'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['status'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                Delay in Expected Billing
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['delta_days'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['delta_days'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>


                        </div>
                    </div>
                }
                {type && type === "ship-delay" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                AZPartes Order ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                COH Customer ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_customer_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_customer_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>
                                Order ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("coh_registration_date")}>
                                Registration Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['coh_registration_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['coh_registration_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("last_accepted_date")}>
                                Last Accepted Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['last_accepted_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['last_accepted_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("line")}>
                                Order Line
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['line'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['line'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("split_id")}>
                                Order Line Split
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['split_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['split_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("SKU")}>
                                SKU
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['SKU'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['SKU'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("quantity")}>
                                Quantity
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['quantity'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['quantity'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("dpc")}>
                                DPC
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['dpc'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['dpc'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("status")}>
                                Status
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['status'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['status'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("coh_shipment_date")}>
                                COH Shipment Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['coh_shipment_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['coh_shipment_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/13 flex-row justify-center text-center" onClick={() => sortReport("coh_shipment_date")}>
                                Shipping Delay
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['delta_days'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['delta_days'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                {type && type === "rej-stat" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                COH Customer ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_customer_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_customer_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>
                                Order ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>
                                AZPartes Order ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("coh_registration_date")}>
                                Registration Date
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['coh_registration_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['coh_registration_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("line")}>
                                Order Line
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['line'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['line'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("split_id")}>
                                Order Line Split
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['split_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['split_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("SKU")}>
                                SKU
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['SKU'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['SKU'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("status")}>
                                Status
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['status'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['status'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("SKU")}>
                                Time in REJ
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['specific_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['specific_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>


                        </div>
                    </div>
                }
                {type && type === "can-stat" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("skf_customer_id")}>
                                COH COH Customer ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_customer_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_customer_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>
                                COH Order ID
                                <span className="arrow-up ml-2">{listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("skf_order_id")}>
                                AZPartes Order ID
                                <span className="arrow-up ml-2">{listOfSorting && listOfSorting['skf_order_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_order_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-2/12 flex-row justify-center text-center" onClick={() => sortReport("coh_registration_date")}>Created at<span className="arrow-up ml-2">{listOfSorting && listOfSorting['coh_registration_date'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['coh_registration_date'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("line")}>Order Line<span className="arrow-up ml-2">{listOfSorting && listOfSorting['line'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['line'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("split_id")}>Order Split<span className="arrow-up ml-2">{listOfSorting && listOfSorting['split_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['split_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("SKU")}>SKU<span className="arrow-up ml-2">{listOfSorting && listOfSorting['SKU'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['SKU'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("SKU")}>Quantity<span className="arrow-up ml-2">{listOfSorting && listOfSorting['SKU'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['SKU'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center" onClick={() => sortReport("status")}>Order Status<span className="arrow-up ml-2">{listOfSorting && listOfSorting['status'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['status'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}</span></div>
                            <div className="w-1/12 flex-row justify-center text-center"></div>
                        </div>
                    </div>
                }
                {type && type === "product" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-4/12 flex-row justify-center text-center" onClick={() => sortReport("id")}>
                                Product ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-4/12 flex-row justify-center text-center" onClick={() => sortReport("pricelist")}>
                                Pricelist
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['pricelist'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['pricelist'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-4/12 flex-row justify-center text-center">
                                Errors
                            </div>
                        </div>
                    </div>
                }
                {type && type === "customer" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-6/12 flex-row justify-center text-center" onClick={() => sortReport("id")}>
                                COH Customer ID
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-6/12 flex-row justify-center text-center">
                                Errors
                            </div>
                        </div>
                    </div>
                }
                {type && type === "no-image" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-4/12 flex-row justify-center text-center" onClick={() => sortReport("skf_product_id")}>
                                SKU
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_product_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_product_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-4/12 flex-row justify-center text-center" onClick={() => sortReport("pricelist")}>
                                Pricelist
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['pricelist'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['pricelist'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-4/12 flex-row justify-center text-center" onClick={() => sortReport("catalog")}>
                                Catalog
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['catalog'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['catalog'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                {type && type === "no-register" &&
                    <div id="resultTable" className="flex-col justify-between w-full pointer">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-sm  pl-5 font-bold">
                            <div className="w-4/12 flex-row justify-center text-center" onClick={() => sortReport("skf_product_id")}>
                                SKU
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['skf_product_id'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['skf_product_id'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                            <div className="w-4/12 flex-row justify-center text-center" onClick={() => sortReport("pricelist")}>
                                Pricelist
                                <span className="arrow-up ml-2">
                                    {listOfSorting && listOfSorting['pricelist'] == "asc" ? GlobalSVG.upArrow() : listOfSorting && listOfSorting['pricelist'] == "desc" ? GlobalSVG.downArrow() : GlobalSVG.neutralDownArrow()}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                <div id="resultBody" className="w-full">
                    {loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        listOfFilteredOrder === null ?
                            "" :
                            listOfFilteredOrder.length === 0 ?
                                <div className="loader-wrapper min-h-full min-w-full p-5">
                                    No Data Found
                                </div> :
                                listOfFilteredOrder.map((order, index) => {
                                    switch (type) {
                                        case "inv-check":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.skf_customer_id}</div>
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.skf_order_id}</div>
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.billing_date ? order.billing_date.split(" ")[0] : "-"}</div>
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.nf_number}</div>
                                                <div className="w-3/12 text-center flex-wrap break-words">{order.nf_key}</div>
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.error}</div>
                                            </div>

                                        case "ent-stat":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.skf_customer_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.skf_order_id}</div>
                                                {/* <div className="w-1/12 text-center">{order.order_id}</div> */}
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.coh_registration_date ? order.coh_registration_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.line}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.split_id}</div>
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.SKU}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.quantity}</div>
                                                <div className="w-1/12 text-center flex justify-center">
                                                    <Status
                                                        key={order.order_id + 'orderStatus'}
                                                        id={order.order_id}
                                                        neutral="true"
                                                        currentStatus={order.status}
                                                        clickable="true" />
                                                </div>
                                                <div className="w-1/12 text-center">{order.delta_days}</div>
                                            </div>
                                        case "bil-delay":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.skf_customer_id}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.skf_order_id}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.coh_registration_date ? order.coh_registration_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.last_accepted_date ? order.last_accepted_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.line}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.split_id}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.SKU}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.quantity}</div>
                                                <div className="w-1/13 text-center flex justify-center">
                                                    <Status
                                                        key={order.order_id + 'orderStatus'}
                                                        id={order.order_id}
                                                        neutral="true"
                                                        currentStatus={order.status}
                                                        clickable="true" />
                                                </div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.delta_days}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.impo_cd_ref_externa ? order.impo_cd_ref_externa.split(" ")[0] : "-"}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.impo_st_skf ? order.impo_st_skf.split(" ")[0] : "-"}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.impo_dt_ship_date ? order.impo_dt_ship_date.split(" ")[0] : "-"}</div>
                                            </div>

                                        case "del-delay":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                                <div className="w-1/11 text-center flex-wrap break-words">{order.skf_customer_id}</div>
                                                <div className="w-1/11 text-center flex-wrap break-words">{order.skf_order_id}</div>
                                                <div className="w-1/11 text-center flex-wrap break-words">{order.coh_registration_date ? order.coh_registration_date.split(" ")[0] : "-"}</div>

                                                <div className="w-1/11 text-center flex-wrap break-words">{order.billing_date ? order.billing_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/11 text-center flex-wrap break-words">{order.nf_number ? order.nf_number : "-"}</div>

                                                <div className="w-1/11 text-center flex-wrap break-words">{order.line}</div>
                                                <div className="w-1/11 text-center flex-wrap break-words">{order.split_id}</div>
                                                <div className="w-1/11 text-center flex-wrap break-words">{order.SKU}</div>
                                                <div className="w-1/11 text-center flex-wrap break-words">{order.quantity}</div>
                                                <div className="w-1/11 text-center flex justify-center">
                                                    <Status
                                                        key={order.order_id + 'orderStatus'}
                                                        id={order.order_id}
                                                        neutral="true"
                                                        currentStatus={order.status}
                                                        clickable="true" />
                                                </div>
                                                <div className="w-1/11 text-center flex-wrap break-words">{order.delta_days}</div>

                                            </div>

                                        case "rbk-stat":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5 " key={index + 'order'}>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.order_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.skf_customer_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.skf_order_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.last_accepted_date ? order.last_accepted_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.last_accepted_date ? order.last_accepted_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.last_accepted_date ? order.last_accepted_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.line}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.split_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.SKU}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.quantity}</div>
                                                <div className="w-1/12 text-center flex justify-center">
                                                    <Status
                                                        key={order.order_id + 'orderStatus'}
                                                        id={order.order_id}
                                                        neutral="true"
                                                        currentStatus={order.status}
                                                        clickable="true" />
                                                </div>
                                                <div className="w-1/12 text-center">{order.delta_days ? order.delta_days : "-"}</div>
                                            </div>

                                        case "ship-delay":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5 " key={index + 'order'}>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.order_id}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.skf_customer_id}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.skf_order_id}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.coh_registration_date ? order.coh_registration_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.last_accepted_date ? order.last_accepted_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.line}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.split_id}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.SKU}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.quantity}</div>
                                                <div className="w-1/13 text-center flex-wrap break-words">{order.dpc ? order.dpc : '-'}</div>
                                                <div className="w-1/13 text-center flex justify-center">
                                                    <Status
                                                        key={order.order_id + 'orderStatus'}
                                                        id={order.order_id}
                                                        neutral="true"
                                                        currentStatus={order.status}
                                                        clickable="true" />
                                                </div>
                                                <div className="w-1/13 text-center">{order.specific_date ? order.specific_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/13 text-center">{order.delta_days}</div>
                                            </div>

                                        case "rej-stat":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5 " key={index + 'order'}>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.skf_customer_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.order_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.skf_order_id}</div>
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.coh_registration_date ? order.coh_registration_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.line}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.split_id}</div>
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.SKU}</div>
                                                <div className="w-2/12 text-center flex justify-center">
                                                    <Status
                                                        key={order.order_id + 'orderStatus'}
                                                        id={order.order_id}
                                                        neutral="true"
                                                        currentStatus={order.status}
                                                        clickable="true" />
                                                </div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.specific_date}</div>
                                            </div>

                                        case "can-stat":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.skf_customer_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.order_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.skf_order_id}</div>
                                                {/* <div className="w-1/12 text-center">{order.order_id}</div> */}
                                                <div className="w-2/12 text-center flex-wrap break-words">{order.coh_registration_date ? order.coh_registration_date.split(" ")[0] : "-"}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.line}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.split_id}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.SKU}</div>
                                                <div className="w-1/12 text-center flex-wrap break-words">{order.quantity}</div>
                                                <div className="w-1/12 text-center flex justify-center">
                                                    <Status
                                                        key={order.order_id + 'orderStatus'}
                                                        id={order.order_id}
                                                        neutral="true"
                                                        currentStatus={order.status}
                                                        clickable="true" />
                                                </div>
                                                <div className="w-1/12 text-center">
                                                    <div className="ml-2 w-10 h-10 rounded-full bg-gray-700 p-2 flex flex-row flex-nowrap pointer" onClick={() => handleDelete(order.skf_order_id, order.line, order.split_id)}>
                                                        {GlobalSVG.garbage()}
                                                    </div>
                                                </div>
                                            </div>

                                        case "product":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'product'}>
                                                <div className="w-4/12 text-center flex-wrap break-words">{order.id}</div>
                                                <div className="w-4/12 text-center flex-wrap">{order.pricelist}</div>
                                                <div className="w-4/12 text-center">{
                                                    order.errors ? (
                                                        order.errors.split(",").map((e, index) => {
                                                            return <p key={e + index}>{e}</p>
                                                        })
                                                    ) : (
                                                        <p>No errors found.</p>
                                                    )
                                                }</div>
                                            </div>
                                        case "customer":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'product'}>
                                                <div className="w-1/2 text-center flex-wrap break-words">{order.id}</div>
                                                <div className="w-1/2 text-center">{
                                                    order.errors.split(",").map((e, index) => {
                                                        return <p key={e + index}>{e}</p>
                                                    })
                                                }</div>

                                            </div>
                                        case "no-image":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'product'}>
                                                <div className="w-1/3 text-center flex-wrap break-words">{order.skf_product_id}</div>
                                                <div className="w-1/3 text-center flex-wrap break-words">{order.pricelist}</div>
                                                <div className="w-1/3 text-center flex-wrap break-words">{order.catalog}</div>


                                            </div>
                                        case "no-register":
                                            return <div className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'product'}>
                                                <div className="w-1/2 text-center flex-wrap break-words">{order.skf_product_id}</div>
                                                <div className="w-1/2 text-center flex-wrap break-words">{order.pricelist}</div>

                                            </div>
                                    }
                                })}
                </div>
            </div>
        </div>);
}

export default Orderbook; 