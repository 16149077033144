import React, { useEffect, useState } from "react";
import Loader from '../../components/Loader';
import API from "../../utils/API";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, Rectangle } from 'recharts';
import './style.css';
import { useLanguage } from '../../context/LanguageContext';

const ServicePartnerStats = () => {
    const [data, setData] = useState({
        quantity_per_month: [],
        product_families_per_month: [],
        product_quantity_and_ps_cost: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { translate } = useLanguage();

    useEffect(() => {
        fetchData();
        
    }, []);

    const fetchData = async () => {
        let cancelToken = API.cancelToken();
        try {
            const response = await API.getPartnerStats(cancelToken);
            setData(response.data.metrics);
        } catch (err) {
            console.error(err);
            setError(translate('NoDataAvailable'));
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div className="loader-wrapper"><Loader /></div>;
    }

    if (error) {
        return <div className="error-message p-12">{error}</div>;
    }

    return (
        <div className="statistics-page py-10 px-8 lg:mx-20 lg:px-20 height-limiter">
            <h1 className="text-3xl mb-5">Service Partner</h1>

            <div className="statistics-section mt-4">
                <h2 className="text-2xl mb-10">Quantidade total de produtos</h2>
                <div className="statistics-chart">
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            width={500}
                            height={300}
                            data={data.quantity_per_month}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip cursor={{fill: 'transparent'}}/>
                            <Legend />
                            <Bar dataKey="Quantidade Total" fill="#a1b2c9" activeBar={<Rectangle fill="#a1b2c9" stroke="#1e2b3c" />} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>

            <div className="statistics-section mt-10">
                <h2 className="text-2xl mb-10">Número total de famílias</h2>
                <div className="statistics-chart">
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            width={500}
                            height={300}
                            data={data.product_families_per_month}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip cursor={{fill: 'transparent'}}/>
                            <Legend />
                            <Bar dataKey="Número total de famílias" fill="#a1b2c9" activeBar={<Rectangle fill="#a1b2c9" stroke="#Statistics" />} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>

            <div className="statistics-section mt-10">
                <h2 className="text-2xl mb-10">Quantidade de Protutos X Custo PS</h2>
                <div className="statistics-chart">
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            width={500}
                            height={300}
                            data={data.product_quantity_and_ps_cost}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="skf_product_id" />
                            <YAxis />
                            <Tooltip cursor={{fill: 'transparent'}}/>
                            <Legend />
                            <Bar dataKey="Custo Total PS" fill="#a1b2c9" activeBar={<Rectangle fill="#a1b2c9" stroke="#a1b2c9" />} />
                            <Bar dataKey="Quantidade Total" fill="#3f5a7d" activeBar={<Rectangle fill="#3f5a7d" stroke="#ffffff" />} />
                            {/* <Bar dataKey="month" fill="#1e2b3c" activeBar={<Rectangle fill="#1e2b3c" stroke="#1e2b3c" />} /> */}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default ServicePartnerStats;
