import React, { useEffect, useState, useContext } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import { useParams } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import API from "../../utils/API";
import Swal from 'sweetalert2';
import { Link, useHistory } from "react-router-dom";

function PriceDetails() {

    const { id } = useParams();
    const { product } = useParams();
    const [priceObject, setPrices] = useState(false)
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        setLoading(true)
        API.getPriceDetails(cancelToken, id, decodeURIComponent(product)).then(res => {
            // setMaxPages(res.data.pagination.total_pages)
            console.log(res.data.product.product)
            if (res.data.product.mercCodigo == undefined) {
                setPrices(false);
                Swal.fire({
                    title: 'Ops.',
                    text: 'Dados não disponíveis para este produto.',
                    confirmButtonColor: "#131921",
                    icon: 'info',
                    confirmButtonText: 'Ok'
                });

            }
            res.data.product ? setPrices(res.data.product) : setPrices(false);
        }).catch(err => {
            setPrices(false);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            // setIsClicked(false);
            setLoading(false)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []);

    return (

        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title={priceObject.mercCodigo ? priceObject.mercCodigo + " - " + id : ''}
                link=""
                hasButton="false" />

            <div className="my-4">
                <h1 className="text-lg">Input</h1>
                <div className="flex flex-col bg-gray-100 px-8 py-4 rounded-sm">
                    <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">
                        <div className="flex flex-col w-full md:w-1/3 my-2">
                            <label className="py-1">SKU</label>
                            <input placeholder={priceObject.mercCodigo} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                        </div>
                        <div className="flex flex-col w-full md:w-1/3 my-2">
                            <label className="py-1">Operação</label>
                            <input placeholder={priceObject.operacao} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                        </div>
                        <div className="flex flex-col w-full md:w-1/3 my-2">
                            <label className="py-1">Origem</label>
                            <input placeholder={priceObject.origem} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                        </div>
                        <div className="flex flex-col w-full md:w-1/3 my-2">
                            <label className="py-1">País de Origem</label>
                            <input placeholder={priceObject.paisOrigem} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                        </div>
                    </div>

                    <div className="flex flex-col gap-x-4 md:flex-row">
                        <div className="flex flex-col w-full md:w-1/3 my-2">
                            <label className="py-1">Quantidade</label>
                            <input placeholder={priceObject.quantidade} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                        </div>
                        <div className="flex flex-col w-full md:w-1/3 my-2">
                            <label className="py-1">Preço NET</label>
                            <input placeholder={priceObject.precoTotal} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                        </div>
                        <div className="flex flex-col w-full md:w-1/3 my-2"></div>
                        <div className="flex flex-col w-full md:w-1/3 my-2"></div>
                    </div>
                </div>
            </div>


            <div className="my-4">
                {!priceObject || loading ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div>
                    :
                    <div>
                        <h1 className="text-lg">Output</h1>
                        <div className="flex flex-col bg-gray-100 px-8 py-4 rounded-sm">
                            <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">
                                <div className="flex flex-col w-full md:w-1/2 my-2">
                                    <label className="py-1">NCM</label>
                                    <input placeholder={priceObject.nbmCodigo} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/2 my-2">
                                    <label className="py-1">Código CFOP</label>
                                    <input placeholder={priceObject.cfopCodigo} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor Base PIS</label>
                                    <input placeholder={priceObject.vlBasePis ? priceObject.vlBasePis : "0"} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Alíquota PIS</label>
                                    <input placeholder={priceObject.vlaliqPis ? priceObject.vlaliqPis + '%' : '0'} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor PIS</label>
                                    <input placeholder={priceObject.vlPis} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">

                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor Base COFINS</label>
                                    <input placeholder={priceObject.vlBaseCofins} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Alíquota COFINS</label>
                                    <input placeholder={priceObject.vlAliqCofins ? priceObject.vlAliqCofins + '%' : ''} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor COFINS</label>
                                    <input placeholder={priceObject.vlCofins} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor Base ICMS</label>
                                    <input placeholder={priceObject.vlBaseIcms} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Alíquota ICMS</label>
                                    <input placeholder={priceObject.aliqIcms ? priceObject.aliqIcms + '%' : ''} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor ICMS</label>
                                    <input placeholder={priceObject.vlIcms} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor Base ICMS-ST</label>
                                    <input placeholder={priceObject.vlBaseStf ? priceObject.vlBaseStf : "0"} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Alíquota ICMS-ST</label>
                                    <input placeholder={priceObject.aliqStf ? priceObject.aliqStf + '%' : "0"} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor ICMS-ST</label>
                                    <input placeholder={priceObject.vlIStf ? priceObject.vlIStf : "0"} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor Base IPI</label>
                                    <input placeholder={priceObject.vlBaseIpi} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Alíquota IPI</label>
                                    <input placeholder={priceObject.aliqIpi + '%'} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor IPI</label>
                                    <input placeholder={priceObject.vlIpi} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor Base ISS</label>
                                    <input placeholder={priceObject.vlBaseIss ? priceObject.vlIStf : "0"} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Alíquota ISS</label>
                                    <input placeholder={priceObject.aliqIss ? priceObject.aliqIss + '%' : '0'} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/3 my-2">
                                    <label className="py-1">Valor ISS</label>
                                    <input placeholder={priceObject.vlIss ? priceObject.vlIss : "0"} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-x-4 md:flex-row">
                                <div className="flex flex-col w-full md:w-1/2 my-2">
                                    <label className="py-1">Preço Total (todos os impostos incluídos)</label>
                                    <input placeholder={priceObject.somatorio} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                                <div className="flex flex-col w-full md:w-1/2 my-2">
                                    <label className="py-1">Preço Gross</label>
                                    <input placeholder={priceObject.precoTotal} disabled={true} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>


    )
}

export default PriceDetails;