import React, { useState, useRef, useEffect, useContext } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import SearchBar from "../../components/Searchbar";
import Status from "../../components/Status";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

function ListOfAvailibilies() {
    const [listOfAvailibilities, setListOfAvailibilities] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false)

    const searchFields = [{ fieldValue: "request_id", fieldName: "ID" },
    { fieldValue: "date", fieldName: "Date" },
    { fieldValue: "user_id", fieldName: "COH COH Customer ID" },
    { fieldValue: "product", fieldName: "Product Designation" },
    { fieldValue: "status", fieldName: "Status" }];

    const dropDownValues = {
        status: [{ status: "-2", description: "Expired" },
        { status: "-1", description: "Rejected" },
        { status: "0", description: "Pending Review" },
        { status: "1", description: "Pending Approval" },
        { status: "2", description: "Approved" },
        { status: "3", description: "Purchase Complete" }]
    };

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setMobile(window.innerWidth <= 768);

        setLoading(true)
        cancelToken = API.cancelToken();

        let field = fieldRef.current.value;
        let value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;
        if (field !== "" && value != "") {
            API.getSearchListOfAvailabilities(cancelToken, currentPage, field, value).then(res => {
                console.log(res);
                res.data.availabilities ? setListOfAvailibilities(res.data.availabilities) : setListOfAvailibilities([]);
                setMaxPages(res.data.pagination.total_pages);
            }).catch(err => {
                setListOfAvailibilities([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(
                f => {
                    setIsClicked(false);
                    setLoading(false)
                    setIsSearch(false);
                });
        }
        else{
            API.getListOfAvailibilities(cancelToken, currentPage).then(res => {
                res.data.availabilities ? setListOfAvailibilities(res.data.availabilities) : setListOfAvailibilities([]);
                setMaxPages(res.data.pagination.total_pages);
            }).catch(err => {
                setListOfAvailibilities([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(f => {
                setLoading(false)
            });
        }

        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, reset, isSearch]
    );

    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }
        setPages(pages);
    }, [maxPages, currentPage])

    const doReset =()=>{
        setReset(!reset)
    }

    const handleSearch = (e) => {
        e && e.preventDefault();
        let value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;
        let field = fieldRef.current.value;

        if (!value || value.trim() === '' || !field){
            setError(true)
            return
        }

        if(currentPage === 1){
            setIsSearch(true)
        }else{
            setCurrentPage(1)
        }

        setIsClicked(true);


    }


    return <div className="flex-col md:p-10 p-5 availabilities-page height-limiter">
        <ScreenHeader
            title="Availibility"
            hasButton="false" />

        <SearchBar
            searchFields={searchFields}
            valueRef={valueRef}
            fieldRef={fieldRef}
            fieldStatusRef={fieldStatusRef}
            onSearch={handleSearch}
            clicked={isClicked}
            dropDownValues={dropDownValues}
            error={error}
            reset={doReset}
            isReset={reset} />

        <div className="overflow-auto w-full">
            <div id="resultTable" className="flex-col justify-between">
                <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                    <div className="w-1/12 text-center">ID</div>
                    <div className="w-2/12 text-center">Date</div>
                    <div className="w-1/12 text-center">COH COH Customer ID</div>
                    <div className="w-2/12 text-center">Product Designation</div>
                    <div className="w-1/12 text-center">Quantity</div>
                    <div className="w-3/12 text-center">Additional Information</div>
                    <div className="w-2/12 text-center">Status</div>
                </div>
            </div>
            <div id="resultBody">
                {!listOfAvailibilities || loading ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div> :
                    listOfAvailibilities.length === 0 ?
                        <div className="loader-wrapper min-h-full min-w-full p-5">
                            No Data Found
                        </div> :
                        listOfAvailibilities.map((av, index) => {
                            return <Link to={`./availibility/${av.request_id}`} key={index + 'link'}>
                                <div id="rowDetail" className="w-full bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5 justify-between">
                                    <div className="w-1/12 text-center">{av.request_id}</div>
                                    <div className="w-2/12 text-center">{databaseToDate(av.created_at)}</div>
                                    <div className="w-1/12 text-center">{av.user_id}</div>
                                    <div className="w-2/12 text-center">{av.count == 1? av.product_id : "Multiple Items"}</div>
                                    <div className="w-1/12 text-center">{av.count == 1? av.quantity : "Multiple Items"}</div>
                                    <div className="w-3/12 text-center">{av.message}</div>
                                    <div className="w-2/12 text-center flex justify-center">
                                        <Status
                                            key={index + 'status'}
                                            currentStatus={av.status}
                                            clickable="true" />
                                    </div>
                                </div>
                            </Link>;
                        })}
            </div>
        </div>
        {
            !loading && maxPages ?
            <div className="page-selector flex-row-nowrap space-between">
                <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                    <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                    {
                        !mobile && <p>Previous</p>
                    }
                </div>
                <div className="pages-section flex-row-nowrap">
                    {
                        pages
                    }
                </div>
                <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                    {
                        !mobile && <p>Next</p>
                    }
                    <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                </div>
            </div>:
            null
        }
    </div>;
}

export default ListOfAvailibilies; 