import React, { useState, useEffect, useContext } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import CompanyLine from "../../components/CompanyLine"
import API from "../../utils/API";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import * as XLSX from 'xlsx'
import "./style.css"
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import { useLanguage } from '../../context/LanguageContext';

function CustomerRegistration() {
    const { translate } = useLanguage();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);

    let cancelToken = API.cancelToken();

    let [customerRegistration, setCustomerRegistration] = useState([{
        coh_id: "",
        type: "",
        subBusinessSegment: "",
    }]);
    const context = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
    }, [])


    function handleChange(value, index) {
        let newArr = [...customerRegistration]
        console.log(`It has changed ${value.type}`)
        if (!value.coh_id && !value.type && !value.subBusinessSegment && newArr.length > 1) {
            newArr.splice(index, 1)
        }else{
            newArr[index] = value;
        }
        setCustomerRegistration(newArr);
    }

    const handleSubmit = () => {

        for (let e of customerRegistration) {
        if (!e.coh_id || !e.type|| !e.subBusinessSegment) {
            console.log('error general')
            setError(true)
            return
        }
        }

        cancelToken = API.cancelToken();
        setLoading(true);

        API.registerCustomer(customerRegistration).then(res => {
            setLoading(false);
            let no_coh = res.data.no_coh_id
            let existing = res.data.already_registered

            let title = 'Success'
            let icon = 'success'
            let html = `<p>Registration Process Started!</p>`
            if (existing.length + no_coh.length > 0 ){
                title = 'Partial Success'
                icon = 'warning'
                html = `<p>Registration Process Started!</p>
                ${no_coh.length>0 ? ('<p>The following companies failed registration as they were not allowed to be registered:</p>'+no_coh.join(', ')) :  '' }
                ${existing.length>0 ? ('<p>The following companies are already registered:</p>'+existing.join(', ')) :  '' }`
            }
            if (existing.length + no_coh.length === customerRegistration.length){
                title = 'Failed'
                icon = 'warning'
                html = `<p>Registration Process Failed!</p>
                ${no_coh.length>0 ? ('<p>The following companies failed registration as they were not allowed to be registered:</p>'+no_coh.join(', ')) :  '' }
                ${existing.length>0 ? ('<p>The following companies are already registered:</p>'+existing.join(', ')) :  '' }`
            }
            Swal.fire({
                title: title,
                html: html,
                confirmButtonColor: "#131921",
                icon: icon,
                confirmButtonText: 'Ok'
            });
            let newArr = no_coh.concat(existing);
            if (newArr.length > 0){
                let tempList = []
                customerRegistration.forEach(e=>{
                    if (newArr.includes(`${e.coh_id}`.toUpperCase())) tempList.push(e)
                });
                setCustomerRegistration(tempList)
            }else{
                history.push('/listofcustomers')
            }
        }).catch(err => {
            setLoading(false);
            if (err.message === 'cancelling') return
            if (err.response && (err.response.status === 412)){
                let errorMessage = ""
                for (let item of err.response.data.error){
                    errorMessage += (`<li>${item}</li>`)
                }
                Swal.fire({
                    title: 'Error.',
                    html: "<p>There are divergent fields in the company registration, please request correction as specified below.</p><ul>" + errorMessage + "</ul>",
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }else{
                Swal.fire({
                    title: 'Error.',
                    text: err.response.data.error,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }

    //upload file section 
    // on change states
    const [excelFileError, setExcelFileError] = useState(null);

    // handle File
    const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        processFile(selectedFile);
    }

    function processFile(selectedFile) {
        if (selectedFile) {
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);

                reader.onload = (e) => {
                    setExcelFileError(null);
                    if (e.target.result !== null) {
                        const workbook = XLSX.read(e.target.result, { type: 'buffer' });
                        const worksheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[worksheetName];
                        const data = XLSX.utils.sheet_to_json(worksheet);
                        setCustomerRegistration(data);
                        setMaxPages(Math.ceil(data.length / 10))

                    }
                }
            }
            else {
                setExcelFileError('Please select only excel file types');
            }
        }
        else {
            console.log('Please select a file');
        }
    }

    const onDrop = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
        let file = e.dataTransfer.files[0];
        console.log(file);
        processFile(file);
    }

    const onDragEnter = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = '#131930';
        e.currentTarget.style.border = '1px solid white';
    }
    const onDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
    }
   
    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])

    return (
        <div className="registration-page flex-col md:p-10 p-5 height-limiter">
            <ScreenHeader
                title={translate("CompanyRegistrationlabel")}
                link='https://skfbuy-public-assets.s3.amazonaws.com/insert_company.xlsx'
                external={true}
                buttonDescription={translate("DownloadTemplateLabel")}
                hasButton="true" />

            <div id="sectionTwo">
                <div className="pt-10 pb-6">
                    {translate("FillInLabel")}
                </div>

                <div className="bg-blue h-full max-h-128 rounded text-xs lg:text-base md:p-7 p-3 flex-col justify-between items-center overflow-auto">
                    {customerRegistration.slice((currentPage - 1) * 10, currentPage * 10).map((e, index) => (
                        <CompanyLine key={index + "customer"} data={e} index={index} realIndex={index + ( 10 *(currentPage - 1))} onChange={handleChange} error={error}/>
                    ))}
                </div>

                <div className="flex justify-end">
                    <button
                        className="bg-gray-200 text-xs lg:text-base mt-5 cursor-pointer rounded-md w-44 h-10 flex justify-center items-center"
                        onClick={handleSubmit}>
                        <p>{loading ? "Registering..." : translate("RegisterCompanyLabel")}</p>
                    </button>
                </div>
            </div>

            {customerRegistration.length == 1 && <div id="uploadFile">
                <div className="pt-10 pb-6">
                    <p>{translate("UploadTemplateLabel")}</p>
                </div>
                {excelFileError && <div className="py-2 text-red-600">{excelFileError}</div>}
                <div className="bg-blue flex justify-center align-center h-52 rounded text-xs lg:text-base">
                    <div
                        onClick={() => { document.getElementById('getFile').click() }}
                        onDrop={e => onDrop(e)}
                        onDragEnter={e => onDragEnter(e)}
                        onDragLeave={e => onDragLeave(e)}
                        onDragStart={e => e.preventDefault()}
                        onDragEnd={e => e.preventDefault()}
                        onDragOver={e => onDragEnter(e)}
                        onDrag={e => e.preventDefault()}
                        className="flex w-40 h-40 text-center border-white border border-dashed rounded-md justify-center items-center cursor-pointer">
                        <input type='file' name="getFile" id="getFile" style={{ display: "none" }} onChange={handleFile} />
                        <div>
                            <h1>{translate("DropHereLabel")}</h1>
                            <h1>{translate("OrLabel")}</h1>
                            <h1>{translate("SelectAFileLabel")}</h1>
                        </div>
                    </div>
                </div>
            </div>}
            {
            customerRegistration.length > 10 ?
                <div className="page-selector flex-row-nowrap space-between">
                    <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                        {
                            !mobile && <p>Previous</p>
                        }
                    </div>
                    <div className="pages-section flex-row-nowrap">
                        {
                            pages
                        }
                    </div>
                    <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        {
                            !mobile && <p>Next</p>
                        }
                        <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                    </div>
                </div> :
                null
        }
        </div>);
}

export default CustomerRegistration;