import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function OrderLine(props) {

    const orders = props.orders;
    const [order, setOrder] = useState({ charge_id: "", gross_price: 0, service_dept: "", quantity: 1 });
    const nbOfOrders = orders.length;
    const errorList = props.errorList;

    useEffect(() => {
        props.order ? setOrder(props.order) : null
    }, [props])


    function handleBlur(e){
        let new_value = null
        const { name, value } = e.target;
        new_value = value.trim().replace(/\s+/g, " ");

        props.selectedId({ ...order, [name]: new_value }, props.rowId);
    }


    function handleChange(e) {
        let new_value = null
        const { name, value } = e.target;
        new_value = value
        if (name.includes('price')) {
            new_value = new_value.replace(".", "")
            new_value = parseInt(new_value)
            if (isNaN(new_value)) new_value = ""
            else new_value = parseFloat(parseInt(new_value)/100).toFixed(2)
        } else if(name === "charge_id"){
            new_value = new_value.toUpperCase()
        }
        else new_value = new_value
        props.selectedId({ ...order, [name]: new_value }, props.rowId);
    };

    function plusButton() {
        props.addRow(props.rowId)
    }

    return <div className="flex md:flex-row gap-2 w-full md:w-11/12 m-auto wrap-override flex-col justify-center align-end">
        <div className="flex flex-col gap-x-1 mt-3 md:w-1/5 w-full">
            <p className='mb-2'>Charge ID</p>
            <input
                placeholder="Charge ID"
                type="text"
                name="charge_id"
                value={order ? order.charge_id : ''}
                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${props.error && order && !order.charge_id ? "border border-amber-900" : "border-0"}`}
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
            />
            <p className="mt-1 px-1 text-sm text-red-600 font-bold">{errorList.orders_lines && errorList.orders_lines[props.rowId] && errorList.orders_lines[props.rowId].charge_id}</p>
        </div>
        <div className="flex flex-col gap-x-1 mt-3 md:w-1/5 w-full">
            <p className='mb-2'>Quantity</p>
            <input
                placeholder="Quantity"
                type="number"
                name="quantity"
                value={order ? order.quantity : ''}
                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${props.error && order && !order.quantity[props.rowId].quantity ? "border border-amber-900" : "border-0"}`}
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
            />
            <p className="mt-1 px-1 text-sm text-red-600 font-bold">{errorList.orders_lines && errorList.orders_lines[props.rowId] && errorList.orders_lines[props.rowId].quantity}</p>
        </div>
        <div className="flex flex-col gap-x-1 mt-3 md:w-1/5 w-full">
            <p className='mb-2'>Unit Gross Price</p>
            <input
                placeholder="0.00"
                type="text"
                name="gross_price"
                value={order ? order.gross_price : ''}
                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${props.error && order && !order.gross_price[props.rowId].gross_price ? "border border-amber-900" : "border-0"}`}
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
            />
            <p className="mt-1 px-1 text-sm text-red-600 font-bold">{errorList.orders_lines && errorList.orders_lines[props.rowId] && errorList.orders_lines[props.rowId].gross_price}</p>
        </div>
        <div className="flex flex-col gap-x-1 mt-3 md:w-1/5 w-full">
            <p className='mb-2'>Service Dept</p>
            <input
                placeholder="Service Department"
                type="text"
                name="service_dept"
                value={order ? order.service_dept : ''}
                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${props.error && order && !order.service_dept[props.rowId].service_dept ? "border border-amber-900" : "border-0"}`}
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
            />
            <p className="mt-1 px-1 text-sm text-red-600 font-bold">{errorList.orders_lines && errorList.orders_lines[props.rowId] && errorList.orders_lines[props.rowId].service_dept}</p>
        </div>
        <div className="flex flex-col gap-x-1 mt-3 md:w-1/5 w-full">
            <p className='mb-2'>Total Gross Price Per Line</p>
            <input
                placeholder="0"
                type="number"
                disabled={true}
                value={order ? (parseInt(order.quantity) * parseFloat(order.gross_price)).toFixed(2) : '0'}
                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${props.error && order && !order.quantity[props.rowId].quantity ? "border border-amber-900" : "border-0"}`}
            />
            <p className="mt-1 px-1 text-sm text-red-600 font-bold">{errorList.orders_lines && errorList.orders_lines[props.rowId] && errorList.orders_lines[props.rowId].quantity}</p>
        </div>
        <div className="pb-1 flex-row items-end justify-center md:justify-between gap-x-2 mt-3 md:w-1/12 w-full wrap-override">
            <button
                className={`bg-gray-200 rounded flex-row h-10 w-10  align-center justify-center ${(nbOfOrders < 2 && props.rowId === 0) && "bg-gray-400"}`}
                onClick={() => { props.orders.length > 1 ? props.deleteRow(props.rowId) : null }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-minus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
            </button>
            <button
                className="bg-gray-200 rounded flex-row h-10 w-10  align-center justify-center"
                onClick={() => plusButton()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
            </button>
        </div>
    </div>
}

export default OrderLine; 