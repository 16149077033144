import React from "react";
import Status from "../Status";
import Loader from "../Loader";
import './style.css'

function LineSplitDetails(props) {
    const orderDetails = props.orderDetails;
    const statusRanking = new Map([
        ['RPI', [0, 'Processo de Faturamento']],
        ['PIC', [1, 'Em Separação']],
        ['ALL', [2, 'Processando Pagamento']],
        ['SHS', [3, 'Importação em Trânsito']],
        ['ENT', [4, 'Sem Previsão de Entrega']],//yellow
        ['BOK', [5, 'Entrega Programada']],
        ['RBK', [6, 'Entrega Reprogramada']], //yellow
        ['REJ', [7, 'Rejeitado']], //red
        ['INV', [8, 'Faturado']],
        ['CAN', [9, 'Cancelado']]
    ]);

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    var tooltip = document.getElementsByClassName('tooltiptext');

    window.onmousemove = function (e) {
        var x = e.clientX,
            y = e.clientY;
        for (var i = 0; i < tooltip.length; i++) {
            tooltip[i].style.top = (y - 60) + 'px';
            tooltip[i].style.left = (x - 120) + 'px';
        }
    };

    return (
        <div id="details" style={{ display: props.doDisplay ? "" : "none" }} className="mt-5">
            <p>Line/Split Details</p>
            <div className="w-full md:overflow-hidden overflow-auto">

                <div id="resultTable" className="flex-col justify-between mt-2">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-1/12 text-center">Line</div>
                        <div className="w-1/12 text-center">Order Line Split</div>
                        <div className="w-1/12 text-center">SKU</div>
                        <div className="w-1/12 text-center">Quantity</div>
                        <div className="w-3/12 text-center">Invoice Number</div>
                        <div className="w-1/12 text-center">Billing Expected Date</div>
                        <div className="w-2/12 text-center">Billing Date</div>
                        <div className="w-2/12 text-center">Status</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!orderDetails ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        orderDetails.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            orderDetails.map((order, index) => {
                                return <div id="rowDetail" className={`tooltip bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5 ${!order.exists_az && "line-red cursor-pointer"} ${!order.FINANCE && order.exists_az && order.LINE_STATUS == "INV" && "line-red cursor-pointer"}`} key={index + "line"}>
                                    <div className="w-1/12 text-center">{order.ORDER_NUMBER}</div>
                                    <div className="w-1/12 text-center">{order.ORDER_SPLIT}</div>
                                    <div className="w-1/12 text-center">{order.PRODUCT_ID}</div>
                                    <div className="w-1/12 text-center">{order.ACCEPTED_QUANTITY}</div>
                                    <div className="w-3/12 text-center truncate">{order.FINANCE && order.FINANCE.INVOICE_NUMBER}</div>
                                    <div className="w-1/12 text-center">{order.ACCEPTED_DELIVERY_DATE && order.ACCEPTED_DELIVERY_DATE.toString().substring(6, 8) + "/" + order.ACCEPTED_DELIVERY_DATE.toString().substring(4, 6) + "/" + order.ACCEPTED_DELIVERY_DATE.toString().substring(0, 4)}</div>
                                    <div className="w-2/12 text-center">{order.FINANCE && databaseToDate(order.FINANCE.INVOICE_DATE)}</div>
                                    <div className="w-2/12 text-center flex justify-center">
                                        <Status
                                            key={index + "status"}
                                            currentStatus={statusRanking.get(order.LINE_STATUS)[1]}
                                            clickable="false" />
                                    </div>
                                    {
                                        !order.FINANCE && order.exists_az && order.LINE_STATUS == "INV" &&
                                        <span className="tooltiptext">Invoice Number not found</span>
                                    }
                                    {
                                        !order.exists_az &&
                                        <span className="tooltiptext">SKU not registered in AZs</span>
                                    }
                                </div>
                            })}
                </div>
            </div>
        </div>

    )
}


export default LineSplitDetails;

