import React, { useState, useEffect, useContext } from "react";
import Status from "../../components/Status";
import CatalogSearchBar from "../../components/CatalogSearchBar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import Swal from 'sweetalert2';
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import { useParams, useHistory, Link } from "react-router-dom";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';


function EditIndustrial() {

    const { id } = useParams();
    const history = useHistory();
    const [productDetails, setProductDetails] = useState(null);
    const context = useContext(AppContext);
    console.log(id);

    let cancelToken = API.cancelToken();

    function handleChange(event) {
        const { name, value } = event.target;
        setProductDetails(preValue => {
            return {
                ...preValue,
                [name]: value
            }
        });
        console.log(productDetails)
    }

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getIndustrialCatalogProduct(cancelToken, id).then(res => {
            res.data ? setProductDetails(res.data.product) : setProductDetails([]);
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []
    );

    function saveTranslation(e){
        Swal.fire({
            title:'Saving translation...',
            showConfirmButton: false,    
        })
        console.log(e.target.id)
        let target = e.target.id
        var value = ""
        if (target == "description_en"){
            value = productDetails.description_en
        }
        if (target == "description_es"){
            value = productDetails.description_es
        }
        if (target == "description_pt"){
            value = productDetails.description_pt
        }
        API.postIndustrialCatalogTranslation(cancelToken, id, e.target.id, value).then(res => {
            Swal.fire({
                title: 'Success',
                text: 'Translation sucessfully saved.',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });

    }

    return (


        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title={productDetails && productDetails.skf_product_id}
                link=""
                hasButton="false" />
            { productDetails ?
                <div className="bg-gray-100 px-8 py-4 rounded-2xl">
                    <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">
                        <div className="flex flex-col w-full md:w-1/5 my-2">
                            <label className="py-1">Price List</label>
                            <input name="priceList" disabled={true} placeholder={productDetails && productDetails.origin} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                        </div>
                        <div className="flex flex-col w-full md:w-1/5 my-2">
                            <label className="py-1">SKU</label>
                            <input name="sku" disabled={true} placeholder={productDetails && productDetails.skf_product_id} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                        </div>
                        <div className="flex flex-col w-full md:w-1/5 my-2">
                            <label className="py-1">XML</label>
                            <input name="xml" disabled={true} placeholder={productDetails && productDetails.origin} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                        </div>
                    </div>
                    <div className="p-1 mt-4 text-l">Product Information</div>
                    <div className="flex flex-col rounded-lg border-2 px-8 py-4">
                        <label>Original</label>
                        <div className="flex flex-col gap-y-2 gap-x-4 my-2 md:flex-row">
                            <input disabled={true} className="px-2 h-10 w-full md:w-5/6 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" defaultValue = {productDetails && productDetails.description}/>
                            <div className="md:w-1/6"></div>
                        </div>
                        <label className="mt-2">English</label>
                        <div className="flex flex-col gap-y-2 gap-x-4 my-2 md:flex-row">
                            <input className="px-2 h-10 w-full md:w-5/6 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" name="description_en" onChange={handleChange} defaultValue = {productDetails && productDetails.description_en} />
                            <button className="h-10 w-1/2 md:w-1/6 bg-gray-700 rounded-md" id="description_en" onClick={saveTranslation}>Save</button>
                        </div>
                        <label className="mt-2">Spanish</label>
                        <div className="flex flex-col gap-y-2 gap-x-4 my-2 md:flex-row">
                            <input className="px-2 h-10 w-full md:w-5/6 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" name="description_es" onChange={handleChange} defaultValue = {productDetails && productDetails.description_es}/>
                            <button className="h-10 w-1/2 md:w-1/6 bg-gray-700 rounded-md" id="description_es" onClick={saveTranslation}>Save</button>
                        </div>
                        <label className="mt-2">Portuguese</label>
                        <div className="flex flex-col gap-y-2 gap-x-4 my-2 md:flex-row">
                            <input className="px-2 h-10 w-full md:w-5/6 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" name="description_pt" onChange={handleChange} defaultValue = {productDetails && productDetails.description_pt}/>
                            <button className="h-10 w-1/2 md:w-1/6 bg-gray-700 rounded-md" id="description_pt" onClick={saveTranslation}>Save</button>
                        </div>
                    </div>
                </div>:
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div>
            }
        </div>


    )
}

export default EditIndustrial;