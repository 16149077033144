import React from "react";


export default function UploadFileField(props) {

    return <div className={`flex-col w-full md:w-full md:mr-2 md:mt-0 mt-4`}>
        <p>{props.label}</p>
        <input disabled={props.disabled} onChange={(e) => props.setValue(e)} class={`block w-full h-10 mt-2 text-base text-[#2D4560] rounded-lg cursor-pointer ${props.error ? "border border-amber-900" : "border-0"} bg-[#0C1825] file:cursor-pointer file:bg-[#08121D] file:text-[#FFF] file:h-10 file:border-0 dark:text-[#2D4560] focus:outline-none dark:bg-[#0C1825] dark:placeholder-[#2D4560]`} aria-describedby="file_input_help" id="file_input" type="file" />
    </div>
}

