import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import SortableTable from "../../components/SortableTable";
import { Link, useHistory, useParams } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import Loader from "../../components/Loader";

function CapgeminiTask() {
    const { id } = useParams();
    const [orderResume, setOrderResume] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [downloadingNF, setDownloadingNF] = useState(false);
    const [orderLineDetails, setOrderLineDetails] = useState(null);
    const [nf, setNF] = useState(false);
    const context = useContext(AppContext);
    const history = useHistory();
    const [openHistoryPending, setOpenHistoryPending] = useState(null)
    const [listOfOrder, setListOfOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false)
    const [openHistory, setOpenHistory] = useState(null)


    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }


    let cancelToken = API.cancelToken();
    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getCapgeminiTask(cancelToken, id).then(res => {
            console.log(res.data)
            res.data ? setOrderResume(res.data.order) : setOrderResume(null);
            res.data ? setOrderDetails(res.data.order.nfs) : setOrderDetails([]);
            res.data ? setOrderStatus(res.data.order.order_finance_status === 0 ? "Em Processamento" : "Pedido Concluído") : setOrderStatus('NOT ABLE TO FETCH');
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [id]
    );

    const handleShowHistoryPending = () => {
        if (openHistoryPending === true) setOpenHistoryPending(null)
        else setOpenHistoryPending(true)
    }

    useEffect(() => {
        console.log("**************")
        console.log(orderResume)
    }, [orderResume])

    const handleNextStatus = (orderId) => {
        Swal.fire({
            title: `Atenção!`,
            html: "<hr><p class='mt-3'>Essa tarefa será convertida para NF-e baixada. Você confirma esta ação?</p>",
            showCancelButton: true,
            confirmButtonText: 'SIM',
            confirmButtonColor: "#289067",
            cancelButtonText: 'NÃO',
            cancelButtonColor: "#81263B",
            customClass: { container: 'capgemini-swal' }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                console.log("UPDATING")
                API.updateDownloadedInvoice(cancelToken, orderId).then(res => {
                    console.log(res)
                    Swal.fire({
                        title: 'Success.',
                        text: 'Status sucessfully updated.',
                        confirmButtonColor: "#1e2b3c",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                    let tempDetails = [...orderDetails]
                    var found = tempDetails.filter(item => (item.order_finance_nf_id === orderId));
                    found[0].action_status = res.data.timestamp
                    found[0].user = res.data.user
                    setOrderDetails(tempDetails)
                }).catch(err => {
                    if (err.message === 'cancelling') return
                    if (err.response && err.response.status === 500) {
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            confirmButtonColor: "#1e2b3c",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                })
            }
        })
    }

    useEffect(()=>{
        console.log(orderDetails)
    },[orderDetails])


    return (
        <div className="flex-col md:p-10 p-5 capgemini-task-page height-limiter">
            <div className="flex-row justify-between">
                <h1 className="text-2xl">Capgemini Task - {orderResume && orderResume.skf_order_id} </h1>
                <Status
                    currentStatus={orderStatus && orderStatus}
                    clickable="false" />
            </div>

            <div className="relative mt-7">
                {openHistoryPending !== null &&
                    <div className="absolute right w-full h-full left-0 top-0 z-50 flex justify-center align-center" onClick={() => setOpenHistoryPending(null)}>
                        <div className="flex-col justify-center align-center custom-color w-fit">
                            <div className="flex flex-nowrap justify-between text-xs lg:text-base font-bold w-fit w-fit p-3">
                                <div className="col-width-force text-center">Status</div>
                                <div className="col-width-force text-center">Timestamp</div>
                                <div className="col-width-force text-center">User</div>
                            </div>
                            <div className="flex-col justify-center align-center custom-color w-fit max-h-36 overflow-y-auto">

                                {
                                    orderResume.tasks.map((e, index) => (

                                        <div key={"history" + index + e.status} className="flex flex-nowrap justify-between text-xs lg:text-base w-fit w-fit px-3 py-1">
                                            <div className="col-width-force text-center">{e.task_type}</div>
                                            <div className="col-width-force text-center" >{e.updated_at}</div>
                                            <div className="col-width-force text-center">{e.task_user}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
                <h3 className="mb-3">Resume</h3>
                <SortableTable headers={
                    [{ name: 'Registration Date', sorts: 'created_at' },
                    { name: 'COH Customer ID', sorts: 'skf_id' },
                    { name: 'COH Order ID', sorts: 'name' },
                    { name: 'Total Paid', sorts: 'client_order_id' },
                    { name: 'Total Deposit', sorts: 'payment_method' },
                    { name: 'Deposit Timestamp', sorts: 'payment_method' },
                    { name: 'Amount Paid by Cost Center', sorts: 'payment_method' },
                    { name: 'Cost Center Timestamp', sorts: 'payment_method' },
                    { name: 'Total Chargeback', sorts: 'payment_method' },
                    { name: 'Chargeback Timestamp', sorts: 'payment_method' },
                    { name: 'Execution History', sorts: 'payment_method' }]}
                    data={
                        orderResume ?
                            <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-nowrap py-5 text-xs lg:text-base pl-5 w-fit">
                                <div className="col-width-force text-center">{databaseToDate(orderResume.created_at)}</div>
                                <div className="col-width-force text-center">{orderResume.skf_id}</div>
                                <div className="col-width-force text-center">{orderResume.skf_order_id}</div>
                                <div className="col-width-force text-center">{orderResume.total_paid === null ? "-" : orderResume.total_paid}</div>
                                <div className="col-width-force text-center">{orderResume.total_deposited === null ? "-" : orderResume.total_deposited}</div>
                                <div className="col-width-force text-center">{orderResume.deposit_timestamp === null ? "-" : orderResume.deposit_timestamp}</div>
                                <div className="col-width-force text-center">{orderResume.amount_paid_by_cost_center === null ? "-" : orderResume.amount_paid_by_cost_center}</div>
                                <div className="col-width-force text-center">{orderResume.cost_center_timestamp === null ? "-" : orderResume.cost_center_timestamp}</div>
                                <div className="col-width-force text-center">{orderResume.total_chargeback === null ? "-" : orderResume.total_chargeback}</div>
                                <div className="col-width-force text-center">{orderResume.chargeback_timestamp === null ? "-" : orderResume.chargeback_timestamp}</div>
                                <div className={`col-width-force text-center ${openHistoryPending ? "rotate-180" : ""}`} onClick={handleShowHistoryPending}><img src={require('../../assets/images/tooltip.png')} alt="previous" /></div>
                            </div>
                            :
                            <p className="mt-5 ml-5">No data found</p>
                    }
                    dataSet={listOfOrder}
                    setDataSet={(value) => handleSort(value)}
                    loading={loading} />


                <h3 className="mb-3">Details</h3>
                <div className="sortable-table flex-row p-1 w-full">
                    {!orderDetails || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        <div id="resultTable" className="flex-col justify-center overflow-x-scroll w-full">
                            <div className="bg-gray-200 flex flex-nowrap justify-between text-xs lg:text-base pt-4 font-bold w-full">
                                <div className="w-1/2 text-center border-b border-slate-700 mx-2 pb-2">Invoice</div>
                                <div className="w-1/2 text-center border-b border-slate-700 mx-2 pb-2">Capgemini</div>
                            </div>
                            <div id="tableHeader" className="bg-gray-200 flex flex-nowrap justify-between py-3 text-xs lg:text-base pl-5 font-bold w-full">
                                <div className="pointer w-2/12 text-center">Number</div>
                                <div className="pointer w-2/12 text-center">Timestamp</div>
                                <div className="pointer w-2/12 text-center">Status</div>
                                <div className="pointer w-2/12 text-center">Timestamp (last update)</div>
                                <div className="pointer w-2/12 text-center">User</div>
                                <div className="pointer w-2/12 text-center">Checklist</div>
                            </div>
                            <div id="resultBody" className="table-height-limiter overflow-y-scroll w-full">
                                {
                                    orderDetails ?
                                        (orderDetails.length > 0 ?
                                            orderDetails.map((order, index) => {
                                                return <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-nowrap py-5 text-xs lg:text-base pl-5 w-full" key={index + 'order'}>
                                                    <div className="w-2/12 text-center">{order.nf_number}</div>
                                                    <div className="w-2/12 text-center">{databaseToDate(order.nf_timestamp)}</div>
                                                    <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="w-2/12 text-center">
                                                        <Status
                                                            key={order.nf_number + 'orderStatus'}
                                                            id={order.nf_number}
                                                            currentStatus={order.action_status !== null ? "NF-e baixada" : "Baixar NF-e"}
                                                            clickable="true" />
                                                    </div>
                                                    <div className="w-2/12 text-center">{databaseToDate(order.action_status)}</div>
                                                    <div className="w-2/12 text-center">{order.user}</div>
                                                    <div className="w-2/12 text-center pointer" onClick={() => order.action_status === null ? handleNextStatus(order.order_finance_nf_id) : null}>
                                                    <input type="checkbox" name="" checked={order.action_status !== null} />
                                                    </div>
                                                </div>
                                            })
                                            :
                                            <p className="m-5">No data found</p>
                                        )
                                        :
                                        null
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>);
}

export default CapgeminiTask; 