import React, { useState, useRef, useEffect, useContext } from "react";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import './style.css';
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import GlobalSVG from "../../utils/GlobalSVG";
import { useLanguage } from '../../context/LanguageContext';

function ListOfPartners() {
    const [listOfPartners, setListOfPartners] = useState([]);
    const [filteredPartners, setFilteredPartners] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const valueRef = useRef();
    const fieldRef = useRef();
    const context = useContext(AppContext);
    const history = useHistory();
    let cancelToken = API.cancelToken();
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [isSearch, setIsSearch] = useState(false);
    const [reset, setReset] = useState(false);
    const [error, setError] = useState(false);
    const { translate } = useLanguage();

    const searchFields = [
        { fieldValue: "name", fieldName: "Name" },
        { fieldValue: "phone_number", fieldName: "Phone Number" },
        { fieldValue: "email", fieldName: "Email" },
        { fieldValue: "cnpj", fieldName: "CNPJ" }
    ];

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        setMobile(window.innerWidth <= 768);
        hotjar.initialize('3361828');
        ReactGA.pageview(window.location.pathname);

        setLoading(true);
        cancelToken = API.cancelToken();

        API.getListOfPartners(cancelToken, currentPage).then(res => {
            const partners = res.data.partners || [];
            setListOfPartners(partners);
            setFilteredPartners(partners);
            setMaxPages(res.data.pagination.total_pages);
        }).catch(err => {
            setListOfPartners([]);
            if (err.message === 'cancelling') return;
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(() => {
            setLoading(false);
        });

        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage]);

    useEffect(() => {
        let pages = [];
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>);
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>);
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>);
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + (currentPage - 1)} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>);
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>);
                    pages.push(<div key={'page' + (currentPage + 1)} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>);
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>);
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>);
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>);
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>);
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>);
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>);
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>);
                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)}>{maxPages}</div>);
        }

        setPages(pages);
    }, [maxPages, currentPage]);

    const doReset = () => {
        window.location.reload();
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const value = valueRef.current.value.toLowerCase();
    
        if (!value) {
            setError(true);
            return;
        }
    
        // Fields to search in, based on your searchFields array
        const searchableFields = ["name", "phone_number", "email", "cnpj"];
    
        // Filter partners based on matching any of the searchable fields
        const filtered = listOfPartners.filter(partner => 
            searchableFields.some(field =>
                partner[field] && partner[field].toString().toLowerCase().includes(value)
            )
        );
    
        setFilteredPartners(filtered);
        setError(false);
        setIsSearch(true);
    }

    const handleDelete = (distributor) => {
        Swal.fire({
            title: 'Tem certeza que deseja remover este partner?',
            text: "Esta ação não pode ser revertida!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1e2b3c',
            cancelButtonColor: '##81263B',
            confirmButtonText: 'Sim, remover!',
        }).then((result) => {
            Swal.fire({
                title: 'Removendo...',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                }
            });
            if (result.isConfirmed) {
                let cancelToken = API.cancelToken();
                console.log(distributor.email)
                API.deletePartner(cancelToken, distributor).then(res => {
                    Swal.fire(
                        'Removido!',
                        'O partner foi removido com sucesso.',
                        'success'
                    ).then(() => {
                        window.location.reload();
                    });
                }).catch(err => {
                    Swal.fire(
                        'Error!',
                        'There was an error deleting the partner.',
                        'error'
                    );
                });
            }
        });
    }

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[2]}/${date[1]}/${date[0]}`;
    }

    return (
        <div className="flex-col md:p-10 p-5 distributors-page height-limiter">
            <ScreenHeader
                title={translate('ListOfPartners')}
                link="../addpartners"
                buttonDescription={translate('AddPartner')}
                svg="listOfPartners"
                hasButton="true" />

            <SearchBar
                searchFields={searchFields}
                hideField={true}
                valueRef={valueRef}
                fieldRef={fieldRef}
                onSearch={handleSearch}
                clicked={isClicked}
                error={error}
                reset={doReset} />

            <div className="text-xs lg:text-base overflow-auto">
                <div id="resultTable" className="flex-col justify-between w-full">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 pl-5 font-bold w-full">
                        <div className="w-1/12 text-center"></div>
                        <div className="w-2/12 text-center">{translate('CreatedAtLabel')}</div>
                        <div className="w-2/12 text-center">{translate('Partner')}</div>
                        <div className="w-2/12 text-center">{translate('PhoneNumberLabel')}</div>
                        <div className="w-2/12 text-center">Email</div>
                        <div className="w-2/12 text-center">CNPJ</div>
                        <div className="w-1/12 text-center"></div>
                    </div>
                </div>
                <div id="resultBody" className="">
                    {!filteredPartners || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        filteredPartners.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            filteredPartners.map((distributor, index) => {
                                return (
                                    <div>
                                        <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 pl-5">
                                            <div className="w-1/12 text-center">{index+1}</div>
                                            <div className="w-2/12 text-center">{databaseToDate(distributor.created_at)}</div>
                                            <div className="w-2/12 text-center">{distributor.name}</div>
                                            <div className="w-2/12 text-center">{distributor.phone_number}</div>
                                            <div className="w-2/12 text-center truncate">{distributor.email}</div>
                                            <div className="w-2/12 text-center">{distributor.cnpj}</div>
                                            <div className="w-1/12  align-center justify-center flex flex-row flex-nowrap">
                                                <Link to={`./partner/${distributor.service_partner_user_id}`} className="w-5 h-5 cursor-pointer mr-5">
                                                    {GlobalSVG.pencil()}
                                                </Link>
                                                <div className="w-5 h-5 cursor-pointer mr-5" title="Delete" onClick={() => handleDelete(distributor)}>
                                                    {GlobalSVG.garbage()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                </div>
            </div>
        </div>
    );
}

export default ListOfPartners;
