import React, { useEffect, useRef, useState, useContext } from "react";
import { useLanguage } from '../../context/LanguageContext';

function FilterMenu({ onFilterChange, onFilterTypeChange, onFilterDemoChange, onShowFilterMenu, exportTable}) {
    const [demoAccount, setDemoAccount] = useState(localStorage.getItem('demo') === 'true' ? true : false);
    const [status, setStatus] = useState(localStorage.getItem('country') ? localStorage.getItem('country') : '');
    const [status2, setStatus2] = useState('');
    const [status3, setStatus3] = useState('');
    const { translate } = useLanguage();


    useEffect(() => {
        onFilterChange({ status: demoAccount });
    },[demoAccount])
    

    useEffect(() => {
        console.log('Current demo account:', demoAccount)
    },[])
    
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
        // setStatus2('');
        onFilterChange({ status: e.target.value });
      };
    
    const handleStatusChange2 = (e) => {
        setStatus2(e.target.value);
        onFilterTypeChange({ status: e.target.value });
      };

    const handleStatusChange3 = () => {
        var new_demo = !demoAccount;
        setStatus3(new_demo);
        onFilterDemoChange({ status: new_demo });
        setDemoAccount(new_demo);
      };

    const showFilter = () => {
        onShowFilterMenu()
      };
    
    const exportTableAction = () => {
        exportTable()
      };

    return (
        <div className="flex w-full justify-end gap-5 self-stretch px-11 max-md:flex-wrap max-md:px-5 mb-5">
            <div className="flex gap-2 pl-20 max-md:flex-wrap">
                <div className="my-auto text-sm leading-5 text-white">{translate('filters')}</div>
                <div className="flex gap-4 max-md:flex-wrap">
                    <select name="searchFields" onChange={handleStatusChange} value={localStorage.getItem('country') ? localStorage.getItem('country') : status}
                        className={`flex flex-col justify-center py-1 pr-4 pl-4 text-base leading-5 text-white whitespace-nowrap rounded-md bg-slate-800 max-md:pl-5`}>
                        <option value={''} key='sel1'>{translate('countrySelection')}</option>;
                        <option value={'BRA'} key='BRA'>BRA</option>;
                        <option value={'ARG'} key='ARG'>ARG</option>;
                    </select>
                    <select  name="searchFields" onChange={handleStatusChange2} value={localStorage.getItem('sb') ? localStorage.getItem('sb') : status2}
                        className={`flex flex-col justify-center py-1 pr-4 pl-4 text-base leading-5 text-white whitespace-nowrap rounded-md bg-slate-800 max-md:pl-5`}>
                        <option value={''} key='sel2'>{translate('subBusiness')}</option>;
                        {
                            status === "ARG" ?
                                <option value={'0'} key='0'>Argentina E-Commerce</option>
                            :
                            <>
                                <option value={'1'} key='1'>Bearing Tracker</option>;
                                <option value={'2'} key='2'>MS Dynamics</option>;
                                <option value={'VA Distributor'} key='VA Distributor'>VA Distributor</option>;
                                <option value={'SKF College'} key='SKF College'>SKF College</option>;
                                <option value={'SKF Center'} key='SKF Center'>SKF Center</option>;
                                <option value={'Two Wheeler'} key='Two Wheeler'>Two Wheeler</option>;
                            </>
                        }
                        {/* <option value={'0'} key='0'>E-Commerce</option>; */}
                    </select>
                    {
                        demoAccount === true ?
                        <div className="flex gap-2 my-auto">
                            <div className="flex flex-col justify-center items-start py-0.5 pl-3.5 my-auto bg-emerald-600 rounded-2xl" onClick={() => handleStatusChange3()}>
                                <div className="shrink-0 w-3 h-3 bg-white rounded-2xl" />
                            </div>
                            <div className="text-base leading-6 text-white">
                                {translate('DemoLabel')}
                            </div>
                        </div>
                        :
                        <div className="flex gap-2 my-auto">
                            <div className="flex flex-col justify-center items-start py-0.5 pr-0.5 pr-3.5 my-auto bg-[#43454F] rounded-2xl" onClick={() => handleStatusChange3()}>
                                <div className="shrink-0 w-3 h-3 bg-white rounded-2xl" />
                            </div>
                            <div className="text-base leading-6 text-white">
                                {translate('DemoLabel')}
                            </div>
                        </div>
                    }
                </div>
            </div>
            {/* <div className="my-auto text-base leading-5 text-neutral-400">|</div> */}
            <div className="flex gap-2.5 text-base font-medium leading-5 text-white">
                <div className="flex flex-col justify-center py-2 pr-6 pl-5 rounded-md bg-slate-800 pointer" onClick={exportTableAction}>
                    <div className="flex gap-2 justify-center">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2b7d1abb12a2eda73d477edc444429fbacbed7281bd0261ddf2f085309d436d4?"
                            className="shrink-0 my-auto w-4 aspect-square"
                        />
                        <div>{translate('exportLabel')}</div>
                    </div>
                </div>
                <div className="flex flex-col justify-center py-2 pr-6 pl-5 rounded-md bg-zinc-800 pointer" onClick={showFilter}>
                    <div className="flex gap-2 justify-center">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0e0bbf1617a5e75df110245c0d5dd4cf731ff40578d0156e7992c308ce0a2798?"
                            className="shrink-0 my-auto w-4 aspect-square pointer"
                        />
                        <div>{translate('editColumnLabel')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FilterMenu; 