import React, { useState, useEffect, useContext } from "react";
import Loader from "../../components/Loader";
import './style.css'
import InputField from "../../components/InputField";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom";
import RadioButtonField from "../../components/RadioButtonField";
import SelectField from "../../components/SelectField";
import CheckBoxesField from "../../components/CheckBoxesField";
import ReactDatePicker from "react-datepicker";
import InputNumberField from "../../components/InputNuberField";
import UploadFileField from "../../components/UploadFileField";
import Swal from "sweetalert2";
import Status from "../../components/Status";


function AddBanner(props) {
    const [bannerName, setBannerName] = useState()
    const [segment, setSegment] = useState()
    const [bannerType, setBannerType] = useState()
    const [sequenceSite, setSequenceSite] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [devices, setDevices] = useState({ "All devices": false, "Desktop": false, "Mobile": false, "Tablet/iPad": false })
    const [displayTime, setDisplayTime] = useState()
    const [redirectHyperlink, setRedirectHyperlink] = useState()
    const [bannerFile, setBannerFile] = useState(null)
    const [type, setType] = useState("add")
    const [errorForm, setErrorForm] = useState({
        resp: false,
        bannerName: false,
        segment: false,
        bannerType: false,
        sequenceSite: false,
        displayTime: false,
        redirectHyperlink: false,
        devices: false,
        startDate: false,
        endDate: false,
        bannerFile: false
    })
    const { location } = props;

    useEffect(() => {

        Swal.fire({
            title: 'Attention',
            text: "You don't have permission to access this page.",
            confirmButtonColor: "#1e2b3c",
            icon: 'warning',
            confirmButtonText: 'OK',
            allowEscapeKey: false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                history.push('/banners')
            }
        });

    if (location.state && location.state.type && location.state.type !== "add") {
        setBannerName(location.state.name)
        setSegment(location.state.segment)
        setBannerType(location.state.bannerType)
        setSequenceSite(location.state.sequence)
        setDisplayTime(location.state.displayTime)
        setRedirectHyperlink(location.state.redirectHyperlink)
        setDevices(location.state.devices)
        setStartDate(new Date(location.state.startDate))
        setEndDate(new Date(location.state.endDate))
        setBannerFile(location.state.bannerFile)
    }
    if (location.state.type) {
        setType(location.state.type)
    }
}, [])
const history = useHistory()
const validationForm = () => {
    let error = {
        resp: false,
        bannerName: false,
        segment: false,
        bannerType: false,
        sequenceSite: false,
        displayTime: false,
        redirectHyperlink: false,
        devices: false,
        startDate: false,
        endDate: false,
        bannerFile: false
    }
    if (!bannerName || bannerName.length <= 0) {
        error.bannerName = true
        error.resp = true
    }
    if (!segment || segment.length <= 0) {
        error.segment = true
        error.resp = true
    }
    if (!bannerType || bannerType.length <= 0) {
        error.bannerType = true
        error.resp = true
    }
    if (!sequenceSite || sequenceSite.length <= 0) {
        error.sequenceSite = true
        error.resp = true
    }
    if (!redirectHyperlink || redirectHyperlink.length <= 0) {
        error.redirectHyperlink = true
        error.resp = true
    }
    if (!displayTime || displayTime.length <= 0) {
        error.displayTime = true
        error.resp = true
    }
    if (!devices || devices.length <= 0) {
        error.devices = true
        error.resp = true
    }
    if (devices) {
        var checked = false
        for (let key in devices) {
            if (devices[key] == true) {
                checked = true
            }
        }
        if (checked == false) {
            error.devices = true
            error.resp = true
        }

    }
    if (!startDate || startDate.length <= 0) {
        error.startDate = true
        error.resp = true
    }
    if (!endDate || endDate.length <= 0) {
        error.endDate = true
        error.resp = true
    }
    if (!bannerFile || bannerFile.length <= 0) {
        error.bannerFile = true
        error.resp = true
    }
    setErrorForm(error)
    return error.resp
}
const future = (date) => new Date().setDate(new Date().getDate()) < date;

const handleChange = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
};

const handleImageChange = (e) => {
    // Get the file extension
    const file = e.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();

    if (fileExtension !== 'png') {
        setErrorForm({ ...errorForm, bannerFile: true })
        Swal.fire({
            title: 'Error.',
            text: 'A PNG file is required.',
            confirmButtonColor: "#1e2b3c",
            icon: 'warning',
            confirmButtonText: 'Ok'
        });
        return;
    } else {
        setBannerFile(URL.createObjectURL(file));
    }

};

const handleCreate = () => {

    Swal.fire({
        title: type == "add" ? "Do you want create this banner?" : "Do you want edit this banner?"
        ,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#1e2b3c"
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            Swal.fire({
                title: type == "add" ? 'Creating the banner...' : 'Editing banner...',
                showConfirmButton: false,
                allowOutsideClick: false
            })
            const error = validationForm()
            if (!error) {
                Swal.fire({
                    title: 'Success.',
                    text: type == "add" ? 'Your banner was successfully create.' : 'Your banner was successfully edit.',
                    showCancelButton: true,
                    cancelButtonText: "BACK TO BANNERS",
                    confirmButtonColor: "#1e2b3c",
                    icon: 'success',
                    confirmButtonText: 'ADD ANOTHER BANNER',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                        return;
                    } else {
                        history.push('/banners')
                        return;
                    }
                });
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please, fill the mandatory fields.',
                    confirmButtonColor: "#1e2b3c",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }
    }).catch(err => {
        if (err.message === 'cancelling') return
        if (err.response && err.response.status === 500) {
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                confirmButtonColor: "#1e2b3c",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        }
    })
}

const loading = true
return <div className="flex-col md:p-10 p-5 height-limiter">
    <div className="flex-row">
        {type == "add" && <h1 className="lg:text-2xl ">New Banner</h1>}
        {type == "edit" && <h1 className="lg:text-2xl ">{"Edit Banner - " + bannerName.toUpperCase()}</h1>}
        {type == "details" && <h1 className="lg:text-2xl ">{"BANNER - DETAILS - " + bannerName.toUpperCase()}</h1>}

    </div>
    {
        loading ? <>
            <div className="bg-gray-200 flex flex-col justify-center gap-6 items-end self-stretch rounded mt-10 text-xs lg:text-base md:p-10 p-8">
                <div className="flex flex-start gap-10 self-stretch">
                    <div className="flex w-1/3 flex-col items-start gap-2">
                        <InputField setValue={setBannerName} value={bannerName} error={errorForm.bannerName} name="banner-name" disabled={type == "details" ? true : false} label="Banner Name" />
                    </div>
                    <div className="flex w-1/3 flex-col items-start gap-2">
                        <RadioButtonField name={"sub_business_segment"} label={"Sub Business Segment"} error={errorForm.segment} value={segment} disabled={type == "details" ? true : false} list={["SKF Center", "2W", "VA Distributor"]} setValue={setSegment} />
                    </div>
                    {type == "details" && <div className="flex w-1/3 flex-row items-center justify-end gap-2">
                        <p>Banner Status</p>
                        <Status
                            key={'Status'}
                            id={"Status"}
                            currentStatus={"active"}
                            clickable="true" />
                    </div>}
                </div>
                <div className="flex flex-start gap-6 self-stretch">
                    <div className="flex w-auto gap-2">
                        <RadioButtonField name={"banner_type"} label={"Banner Type"} error={errorForm.bannerType} value={bannerType} disabled={type == "details" ? true : false} list={["Default", "Fixed"]} setValue={setBannerType} />
                    </div>
                    <div className="flex w-56 gap-2 border-1 border-red">
                        <SelectField label={"Sequence on site"} name={"sequence_on_site_select"} error={errorForm.sequenceSite} value={sequenceSite} disabled={type == "details" ? true : false} options={["1", "2", "3", "4", "5"]} setValue={setSequenceSite} />
                    </div>
                    <div className="flex w-auto gap-2">
                        <CheckBoxesField label={"Devices"} name={"devices"} list={devices} error={errorForm.devices} disabled={type == "details" ? true : false} setValue={setDevices} />
                    </div>
                    <div className="flex w-56 gap-2">
                        <div class="flex-col w-full gap-2">
                            <p>Start Date   {"  >  "}  End Date </p>
                            <ReactDatePicker
                                // selected={selectedDate ? new Date(selectedDate) : null}
                                selected={startDate}
                                error={errorForm.startDate}
                                // onChange={(value) => setDate(value.toISOString())}
                                placeholderText="yyyy/MM/dd - yyyy/MM/dd"
                                onChange={handleChange}
                                startDate={startDate}
                                endDate={endDate}
                                filterDate={future}
                                selectsRange
                                monthsShown={2}
                                disabled={type == "details" ? true : false}
                                showYearDropdown
                                dateFormat="yyyy/MM/dd"
                                className={`bg-[#0C1825] w-full col-span-3 lg:text-base placeholder-[#2D4560] px-5 py-2 rounded-md mt-2 text-base box-border ${errorForm.startDate || errorForm.endDate ? "border border-amber-900" : "border-0"}`} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-start gap-6 self-stretch">
                    <div className="flex w-36 gap-2">
                        <InputNumberField label={"Display time (seconds)"} error={errorForm.displayTime} disabled={type == "details" ? true : false} setValue={setDisplayTime} value={displayTime} placeholder="00" />
                    </div>
                    <div className="flex w-1/3 flex-col items-start gap-2">
                        <InputField setValue={setRedirectHyperlink} error={errorForm.redirectHyperlink} name="redirect-hyperlink" value={redirectHyperlink} disabled={type == "details" ? true : false} placeholder="http://example.com" label="Redirect hyperlink" />
                    </div>
                    <div className="flex w-1/3 flex-col items-start gap-2">
                        <UploadFileField label={"Correlated SKU's"} error={errorForm.bannerFile} disabled={type == "details" ? true : false} />
                    </div>
                </div>
                <div className="flex flex-start gap-6 self-stretch">
                    <div className="flex w-1/3 flex-col items-start py-10 gap-2">
                        <p className="font-bold text-lg leading-7">Select a file</p>
                        <UploadFileField setValue={handleImageChange} error={errorForm.bannerFile} disabled={type == "details" ? true : false} />
                    </div>
                </div>
                {bannerFile && <>
                    <div className="flex flex-start gap-6 self-stretch">
                        <div className="flex w-full flex-col items-start py-10 gap-2">
                            <p className="font-bold text-lg leading-7">Preview</p>
                            <p className="text-sm font-light leading-tight">Desktop (1024x768 a 1920x1080)</p>
                            <div className="flex h-auto w-full p-4 flex-col items-center gap-6 self-stretch">
                                <img src={bannerFile} alt="Uploaded" className=" h-full w-full" />
                            </div>

                        </div>
                    </div>
                    <div className="flex w-full flex-start gap-6 self-stretch">
                        <div className="flex flex-col w-2/3 items-start gap-2">
                            <p className="text-sm font-light leading-tight">Tablet/iPad (601x962 a 1280x800)</p>
                            <div className="flex h-auto p-4 flex-col items-center gap-6 self-stretch ">
                                <img src={bannerFile} alt="Uploaded" className=" h-full w-full" />

                            </div>
                        </div>
                        <div className="flex flex-col w-1/3 items-start gap-2">
                            <p className="text-sm font-light leading-tight">Mobile (360x640 a 414x896)</p>
                            <div className="flex h-auto w-full p-4 flex-col items-center gap-6 self-stretch ">
                                <img src={bannerFile} alt="Uploaded" className=" h-full w-full" />
                            </div>
                        </div>
                    </div>
                </>
                }
            </div>
            <div className="flex justify-end mt-4 flex-row gap-4" >
                <NavLink className="flex flex-row h-12 py-1 px-6 justify-center items-center gap-4 self-stretch rounded" to="/banners">
                    <p>CANCEL</p>
                </NavLink>
                {type == "add" && <button className="flex flex-row h-12 py-1 px-6 justify-center items-center gap-4 self-stretch rounded bg-[#1E2B3C]" onClick={handleCreate}>
                    <p>ADD BANNER</p>
                </button>}
                {type == "edit" && <button className="flex flex-row h-12 py-1 px-6 justify-center items-center gap-4 self-stretch rounded bg-[#1E2B3C]" onClick={handleCreate}>
                    <p>SAVE BANNER</p>
                </button>}
            </div>
        </>
            :
            <div className="loader-wrapper min-h-full min-w-full">
                <Loader light={true} />
            </div>
    }
</div>;
}

export default AddBanner;