import React, { useEffect, useState } from "react";
import DropDown from "../DropDown";

function CatalogSearchBar(props) {

    const [selectedValue, setSelectedValue] = useState('');
    const [selectedSecondValue, setSecondSelectedValue] = useState('');
    const [searchInput, setSearchInput] = useState('');

    // Set the initial value when the component mounts or when props.firstSearchFields changes
    useEffect(() => {
        if (props.firstSearchFields.length > 0) {
            setSelectedValue(props.firstSearchFields[0].fieldValue);
        }
    }, [props.firstSearchFields]);
    
    useEffect(() => {
        if (props.secondSearchFields.length > 0) {
            setSecondSelectedValue(props.secondSearchFields[0].fieldValue);
        }
    }, [props.secondSearchFields]);

    const handleReset = (e) => {
        console.log("resetting")
        e.preventDefault();
        if (props.firstSearchFields.length > 0) {
            setSelectedValue(props.firstSearchFields[0].fieldValue);
        }
        if (props.secondSearchFields.length > 0) {
            setSecondSelectedValue(props.secondSearchFields[0].fieldValue);
        }
        setSearchInput('');
        props.reset();

    }
    const handleChange = (e) => {
        setSelectedValue(e.target.value);
        setSecondSelectedValue(e.target.value);
    }

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    return (
        <form onSubmit={(e) => {
            props.onSearch(e);
        }}>
            <div id="searchBar" className="bg-gray-200 flex-row wrap-override h-30 my-6 p-6 rounded-md">
                <div className="w-full md:w-2/5 md:mr-2 mt-2 md:mt-0">
                    <input
                        name="search"
                        type="text"
                        className={`bg-blue col-span-3 md:text-base placeholder-white px-5 py-2 rounded-md  text-xs w-full box-border `}
                        placeholder="Type to search a product..."
                        value={searchInput}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="w-full md:w-1/5 md:mr-2 mt-2 md:mt-0">
                    <select name="firstField" value={selectedValue} onChange={handleChange} className="bg-blue cursor-pointer flex items-center justify-between w-full h-full rounded-md py-2 px-5 text-xs md:text-base">
                        {
                            props.firstSearchFields.map((e, index) => {
                                return <option value={e.fieldValue} key={index + "field"}>{e.fieldName}</option>
                            })
                        }
                    </select>
                </div>
                <div className="w-full md:w-1/5 md:mr-2 mt-2 md:mt-0">
                    <select name="secondField" value={selectedSecondValue} onChange={handleChange} className="bg-blue cursor-pointer flex items-center justify-between w-full h-full rounded-md py-2 px-5 text-xs md:text-base">
                        {
                            props.secondSearchFields.map((e, index) => {
                                return <option value={e.fieldValue} key={index + "field"}>{e.fieldName}</option>
                            })
                        }
                    </select>
                </div>

                <div className="w-full md:w-1/5 md:mr-2 mt-2 md:mt-0">
                    <button className="bg-blue cursor-pointer flex items-center justify-center md:px-5 md:text-base placeholder-white py-2 rounded-md text-xs w-full"
                        type="submit" >
                        Search
                    </button>
                </div>
                <div className="w-full md:w-1/6 mt-2 md:mt-0">
                    <button className="bg-blue cursor-pointer flex items-center justify-center lg:px-5 lg:text-base placeholder-white py-2 rounded-md text-xs w-full"
                        disabled={props.clicked}
                        onClick={(e) => handleReset(e)} >
                        Clear
                    </button>
                </div>
            </div>
        </form>
    )
}

export default CatalogSearchBar; 