import React, { useState, useEffect, useContext } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import UserLine from "../../components/UserLine"; // Assuming this can be reused for distributor input
import API from "../../utils/API";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import * as XLSX from 'xlsx';
import "./style.css";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import { useLanguage } from '../../context/LanguageContext';
import DistributorLine from "../../components/DistributorLine";

function AddDistributor() {
    const { translate } = useLanguage();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);

    let cancelToken = API.cancelToken();

    let [distributorRegistration, setDistributorRegistration] = useState([{
        name: "",
        email: "",
        phone_number: "",
        cnpj: "",
    }]);

    const context = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828');
        ReactGA.pageview(window.location.pathname);
    }, []);

    function handleChange(value, index) {
        let newArr = [...distributorRegistration];
        if (!value.name && !value.email && !value.phone_number && !value.cnpj) {
            newArr.splice(index, 1);
        } else {
            newArr[index] = value;
        }
        setDistributorRegistration(newArr);
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validatePhone = (phone) => {
        phone = `${phone}`.replaceAll("_", "");
        if (!phone.includes('+55')) {
            phone = `+55 ${phone}`;
        }
        phone = `${phone}`.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "");
        return phone.length === 13 || phone.length === 14;
    };

    function transformPhoneNumber(phoneNumber) {
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
        const countryCode = cleanedPhoneNumber.slice(0, 2);
        const areaCode = cleanedPhoneNumber.slice(2, 4);
        const prefix = cleanedPhoneNumber.slice(4, 8);
        const lineNumber = cleanedPhoneNumber.slice(8);
        return `+${countryCode} (${areaCode}) ${prefix}-${lineNumber}`;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        for (let e of distributorRegistration) {
            if (!e.name || !e.email || !e.phone_number || !e.cnpj) {
                setError(true);
                return;
            }
            if (!validateEmail(e.email) || !validatePhone(e.phone_number)) { 
                setError(true);
                return;
            }
        }

        cancelToken = API.cancelToken();
        setLoading(true);

        API.registerDistributor(distributorRegistration).then(res => {
            setLoading(false);
            let failedRegistrations = res.data.failed_users || [];

            let title = 'Success';
            let icon = 'success';
            let html = `<p>Registration Process Successful!</p>`;
            if (failedRegistrations.length > 0) {
                title = 'Partial Success';
                icon = 'warning';
                html = `<p>Registration Process Successful!</p>
                <p>The following distributors failed registration:</p>${failedRegistrations.join(', ')}`;
            }
            if (failedRegistrations.length === distributorRegistration.length) {
                title = 'Failed';
                icon = 'error';
                html = `<p>Registration Process Failed!</p>
                <p>The following distributors failed registration:</p>${failedRegistrations.join(', ')}`;
            }
            Swal.fire({
                title: title,
                html: html,
                confirmButtonColor: "#131921",
                icon: icon,
                confirmButtonText: 'Ok'
            });
            if (failedRegistrations.length > 0) {
                let tempList = distributorRegistration.filter(e => !failedRegistrations.includes(e.name));
                setDistributorRegistration(tempList);
            } else {
                history.push('/distributors');
            }
        }).catch(err => {
            setLoading(false);
            if (err.message === 'cancelling') return;
            Swal.fire({
                title: 'Error.',
                text: err.response?.data?.error || "An error occurred.",
                confirmButtonColor: "#131921",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        });

        return () => {
            API.cancel(cancelToken);
        };
    };

    // Upload file section
    const [excelFileError, setExcelFileError] = useState(null);

    const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        processFile(selectedFile);
    };

    function processFile(selectedFile) {
        console.log("Processing FILE")
        if (selectedFile) {
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);

                reader.onload = (e) => {
                    setExcelFileError(null);
                    if (e.target.result !== null) {
                        const workbook = XLSX.read(e.target.result, { type: 'buffer' });
                        const worksheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[worksheetName];
                        const data = XLSX.utils.sheet_to_json(worksheet);
                        console.log(data)
                        setDistributorRegistration(data);
                        setMaxPages(Math.ceil(data.length / 10));
                    }
                };
            } else {
                setExcelFileError('Please select only excel file types');
            }
        } else {
            console.log('Please select a file');
        }
    }

    const onDrop = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
        let file = e.dataTransfer.files[0];
        processFile(file);
    };

    const onDragEnter = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = '#131930';
        e.currentTarget.style.border = '1px solid white';
    };
    const onDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
    };

    useEffect(() => {
        let pages = [];
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>);
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>);
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>);
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + (currentPage - 1)} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>);
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>);
                    pages.push(<div key={'page' + (currentPage + 1)} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>);
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>);
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>);
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>);
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>);
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>);
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>);
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>);
                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)}>{maxPages}</div>);
        }

        setPages(pages);
    }, [maxPages, currentPage]);

    return (
        <div className="registration-page flex-col md:p-10 p-5 height-limiter">
            <ScreenHeader
                title={translate("DistributorRegistrationLabel")}
                link='https://azpartes-product-images.s3.amazonaws.com/service-partner-template.xlsx'
                external={true}
                buttonDescription={translate("DownloadTemplateLabel")}
                hasButton="true" />

            <div id="sectionTwo">
                <div className="pt-10 pb-6">
                    {translate("FillInDistributorDetailsLabel")}
                </div>

                <div className="bg-blue h-full max-h-128 rounded text-xs lg:text-base md:p-7 p-3 flex-col justify-between items-center overflow-auto">
                    {distributorRegistration.slice((currentPage - 1) * 10, currentPage * 10).map((e, index) => (
                        <DistributorLine key={index + "distributor"} data={e} index={index} realIndex={index + ( 10 *(currentPage - 1))} onChange={handleChange} error={error}/>
                    ))}
                </div>

                <div className="flex justify-end">
                    <button
                        className="bg-gray-200 text-xs lg:text-base mt-5 cursor-pointer rounded-md w-44 h-10 flex justify-center items-center"
                        onClick={(e)=>handleSubmit(e)}>
                        <p>{loading ? "Registering..." : translate("RegisterDistributorLabel")}</p>
                    </button>
                </div>
            </div>

            {distributorRegistration.length == 1 && <div id="uploadFile">
                <div className="pt-10 pb-6">
                    <p>{translate("UploadDistributorTemplateLabel")}</p>
                </div>
                {excelFileError && <div className="py-2 text-red-600">{excelFileError}</div>}
                <div className="bg-blue flex justify-center align-center h-52 rounded text-xs lg:text-base">
                    <div
                        onClick={() => { document.getElementById('getFile').click() }}
                        onDrop={e => onDrop(e)}
                        onDragEnter={e => onDragEnter(e)}
                        onDragLeave={e => onDragLeave(e)}
                        onDragStart={e => e.preventDefault()}
                        onDragEnd={e => e.preventDefault()}
                        onDragOver={e => onDragEnter(e)}
                        onDrag={e => e.preventDefault()}
                        className="flex w-40 h-40 text-center border-white border border-dashed rounded-md justify-center items-center cursor-pointer">
                        <input type='file' name="getFile" id="getFile" style={{ display: "none" }} onChange={handleFile} />
                        <div>
                            <h1>{translate('DropHereLabel')}</h1>
                            <h1>{translate('OrLabel')}</h1>
                            <h1>{translate("SelectAFileLabel")}</h1>
                        </div>
                    </div>
                </div>
            </div>}
            {distributorRegistration.length > 10 &&
                <div className="page-selector flex-row-nowrap space-between">
                    <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                        {!mobile && <p>Previous</p>}
                    </div>
                    <div className="pages-section flex-row-nowrap">
                        {pages}
                    </div>
                    <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        {!mobile && <p>Next</p>}
                        <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                    </div>
                </div>}
        </div>
    );
}

export default AddDistributor;
