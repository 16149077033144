import React, { useState, useEffect, useContext } from "react";
import InputMask from 'react-input-mask';

function CompanyLine(props) {

    const [field, setField] = useState({ coh_id: '', name: '', phone: '', email: '', type:'' });
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [subBusinessSegment, setSubBusinessSegment] = useState('')
    const [mask, setMask] = useState("+55 (99) 99999-9999");

    useEffect(() => {
        props.data ? setField(props.data) : setField(null)
        console.log("DATA")
        console.log(props)
        if (props.data.email && !validateEmail(props.data.email)){ 
            setEmailError(true)
        }else{
            setEmailError(false)
        }

        if(props.data.subBusinessSegment && (!props.data.subBusinessSegment || props.data.subBusinessSegment == undefined)){
            setSubBusinessSegment(false)
        }else{
            console.log(`Segment: ${props.data.subBusinessSegment}`)
            setSubBusinessSegment(props.data.subBusinessSegment)
        }

        if (props.data.phone && !validatePhone(props.data.phone)){ 
            console.log("SetPhoneError: True (UseEffect)")
            setPhoneError(true)
        }else{
            setPhoneError(false)
        }
        if(props.data.phone){
            var phone = `${props.data.phone}`
            phone = phone.replace(" ", "")
            phone = phone.replace("-", "")
            phone = phone.replace("(", "")
            phone = phone.replace(")", "")

            if (phone.replace("_", "").length === 10) {
                setMask("+55 (99) 9999-9999");
              }
        }
        console.log("SetPhoneError: False (UseEffect)")
    }, [props.data])
    
    
    function handleChange(e) {
        const { name, value } = e.target;
        console.log(name)
        console.log(value)
        console.log(props.data.subBusinessSegment)
        console.log(name==='email')
        console.log(name==='phone')
        if (name==='email' && !validateEmail(value)){ 
            setEmailError(true)
        }else{
            setEmailError(false)
        }
        if (name==='phone' && !validatePhone(value)){ 
            console.log("SetPhoneError: True")
            setPhoneError(true)
        }else{
            console.log("SetPhoneError: False")
            setPhoneError(false)
        }

        if(name==='subBusinessSegment' && (!props.data.subBusinessSegment || props.data.subBusinessSegment == undefined)){
            setSubBusinessSegment(false)
        }else{
            console.log(`Segment: ${props.data.subBusinessSegment}`)
            setSubBusinessSegment(props.data.subBusinessSegment)
        }
        
        props.onChange({ ...field, [name]: value }, props.realIndex);
    };

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      const validatePhone = (phone) => {
        console.log(`Phone: ${phone}`)
        phone = `${phone}`
        if(!phone.includes("+55 ")){
            phone = `+55 ${phone}`
        }
        phone = `${phone}`.replace(/\D/g,'');
        console.log(phone)
        console.log(phone.length)
        if (phone.length === 13 || phone.length === 12){
            return true
        }else{
            return false
        }
      };
    return (
        <div className="flex-row wrap-override p-1">

            <div className={`flex-col w-full md:w-4/12 md:mr-2 md:mt-0 mt-4`}>
                {props.index == 0 && <p>COH COH Customer ID</p>}
                <input
                    type="text"
                    name="coh_id"
                    value={field ? field.coh_id : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${props.error && !field.coh_id ? "border border-amber-900" : "border-0"}`}
                />
            </div>

            {/* <div className="flex-col w-full md:w-1/5 md:mr-2 md:mt-0 mt-4">
                {props.index == 0 && <p>Customer Contact Name</p>}
                <input
                    type="text"
                    name="name"
                    value={field ? field.name : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${props.error && !field.name ? "border border-amber-900" : "border-0"}`}
                />
            </div> */}

            {/* <div className="flex-col w-full md:w-1/5 md:mr-2 md:mt-0 mt-4">
                {props.index == 0 && <p>Customer Notification Email</p>}
                <input
                    type="email"
                    name="email"
                    value={field ? field.email : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${emailError || (props.error && !field.email) ? "border border-amber-900" : "border-0"}`}
                />
            </div> */}
            <div className="flex-col w-full md:w-4/12 md:mr-2 md:mt-0 mt-4">
                {props.index == 0 && <p>Business Model</p>}
                <select name="type"
                    onChange={handleChange}
                    defaultValue={""}
                    className={`appearance-none bg-gray-200 p-5 h-10 mt-2  col-span-2 lg:text-base placeholder-white px-5 py-2 rounded-md text-xs w-full box-border ${props.error && !field.type ? "border border-amber-900" : "border-0"}`}>
                    <option disabled={true} value="">SELECT</option>
                    <option selected={false} disabled={false} value="B2B">B2B</option>
                    
                </select>
            </div>
            
            <div className="flex-col w-full md:w-4/12 md:mt-0 mt-4">
                {props.index == 0 && <p>Sub Business Segment</p>}
                <select name="subBusinessSegment"
                    onChange={handleChange}
                    defaultValue={""}
                    className={`appearance-none bg-gray-200 p-5 h-10 mt-2  col-span-2 lg:text-base placeholder-white px-5 py-2 rounded-md text-xs w-full box-border ${props.error && !field.subBusinessSegment ? "border border-amber-900" : "border-0"}`}>
                    <option disabled={true} value="">SELECT</option>
                    <option selected= {subBusinessSegment && subBusinessSegment === "SKF Center" ? true : false } disabled={false} value="SKF Center">SKF Center</option>
                    <option selected= {subBusinessSegment && subBusinessSegment === "Two Wheeler" ? true : false } disabled={false} value="Two Wheeler">Two Wheeler</option>
                    <option selected= {subBusinessSegment && subBusinessSegment === "VA Distributor" ? true : false } disabled={false} value="VA Distributor">VA Distributor</option>
                    <option selected= {subBusinessSegment && subBusinessSegment === "Stock Car" ? true : false } disabled={false} value="Stock Car">Stock Car</option>
                    <option selected= {subBusinessSegment && subBusinessSegment === "Private Label" ? true : false } disabled={false} value="Private Label">Private Label</option>
                </select>
            </div>
        </div>
    )
}
export default CompanyLine