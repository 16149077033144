import React, { useState, useRef, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import './style.css';
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import PreviousMap from "postcss/lib/previous-map";


function Login() {

    const context = useContext(AppContext);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const userName = useRef();
    const password = useRef();
    const history = useHistory();

    const logIn = (e) => {
        e.preventDefault();
        setError('');
        if (!userName.current.value) {
            setError('MISSING');
            return;
        }
        if (!password.current.value) {
            setError('MISSING');
            return;
        }
        setLoading(true);
        const cancelToken = API.cancelToken();
        API.logIn(cancelToken, userName.current.value, password.current.value).then(res => {
            setLoading(false);
            context.logIn(res.data.email, res.data.token, res.data.user, res.data.role);
            history.push('/home');
        }).catch(err => {
            if (err.message === 'cancelling') return
            setLoading(false);
            if (err.response && err.response.status === 401) {
                setError('INVALID');
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);

        }
    }


    return (
        <div className="login-page">
            <div className="form-container">
                {/* <img className="max-widht-18 mx-auto mb-4" src={require('../../assets/images/lisa-logo-noalpha.png')} alt="logo" /> */}
                <h1 className="margin-bottom-20 text-center">AZPartes Admin</h1>
                <form>
                    <p className="label">Username</p>
                    <input ref={userName} type="email" placeholder="@skf.com.br" />
                    <p className="label">Password</p>
                    <input ref={password} type="password" />
                    <p className="forgotPass">Forgot your password?</p>
                    {error &&
                        <p className="text-red-100 text-bold">
                            Invalid Username Or Password
                        </p>
                    }
                    {
                        loading ?
                            <div className="loader-wrapper">
                                <Loader light={true} />
                            </div> :
                            <input type="submit" className="action-button margin-bottom-20" onClick={(e) => logIn(e)} value="LOGIN" />
                    }
                <h5 className="mt-5">Precisa de ajuda?</h5>
                <Link className="reset" to={'/recuperacao'}>
                    <p>Esqueci a senha</p>
                </Link>
                </form>


            </div>
        </div>

    );
}

export default Login;