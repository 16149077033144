import { useState, useRef } from 'react';
import './style.css';
import API from "../../utils/API";
import Loader from "../../components/Loader";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function Recover() {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null)
    const email = useRef();

    const recover = (e) => {
        e.preventDefault();
        setError(null);
        if (!email.current.value) return;
        setLoading(true);
        const cancelToken = API.cancelToken();
        API.recoverPassword(cancelToken, email.current.value).then(res => {
            setLoading(false);
            if (res.data.status === "NOT_OK") {
                setError(res.data.message);
            }
            else {
                setSuccess(true);
            }
        }).catch(err => {
            if(err.message==='cancelling') return
            console.log(err.response);
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
        return () => {
            API.cancel(cancelToken);

        }
    }


    return (
        <div className="recovery-page">
            <div className="form-container">
                <Link className="reset" to={'/home'}>
                    <h3 className="margin-bottom-20">AZPartes.com</h3>
                </Link>
                {
                    success ?
                        <p className="success-message text-center">O email de recuperação foi enviado.</p> :
                        <form>
                            <p className="label">E-mail</p>
                            <input type="email" ref={email} />
                            {error &&
                                <p className="error margin-bottom-20">
                                    {error}
                                </p>
                            }
                            {
                                loading ?
                                    <div className="loader-wrapper">
                                        <Loader light={true} />
                                    </div> :
                                    <input type="submit" className="action-button margin-bottom-20" onClick={(e) => recover(e)} value="Entrar" />

                            }
                            <p className="disclaimer margin-bottom-30">
                                Você receberá um email com as instruções de recuperação de senha.
                            </p>
                            {/* <h5>Precisa de ajuda?</h5> */}
                            {/* <Link className="reset" to={'/'}>
                            <p>Outros problemas com o acesso</p>
                        </Link> */}
                        </form>
                }
            </div>
        </div>
    )
}

export default Recover;