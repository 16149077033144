import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import SortableTable from "../../components/SortableTable";
import { Link, useHistory, useLocation } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import Loader from "../../components/Loader";

function ApiTokenAccess() {
    const location = useLocation();
    const [token, setToken] = useState(null);
    const [apiToken, setApiToken] = useState(null);
    const [copied, setCopied] = useState(false);
    const [expired, setExpired] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('comp') !== null) history.push('/')
        params.get('ref') ? setToken(params.get('ref')) : setToken(false);
    }, [])

    const copyText = () => {
        if (apiToken === null) return
        navigator.clipboard.writeText(apiToken);
        setCopied(true)
    }


    useEffect(() => {
        if (token === null) return
        if (token === false) {
            setExpired(true)
            return
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        let cancelToken = API.cancelToken();
        API.getApiToken(cancelToken, token).then(res => {
            setApiToken(res.data.token)
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err)
            setApiToken(false)
            setExpired(true)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [token]
    );

    return (
        <div className="api-token-page md:p-10 p-5">
            <h1>{apiToken === null ? "Loading your token..." : (expired ? "Link expired, please contact AZPartes team again" : "You API token")}</h1>
            {!expired && apiToken !== null &&
                <div className="flex flex-nowrap force-color align-center justify-between w-72 rounded p-2 my-5">
                    <input className="rounded force-color text-white" type="text" value={apiToken ? apiToken : ""} placeholder="" disabled={true} />
                    <img onClick={copyText} className="pointer" src={require('../../assets/images/copy-icon.png')} alt="next" />
                </div>
            }
            {
                copied && !expired && <div className="bg-green-700 p-2 rounded mt-3">Copied!</div>
            }

        </div>);
}

export default ApiTokenAccess;