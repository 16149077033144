import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import GlobalSVG from '../../utils/GlobalSVG';
import ReactGA from 'react-ga';

function servicelist() {
    const [listOfOrder, setListOfOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false)
    const [sorting, setSorting] = useState("desc")

    const searchFields = [{ fieldValue: "date", fieldName: "Request Date Range" },
    { fieldValue: "name", fieldName: "Customer Name" }];

    const dropDownValues = {
        status: [{ status: "received", description: "Aguardando Registro COH" }]
    };

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setMobile(window.innerWidth <= 768);
        

        cancelToken = API.cancelToken();

        let field = fieldRef.current.value;
        let value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;
        setLoading(true)
        if (field !== "" && value != "") {
            API.getSearchListOfServiceInvoice(cancelToken, currentPage, field, value, sorting).then(res => {
                console.log(res);
                console.log(`total_pages: ${res.data.pagination.total_pages}`)
                setMaxPages(res.data.pagination.total_pages)
                res.data.orders ? setListOfOrder(res.data.orders) : setListOfOrder([]);
            }).catch(err => {
                setListOfOrder([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(
                f => {
                    setIsClicked(false);
                    setLoading(false)
                    setIsSearch(false)
                }
            );
        } else {
            setLoading(true)
            API.getSearchListOfServiceInvoice(cancelToken, currentPage, '', '').then(res => {
                console.log(`total_pages: ${res.data.pagination.total_pages}`)
                setMaxPages(res.data.pagination.total_pages)
                res.data.orders ? setListOfOrder(res.data.orders) : setListOfOrder([]);
                setLoading(false)
            }).catch(err => {
                setListOfOrder([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(f => {
                // setLoading(false)
            });
        }

        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, reset, isSearch, sorting]);


    useEffect(() => {
        let pages = []
        console.log(`maxPages: ${maxPages}`)
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])


    const doReset = () => {
        setReset(!reset)
    }

    const handleSearch = (e) => {
        e && e.preventDefault();

        let value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;
        let field = fieldRef.current.value;
        
        console.log(`VALUE: ${value}`)
        if (!value || value.trim() === '' || !field) {
            setError(true)
            return
        }
        if(currentPage === 1){
            setIsSearch(true)
        }else{
            setCurrentPage(1)
        }
        setIsClicked(true);
    }

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title="Service Billing PJ"
                buttonDescription="Add Order"
                svg="orders"
                hasButton="false" />

            <SearchBar
                searchFields={searchFields}
                valueRef={valueRef}
                fieldRef={fieldRef}
                fieldStatusRef={fieldStatusRef}
                onSearch={handleSearch}
                clicked={isClicked}
                dropDownValues={dropDownValues}
                error={error}
                reset={doReset} />

            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-3/12 text-center">Client Name</div>
                        <div className="w-3/12 text-center">Order's number</div>
                        <div className="w-3/12 text-center">Invoice amount</div>
                        <div className="w-3/12 text-center">Status</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!listOfOrder || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        listOfOrder.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            listOfOrder.map((order, index) => {
                                return  <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                        <div className="w-3/12 text-center">{order.name}</div>
                                        <div className="w-3/12 text-center">{order.skf_order_id == 0 ? '-' : order.skf_order_id}</div>
                                        <div className="w-3/12 text-center">{order.sum}</div>
                                        <div className="w-3/12 text-center flex justify-center">
                                            <Status
                                                key={order.skf_order_id + 'orderStatus'}
                                                id={order.skf_order_id}
                                                currentStatus={order.status}
                                                clickable="true" />
                                        </div>
                                    </div>
                              
                            })
                    }
                </div>
            </div>
            {
                !loading && maxPages ?
                    <div className="page-selector flex-row-nowrap space-between">
                        <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                            {
                                !mobile && <p>Previous</p>
                            }
                        </div>
                        <div className="pages-section flex-row-nowrap">
                            {
                                pages
                            }
                        </div>
                        <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            {
                                !mobile && <p>Next</p>
                            }
                            <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                        </div>
                    </div> :
                    null
            }
        </div>);
}

export default servicelist; 