import React, { useState, useEffect, useContext } from "react";
import OrderLinePJ from "../../components/OrderLinePJ";
import DropDown from "../../components/DropDown";
import { useParams, useHistory, Link } from "react-router-dom";
import API from '../../utils/API';
import Swal from 'sweetalert2';
import { AppContext } from "../../utils/ContextProvider";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

function ServicePJ() {

    const [orders, setOrders] = useState([{
        charge_id: "",
        gross_price: 0,
        quantity: 1,
        service_dept: "",
        item_description: ""
    }]);
    const [authorization, setAuthorization] = useState(null)
    const [total_amount, setTotal_amount] = useState(null)
    const [underReview, setUnderReview] = useState(false)
    const history = useHistory();
    const [totalCount, setTotalCount] = useState(1)
    const [customerId, setCustomerId] = useState(null)
    const [customerInfo, setCustomerInfo] = useState(null)
    const [analystCodes, setAnalystCodes] = useState(null)
    const [paymentCodes, setPaymentCodes] = useState(null)
    const [complementaryIsFocused, setComplementaryIsFocused] = useState(false)
    const [invoice, setInvoice] = useState({
        customer_id: "",
        text: "",
        email: "",
        analyst: "",
        payment_condition: "",
        subtype: "",
        complementary_info: "",
        orders_lines: [
            {
                charge_id: "",
                gross_price: 0,
                quantity: 1,
                service_dept: "",
                item_description: ""
            }
        ]
    })
    const [error, setError] = useState(false);
    const context = useContext(AppContext);
    const role = context.getRole;
    const [token, setToken] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [errorList, setErrorList] = useState(
        {
            customer_id: "",
            text: "",
            email: "",
            analyst: "",
            payment_condition: "",
            subtype: "",
            complementary_info: "",
            orders_lines: [
                {
                    charge_id: "",
                    gross_price: "",
                    quantity: "",
                    service_dept: "",
                    item_description: ""
                }
            ]
        }
    );

    useEffect(() => {

        if (!context.getLogin) {
            history.push('/login');
            return;
        }

        let cancelToken = API.cancelToken();
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        Swal.fire({
            title: 'Verifying user authorization...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
        });
        API.getToken(cancelToken).then(res => {
            console.log(res.data.token)
            setToken(res.data.token)
            Swal.close();
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 401 || err.response && err.response.status === 400) {
                Swal.fire({
                    title: 'Error.',
                    text: 'You are not authorized to perform this action.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(e => {
                    history.push('/home');
                });
            } else if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []
    );

    const submit_service = async (e) => {
        e.preventDefault()
        setErrorList({
            customer_id: "",
            text: "",
            email: "",
            analyst: "",
            payment_condition: "",
            subtype: "",
            complementary_info: "",
            orders_lines: [
                {
                    charge_id: "",
                    gross_price: "",
                    quantity: "",
                    service_dept: "",
                    item_description: ""
                }
            ]
        })
        let button = e.currentTarget
        button.disabled = true
        button.innerHTML = "Submitting..."
        // setError(false)
        let cancelToken = API.cancelToken();
        console.log(invoice)
        // if(invoice.analyst.includes("Selecione") || invoice.payment_condition.includes("Selecione") || invoice.subtype.includes("Selecione")){
        //     let newErrorList = { ...errorList }
        //     if(invoice.analyst.includes("Selecione")){newErrorList.analyst = "Campo obrigatório"}
        //     if(invoice.payment_condition.includes("Selecione")){newErrorList.payment_condition = "Campo obrigatório"}
        //     if(invoice.subtype.includes("Selecione")){newErrorList.subtype = "Campo obrigatório"}
        //     setErrorList(newErrorList)
        //     button.innerHTML = "Submit Service Invoice"
        //     button.disabled = false
        //     return
        // }

        // if (invoice.analyst == "" || invoice.payment_condition == "" || invoice.subtype == ""){
        //     let newErrorList = { ...errorList }
        //     if (invoice.analyst == ""){
        //         newErrorList.analyst = "Campo obrigatório"

        //     }
        //     if (invoice.payment_condition == ""){
        //         newErrorList.payment_condition = "Campo obrigatório"
        //     }
        //     if (invoice.subtype == ""){
        //         newErrorList.subtype = "Campo obrigatório"
        //     }
        //     button.innerHTML = "Submit Service Invoice"
        //     button.disabled = false
        //     setErrorList(newErrorList)
        //     return
        // }
        API.submitPJService(cancelToken, invoice, token).then(res => {
            Swal.fire({
                title: 'Success',
                text: `Requisição criada com sucesso! O número do seu processo é ${res.data.requisition_number}`,
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    location.reload()
                }
            })
            button.innerHTML = "Add new service"
            setSubmitted(true);
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.status !== 400 && err.response.status !== 401 && err.response.status !== 403) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                if (err.response.data.hasOwnProperty("message")) {
                    setErrorList(err.response.data.message)
                } else {
                    setErrorList(err.response.data)
                }
            }
            button.innerHTML = "Submit Service Invoice"
        }).finally(f => {
            button.disabled = false
        });
    }


    useEffect(() => {
        let newArr = { ...invoice, "orders_lines": orders }
        setInvoice(newArr);
        var total = 0;
        for (let i = 0; i < orders.length; i++) {
            try {
                total += parseFloat(orders[i].gross_price) * parseInt(orders[i].quantity)
            } catch (err) {
                console.log(err)
            }
        }
        setTotal_amount(total.toFixed(2))
    }, [orders])

    useEffect(() => {
        let tempTotal = 0
        let tempTotalCount = 0
        invoice.orders_lines.forEach(e => {
            if (!isNaN(parseFloat(e.gross_price))) tempTotal += (parseFloat(e.gross_price) * parseInt(e.quantity))
            tempTotalCount += parseInt(e.quantity)
        })
        let tempInvoice = { ...invoice, total_gross_price: tempTotal.toFixed(2) }
        setTotalCount(tempTotalCount)
        setInvoice(tempInvoice)
    }, [invoice.orders_lines])

    function removeAccents(text) {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    function removeSpecialCharacters(text) {
        var regex = /[!#$%^&*()_+{}"<>?[\]'\\|`~=€£¥¢¬°©®™§¶¦ªº†‡µ‰¼½¾‹›«»‘’“”„†‡•…‰′″‹›«»↔↑↓→←∆∑∞√∩∪∈∫≈≠≡≤≥÷×±∓∝∅∴∵⊂⊃⊆⊇⊕⊗⊥∠∨∧∥∣∂∇∈∉⊂⊄⊃⊅π]/g;
        return text.replace(regex, '');
    }

    function handleBlur(e) {
        let new_value = null
        const { name, value } = e.target;
        new_value = value.trim().replace(/\s+/g, " ");
        getCustomer(new_value)
    }


    function handleChange(e) {
        let new_value = null
        const { name, value } = e.target;

        new_value = value;

        if (name == "customer_id") {
            new_value = new_value.toUpperCase()

        }

        if (name == "complementary_info" || name == "item_description") {
            new_value = removeAccents(new_value)
            new_value = new_value.replace(/@/g, 'A');
            new_value = new_value.replace(/&/g, 'e');
            new_value = removeSpecialCharacters(new_value)
        }
        else if (name.includes('price')) {
            new_value = new_value.replace(".", "")
            new_value = parseInt(new_value)
            if (isNaN(new_value)) new_value = ""
            else new_value = parseFloat(parseInt(new_value) / 100).toFixed(2)
        }

        let newArr = { ...invoice, [name]: new_value }
        setInvoice(newArr);
    }

    function selectedID(newIndex, rowID) {
        let newArr = [...orders]
        let newStage = newIndex;
        if (rowID + 1 <= orders.length) {
            newArr[rowID] = newStage;
        } else {
            newArr.push(newStage);
        }
        if (newArr.length === 0) newArr.push(-1)
        setOrders(newArr);
    }

    function onKeyDown(e) {
        if (e.key === 'Enter') {
            const { name, value } = e.target;
            getCustomer(value.trim().replace(/\s+/g, " "))
        }
    }

    function getCustomer(new_value) {
        console.log('Enter key pressed!');
        console.log(`new_value: ${new_value} - customer_id: ${customerId}`)
        if (new_value != "" && new_value != customerId) {
            setErrorList({
                customer_id: "",
                text: "",
                email: "",
                analyst: "",
                payment_condition: "",
                subtype: "",
                complementary_info: "",
                orders_lines: [
                    {
                        charge_id: "",
                        gross_price: "",
                        quantity: "",
                        service_dept: "",
                        item_description: ""
                    }
                ]
            })
            setCustomerInfo(null)
            setCustomerId(new_value)
            let newArr = { ...invoice }
            invoice.customer_id = new_value
            setInvoice(newArr);
            let cancelToken = API.cancelToken();
            Swal.fire({
                title: 'Loading customer data...',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            });
            var retry = true
            while (retry == true) {
                retry = false
                API.getCompanyInfo(cancelToken, token, new_value).then(res => {
                    console.log(res)
                    Swal.close();
                    if (res.data.customer.name == "") {
                        Swal.fire({
                            title: `${new_value}`,
                            text: 'Cliente não encontrado',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                        return
                    }
                    setCustomerInfo({ "name": res.data.customer.name, "cnpj": res.data.customer.cnpj, "city": res.data.customer.location})
                    setAnalystCodes(res.data.analyst_codes)
                    setPaymentCodes(res.data.payment_codes)
                    setUnderReview(true)
                }).catch(err => {
                    console.log(err)
                    Swal.close();
                    if (err.response.status == 401) {
                        retry = true
                    }
                    if (err.message === 'cancelling') return
                    if (err.response && err.response.status !== 400 && err.response.status !== 401 && err.response.status !== 403) {
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                })
            }
        }
    }

    const addROW = (rowID) => {
        let newArr = [...orders];
        newArr.splice(rowID + 1, 0, {
            charge_id: "", gross_price: 0, quantity: 1, service_dept: "", item_description: ""
        })
        setOrders(newArr)
    }

    function deleteROW(rowID) {
        let newArr = [...orders];
        newArr = newArr.filter((item, index) => {
            return (index !== rowID)
        })
        setOrders(newArr);
    }

    const resetInvoice = () => {
        setInvoice({
            customer_id: "",
            text: "",
            email: "",
            analyst: "",
            payment_condition: "",
            subtype: "",
            complementary_info: "",
            orders_lines: [
                {
                    charge_id: "",
                    gross_price: 0,
                    quantity: 1,
                    service_dept: "",
                    item_description: ""
                }
            ]
        })
        setOrders([{ charge_id: "", gross_price: 0, service_dept: "", quantity: 1, item_description: "" }])
        setSubmitted(false)
    }

    function priceToReal(value) {
        if (value === null) return;
        console.log(value)
        try {
            value = parseFloat(value);
            let temp = value.toFixed(2).toString().replace('.', ',');
            return 'R$ ' + temp.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } catch (err) {
            return 'Not able to parse'
        }
    }

    return <div className="flex-col md:p-10 p-5 height-limiter max-w-sm md:max-w-full m-auto text-sm md:text-base">

        <h1 className="mb-6 md:mb-12 text-xl">Service Invoice Automate PJ</h1>
        <div className="flex flex-col bg-blue w-full h-90 px-9 py-8">
            {true &&
                <>
                    <div className="flex md:flex-row gap-2 w-full wrap-override flex-col justify-left align-left">
                        <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                            <p>Customer Code</p>
                            <input
                                value={invoice.customer_id}
                                placeholder="COH Customer ID"
                                name="customer_id"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleBlur(e)}
                                onKeyDown={(e) => onKeyDown(e)}
                                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.customer_id ? "border border-amber-900" : "border-0"}`} />
                            <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.customer_id}</p>
                        </div>
                    </div>
                    {
                        <>

                        </>
                    }
                    <div className={`flex md:flex-row gap-2 w-full wrap-override flex-col justify-center align-center ${customerInfo ? "" : "hidden"} `}>
                        <div className="flex flex-col gap-x-1 mt-3 md:w-1/3 w-full">
                            <p>Customer Name</p>
                            <input
                                value={customerInfo ? customerInfo.name : ""}
                                placeholder="Customer Name"
                                name="customer_name"
                                disabled={true}
                                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite border-0"}`} />
                            <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.customer_name}</p>
                        </div>
                        <div className="flex flex-col gap-x-1 mt-3 md:w-1/3 w-full">
                            <p>Customer CNPJ</p>
                            <input
                                value={customerInfo ? customerInfo.cnpj : ""}
                                placeholder="Customer CNPJ"
                                name="customer_cnpj"
                                disabled={true}
                                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite border-0"}`} />
                            <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.customer_cnpj}</p>
                        </div>
                        <div className="flex flex-col gap-x-1 mt-3 md:w-1/3 w-full">
                            <p>Customer City</p>
                            <input
                                value={customerInfo ? customerInfo.city : ""}
                                placeholder="Customer CNPJ"
                                name="customer_cnpj"
                                disabled={true}
                                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite border-0"}`} />
                            <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.customer_cnpj}</p>
                        </div>
                        <div className="flex flex-col gap-x-1 mt-3 md:w-1/3 w-full">
                            <p>Email</p>
                            <input
                                placeholder="Email"
                                name="email"
                                value={invoice.email}
                                onChange={(e) => handleChange(e)}
                                className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.email ? "border border-amber-900" : "border-0"}`} />
                            <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.email}</p>
                        </div>
                    </div>
                    <div className={`flex md:flex-row gap-2 w-full wrap-override flex-col justify-center ${customerInfo ? "" : "hidden"} `}>

                        <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                            <p>Analyst</p>
                            <select name="analyst" value={invoice.analyst} onChange={(e) => handleChange(e)} className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.analyst ? "border border-amber-900" : "border-0"}`}>
                                <option value="" index="-1" className="p-4">Selecione um analista</option>
                                {
                                    analystCodes && analystCodes.map((item, index) => {
                                        return <option value={item.CSR_NAME} index={`${index}`} className="p-4">{`${item.CSR_CODE} - ${item.CSR_NAME}`}</option>
                                    })
                                }
                            </select>
                            <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.analyst}</p>
                        </div>
                        <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                            <p>Payment Condition</p>
                            <select name="payment_condition" value={invoice.payment_condition} onChange={(e) => handleChange(e)} className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.payment_condition ? "border border-amber-900" : "border-0"}`}>
                                <option value="" index="-1" className="p-4">Selecione uma condição de pagamento</option>
                                {
                                    paymentCodes && paymentCodes.map((item, index) => {
                                        return <option value={item.PAYMENT_CONDITION_COH_ID} className="p-4">{`${item.PAYMENT_CONDITION_COH_ID} - ${item.PAYMENT_CONDITION_COH_DESCRIPTION}`}</option>
                                    })
                                }
                            </select>
                            <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.payment_condition}</p>
                        </div>
                        <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                            <p>Subtype</p>
                            <select name="subtype" value={invoice.subtype} onChange={(e) => handleChange(e)} className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.subtype ? "border border-amber-900" : "border-0"}`} >
                                <option value="" index="-1" className="p-4">Selecione um subtipo</option>
                                <option value="L1" className="p-4">L1</option>
                                <option value="A2" className="p-4">A2</option>
                            </select>
                            <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.subtype}</p>
                        </div>
                        <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                            <p>Complementary Info</p>
                            <textarea
                                placeholder="Complementary Info"
                                name="complementary_info"
                                rows="12"
                                onChange={(e) => handleChange(e)}
                                value={invoice.complementary_info}
                                onBlur={(e) => { setComplementaryIsFocused(false); }}
                                onFocus={() => setComplementaryIsFocused(true)}
                                className={`w-full bg-gray-200 ${complementaryIsFocused ? 'h-100' : 'h-10'} rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.complementary_info ? "border border-amber-900" : "border-0"}`} />
                            <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.complementary_info}</p>
                        </div>
                    </div>
                    <div className={`mt-6 ${customerInfo ? "" : "hidden"}`}>
                        <p className="text-lg mb-4">Item</p>
                        {
                            orders.map((order, index) => {
                                return <OrderLinePJ
                                    key={index + 'order'}
                                    orders={orders}
                                    rowId={index}
                                    addRow={addROW}
                                    deleteRow={deleteROW}
                                    selectedId={selectedID}
                                    order={order}
                                    error={error}
                                    errorList={errorList}
                                />
                            })
                        }
                    </div>
                </>
            }
        </div>

        {
            underReview &&
            <div className="flex flex-col bg-blue w-full h-90 px-9 py-8 mt-5">
                <div className="flex  gap-2 w-full wrap-override flex-col justify-left align-left">
                    <div className="flex flex-col gap-x-1 mt-3 mb-5 w-full">
                        <p className="text-3xl">Summary</p>
                    </div>
                    <div className="flex flex-col gap-x-1 mt-3  w-full">
                        <p>Customer Code: {invoice && invoice.customer_id ? invoice.customer_id : ''}</p>
                    </div>
                    <div className="flex flex-col gap-x-1 mt-3 w-full">
                        <p>Customer Name: {customerInfo && customerInfo.name ? customerInfo.name : ''}</p>
                    </div>
                    <div className="flex flex-col gap-x-1 mt-3 w-full">
                        <p>Customer CNPJ: {customerInfo && customerInfo.cnpj ? customerInfo.cnpj : ''}</p>
                    </div>
                    <div className="flex flex-col gap-x-1 mt-3 w-full">
                        <p>Email: {invoice && invoice.email ? invoice.email : ''}</p>
                    </div>
                    <div className="flex flex-col gap-x-1 mt-3 w-full">
                        <p>Analyst: {invoice && invoice.analyst ? invoice.analyst : ''}</p>
                    </div>
                    <div className="flex flex-col gap-x-1 mt-3 w-full">
                        <p>Payment Condition: {invoice && invoice.payment_condition ? invoice.payment_condition : ''}</p>
                    </div>
                    <div className="flex flex-col gap-x-1 mt-3 w-full">
                        <p>Subtype: {invoice && invoice.subtype ? invoice.subtype : ''}</p>
                    </div>
                    <div className="flex flex-col gap-x-1 mt-3 w-full">
                        <p>Complementary Info: {invoice && invoice.complementary_info ? invoice.complementary_info : ''}</p>
                    </div>
                    <div className="flex flex-col gap-x-1 mt-5 w-full">
                        <p className="text-2xl">Itens</p>
                    </div>

                    <div className="flex flex-col gap-x-1 mt-3 w-full">
                        <table class="table-auto">
                            <tbody>
                                {
                                    orders.map((order, index) => {
                                        return (
                                            <tr>
                                                <td>{order && order.gross_price ? order.quantity : ''}</td>
                                                <td>{order && order.charge_id ? order.charge_id : ''}</td>
                                                <td>{order && order.gross_price ? priceToReal(order.gross_price) : ''}</td>
                                                <td>{order && order.gross_price ? order.service_dept : ''}</td>
                                                <td>{order && order.gross_price ? order.item_description : ''}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div class="my-8 border-t border-[#5c5c5c] w-full"></div>
                        <div className="flex flex-col gap-x-1 w-full text-xl">
                            <p>Total: {priceToReal(total_amount)}</p>
                        </div>
                    </div>

                </div>
            </div>

        }
        <div className="flex flex-row align-end justify-end my-7">
            <button onClick={e => submitted ? resetInvoice() : submit_service(e)} className={`h-10 w-64 bg-gray-200 rounded-md ${customerInfo ? "" : "hidden"}`}>{submitted ? "Add a New Invoice" : "Submit Service Invoice"}</button>
        </div>
    </div>
}

export default ServicePJ;