import React from "react";
import ProductDisplay from "../../pages/ProductDisplay";

function Notification(props) {

    const number = props.priority;
    
    return (
        <div className="bg-darkblue rounded-md flex-row justify-between mb-5">

            <div className={`${number > 1 ? "bg-yellow" : "bg-red-800"} w-1/12 text-center text-3xl rounded-tl-md rounded-bl-md py-8`}>
                {props.priority}
            </div>
            <div className="flex flex-col p-4 w-9/12 align-start justify-center">
                <h1>{props.title}</h1>
                <p className="font-thin text-sm">{props.subtitle}</p>
            </div>
            <div className="flex align-center justify-center w-2/12">{props.created_at}</div>

        </div>
    );
}

export default Notification;