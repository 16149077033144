import React, { useState, useEffect } from "react";
import InputMask from 'react-input-mask';

function DistributorLine(props) {
    const [field, setField] = useState({ name: '', phone_number: '', email: '', cnpj: '' });
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [cnpjError, setCnpjError] = useState(false);
    const [mask, setMask] = useState("+55 (99) 99999-9999");

    useEffect(() => {
        if (props.data) {
            setField(props.data);
        }

        // Validate email if present
        if (props.data.email && !validateEmail(props.data.email)) { 
            setEmailError(true);
        } else {
            setEmailError(false);
        }

        // Validate phone number if present
        if (props.data.phone_number && !validatePhone(props.data.phone_number)) { 
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }

        // Validate CNPJ if present
        if (props.data.cnpj && !validateCNPJ(props.data.cnpj)) { 
            setCnpjError(true);
        } else {
            setCnpjError(false);
        }

        // Adjust mask based on phone number length
        if (props.data.phone_number) {
            var phone = `${props.data.phone_number}`.replace(/\D/g, '');
            if (phone.length === 10) {
                setMask("+55 (99) 9999-9999");
            }
        }
    }, [props.data]);

    // Handle change in input fields
    function handleChange(e) {
        const { name, value } = e.target;

        if (name === 'email' && !validateEmail(value)) { 
            setEmailError(true);
        } else {
            setEmailError(false);
        }

        if (name === 'phone_number' && !validatePhone(value)) { 
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }

        if (name === 'cnpj' && !validateCNPJ(value)) { 
            setCnpjError(true);
        } else {
            setCnpjError(false);
        }

        props.onChange({ ...field, [name]: value }, props.realIndex);
    }

    // Email validation function
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    // Phone number validation function
    const validatePhone = (phone) => {
        phone = `${phone}`
        phone = `${phone}`.replace(/\D/g, '');
        return phone.length === 13 || phone.length === 12;
    };

    // CNPJ validation function (basic format check)
    const validateCNPJ = (cnpj) => {
        cnpj = `${cnpj}`
        cnpj = cnpj.replace(/\D/g, '');
        return cnpj.length === 14;
    };

    return (
        <div className="flex-row wrap-override p-1">
            <div className="flex-col w-full md:w-3/12 md:mr-2 md:mt-0 mt-4">
                {props.index === 0 && <p>{props.partner ? 'Partner' : 'Distributor'} Name</p>}
                <input
                    type="text"
                    name="name"
                    value={field ? field.name : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${props.error && !field.name ? "border border-amber-900" : "border-0"}`}
                />
            </div>

            <div className="flex-col w-full md:w-3/12 md:mr-2 md:mt-0 mt-4">
                {props.index === 0 && <p>{props.partner ? 'Partner' : 'Distributor'} Phone Number</p>}
                <InputMask
                    type="text"
                    name="phone_number"
                    mask={mask}
                    onBlur={e => {
                        if (e.target.value.replace("_", "").length === 18) {
                          setMask("+55 (99) 9999-9999");
                        }
                    }}
                    onFocus={e => {
                        if (e.target.value.replace("_", "").length === 18) {
                          setMask("+55 (99) 99999-9999");
                        }
                    }}
                    value={field ? field.phone_number : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${phoneError || (props.error && !field.phone_number) ? "border border-amber-900" : "border-0"}`}
                />
            </div>

            <div className="flex-col w-full md:w-3/12 md:mr-2 md:mt-0 mt-4">
                {props.index === 0 && <p>{props.partner ? 'Partner' : 'Distributor'} Email</p>}
                <input
                    type="email"
                    name="email"
                    value={field ? field.email : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${emailError || (props.error && !field.email) ? "border border-amber-900" : "border-0"}`}
                />
            </div>

            <div className="flex-col w-full md:w-3/12 md:mr-2 md:mt-0 mt-4">
                {props.index === 0 && <p>{props.partner ? 'Partner' : 'Distributor'} CNPJ</p>}
                <InputMask
                    type="text"
                    name="cnpj"
                    mask="99.999.999/9999-99"
                    value={field ? field.cnpj : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${cnpjError || (props.error && !field.cnpj) ? "border border-amber-900" : "border-0"}`}
                />
            </div>
        </div>
    );
}

export default DistributorLine;
