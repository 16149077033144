import React, { useState, useEffect, useContext } from "react";
import Loader from "../../components/Loader";
import './style.css'
import InputField from "../../components/InputField";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom";
import RadioButtonField from "../../components/RadioButtonField";
import SelectField from "../../components/SelectField";
import CheckBoxesField from "../../components/CheckBoxesField";
import ReactDatePicker from "react-datepicker";
import InputNumberField from "../../components/InputNuberField";
import UploadFile from "../../components/UploadFile";
import UploadFileField from "../../components/UploadFileField";
import Swal from "sweetalert2";
import Status from "../../components/Status";


function AddCampaign(props) {
    const [campaignName, setCampaignName] = useState()
    const [segment, setSegment] = useState()
    const [devices, setDevices] = useState({ "All devices": false, "Desktop": false, "Mobile": false, "Tablet/iPad": false })
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [type, setType] = useState(null)
    const [errorForm, setErrorForm] = useState({
        resp: false,
        campaignName: false,
        segment: false,
        devices: false,
        startDate: false,
        endDate: false,
    })
    const { location } = props;

    useEffect(() => {
        
        Swal.fire({
            title: 'Attention',
            text: "You don't have permission to access this page.",
            confirmButtonColor: "#1e2b3c",
            icon: 'warning',
            confirmButtonText: 'OK',
            allowEscapeKey: false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                history.push('/banners')
            }
        });

        if (location.state && location.state.type && location.state.type !== "add") {
            setCampaignName(location.state.name)
            setSegment(location.state.segment)
            setDevices(location.state.devices)
            setStartDate(new Date(location.state.startDate))
            setEndDate(new Date(location.state.endDate))
        }
        if (location.state.type) {
            setType(location.state.type)
        }
    }, [])
    const history = useHistory()

    const validationForm = () => {
        let error = {
            resp: false,
            campaignName: false,
            segment: false,
            devices: false,
            startDate: false,
            endDate: false,
        }
        if (!campaignName || campaignName.length <= 0) {
            error.campaignName = true
            error.resp = true
        }
        if (!segment || segment.length <= 0) {
            error.segment = true
            error.resp = true
        }
        if (!devices || devices.length <= 0) {
            error.devices = true
            error.resp = true
        }
        if (devices) {
            var checked = false
            for (let key in devices) {
                if (devices[key] == true) {
                    checked = true
                }
            }
            if (checked == false) {
                error.devices = true
                error.resp = true
            }

        }
        if (!startDate || startDate.length <= 0) {
            error.startDate = true
            error.resp = true
        }
        if (!endDate || endDate.length <= 0) {
            error.endDate = true
            error.resp = true
        }
        setErrorForm(error)
        return error.resp
    }

    const handleCreate = () => {
        Swal.fire({
            title: type == "add" ? "Do you want create this campaign?" : "Do you want edit this campaign?"
            ,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: "#1e2b3c"
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Creating the campaign...',
                    showConfirmButton: false,
                    allowOutsideClick: false
                })
                const error = validationForm()
                if (!error) {
                    Swal.fire({
                        title: 'Success.',
                        text: 'Your campaign was successfully create.',
                        showCancelButton: true,
                        cancelButtonText: "BACK TO CAMPAIGN",
                        confirmButtonColor: "#1e2b3c",
                        icon: 'success',
                        confirmButtonText: 'ADD ANOTHER CAMPAIGN',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                            return;
                        } else {
                            history.push('/campaigns')
                            return;
                        }
                    });
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please, fill the mandatory fields.',
                        confirmButtonColor: "#1e2b3c",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#1e2b3c",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        })
    }

    const handleImageChange = (e) => {
        // Get the file extension
        const file = e.target.files[0];
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop();

        if (fileExtension !== 'png') {
            setErrorForm({ ...errorForm, bannerFile: true })
            Swal.fire({
                title: 'Error.',
                text: 'A .xlsx in the correct template format file is required.',
                confirmButtonColor: "#1e2b3c",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        } else {
            setBannerFile(URL.createObjectURL(file));
        }

    };

    const future = (date) => new Date().setDate(new Date().getDate()) < date;

    const handleChange = (range) => {
        const [startDate, endDate] = range;
        setStartDate(startDate);
        setEndDate(endDate);
        if (startDate != null && endDate != null) {
            console.log(`Setting selected date: ${startDate.toISOString()} - ${endDate.toISOString()}`)
            // setDate(`${startDate.toISOString()} - ${endDate.toISOString()}`)
        }
    };

    const loading = true
    return <div className="flex-col md:p-10 p-5 height-limiter">
        <div className="flex-row">
            {type == "add" && <h1 className="lg:text-2xl ">New Campaign</h1>}
            {type == "edit" && <h1 className="lg:text-2xl ">{"Edit Campaign- " + campaignName.toUpperCase()}</h1>}
            {type == "details" && <h1 className="lg:text-2xl ">{"CAMPAIGN - DETAILS - " + campaignName.toUpperCase()}</h1>}
        </div>
        {
            loading ? <>
                <div className="bg-gray-200 flex flex-col justify-center gap-6 items-end self-stretch rounded mt-10 text-xs lg:text-base md:p-10 p-8">
                    <div className="flex flex-start gap-10 self-stretch">
                        <div className="flex w-1/3 flex-col items-start gap-2">
                            <InputField setValue={setCampaignName} value={campaignName} error={errorForm.campaignName} name="campaign-name" disabled={type == "details" ? true : false} label="Campaign Name" />
                        </div>
                        <div className="flex w-1/3 flex-col items-start gap-2">
                            <RadioButtonField name={"sub_business_segment"} label={"Sub Business Segment"} error={errorForm.segment} value={segment} disabled={type == "details" ? true : false} list={["SKF Center", "2W", "VA Distributor"]} setValue={setSegment} />
                        </div>
                        {type == "details" && <div className="flex w-1/3 flex-row items-center justify-end gap-2">
                            <p>Campaign Status</p>
                            <Status
                                key={'Status'}
                                id={"Status"}
                                currentStatus={"active"}
                                clickable="true" />
                        </div>}
                    </div>
                    <div className="flex flex-start gap-10 self-stretch">
                        <div className="flex w-auto gap-2">
                            <CheckBoxesField label={"Devices"} name={"devices"} list={devices} error={errorForm.devices} disabled={type == "details" ? true : false} setValue={setDevices} />
                        </div>
                        <div className="flex w-56 gap-2">
                            <div class="flex-col w-full gap-2">
                                <p>Start Date   {"  >  "}  End Date </p>
                                <ReactDatePicker
                                    // selected={selectedDate ? new Date(selectedDate) : null}
                                    selected={startDate}
                                    // onChange={(value) => setDate(value.toISOString())}
                                    placeholderText="yyyy/MM/dd - yyyy/MM/dd"
                                    onChange={handleChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    filterDate={future}
                                    selectsRange
                                    monthsShown={2}
                                    disabled={type == "details" ? true : false}
                                    showYearDropdown
                                    dateFormat="yyyy/MM/dd"
                                    className={`bg-[#0C1825] w-full col-span-3 lg:text-base placeholder-[#2D4560] px-5 py-2 rounded-md mt-2 text-base box-border ${errorForm.startDate || setErrorForm.endDate ? "border border-amber-900" : "border-0"}`} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-start gap-20 self-stretch">
                        <div className="flex w-1/3 flex-col items-start py-10 gap-2">
                            <p className="font-bold text-lg leading-7">Select a file to Banner</p>
                            <UploadFileField  setValue={handleImageChange} />
                        </div>
                        <div className="flex w-1/3 flex-col items-start py-10 gap-2">
                            <p className="font-bold text-lg leading-7">Select a file to Produtos em Destaque</p>
                            <UploadFileField setValue={handleImageChange} />
                        </div>
                    </div>
                    <div className="flex flex-start gap-20 self-stretch">
                        <div className="flex w-1/3 flex-col items-start py-10 gap-2">
                            <p className="font-bold text-lg leading-7">Select a file to Produtos Recomendados</p>
                            <UploadFileField setValue={handleImageChange} />
                        </div>
                        <div className="flex w-1/3 flex-col items-start py-10 gap-2">
                            <p className="font-bold text-lg leading-7">Select a file to Footer</p>
                            <UploadFileField setValue={handleImageChange} />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end mt-4 flex-row gap-4" >
                    <NavLink className="flex flex-row h-12 py-1 px-6 justify-center items-center gap-4 self-stretch rounded" to="/campaigns">
                        <p>CANCEL</p>
                    </NavLink>
                    {type == "add" && <button className="flex flex-row h-12 py-1 px-6 justify-center items-center gap-4 self-stretch rounded bg-[#1E2B3C]" onClick={handleCreate}>
                        <p>ADD CAMPAIGN</p>
                    </button>}
                    {type == "edit" && <button className="flex flex-row h-12 py-1 px-6 justify-center items-center gap-4 self-stretch rounded bg-[#1E2B3C]" onClick={handleCreate}>
                        <p>SAVE CAMPAIGN</p>
                    </button>}
                </div>
            </>
                :
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div>
        }
    </div>;
}

export default AddCampaign;