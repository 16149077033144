import React, { useState, useEffect, useContext } from "react";
import OrderLine from "../../components/OrderLine";
import DropDown from "../../components/DropDown";
import { useParams, useHistory, Link } from "react-router-dom";
import API from '../../utils/API';
import Swal from 'sweetalert2';
import { AppContext } from "../../utils/ContextProvider";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

function Service() {

    const [orders, setOrders] = useState([{ charge_id: "", gross_price: 0, service_dept: "", quantity: 1 }]);
    const [authorization, setAuthorization] = useState(null)
    const history = useHistory();
    const [totalCount, setTotalCount] = useState(1)
    const [invoice, setInvoice] = useState({
        customer_id: "",
        customer_own_order_number: "",
        orders_lines: [
            {
                charge_id: "",
                gross_price: 0,
                service_dept: "",
                quantity: 1
            }
        ],
        total_gross_price: "",
        text: "",
        email_to: "",
        email_cc: "",
        source: "beat"
    })
    const [error, setError] = useState(false);
    const context = useContext(AppContext);
    const role = context.getRole;
    const [token, setToken] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [errorList, setErrorList] = useState(
        {
            customer_id: "",
            customer_own_order_number: "",
            orders_lines: [
                {
                    charge_id: "",
                    gross_price: "",
                    service_dept: "",
                    quantity: ""
                },
            ],
            total_gross_price: "",
            text: "",
            email_to: "",
            email_cc: "",
            source: ""
        }
    );

    useEffect(() => {

        if (!context.getLogin) {
            history.push('/login');
            return;
        }

        if (role != 1 && role != 3){
            history.push('/listoforders');
            return;
        }
        let cancelToken = API.cancelToken();
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getToken(cancelToken).then(res => {
            console.log(res.data.token)
            setToken(res.data.token)
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 401 || err.response && err.response.status === 400) {
                Swal.fire({
                    title: 'Error.',
                    text: 'You are not authorized to perform this action.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(e => {
                    history.push('/home');
                });
            } else if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []
    );

    const submit_service = async (e) => {
        e.preventDefault()
        setErrorList({
            customer_id: "",
            customer_own_order_number: "",
            orders_lines: [
                {
                    charge_id: "",
                    cost_price: "",
                    service_dept: "",
                    quantity: ""
                },
            ],
            total_gross_price: "",
            text: "",
            email_to: "",
            email_cc: "",
            source: ""
        })
        let button = e.currentTarget
        button.disabled = true
        button.innerHTML = "Submitting..."
        // setError(false)
        let cancelToken = API.cancelToken();
        console.log(invoice)
        API.submitService(cancelToken, invoice, token).then(res => {
            Swal.fire({
                title: 'Success',
                text: 'Service Billing Submitted!',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            button.innerHTML = "Add new service"
            setSubmitted(true);
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.status !== 400 && err.response.status !== 401 && err.response.status !== 403) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                if (err.response.data.hasOwnProperty("message")) {
                    setErrorList(err.response.data.message)
                } else {
                    setErrorList(err.response.data)
                }
            }
            button.innerHTML = "Submit Service Invoice"
        }).finally(f => {
            button.disabled = false
        });
    }


    useEffect(() => {
        let newArr = { ...invoice, "orders_lines": orders }
        setInvoice(newArr);
    }, [orders])

    useEffect(() => {
        let tempTotal = 0
        let tempTotalCount = 0
        invoice.orders_lines.forEach(e => {
            if (!isNaN(parseFloat(e.gross_price))) tempTotal += (parseFloat(e.gross_price) * parseInt(e.quantity))
            tempTotalCount += parseInt(e.quantity)
        })
        let tempInvoice = { ...invoice, total_gross_price: tempTotal.toFixed(2) }
        setTotalCount(tempTotalCount)
        setInvoice(tempInvoice)
    }, [invoice.orders_lines])

    function removeAccents(text) {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }
    function removeSpecialCharacters(text) {
        var regex = /[!#$%^&*()_+{}:"<>?[\];',./\\|`~=€£¥¢¬°©®™§¶¦ªº†‡µ‰¼½¾‹›«»‘’“”„†‡•…‰′″‹›«»↔↑↓→←∆∑∞√∩∪∈∫≈≠≡≤≥÷×±∓∝∅∴∵⊂⊃⊆⊇⊕⊗⊥∠∨∧∥∣∂∇∈∉⊂⊄⊃⊅π]/g;
        return text.replace(regex, '');
    }

    function handleBlur(e){
        let new_value = null
        const { name, value } = e.target;
        new_value = value.trim().replace(/\s+/g, " ");

        let newArr = { ...invoice, [name]: new_value }
        setInvoice(newArr);
    }


    function handleChange(e) {
        let new_value = null
        const { name, value } = e.target;
        

        // new_value = value.trim().replace(/\s+/g, " ");
        new_value = value;
        if(name == "text"){
            console.log("GERE")
            new_value = removeAccents(new_value)
            new_value = new_value.replace(/@/g, 'A');
            new_value = new_value.replace(/&/g, 'e');
            new_value = removeSpecialCharacters(new_value)
        }
        else if (name.includes('price')) {
            new_value = new_value.replace(".", "")
            new_value = parseInt(new_value)
            if (isNaN(new_value)) new_value = ""
            else new_value = parseFloat(parseInt(new_value) / 100).toFixed(2)
        }
        
        let newArr = { ...invoice, [name]: new_value }
        setInvoice(newArr);
    }

    function selectedID(newIndex, rowID) {
        let newArr = [...orders]
        let newStage = newIndex;
        if (rowID + 1 <= orders.length) {
            newArr[rowID] = newStage;
        } else {
            newArr.push(newStage);
        }
        if (newArr.length === 0) newArr.push(-1)
        setOrders(newArr);
    }


    const addROW = (rowID) => {
        let newArr = [...orders];
        newArr.splice(rowID + 1, 0, {
            charge_id: "", gross_price: 0, service_dept: "", quantity: 1
        })
        setOrders(newArr)
    }

    function deleteROW(rowID) {
        let newArr = [...orders];
        newArr = newArr.filter((item, index) => {
            return (index !== rowID)
        })
        setOrders(newArr);
    }

    const resetInvoice =()=>{
        setInvoice({
                customer_id: "",
                customer_own_order_number: "",
                orders_lines: [
                    {
                        charge_id: "",
                        gross_price: 0,
                        service_dept: "",
                        quantity: 1
                    }
                ],
                total_gross_price: "",
                text: "",
                email_to: "",
                email_cc: "",
                source: "beat"
            })
        setOrders([{ charge_id: "", gross_price: 0, service_dept: "", quantity: 1 }])
        setSubmitted(false)
    }

    return <div className="flex-col md:p-10 p-5 height-limiter max-w-sm md:max-w-full m-auto text-sm md:text-base">

        <h1 className="mb-6 md:mb-12 text-xl">Service Invoice Trigger</h1>
        <div className="flex flex-col bg-blue w-full h-90 px-9 py-8">
            <div className="flex flex-col gap-x-1 my-3">
                <p>Source</p>
                <select name="source" onChange={(e) => handleChange(e)} onBlur={(e) => handleBlur(e)} className="w-full bg-gray-200 h-10 rounded-md px-2 text-white md:w-60">
                    <option value="beat" className="p-4">Bearing Tracker</option>
                    <option value="dynamics" className="p-4">Dynamics</option>
                </select>
            </div>
            <div className="flex md:flex-row gap-2 w-full wrap-override flex-col justify-center align-center">
                <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                    <p>COH Customer ID</p>
                    <input
                        value={invoice.customer_id}
                        placeholder="COH Customer ID"
                        name="customer_id"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.customer_id ? "border border-amber-900" : "border-0"}`} />
                    <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.customer_id}</p>
                </div>
                <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                    <p>Customer Own Order Number</p>
                    <input
                        value={invoice.customer_own_order_number}
                        placeholder="Customer Own Order Number"
                        name="customer_own_order_number"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.customer_own_order_number ? "border border-amber-900" : "border-0"}`} />
                    <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.customer_own_order_number}</p>
                </div>
                <div className="flex flex-col gap-x-1 mt-3 md:w-1/2 w-full">
                    <p>Text</p>
                    <input
                        placeholder="Text"
                        name="text"
                        value={invoice.text}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.text ? "border border-amber-900" : "border-0"}`} />
                    <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.text}</p>
                </div>
            </div>
            <div className="flex md:flex-row gap-2 w-full wrap-override flex-col justify-center align-center">
                <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                    <p>Email TO</p>
                    <input
                        placeholder="Email To"
                        name="email_to"
                        value={invoice.email_to}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.email_to ? "border border-amber-900" : "border-0"}`} />
                    <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.email_to}</p>
                </div>
                <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                    <p>Email CC</p>
                    <input
                        placeholder="Email CC"
                        value={invoice.email_cc}
                        name="email_cc"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.email_cc ? "border border-amber-900" : "border-0"}`} />
                    <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.email_cc}</p>
                </div>
                <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                    <p>Total Gross Price</p>
                    <input
                        placeholder="0.00"
                        name="total_gross_price"
                        type="text"
                        disabled={true}
                        value={!isNaN(invoice.total_gross_price) && parseFloat(invoice.total_gross_price).toFixed(2)}
                        className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.total_gross_price ? "border border-amber-900" : "border-0"}`} />
                    <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2">{errorList && errorList.total_gross_price}</p>
                </div>
                <div className="flex flex-col gap-x-1 mt-3 md:w-1/4 w-full">
                    <p>Total Quantity</p>
                    <input
                        placeholder="0.00"
                        name="total_gross_price"
                        type="text"
                        disabled={true}
                        value={totalCount}
                        className={`w-full bg-gray-200 h-10 rounded-md px-2 placeholder:text-lightWite ${error && invoice && !invoice.total_gross_price ? "border border-amber-900" : "border-0"}`} />
                    <p className="h-5 mt-1 px-1 text-sm text-red-600 font-bold mb-2"></p>
                </div>
            </div>
            <div className="mt-6">
                <p className="text-lg mb-4">Order Lines</p>
                {
                    orders.map((order, index) => {
                        return <OrderLine
                            key={index + 'order'}
                            orders={orders}
                            rowId={index}
                            addRow={addROW}
                            deleteRow={deleteROW}
                            selectedId={selectedID}
                            order={order}
                            error={error}
                            errorList={errorList}
                        />
                    })
                }
            </div>
        </div>
        <div className="flex flex-row align-end justify-end my-7">
            <button onClick={e => submitted ? resetInvoice() : submit_service(e)} className="h-10 w-64 bg-gray-200 rounded-md">{submitted ? "Add a New Invoice" : "Submit Service Invoice"}</button>
        </div>
    </div>
}

export default Service;