import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Status from "../../components/Status";
import API from "../../utils/API";
import Swal from 'sweetalert2';
import AddField from "../../components/addField";
import RequestUpdate from "../../components/RequestUpdate";
import Loader from "../../components/Loader";
import { AppContext } from "../../utils/ContextProvider";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import "./style.css"


function Availibility() {
    const { id } = useParams();
    const [availability, setAvailability] = useState([]);
    const [availabilityDetails, setavailabilityDetails] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [visibilityCustomerInfo, setVisibilityCustomerInfo] = useState(true);
    const [isRejecting, setIsRejecting] = useState("false");
    const [baseUrl, setBaseUrl] = useState("");
    const [visibilityAvailibility, setVisibilityAvailibility] = useState(false);
    const [listOfFields, setListOfFields] = useState([]);
    const [error, setError] = useState(false);
    const { REACT_APP_ENV } = process.env;


    const submitReply = async (e) => {
        console.log(listOfFields);
        console.log(availabilityDetails);

        e.preventDefault()
        for (let e of listOfFields) {
            if (!e.date || e.date === '' || !e.quantity || e.quantity === ''
                || !e.product_id || e.product_id === '' || !e.dpc || e.dpc === '') {
                setError(true)
                return
            }
        }

        let quantities = true;

        availabilityDetails.forEach((available) => {
            let total_quantity = 0;
            listOfFields.forEach((field) => {
                if (available.product_id === field.product_id) {
                    total_quantity += parseInt(field.quantity);
                }
            })
            console.log(available)
            console.log(`${available.quantity} - ${total_quantity}`)
            if (parseInt(available.quantity) !== parseInt(total_quantity)) {
                quantities = false;
            }
        })

        if (quantities === true) {
            let button = e.currentTarget
            button.disabled = true
            button.innerHTML = "SUBMITTING..."
            setError(false)
            API.availibilityResponse(listOfFields).then(res => {
                Swal.fire({
                    title: 'Success',
                    text: 'Availabilty Request Proposal Submitted!',
                    confirmButtonColor: "#131921",
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                let tempArr = [...availabilityDetails]
                tempArr[0].status_id = 1
                setavailabilityDetails(tempArr)
            }).catch(err => {
                if (err.message === 'cancelling') return
                if (err.response && (err.response.status === 500 || err.response.status === 400)) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(f => {
                button.disabled = false
                button.innerHTML = "Submit"
            });
        } else {
            Swal.fire({
                title: 'Wait.',
                text: "Some quantities don't match.",
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        }
    }


    function selectedID(newIndex, rowID, type) {
        switch (type) {

            case 'fields':
                let newArr = [...listOfFields]
                let newStage = newIndex;
                if (rowID + 1 <= listOfFields.length) {
                    newArr[rowID] = newStage;
                } else {
                    newArr.push(newStage);
                }
                if (newArr.length === 0) newArr.push(-1)
                setListOfFields(newArr);
                break
            case 'avail':
                let newArr2 = [...availabilityDetails]
                let newStage2 = newIndex;
                if (rowID + 1 <= availabilityDetails.length) {
                    newArr2[rowID] = newStage2;
                } else {
                    newArr2.push(newStage2);
                }
                if (newArr2.length === 0) newArr2.push(-1)
                setavailabilityDetails(newArr2);
                break
        }
    }

    const addROW = (rowID, product_id, type) => {
        switch (type) {

            case 'fields':
                let newArr = [...listOfFields];
                newArr.splice(rowID + 1, 0, {
                    product_id: product_id,
                    quantity: '',
                    date: '',
                    request_id: id,
                    dpc: ''
                })
                setListOfFields(newArr)
                break
            case 'avail':
                let newArr2 = [...availabilityDetails];
                let obj = { ...availabilityDetails[0], product_id: '', quantity: '' }
                newArr2.push(obj)
                setavailabilityDetails(newArr2)
                break
        }
    }
    function deleteROW(rowID, type) {
        switch (type) {
            case 'fields':
                let newArr = [...listOfFields];
                let count = 0;
                listOfFields.forEach(element => {
                    if (element.product_id === listOfFields[rowID].product_id) {
                        count += 1;
                    }
                });
                if (count > 1) {
                    newArr = newArr.filter((item, index) => {
                        return (index !== rowID)
                    })
                }
                setListOfFields(newArr);
                break
            case 'avail':
                let newArr2 = [...availabilityDetails];
                newArr2 = newArr2.filter((item, index) => {
                    return (index !== rowID)
                })
                setavailabilityDetails(newArr2);
                break
        }

    }

    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if (REACT_APP_ENV === 'developer') {
            setBaseUrl('https://developer.azpartes.com/')
        }
        if (REACT_APP_ENV === 'staging') {
            setBaseUrl('https://staging.azpartes.com/')
        }
        if (REACT_APP_ENV === 'production') {
            setBaseUrl('https://azpartes.com/')
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getAvailability(cancelToken, id).then(res => {
            res.data ? setavailabilityDetails(res.data.company) : setavailabilityDetails([]);
            res.data ? setAvailability(res.data) : setAvailability([]);
            setQuantity(res.data.company[0].quantity)
            if (res.data && res.data.company[0].status_id !== 0) {
                setListOfFields(res.data.response)
            } else {
                setListOfFields(() => {
                    let new_arr = []
                    res.data.company.map((comp) => {
                        new_arr.push(
                            {
                                product_id: comp.product_id,
                                quantity: '',
                                date: '',
                                request_id: id,
                                dpc: ''
                            }
                        )
                    })
                    return new_arr
                })
            }
        }).catch(err => {
            setavailabilityDetails([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []
    );

    const resendEmail = e => {
        Swal.fire({
            title: "Please add CC's to the email",
            input: "text",
            showCancelButton: true,
            confirmButtonText: "SEND",
            cancelButtonText: "CANCEL",
            confirmButtonColor: "#131921",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                cc_email = result.value
                API.availibilityResendEmail(id, cc_email).then(res => {
                    Swal.fire({
                        title: 'OK',
                        text: `Email encaminhado para cliente com cópia para ${cc_email}.`,
                        confirmButtonColor: "#131921",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                }).catch(err => {
                    Swal.fire({
                        title: 'Oops..',
                        html: `Erro ao encaminhar email. Tente novamente mais tarde`,
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                })
            }
        });
    }

    const replyAvailibilityRequest = e => {
        e.preventDefault()
        let duplicates = false;
        for (var i = 0; i < availabilityDetails.length; i++) {
            for (var j = 0; j < availabilityDetails.length; j++) {
                if (availabilityDetails[i].product_id === availabilityDetails[j].product_id && i != j) {
                    duplicates = true;
                }
            }
        }
        if (duplicates) {
            Swal.fire({
                title: 'Wait.',
                text: 'You have entered two products with the same ID.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        } else {
            if (availabilityDetails[0].status_id === 0) {
                let button = e.currentTarget
                let tempQtt = 0
                let prodSet = new Set()
                for (let e of availabilityDetails) {
                    tempQtt += parseInt(e.quantity)
                    prodSet.add(e.product_id)
                    if (!e.quantity || e.quantity === ''
                        || !e.product_id || e.product_id === '') {
                        setError(true)
                        return
                    }
                }
                if (availabilityDetails.length === 0 && tempQtt !== quantity) {
                    Swal.fire({
                        title: 'Wait!',
                        html: `<p>Submitted quantity does not match request</p>
                            <p>Requested: ${quantity}</p>
                                <p>Submitted: ${tempQtt}</p>
                                <p>Are you sure you want to continue?</p>`,
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Yes',
                        showCancelButton: true
                    }).then(async result => {
                        if (result.isConfirmed) {
                            submitOrderUpdate(button, prodSet)
                        } else {
                            return
                        }
                        setError(false)
                    })
                } else {
                    submitOrderUpdate(button, prodSet)
                }
            } else {
                setVisibilityCustomerInfo(!visibilityCustomerInfo);
                setVisibilityAvailibility(!visibilityAvailibility);
            }
        }
    }

    const submitOrderUpdate = async (button, prodSet) => {
        button.disabled = true
        button.innerHTML = "UPDATING REQUEST..."
        try {
            let wrongProd = await API.validateProducts({ list: Array.from(prodSet), user: availabilityDetails[0].user_id })
            console.log(wrongProd)
            let wrongProds = wrongProd.data.product
            if (wrongProds.length > 0) {
                let tempList = []
                for (let item of listOfFields) {
                    if (wrongProds.includes(item.product_id)) {
                        item = { ...item, wrongProd: true }
                        tempList.push(item)
                    }
                }
                setListOfFields(tempList)
                Swal.fire({
                    title: 'Oops..',
                    html: `<p>Products out of client's pricelist:</p>
                           <p>${wrongProds.join(", ")}</p>`,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
                button.disabled = false
                button.innerHTML = "REPLY AVAILABILITY REQUEST"
                return
            } else {
                API.availibilityUpdate(availabilityDetails, id).then(res => {
                    setListOfFields(() => {
                        let new_arr = []
                        availabilityDetails.map((comp) => {
                            new_arr.push(
                                {
                                    product_id: comp.product_id,
                                    quantity: '',
                                    date: '',
                                    request_id: id,
                                    dpc: ''
                                }
                            )
                        })
                        return new_arr
                    })
                    setVisibilityCustomerInfo(!visibilityCustomerInfo);
                    setVisibilityAvailibility(!visibilityAvailibility);
                    button.disabled = false
                    button.innerHTML = "Reply Availability Request"
                }).catch(err => {
                    if (err.message === 'cancelling') return
                    if (err.response && (err.response.status === 500 || err.response.status === 400)) {
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                    button.disabled = false
                    button.innerHTML = "Reply Availability Request"
                })

            }
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && (err.response.status === 500 || err.response.status === 400)) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
            button.disabled = false
            button.innerHTML = "Reply Availability Request"
            return
        }
    }

    function handleReasonChange(event) {
        const { name, value } = event.target;
        setIsRejecting(value)
    };

    const rejectAvailibilityRequestFinal = (e) => {
        let button = e.currentTarget
        console.log(isRejecting)
        if (isRejecting === "false") {
            setIsRejecting("true")
        } else {
            Swal.fire({
                title: 'Wait!',
                html: `<p>Are you sure you want to reject this request?</p>`,
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Yes',
                showCancelButton: true
            }).then(async result => {
                if (result.isConfirmed) {
                    button.disabled = true
                    button.innerHTML = "REJECTING..."
                    API.availibilityReject(id, { "message": isRejecting }).then(res => {
                        console.log(res)
                        Swal.fire({
                            title: 'Success',
                            text: 'Availabilty Request Rejected!',
                            confirmButtonColor: "#131921",
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        }).then(async result => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        })
                    }).catch(err => {
                        console.log(err)
                        if (err.message === 'cancelling') return
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                        button.disabled = false
                        button.innerHTML = "Reject Availability Request"
                    })
                } else if (result.isDenied) {
                    return
                }
            });
        }

    }

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    function goBack() {
        setVisibilityCustomerInfo(true);
        setVisibilityAvailibility(false);
    }

    return <div className="flex-col md:p-10 p-5  height-limiter">
        <div className="flex-row justify-between">
            <div>
                <h1 className="text-3xl">Availibility #{id}</h1>
                <div className="flex-row lg:text-xs text-xs mt-1">
                    <p className="font-bold text-base">Created At: </p>
                    <p className="pl-2 text-base">{availabilityDetails && databaseToDate(availabilityDetails.length > 0 && availabilityDetails[0].created_at)}</p>
                </div>
            </div>

            {availabilityDetails.length > 0 ?
                <div className="flex-row h-10">
                    {
                        availabilityDetails[0] && availabilityDetails[0].status == "pending approval" &&
                        <button
                            className="bg-gray-200 mx-2 text-sm lg:text-sm  h-50 cursor-pointer rounded-md p-4 flex justify-center items-center"
                            onClick={(e) => resendEmail(e)}>
                            RESEND EMAIL
                        </button>
                    }
                    <Status
                        currentStatus={availabilityDetails[0] && availabilityDetails[0].status}
                        clickable="false" />
                </div>

                :
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div>}
        </div>

        <div className="bg-blue h-full rounded mt-10 text-xs lg:text-base md:p-10 p-5 ">
            {availabilityDetails ?
                <div>
                    <div id="firstRow" className="flex-row items-center gap-x-4 ">
                        <div className="flex-col py-2 lg:w-2/12">
                            <p>Customer COH COH Customer ID</p>
                        </div>
                        <div className="flex-col py-2 lg:w-5/12">
                            <p>SKU</p>
                        </div>
                        <div className="flex-col py-2 lg:w-3/12">
                            <p>Quantity</p>
                        </div>
                    </div>
                    {
                        availabilityDetails && availabilityDetails.map((e, index) => (
                            <RequestUpdate
                                key={`request_index_${index}`}
                                visibilityAvailibility={visibilityAvailibility}
                                availabilityDetails={availabilityDetails}
                                rowId={index}
                                addRow={addROW}
                                deleteRow={deleteROW}
                                selectedId={selectedID}
                                field={e}
                                user_id={availabilityDetails[0].user_id}
                                readOnly={availabilityDetails.length > 0 && availabilityDetails[0].status_id !== 0}
                                error={error} />
                        ))
                    }
                    <div style={{ display: visibilityCustomerInfo === true ? "" : "none" }} className="pt-5">
                        <div className="flex-col">
                            <p>Customer Additional Information</p>
                            <div className=" h-24 bg-gray-200 rounded-md text-lightWite pl-3 pt-2 mt-1">
                                <p>{availabilityDetails.length > 0 && availabilityDetails[0].message}</p>
                            </div>
                        </div>
                        {availability && availability.company && availability.company[0].response_token &&
                            <div className="flex-col mt-4 text-base">
                                <p className="mb-3">Actions URL</p>
                                <p><strong>Accept URL: </strong>{baseUrl}aprovarsolicitacao?ref={availability.company[0].response_token}</p>
                                <p className="mt-2"><strong>Reject URL: </strong>{baseUrl}rejeitarsolicitacao?ref={availability.company[0].response_token}</p>
                            </div>

                        }
                    </div>
                    <div style={{ display: isRejecting !== "false" ? "" : "none" }} className="pt-5">
                        <div className="flex-col">
                            <p>Reject Reason</p>
                            <textarea text="Describe the reason why this availability request is being rejected" name="reject_reason" onChange={handleReasonChange} id="reject_reason" className="w-full rounded-md h-24 p-3 px-3 mt-2 bg-gray-200" required />
                        </div>
                    </div>
                    {/* {
                        visibilityAvailibility &&
                        <div className="flex flex-col gap-x-4 lg:flex-row mt-5">
                            <div className="w-full py-2 lg:w-2/12">
                                Date
                            </div>
                            <div className="w-full py-2 lg:w-5/12">
                                SKU
                            </div>
                            <div className="w-full py-2 lg:w-3/12">
                                Quantity
                            </div>
                            <div className="w-full py-2 lg:w-3/12">
                                Pack
                            </div>
                            <div className="w-full py-2 lg:w-3/12">
                                DPC
                            </div>
                        </div>
                    } */}
                    {
                        visibilityAvailibility &&
                        <div className="text-2xl mt-5">Availability</div>
                    }

                    {
                        listOfFields.length > 0 && availabilityDetails.length > 0 ?
                            listOfFields.map((field, index) => (
                                <AddField
                                    key={index + 'fields'}
                                    visibilityAvailibility={visibilityAvailibility}
                                    availabilityDetails={availabilityDetails}
                                    availability={availability}
                                    listOfFields={listOfFields}
                                    rowId={index}
                                    addRow={addROW}
                                    deleteRow={deleteROW}
                                    selectedId={selectedID}
                                    field={field}
                                    product_id={availabilityDetails.product_id}
                                    request_id={id}
                                    readOnly={availabilityDetails[0].status_id !== 0}
                                    error={error}
                                />
                            )) :
                            (availabilityDetails.length > 0 && availabilityDetails[0].status_id === 0) &&
                            <AddField
                                visibilityAvailibility={visibilityAvailibility}
                                availabilityDetails={availabilityDetails}
                                listOfFields={listOfFields}
                                rowId={0}
                                addRow={addROW}
                                deleteRow={deleteROW}
                                selectedId={selectedID}
                                field={null}
                                request_id={id}
                                product_id={availabilityDetails.product_id}
                                error={error}
                            />

                    }

                </div> :
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div>}
        </div>
        <div className="flex-row-nowrap align-center justify-center"></div>
        <div style={{ display: visibilityCustomerInfo === true ? "" : "none" }} className="flex justify-end">
            {availabilityDetails.length > 0 && (availabilityDetails[0].status_id === 0) &&
                <button
                    className="bg-red-600 mx-2 text-sm lg:text-sm mt-5 cursor-pointer rounded-md h-10 p-4 flex justify-center items-center"
                    onClick={(e) => rejectAvailibilityRequestFinal(e)}
                    disabled={availabilityDetails[0].status_id !== 0}>
                    <p>
                        {availabilityDetails.length > 0 && <p>{availabilityDetails[0].status_id === 0 ? isRejecting == "true" ? "SUBMIT REJECTION" : "REJECT AVAILABILITY REQUEST" : "REJECTED"}</p>}
                    </p>
                </button>}
            <button
                className="bg-gray-200 mx-2 text-sm lg:text-sm mt-5 cursor-pointer rounded-md h-10 p-4 flex justify-center items-center"
                onClick={(e) => replyAvailibilityRequest(e)}>
                {availabilityDetails.length > 0 && availabilityDetails[0].status_id === 0 ? "REPLY AVAILABILITY REQUEST" : "VIEW AVAILABILITY RESPONSE"}
            </button>
        </div>
        <div className="gap-4 flex justify-end">
            {
                visibilityAvailibility &&
                <button className="bg-gray-200 text-sm lg:text-sm mt-5 cursor-pointer rounded-md w-44 h-10 flex justify-center items-center" onClick={goBack}>
                    GO BACK
                </button>
            }
            {(availabilityDetails.length > 0 && availabilityDetails[0].status_id === 0) &&
                <div style={{ display: visibilityAvailibility === true ? "" : "none" }} className="gap-5 flex justify-end">
                    <button
                        className="bg-gray-200 text-sm lg:text-sm mt-5 cursor-pointer rounded-md w-44 h-10 flex justify-center items-center"
                        onClick={e => submitReply(e)}>
                        <p>SUBMIT</p>
                    </button>
                </div>}
        </div>
    </div>;
}

export default Availibility; 