import React, { useState, useEffect, useContext } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import UserLine from "../../components/UserLine"
import API from "../../utils/API";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import * as XLSX from 'xlsx'
import "./style.css"
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';


function BulkExcludedIds() {

    let cancelToken = API.cancelToken();

    let [exclusionRegistration, setExclusionRegistration] = useState([]);
    const context = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
    }, [])


    useEffect(()=>{
        console.log(exclusionRegistration)
    },[exclusionRegistration])


    const handleSubmit = (e) => {
        e.preventDefault()
        cancelToken = API.cancelToken();
        let button = e.currentTarget
        button.disabled = true
        button.innerHTML = "Adding..."

        API.createExlusion(cancelToken, exclusionRegistration).then(res => {
            console.log(res)
            let html = ""
            if (res.data.errors !== null){
                html+=( "<p>" + res.data.errors + "</p>")
            }
            if (res.data.existent !== null){
                html+=( "<p>" + res.data.existent + "</p>")
            }
            if (html !== ""){
                Swal.fire({
                    title: "Partial Success",
                    html: html,
                    confirmButtonColor: "#131921",
                    icon: "warning",
                    confirmButtonText: 'Ok'
                });
            }else{
                history.push('/excludedids');
            }
            
            
            button.disabled = false
            button.innerHTML = "Add"
        }).catch(err => {
            console.log(err)
            button.disabled = false
            button.innerHTML = "Add"
            if (err.message === 'cancelling') return
            if (err.response && (err.response.status === 412)) {
                let errorMessage = ""
                for (let item of err.response.data.error) {
                    errorMessage += (`<li>${item}</li>`)
                }
                Swal.fire({
                    title: 'Error.',
                    html: "<p>There are divergent fields in the company registration, please request correction as specified below.</p><ul>" + errorMessage + "</ul>",
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: err.response.error,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }

    //upload file section 
    // on change states
    const [excelFileError, setExcelFileError] = useState(null);

    // handle File
    const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        processFile(selectedFile);
    }

    function processFile(selectedFile) {
        if (selectedFile) {
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);

                reader.onload = (e) => {
                    setExcelFileError(null);
                    if (e.target.result !== null) {
                        const workbook = XLSX.read(e.target.result, { type: 'buffer' });
                        const worksheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[worksheetName];
                        const data = XLSX.utils.sheet_to_json(worksheet, {"defval": null, raw:false});
                        setExclusionRegistration(data);

                    }
                }
            }
            else {
                setExcelFileError('Please select only excel file types');
            }
        }
        else {
            console.log('Please select a file');
        }
    }

    const onDrop = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
        let file = e.dataTransfer.files[0];
        console.log(file);
        processFile(file);
    }

    const onDragEnter = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = '#131930';
        e.currentTarget.style.border = '1px solid white';
    }
    const onDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
    }


    return (
        <div className="registration-page flex-col md:p-10 p-5 height-limiter">
            <ScreenHeader
                title="Qlikview Bulk Insertion"
                link='https://skfbuy-public-assets.s3.amazonaws.com/exclusion_template.xlsx'
                external={true}
                buttonDescription="Download Template"
                hasButton="true" />
            <div id="uploadFile">
                <div className="pt-10 pb-6">
                    <p>Upload .xlsx Template</p>
                </div>
                {excelFileError && <div className="py-2 text-red-600">{excelFileError}</div>}
                <div className="bg-blue flex justify-center align-center h-52 rounded text-xs lg:text-base">
                    <div
                        onClick={() => { document.getElementById('getFile').click() }}
                        onDrop={e => onDrop(e)}
                        onDragEnter={e => onDragEnter(e)}
                        onDragLeave={e => onDragLeave(e)}
                        onDragStart={e => e.preventDefault()}
                        onDragEnd={e => e.preventDefault()}
                        onDragOver={e => onDragEnter(e)}
                        onDrag={e => e.preventDefault()}
                        className="flex w-40 h-40 text-center border-white border border-dashed rounded-md justify-center items-center cursor-pointer">
                        <input type='file' name="getFile" id="getFile" style={{ display: "none" }} onChange={handleFile} />
                        <div>
                            <h1>DROP HERE</h1>
                            <h1>OR</h1>
                            <h1>SELECT A FILE</h1>
                        </div>
                    </div>
                </div>
            </div>
            {exclusionRegistration.length > 0 && <div className="w-full md:w-1/5 md:mr-2 mt-4 float-right">
                    <button onClick={handleSubmit} className="bg-blue cursor-pointer flex items-center justify-center md:px-5 md:text-base placeholder-white py-2 rounded-md text-xs w-full"
                        type="submit" >
                        Add
                    </button>
                </div>}
        </div>);
}

export default BulkExcludedIds; 