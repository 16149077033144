import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




function RequestUpdate(props) {

    
    const availabilityDetails = props.availabilityDetails;
    const [field, setField] = useState({ product_id: '', quantity: '' });

    
    useEffect(() => {
        props.field ? setField(props.field) : setField(null)
    }, [props])
    
    function handleChange(e) {
        const { name, value } = e.target;
        props.selectedId({ ...field, [name]: value }, props.rowId, 'avail');
    };

    return (
        <div id="firstRow" className="flex-row items-center gap-x-4 ">
            <div className={`flex-col py-2 lg:w-2/12`}>
                <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite p-5 flex align-center">
                    <p>{field.user_id}</p>
                </div>
            </div>
            <div className={`flex-col py-2 ${!props.visibilityAvailibility ? "lg:w-5/12" : "lg:w-6/12"}`}>
                <input
                    type="text"
                    name="product_id"
                    value={field && field.product_id !== "" ? field.product_id : ""}
                    defaultValue={props.product_id && props.product_id}
                    onChange={handleChange}
                    className={`w-full h-10 bg-gray-200 rounded-md p-5 placeholder-neutral-400 ${field && field.wrongProd ? "border border-amber-900" : "border-0"}`}
                    placeholder="Product ID"
                    disabled={props.readOnly || props.visibilityAvailibility}
                />
            </div>

            <div className="flex-col py-2 lg:w-3/12">
                <input
                    type="text"
                    name="quantity"
                    value={field && field.quantity !== "" ? field.quantity : ''}
                    className={`w-full h-10 bg-gray-200 rounded-md p-5 placeholder-neutral-400 ${props.error && (!field.quantity || field.quantity === '') ? "border border-amber-900" : "border-0"}`}
                    onChange={handleChange}
                    placeholder="Quantity"
                    disabled={props.readOnly || props.visibilityAvailibility}
                />
            </div>
            {!props.readOnly && !props.visibilityAvailibility && <div className='flex-row-nowrap align-center'>
                <button
                    className="bg-gray-200 rounded flex-row h-10 w-10  align-center justify-center mr-4"
                    onClick={() => { !props.readOnly && (availabilityDetails.length > 1) ? props.deleteRow(props.rowId, 'avail') : null }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-minus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </button>
                {(!props.readOnly && (field && field.quantity && field.product_id) && ((props.rowId + 1) === availabilityDetails.length)) &&
                    <button
                        className="bg-gray-200 rounded flex-row h-10 w-10  align-center justify-center"
                        onClick={()=>{props.addRow(props.rowId, 0,"avail")}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                    </button>
                }
            </div>}
        </div>

    )
}

export default RequestUpdate; 