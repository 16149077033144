import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import GlobalSVG from '../../utils/GlobalSVG';
import ReactGA from 'react-ga';
import { NavLink } from "react-router-dom/cjs/react-router-dom";

// const listProductGuidesMock = [{ sequence: "1", name: "Frete Grátis", featuredGuide: "Yes", segment: "SKF Center", startDate: "2023-01-31", endDate: "2023-02-28", status: "active", productGuideFile: "" },
// { sequence: "2", name: "Novidades", segment: "SKF Center", featuredGuide: "No", startDate: "2023-03-01", endDate: "2023-03-30", status: "active", productGuideFile: "" },
// { sequence: "3", name: "Mais Vendidos", segment: "SKF Center", featuredGuide: "No", startDate: "2023-04-01", endDate: "2023-04-30", status: "active", productGuideFile: "" },
// { sequence: "4", name: "Exclusivos", segment: "SKF Center", featuredGuide: "Yes", startDate: "2023-05-01", endDate: "2023-05-30", status: "active", productGuideFile: "" }]

const listProductGuidesMock = []

export default function ProductGuides() {
    const [listOfProductGuides, setListOfProductGuides] = useState(listProductGuidesMock);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(false);
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [sorting, setSorting] = useState("desc")


    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }


    const downloadTemplate = () => {
        Swal.fire({
            title: 'Template Successfully Downloaded.',
            confirmButtonColor: "#131921",
            icon: 'success',
            confirmButtonText: 'Ok'
        }).then(() => {
            // Create a link element
            const link = document.createElement('a');
            // Set the URL of the file
            link.href = '/templates/bannerTemplate.xlsx'; // Update with your file's relative path
            // Set the download attribute to prompt a download
            link.setAttribute('download', 'productGuideTemplate.xlsx'); // Update with the desired file name
            // Append the link to the body
            document.body.appendChild(link);
            // Programmatically click the link
            link.click();
            // Remove the link from the document
            link.remove();
        });
    }


    useEffect(() => {
        let pages = []
        console.log(`maxPages: ${maxPages}`)
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])

    const sortArrow = () => {
        if (sorting == "asc") {
            setSorting("desc")
            setCurrentPage(1)
            localStorage.setItem('sorting', "desc");
        } else {
            setSorting("asc")
            setCurrentPage(1)
            localStorage.setItem('sorting', "asc");
        }
    }

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <div className="flex flex-row justify-between my-4">
                <div className="flex-row">
                    <h1 className="lg:text-2xl ">Product Guides</h1>
                </div>
                <div className="flex flex-row gap-4" >

                    <div className="cursor-pointer flex flex-row h-10 py-1 px-6 justify-center items-center gap-4 self-stretch rounded bg-[#1E2B3C]" onClick={downloadTemplate}>
                        Download template
                    </div>
                    <Link className="flex flex-row h-10 py-1 px-6 justify-center items-center gap-4 self-stretch rounded bg-[#1E2B3C]" to={{
                        pathname: '/addproductguide',
                        state: { type: "add" }
                    }}>
                        {GlobalSVG.plus()}
                        <p>Add guide</p>
                    </Link>

                </div>
            </div>

            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-1/12 text-center flex items-center pl-8">Sequence <span className="arrow-up" onClick={sortArrow}>{sorting == "asc" ? GlobalSVG.upArrow() : GlobalSVG.downArrow()}</span></div>
                        <div className="w-2/12 text-center">Name</div>
                        <div className="w-1/12 text-center">Sub Business Segment</div>
                        <div className="w-1/12 text-center">Start Date</div>
                        <div className="w-1/12 text-center">End Date</div>
                        <div className="w-2/12 text-center">Status</div>
                        <div className="w-2/12 text-center">Product List</div>
                        <div className="w-1/12 text-center">Ações</div>
                        <div className="w-1/12 text-center">Details</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!listOfProductGuides || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        listOfProductGuides.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            listOfProductGuides.map((productGuide, index) => {
                                return <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'productGuide'}>
                                    <div className="w-1/12 text-center">{index + 1}</div>
                                    <div className="w-2/12 text-center">{productGuide.name}</div>
                                    <div className="w-1/12 text-center">{productGuide.segment}</div>
                                    <div className="w-1/12 text-center">{productGuide.startDate}</div>
                                    <div className="w-1/12 text-center">{productGuide.endDate}</div>
                                    <div className="w-2/12 text-center flex justify-center">
                                        <div className="w-32">
                                            <Status
                                                key={productGuide.id + 'productGuideStatus'}
                                                id={productGuide.id}
                                                currentStatus={productGuide.status}
                                                clickable="true" />
                                        </div>
                                    </div>
                                    <div className="w-2/12 text-center flex flex-row justify-center cursor-pointer">
                                        <div className="cursor-pointer flex flex-row h-10 py-1 px-6 justify-center items-center gap-4 self-stretch rounded bg-[#1E2B3C]" to="/addbanner"  onClick={downloadTemplate}>
                                            Download
                                        </div>
                                    </div>
                                    <div className="w-1/12 text-center flex flex-row justify-center">
                                        <Link className="text-center w-6 h-6 flex justify-center cursor-pointer" to={{
                                            pathname: '/addproductguide',
                                            state: { ...productGuide, type: "edit" }
                                        }}>
                                            <img src={require('../../assets/images/edit.png')} alt="edit" />
                                        </Link>
                                    </div>
                                    <div className="w-1/12 text-center flex flex-row justify-center">
                                        <Link className="text-center w-6 h-6 flex justify-center cursor-pointer" to={{
                                            pathname: '/addproductguide',
                                            state: { ...productGuide, type: "details" }
                                        }}>
                                            <img src={require('../../assets/images/subject.png')} alt="details" />
                                        </Link>
                                    </div>
                                </div>;

                            })
                    }
                </div>
            </div>
            {
                !loading && maxPages ?
                    <div className="page-selector flex-row-nowrap space-between">
                        <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                            {
                                !mobile && <p>Previous</p>
                            }
                        </div>
                        <div className="pages-section flex-row-nowrap">
                            {
                                pages
                            }
                        </div>
                        <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            {
                                !mobile && <p>Next</p>
                            }
                            <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                        </div>
                    </div> :
                    null
            }
        </div>);
}
