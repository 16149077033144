import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import CatalogSearchBar from "../../components/CatalogSearchBar";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import * as XLSX from 'xlsx'
import Loader from "../../components/Loader";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import API from "../../utils/API";
import Swal from 'sweetalert2';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import { useLanguage } from '../../context/LanguageContext';


function PriceCalculation() {
    const { translate } = useLanguage();

    // const priceCalculation = API.getPriceCalculation();
    const [list, setList] = useState(null)
    const [afterSearchList, setAfterSearchList] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    const [isClicked, setIsClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [filters, setFilters] = useState([]);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false)
    const [reportLoading, setReportLoading] = useState(false);
    const [reportLoading2, setReportLoading2] = useState(false);
    const [searchTerm, setsearchTerm] = useState('')
    const [productDetails, setProductDetails] = useState([]);
    const [productDetailsAll, setProductDetailsAll] = useState([]);
    const location = useLocation();
    const [b2e, setB2e] = useState(false)


    useEffect(() => {
        if (location.pathname.includes("B2E")) {
            setB2e(true)
            setsearchTerm('XAZ102')
        } else {
            setB2e(false)
            setMaxPages(null)
            setList([])
        }
    }, [location])
    useEffect(() => {
        if (b2e) {
            doSearch()
        }
    }, [b2e])

    useEffect(() => {
        if (productDetails.length > 0) {
            console.log("GET DATA")
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(productDetails);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Calculation Errors');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, "Tax_Calculation_Errors.xlsx");
        }
    }, [productDetails]);

    useEffect(() => {
        if (productDetailsAll.length > 0) {
            console.log("MAKING EXCEL")
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(productDetailsAll);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Price List');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, "Price_list_data.xlsx");
        }
    }, [productDetailsAll]);

    function getData() {
        setReportLoading2(true)
        Swal.fire({
            title: 'Creating report...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        API.getTaxErrorInformation(cancelToken).then(res => {
            if (res.data) {
                var arr = [['Company', 'SKU', 'Operação', 'Origem', 'Pais Origem', 'Quantidade', 'Valor Unitário', 'Erro']]
                console.log(res.data)
                res.data.errors.forEach(errors => {
                    arr.push([errors['skf_company_id'], errors['skf_product_id'], errors['operacao'], errors['origem'], errors['paisOrigem'], errors['quantidade'], errors['vlUnitario'], errors['erro']])
                });
                setProductDetails(arr)
                setReportLoading2(false)
            } else {
                setReportLoading2(false)
                setProductDetails(null);
            }
        })
    }

    async function getPricelist() {
        setReportLoading(true)
        Swal.fire({
            title: 'Creating report...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        var arr = null
        if (!b2e) {
            arr = [[
                'COH COH Customer ID', 'SKU', 'Price List', 'Net Price', 'Operação', 'Origem',
                'País Origem', 'Quantidade', 'NCM', 'Código CFOP', 'Valor Base ICMS', 'Alíquota ICMS', 'Valor ICMS',
                'Valor Base IPI', 'Alíquota  IPI', 'Valor IPI', 'Valor Base ICMS-ST', 'Alíquota ICMS-ST',
                'Valor ICMS-ST', 'Valor Base ISS', 'Alíquota ISS', 'Valor ISS', 'Valor Base PIS', 'Alíquota PIS',
                'Valor PIS', 'Valor Base COFINS', 'Alíquota COFINS', 'Valor COFINS', 'Valor Total (todos os impostos incluídos)', 'Observação'
            ]]
        } else {
            arr = [[
                'COH COH Customer ID', 'SKU', 'Price List', 'Net Price', 'Operação', 'Origem',
                'País Origem', 'Quantidade', 'NCM', 'Código CFOP', 'Valor Base ICMS', 'Alíquota ICMS', 'Valor ICMS',
                'Valor Base IPI', 'Alíquota  IPI', 'Valor IPI', 'Valor Base ICMS-ST', 'Alíquota ICMS-ST',
                'Valor ICMS-ST', 'Valor Base ISS', 'Alíquota ISS', 'Valor ISS', 'Valor Base PIS', 'Alíquota PIS',
                'Valor PIS', 'Valor Base COFINS', 'Alíquota COFINS', 'Valor COFINS', 'Valor Total (todos os impostos incluídos)', 'Preço Gross', 'Observação'
            ]]
        }
        let page = 1
        while (true) {
            try {
                let res = await API.getPriceWithTax(cancelToken, searchTerm, page)
                page++
                let maxPage = res.data.max_pages
                if (res.data.products) {
                    // For B2E
                    if (!b2e) {
                        for (let product of res.data.products) {
                            arr.push([
                                searchTerm, product['PRODUCT_ID'], product['CMPLID'], product['precoTotal'], product['operacao'], product['origem'],
                                product['paisOrigem'], product['quantidade'], product['nbmCodigo'], product['cfopCodigo'], product['vlBaseIcms'], product['aliqIcms'], product['vlIcms'],
                                product['vlBaseIpi'], product['aliqIpi'], product['vlIpi'], product['vlBaseStf'], product['aliqStf'], product['vlIStf'], product['vlBaseIss'],
                                product['aliqIss'], product['vlIss'], product['vlBasePis'], product['vlaliqPis'], product['vlPis'], product['vlBaseCofins'], product['vlAliqCofins'],
                                product['vlCofins'], product['somatorio'], product['error']

                            ])
                        }
                    } else {
                        for (let product of res.data.products) {
                            arr.push([
                                searchTerm, product['PRODUCT_ID'], product['CMPLID'], product['precoTotal'], product['operacao'], product['origem'],
                                product['paisOrigem'], product['quantidade'], product['nbmCodigo'], product['cfopCodigo'], product['vlBaseIcms'], product['aliqIcms'], product['vlIcms'],
                                product['vlBaseIpi'], product['aliqIpi'], product['vlIpi'], product['vlBaseStf'], product['aliqStf'], product['vlIStf'], product['vlBaseIss'],
                                product['aliqIss'], product['vlIss'], product['vlBasePis'], product['vlaliqPis'], product['vlPis'], product['vlBaseCofins'], product['vlAliqCofins'],
                                product['vlCofins'], product['somatorio'], product['precoTotal'], product['error']

                            ])
                        }
                    }
                } else {
                    null
                }
                if (page == maxPage) break
            } catch (e) {
                Swal.close()
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
                setReportLoading(false)
                return
            }
        }
        setReportLoading(false)
        setProductDetailsAll(arr)
    }

    const searchFields = [
        { fieldValue: "coh_id", fieldName: "SKF COH COH Customer ID" }];

    const dropDownValues = {
        status: [{ status: "received", description: "Aguardando Registro COH" },
        { status: "processing", description: "Em Processamento" },
        { status: "completed", description: "Pedido Concluído" },
        { status: "payment", description: "Aguardando Pagamento" },
        { status: "cancelled", description: "Pedido Cancelado" }],
        payment: [{ status: "1", description: "Company Credit" }, { status: "2", description: "Credit Card" }]
    };

    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setLoading(true)
        if (searchTerm == '') {
            setList([]);
            setLoading(false)
            console.log('no search term')
            return;
        }
        // A0399
        API.getPriceCalculation(cancelToken, currentPage, searchTerm).then(res => {
            setMaxPages(res.data.max_pages)
            res.data.products ? setList(res.data.products) : setList([]);
        }).catch(err => {
            setList([]);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            // setIsClicked(false);
            setLoading(false)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, reset]);

    const doReset = () => {
        setIsSearch(false)
        setReset(!reset)
    }

    function doSearch(e) {
        e && e.preventDefault();
        let company = ""
        let filter = ""
        if (!b2e) {
            if (valueRef.current.value == undefined || valueRef.current.value == "") {
                Swal.fire({
                    title: 'Ops',
                    text: 'Please fill the COH USER ID on the search bar.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
                return
            }
            setsearchTerm(valueRef.current.value)
            setLoading(true)
            if (e.target.name == "noNetPrice") {
                filterFunction(e)
                if (filters.includes("noNetPrice")) {
                    filter = ''
                } else {
                    filter = 'net_price'
                }
            }
            if (e.target.name == "noGrossPrice") {
                filterFunction(e)
                if (filters.includes("noGrossPrice")) {
                    filter = ''
                } else {
                    filter = 'gross_price'
                }
            }
            if (e.target.name != "noGrossPrice" & e.target.name != "noNetPrice") {
                setFilters([])
            }
            company = valueRef.current.value
        } else {
            setLoading(true)
            company = "XAZ102"
            //TODO company = "XAZ102"
        }
        API.getPriceCalculation(cancelToken, 1, company, filter).then(res => {
            setMaxPages(res.data.max_pages)
            res.data.products ? setList(res.data.products) : setList([]);
        }).catch(err => {
            setList([]);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            // setIsClicked(false);
            setLoading(false)
        });
    }


    function filterFunction(e) {
        setFilters(prev => {
            if (prev.includes(e.target.name)) {
                return prev.filter((item) => {
                    return item !== e.target.name;
                })
            } else {
                return [...prev, e.target.name]
            }
        });
    }

    useEffect(() => {
        let tempList = afterSearchList;
        if (searchTerm == '') {
            setList([]);
            setLoading(false)
            console.log('no search term')
            return;
        }

        if (filters.includes("No Net Price")) {
            tempList = tempList.filter(e => e.netPrice.toUpperCase() === "NOT FOUND");
        }
        if (filters.includes("No Price With Taxes")) {
            tempList = tempList.filter(e => e.priceWithTax.toUpperCase() === "NOT FOUND");
        }

        setList(tempList);
        console.log(filters);
    }, [filters])

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <div className="flex flex-row mb-1 gap-x-2 justify-end">
                {
                    (b2e || searchTerm) &&
                    <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2" style={{width:'220px'}} onClick={() => { getPricelist() }}>
                        {
                            reportLoading ?
                                <div className="loader-wrapper-sm">
                                    <Loader light={true} small={true} />
                                </div>
                                :
                            <p className="px-2">{translate("DownloadPriceListLabel")}</p>
                        }   
                    </div>
                }
                <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2" style={{width:'220px'}} onClick={() => { getData() }}>
                    {
                            reportLoading2 ?
                                <div className="loader-wrapper-sm">
                                    <Loader light={true} small={true} />
                                </div>
                                :
                        <p className="px-2">{translate("DownloadErrorsLabels")}</p>
                    }
                </div>
            </div>
            <ScreenHeader
                title={translate("PriceCalculationLabel")}
                subtitle={translate("ProductPriceInstructionLabel")}
                link=""
                hasButton="false" />

            {!b2e && <SearchBar
                searchFields={searchFields}
                valueRef={valueRef}
                fieldRef={fieldRef}
                fieldStatusRef={fieldStatusRef}
                onSearch={doSearch}
                clicked={isClicked}
                dropDownValues={dropDownValues}
                error={error}
                reset={doReset} />}
            <div className="flex flex-row mb-1 gap-x-2 justify-end">
                <button onClick={(e) => doSearch(e)} name="noNetPrice" className={`text-xs mb-2 rounded-md py-2 px-4 ${filters.includes("noNetPrice") ? "bg-red-100" : "bg-gray-200"}`}>{translate("NoNetPriceLabel")}</button>
                <button onClick={(e) => doSearch(e)} name="noGrossPrice" className={`text-xs mb-2 rounded-md py-2 px-4 ${filters.includes("noGrossPrice") ? "bg-red-100" : "bg-gray-200"}`}>{translate("NoPriceWithTaxLabel")}</button>
            </div>
            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex flex-row justify-around py-5 text-xs lg:text-base font-bold">
                        <div className="w-1/6 text-center">COH COH Customer ID</div>
                        <div className="w-1/6 text-center">Price List</div>
                        {!b2e && <div className="w-1/6 text-center">AZPartes ID</div>}
                        <div className="w-1/6 text-center">SKU</div>
                        <div className="w-1/6 text-center">{translate("NetPriceLabel")}</div>
                        {b2e && <div className="w-1/6 text-center">{translate("GrossPriceLabel")}</div>}
                        <div className="w-1/6 text-center">{translate("PriceWithTaxLabel")}</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!list || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        list.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                {translate("NoDataLabel")}
                            </div> :
                            list.map((price, index) => {
                                return (

                                    <Link to={`/pricedetails/${price.skf_id}/${encodeURIComponent(price.skf_product_id)}`} target="_blank" id="rowDetail" className="bg-blue border-b-2 border-gray-600 justify-around flex flex-row py-5 text-xs lg:text-base" key={index + 'product'}>
                                        <div className="w-1/6 text-center">{price.skf_id}</div>
                                        <div className="w-1/6 text-center">{price.price_list}</div>
                                        {!b2e && <div className="w-1/6 text-center">{price.product_id}</div>}
                                        <div className="w-1/6 text-center">{price.skf_product_id}</div>
                                        {
                                            price.precoTotal === "not found" ?
                                                <div className="w-1/6 text-center flex justify-center">
                                                    <Status
                                                        key={price.key + 'priceCalculation'}
                                                        id={price.key}
                                                        currentStatus={price.precoTotal}
                                                        clickable="true" />
                                                </div> :
                                                <div className="w-1/6 text-center">{'R$ ' + price.precoTotal}</div>

                                        }
                                        {
                                            b2e &&
                                            (price.precoTotal === "not found" ?
                                                <div className="w-1/6 text-center flex justify-center">
                                                    <Status
                                                        key={price.key + 'priceCalculation'}
                                                        id={price.key}
                                                        currentStatus={price.precoTotal}
                                                        clickable="true" />
                                                </div> :
                                                <div className="w-1/6 text-center">{'R$ ' + price.precoTotal}</div>)

                                        }
                                        {
                                            price.price === "not found" ?
                                                <div className="w-1/6 text-center flex justify-center">
                                                    <Status
                                                        key={price.key + 'priceCalculation'}
                                                        id={price.key}
                                                        currentStatus={price.price}
                                                        clickable="true" />
                                                </div> :
                                                <div className="w-1/6 text-center">{'R$ ' + price.price}</div>

                                        }
                                    </Link>
                                )
                            })
                    }

                </div>
            </div>
            {
                !loading && maxPages ?
                    <div className="page-selector flex-row-nowrap space-between">
                        <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                            {
                                !mobile && <p>Previous</p>
                            }
                        </div>
                        <div className="pages-section flex-row-nowrap">
                            {
                                pages
                            }
                        </div>
                        <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            {
                                !mobile && <p>Next</p>
                            }
                            <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                        </div>
                    </div> :
                    null
            }
        </div>);
}

export default PriceCalculation; 
