import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import CatalogSearchBar from "../../components/CatalogSearchBar";
import ScreenHeaderTwoAction from "../../components/ScreenHeaderTwoAction";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import { useLanguage } from '../../context/LanguageContext';


function ProductDisplay() {
    const { translate } = useLanguage();

    const [list, setList] = useState(null);
    // const [afterSearchList, setAfterSearchList] = useState(productDisplay);
    const [currentPage, setCurrentPage] = useState(1);
    const [reset, setReset] = useState(false)
    const [segment, setSegment] = useState('')
    const [pricelist, setPricelist] = useState('')
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const context = useContext(AppContext);
    const [productDetails, setProductDetails] = useState([]);
    let cancelToken = API.cancelToken();
    const history = useHistory();
    const [filters, setFilters] = useState([]);
    const valueRef = useRef();


    const firstSearchFields = [
        { fieldValue: "", fieldName: translate("SegmentLabel") },
        { fieldValue: "automotive", fieldName: "Automotive" },
        { fieldValue: "industrial", fieldName: "Industrial" }];

    const secondSearchFields = [{ fieldValue: "", fieldName: "Price List" },
    { fieldValue: "CAR18", fieldName: "CAR18" },
    { fieldValue: "BPL2W", fieldName: "BPL2W" },
    { fieldValue: "KORBE", fieldName: "KORBE" }
    ];

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    useEffect(() => {
        setLoading(false)
    }, [list]);

    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])


    useEffect(() => {
        if(productDetails.length > 0){
            console.log("GET DATA")
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(productDetails);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, `Products_Missing_Dimensions.xlsx`);
        }
    }, [productDetails]);
    
    function getData(){
        setReportLoading(true)
        Swal.fire({
            title:'Creating report...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        API.getMissingDimensions(cancelToken).then(res => {
            if(res.data){
                var arr = [['SKU','Pack','Price List']]
                console.log(res.data)
                res.data.products.forEach(product => {
                    arr.push([product['skf_product_id'],product['pk'],product['price_list']])
                });
                setProductDetails(arr)
                setReportLoading(false)
            }else{
                setProductDetails(null);
                setReportLoading(false)
            }
        })
    }
    
    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setLoading(true)
        var not_registered = 0
        var no_dimensions = 0
        var no_catalog = 0
        var no_availability = 0
        var no_pack = 0
        if(filters.includes("No AZPartes ID")){
            not_registered = 1
        }
        if(filters.includes("No Dimensions")){
            no_dimensions = 1
        }
        if(filters.includes("No Catalogue")){
            no_catalog = 1
        }
        if(filters.includes("No Availability")){
            no_availability = 1
        }
        if(filters.includes("No Pack")){
            no_pack = 1
        }
        if(reset){
            setCurrentPage(1)
            setSegment('')
            setPricelist('')
        }

        API.getProductDisplay(cancelToken, currentPage, '', segment, pricelist, not_registered, no_dimensions, no_catalog, no_availability ,no_pack).then(res => {
            setMaxPages(res.data.max_pages)
            res.data.products ? setList(res.data.products) : setList([]);
        }).catch(err => {
            setList([]);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, reset]);


    const doReset = () => {
        console.log("Do Reset")
        setReset(!reset)
    }

    function doSearch(e) {
        e && e.preventDefault();
        const segment = e.target.firstField.value;
        const priceList = e.target.secondField.value;
        const search = e.target.search.value;
        setSegment(segment)
        setPricelist(priceList)
        var not_registered = 0
        var no_dimensions = 0
        var no_catalog = 0
        var no_availability = 0
        var no_pack = 0
        setLoading(true)
        API.getProductDisplay(cancelToken,1,search,segment, priceList, not_registered, no_dimensions, no_catalog, no_availability ,no_pack).then(res => {
            // setMaxPages(res.data.pagination.total_pages)
            setMaxPages(res.data.max_pages)
            res.data.products ? setList(res.data.products) : setList([]);
        }).catch(err => {
            setList([]);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }

    function filterFunction(e) {
        // setLoading(false)
        setCurrentPage(1)
        setFilters(prev => {
            if (prev.includes(e.target.name)) {
                return prev.filter((item) => {
                    return item !== e.target.name;
                })
            } else {
                return [...prev, e.target.name]
            }
        });
    }

    useEffect(() => {
        console.log("Editing filters...")
        setLoading(true)
        var not_registered = 0
        var no_dimensions = 0
        var no_catalog = 0
        var no_availability = 0
        var no_pack = 0
        if(filters.includes("No AZPartes ID")){
            not_registered = 1
        }
        if(filters.includes("No Dimensions")){
            no_dimensions = 1
        }
        if(filters.includes("No Catalogue")){
            no_catalog = 1
        }
        if(filters.includes("No Availability")){
            no_availability = 1
        }
        if(filters.includes("No Pack")){
            no_pack = 1
        }
        API.getProductDisplay(cancelToken, currentPage, '' ,segment, pricelist, not_registered, no_dimensions, no_catalog, no_availability ,no_pack).then(res => {
            setMaxPages(res.data.max_pages)
            res.data.products ? setList(res.data.products) : setList([]);
        }).catch(err => {
            console.log(err)
            console.log("Error")
            if (err.message === 'cancelling') return
            setList([]);
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            // setLoading(false)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [filters])

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <div id="header" className="flex-row justify-between">
                <div className="text-md w-9/12">
                    <div className="text-2xl my-2 w-12/12">{translate("ProductDisplayLabel")}</div>
                    <div className="text-md w-11/12">{translate("ProductManageTabDescription")}</div>
                    <div className="text-md w-11/12">{translate("NoAzIdDescription")}</div>
                    <div className="text-md w-11/12">{translate("NoPackDescription")}</div>
                    <div className="text-md w-11/12">{translate("NoDimensionsDescription")}</div>
                    <div className="text-md w-11/12">{translate("NoAvailabilityDescription")}</div>
                </div>
                <div className="text-md w-3/12">
                    <Link to={"/productinfo"}>
                    <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                        <p className="px-2">{translate("AddPackLabel")}</p>
                    </div>
                    </Link>
                    <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2" onClick={() => {getData()}}>
                        {
                            reportLoading ?
                            <div className="loader-wrapper-sm">
                                <Loader light={true} small={true}/>
                            </div>
                            :
                            <p className="px-2">{translate("DownloadMissingLabel")}</p>
                        }
                    </div>
                </div>
            </div>

            <CatalogSearchBar
                valueRef={valueRef}
                firstSearchFields={firstSearchFields}
                secondSearchFields={secondSearchFields}
                onSearch={doSearch}
                reset={doReset} 
                />

            <div className="flex flex-row mb-1 gap-x-2 justify-end">
                <button onClick={(e) => filterFunction(e)} name="No AZPartes ID" className={`text-xs mb-2 rounded-md py-2 px-4 ${filters.includes("No AZPartes ID") ? "bg-red-100" : "bg-gray-200"}`}>NO AZPARTES ID</button>
                <button onClick={(e) => filterFunction(e)} name="No Pack" className={`text-xs mb-2 rounded-md py-2 px-4 ${filters.includes("No Pack") ? "bg-red-100" : "bg-gray-200"}`}>NO PACK</button>
                <button onClick={(e) => filterFunction(e)} name="No Dimensions" className={`text-xs mb-2 rounded-md py-2 px-4 ${filters.includes("No Dimensions") ? "bg-red-100" : "bg-gray-200"}`}>NO DIMENSIONS</button>
                {/* <button onClick={(e) => filterFunction(e)} name="No Catalogue" className={`text-xs mb-2 rounded-md py-2 px-4 ${filters.includes("No Catalogue") ? "bg-red-100" : "bg-gray-200"}`}>NO CATALOGUE</button> */}
                <button onClick={(e) => filterFunction(e)} name="No Availability" className={`text-xs mb-2 rounded-md py-2 px-4 ${filters.includes("No Availability") ? "bg-red-100" : "bg-gray-200"}`}>NO AVAILABILITY</button>
            </div>
            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-1/12 text-center">{translate("SegmentLabel")}</div>
                        <div className="w-1/12 text-center">Price List</div>
                        <div className="w-1/12 text-center">SKU</div>
                        <div className="w-1/12 text-center">{translate("CatalogLabel")}</div>
                        <div className="w-1/12 text-center">{translate("AZIdLabel")}</div>
                        <div className="w-1/12 text-center">{translate("PackLabel")}</div>
                        <div className="w-1/12 text-center">{translate("DimensionsLabel")}</div>
                        <div className="w-1/12 text-center">{translate("AvailabilityLabel")}</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!list || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        list.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            list.map((product, index) => {
                                return (

                                    <Link to={`./editproduct/${encodeURIComponent(product.skf_product_id.trim())}?t=${product.segment}&pricelist=${product.price_list}`} id="rowDetail" className="bg-blue border-b-2 border-gray-600 justify-around flex flex-row py-5 text-xs lg:text-base pl-5" target="_blank" key={index + 'product'}>
                                        <div className="w-1/12 text-center">{product.segment && product.segment.toUpperCase()}</div>
                                        {
                                            product.price_list === "not found" ?
                                                <div className="w-1/12 text-center flex justify-center">
                                                    <Status
                                                        key={product.key + 'productCatalog'}
                                                        id={product.key}
                                                        currentStatus={product.price_list.toUpperCase()}
                                                        clickable="true" />
                                                </div> :
                                                <div className="w-1/12 text-center">{product.price_list.toUpperCase()}</div>

                                        }
                                        <div className="w-1/12 text-center">{product.skf_product_id && product.skf_product_id.toUpperCase()}</div>
                                        <div className="w-1/12 text-center">{product.catalog && product.catalog.toUpperCase()}</div>
                                        
                                        {
                                            product.product_id === "not found" ?
                                                <div className="w-1/12 text-center flex justify-center">
                                                    <Status
                                                        key={product.key + 'productCatalog'}
                                                        id={product.key}
                                                        currentStatus={product.product_id}
                                                        clickable="true" />
                                                </div> :
                                                <div className="w-1/12 text-center">{product.product_id}</div>

                                        }
                                        {
                                            product.pk === "not found" || product.pk === "N/A" ?
                                                <div className="w-1/12 text-center flex justify-center">
                                                    <Status
                                                        key={product.key + 'productCatalog'}
                                                        id={product.key}
                                                        currentStatus={product.pk}
                                                        clickable="true" />
                                                </div> :
                                                <div className="w-1/12 text-center">{product.pk}</div>

                                        }
                                        {
                                            product.dimension === "not found"?
                                                <div className="w-1/12 text-center flex justify-center">
                                                    <Status
                                                        key={product.key + 'productCatalog'}
                                                        id={product.key}
                                                        currentStatus={product.dimension}
                                                        clickable="true" />
                                                </div> :
                                                <div className="w-1/12 text-center">{product.dimension}</div>

                                        }
                                        {
                                            product.stock === "not found" || product.stock === "N/A"  ?
                                                <div className="w-1/12 text-center flex justify-center">
                                                    <Status
                                                        key={product.key + 'productCatalog'}
                                                        id={product.key}
                                                        currentStatus={product.stock}
                                                        clickable="true" />
                                                </div> :
                                                <div className="w-1/12 text-center">{product.stock}</div>

                                        }
                                    </Link>

                                )
                            })
                    }

                </div>
            </div>
            {
                !loading && maxPages ?
                    <div className="page-selector flex-row-nowrap space-between">
                        <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                            {
                                !mobile && <p>{translate('PreviousLabel')}</p>
                            }
                        </div>
                        <div className="pages-section flex-row-nowrap">
                            {
                                pages
                            }
                        </div>
                        <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            {
                                !mobile && <p>{translate('NextLabel')}</p>
                            }
                            <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                        </div>
                    </div> :
                    null
            }
        </div>);
}

export default ProductDisplay; 