import React, { useState, useEffect, useContext } from "react";
import InputMask from 'react-input-mask';

function UserLine(props) {

    const [field, setField] = useState({ coh_companies: '', name: '', phone_number: '', email: '',  });
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [subBusinessSegment, setSubBusinessSegment] = useState('')
    const [mask, setMask] = useState("+55 (99) 99999-9999");

    useEffect(() => {
        props.data ? setField(props.data) : setField(null)
        console.log("DATA")
        console.log(props)
        if (props.data.email && !validateEmail(props.data.email)){ 
            setEmailError(true)
        }else{
            setEmailError(false)
        }

        if(props.data.subBusinessSegment && (!props.data.subBusinessSegment || props.data.subBusinessSegment == undefined)){
            setSubBusinessSegment(false)
        }else{
            console.log(`Segment: ${props.data.subBusinessSegment}`)
            setSubBusinessSegment(props.data.subBusinessSegment)
        }

        if (props.data.phone_number && !validatePhone(props.data.phone_number)){ 
            console.log("SetPhoneError: True (UseEffect)")
            setPhoneError(true)
        }else{
            setPhoneError(false)
        }
        if(props.data.phone_number){
            var phone = `${props.data.phone_number}`
            phone = phone.replace(" ", "")
            phone = phone.replace("-", "")
            phone = phone.replace("(", "")
            phone = phone.replace(")", "")

            if (phone.replace("_", "").length === 10) {
                setMask("+55 (99) 9999-9999");
              }
        }
        console.log("SetPhoneError: False (UseEffect)")
    }, [props.data])
    
    
    function handleChange(e) {
        const { name, value } = e.target;
        console.log(name)
        console.log(value)
        console.log(name==='email')
        console.log(name==='phone')
        if (name==='email' && !validateEmail(value)){ 
            setEmailError(true)
        }else{
            setEmailError(false)
        }
        if (name==='phone' && !validatePhone(value)){ 
            console.log("SetPhoneError: True")
            setPhoneError(true)
        }else{
            console.log("SetPhoneError: False")
            setPhoneError(false)
        }
        
        props.onChange({ ...field, [name]: value }, props.realIndex);
    };

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      const validatePhone = (phone) => {
        console.log(`Phone: ${phone}`)
        phone = `${phone}`
        if(!phone.includes("+55 ")){
            phone = `+55 ${phone}`
        }
        phone = `${phone}`.replace(/\D/g,'');
        console.log(phone)
        console.log(phone.length)
        if (phone.length === 13 || phone.length === 12){
            return true
        }else{
            return false
        }
      };
    return (
        <div className="flex-row wrap-override p-1">

            <div className={`flex-col w-full md:w-3/12 md:mr-2 md:mt-0 mt-4`}>
                {props.index == 0 && <p>COH COH Customer ID</p>}
                <input
                    type="text"
                    name="coh_companies"
                    value={field ? field.coh_companies : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${props.error && !field.coh_companies ? "border border-amber-900" : "border-0"}`}
                />
            </div>

            <div className="flex-col w-full md:w-3/12 md:mr-2 md:mt-0 mt-4">
                {props.index == 0 && <p>Customer Contact Name</p>}
                <input
                    type="text"
                    name="name"
                    value={field ? field.name : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${props.error && !field.name ? "border border-amber-900" : "border-0"}`}
                />
            </div>

            <div className="flex-col w-full md:w-3/12 md:mr-2 md:mt-0 mt-4">
                {props.index == 0 && <p>Customer Contact Phone</p>}
                <InputMask
                    type="text"
                    name="phone_number"
                    mask={mask}
                    onBlur={e => {
                        console.log(`Input: ${e.target.value}`)
                        if (e.target.value.replace("_", "").length === 18) {
                          setMask("+55 (99) 9999-9999");
                        }
                      }}
                      onFocus={e => {
                        if (e.target.value.replace("_", "").length === 18) {
                          setMask("+55 (99) 99999-9999");
                        }
                      }}
                    value={field ? field.phone_number : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${phoneError || (props.error && !field.phone_number) ? "border border-amber-900" : "border-0"}`}
                />
            </div>


            <div className="flex-col w-full md:w-3/12 md:mr-2 md:mt-0 mt-4">
                {props.index == 0 && <p>Customer Notification Email</p>}
                <input
                    type="email"
                    name="email"
                    value={field ? field.email : ''}
                    onChange={handleChange}
                    className={`h-10 mt-2 bg-gray-200 rounded-md text-white p-5 w-full  ${emailError || (props.error && !field.email) ? "border border-amber-900" : "border-0"}`}
                />
            </div>
        </div>
    )
}
export default UserLine