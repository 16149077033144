import React, { useState, useContext  } from "react";
import Status from "../../components/Status";
import './style.css';
import CatalogSearchBar from "../../components/CatalogSearchBar";
import ScreenHeader from "../../components/ScreenHeader";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import { useEffect } from "react";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';


function CatalogTransition() {

    let cancelToken = API.cancelToken();
    const industrialCatalog = API.getIndustrialCatalog(cancelToken);
    const [currentPage, setCurrentPage] = useState(1);
    const [reset, setReset] = useState(false)
    const [loading, setLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    console.log(industrialCatalog)
    // const [list, setList] = useState(industrialCatalog);
    const context = useContext(AppContext);
    const [list, setList] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setLoading(true)
        
        API.getIndustrialCatalog(cancelToken, 1, '', '').then(res => {
            console.log(res);
            setMaxPages(res.data.max_pages)
            res.data.products ? setList(res.data.products) : setList([]);
        }).catch(err => {
            setList([]);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setIsClicked(false);
            setLoading(false)
        
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, reset]);

    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])

    const firstSearchFields = [
        { fieldValue: "-1", fieldName: "Language" },
        { fieldValue: "english", fieldName: "English" },
        { fieldValue: "spanish", fieldName: "Spanish" },
        { fieldValue: "portuguese", fieldName: "Portuguese" }];

    const secondSearchFields = [
        { fieldValue: "-1", fieldName: "Status" },
        { fieldValue: "pending", fieldName: "Pending" },
        { fieldValue: "complete", fieldName: "Complete" }];

    const doReset = (e) => {
        console.log("Do Reset")
        setReset(!reset)
    }

    function doSearch(e) {
        e && e.preventDefault();
        const language = e.target.firstField.value;
        const status = e.target.secondField.value;
        const skf_product_id = e.target.search.value;

        var searchFilter = ''
        if (language == 'english'){
            searchFilter = 'description_en'
        }
        if (language == 'spanish'){
            searchFilter = 'description_es'
        }
        if (language == 'portuguese'){
            searchFilter = 'description_pt'
        }

        var searchValue = status

        if (skf_product_id != undefined && skf_product_id != ''){
            searchFilter = 'skf_product_id'
            searchValue = skf_product_id
        }

        setLoading(true)

        API.cancel(cancelToken);
        cancelToken = API.cancelToken();

        API.getIndustrialCatalog(cancelToken, 1, searchFilter, searchValue).then(res => {
            console.log(res);
            setMaxPages(res.data.max_pages)
            res.data.products ? setList(res.data.products) : setList([]);
        }).catch(err => {
            setList([]);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });
    
    }


    return (


        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title="Catalog Translation"
                subtitle="This tab contains the SKF Global XML product descriptions. Descriptions must be translated into English, Spanish and Portuguese."
                link="./bulktranslation"
                buttonDescription="Bulk Translation"
                hasButton="true" />
            <CatalogSearchBar
                firstSearchFields={firstSearchFields}
                secondSearchFields={secondSearchFields}
                onSearch={doSearch}
                reset={doReset}
            />
            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-1/7 text-center">XML</div>
                        <div className="w-1/7 text-center">SKU</div>
                        <div className="w-1/7 text-center">Pricelist</div>
                        <div className="w-1/7 text-center">Status</div>
                        <div className="w-1/7 text-center">English</div>
                        <div className="w-1/7 text-center">Spanish</div>
                        <div className="w-1/7 text-center">Portuguese</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!list || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        list.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            list.map((component, index) => {
                                return <Link to={`./editindustrial/${encodeURIComponent(component.skf_product_id)}`} key={index + 'link'}>
                                    <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                        <div className="w-1_7 text-center">{component.xml}</div>
                                        <div className="w-1_7 text-center">{component.skf_product_id}</div>
                                        <div className="w-1_7 text-center">{component.price_list}</div>
                                        <div className="w-1_7 pl-5 text-center flex justify-center">
                                            <Status
                                                key={component.key + 'Status'}
                                                id={component.key}
                                                currentStatus={component.status}
                                                clickable="false" />
                                        </div>
                                        <div className="w-1_7 check_pl text-center">
                                            {component.english == true ?
                                                <svg xmlns="http://www.w3.org/2000/svg" className="m-auto icon icon-tabler icon-tabler-check" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#61A65B" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M5 12l5 5l10 -10" />
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" className="m-auto icon icon-tabler icon-tabler-x" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#C04949" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            }
                                        </div>
                                        <div className="w-1_7 check_pl text-center">
                                            {component.spanish == true ?
                                                <svg xmlns="http://www.w3.org/2000/svg" className="m-auto icon icon-tabler icon-tabler-check" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#61A65B" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M5 12l5 5l10 -10" />
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" className="m-auto icon icon-tabler icon-tabler-x" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#C04949" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            }
                                        </div>
                                        <div className="w-1_7 check_pl_l text-center">
                                            {component.portuguese == true ?
                                                <svg xmlns="http://www.w3.org/2000/svg" className="m-auto icon icon-tabler icon-tabler-check" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#61A65B" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M5 12l5 5l10 -10" />
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" className="m-auto icon icon-tabler icon-tabler-x" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#C04949" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                </Link>;
                            })}
                </div>
            </div>

            {
                !loading && maxPages ?
                    <div className="page-selector flex-row-nowrap space-between">
                        <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                            {
                                !mobile && <p>Previous</p>
                            }
                        </div>
                        <div className="pages-section flex-row-nowrap">
                            {
                                pages
                            }
                        </div>
                        <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            {
                                !mobile && <p>Next</p>
                            }
                            <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                        </div>
                    </div> :
                    null
            }
        </div>)
}

export default CatalogTransition;