import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import TableOptions from "../../components/TableOptions";
import Loader from "../../components/Loader";
import FilterMenu from "../../components/FilterMenu";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import GlobalSVG from '../../utils/GlobalSVG';
import ReactGA from 'react-ga';
import { useLanguage } from '../../context/LanguageContext';
import * as XLSX from 'xlsx';

function ListOfOrders() {
    const { translate } = useLanguage();

    const [listOfOrder, setListOfOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [showColumnFilter, setShowColumnFilter] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(true);
    const [isClicked, setIsClicked] = useState(false);
    const [country, setCountry] = useState(localStorage.getItem('country') ? localStorage.getItem('country') : '');
    const [sb, setSb] = useState(localStorage.getItem('sb') ? localStorage.getItem('sb') : '');
    const [demo, setDemo] = useState(localStorage.getItem('demo') ? localStorage.getItem('demo') : false);
    const [visibleColumns, setVisibleColumns] = useState(JSON.parse(localStorage.getItem('visibleColumns')) || {
        date: true,
        order_number: true,
        customer_number: true,
        customer_name: true,
        client_order_id: true,
        payment: true,
        status: true,
        nf_number: true,
        nf_key: true,
        billing_date: true
    });

    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const role = context.getRole;
    const history = useHistory();
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false);
    const [sorting, setSorting] = useState("desc");

    const searchFields = [
        { fieldValue: "date", fieldName: "Date" },
        { fieldValue: "order_number", fieldName: translate('SkfOrderNumber') },
        { fieldValue: "customer_number", fieldName: translate('CohId') },
        { fieldValue: "customer_name", fieldName: translate('CohShort') },
        { fieldValue: "client_order_id", fieldName: translate("customerOrderNumber") },
        { fieldValue: "payment", fieldName: translate('PaymentMethod') },
        { fieldValue: "status", fieldName: translate("OrderStatus") },
        { fieldValue: "nf_number", fieldName: translate("NFNumber") },
        { fieldValue: "nf_key", fieldName: translate('NFKey') },
        { fieldValue: "billing_date", fieldName: translate('BillingDateLabel')}
    ];

    const statusDictionary = {
        "Pedido Concluido": "Completed",
        "Pedido Cancelado": "Canceled",
        "Pagamento Expirado": "Expired Payment",
        "Em processamento": "Processing",
        "Aguardando Registro COH": "Awaiting COH Registration",
        "Aguardando Pagamento": "Awaiting Payment",
        "Pagamento Não Autorizado": "Payment Not Authorized",
        "Erro": "Error",
    };

    function replaceStatusString(input) {
        let output = input;
        for (const key in statusDictionary) {
            const value = statusDictionary[key];
            const regex = new RegExp(key, 'gi');
            output = output.replace(regex, value);
        }
        return output;
    }

    const dropDownValues = {
        status: [
            { status: "received", description: "Awaiting COH Registration" },
            { status: "processing", description: "Processing" },
            { status: "completed", description: "Completed" },
            { status: "payment", description: "Awaiting Payment" },
            { status: "cancelled", description: "Canceled" },
            { status: "expired", description: "Expired Payment" },
            { status: "denied", description: "Payment Not Authorized" },
            { status: "erro", description: "Erro" },
        ],
        payment: [
            { status: "1", description: "Credit Limit" },
            { status: "2", description: "Credit Card" }
        ]
    };

    const paymentMethod = new Map([[1, 'Credit Limit'], [2, 'Credit Card']])

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    useEffect(() => {
        if (localStorage.getItem('valueRef') != "" && localStorage.getItem('valueRef') != null && localStorage.getItem('fieldRef') != "" && localStorage.getItem('fieldRef') != null) {
            if (localStorage.getItem('fieldRef') != "status" && localStorage.getItem('fieldRef') != "payment") {
                try {
                    valueRef.current.value = localStorage.getItem('valueRef');
                } catch {
                    valueRef.current.value = translate('fieldSelect')
                }
                fieldRef.current.value = localStorage.getItem('fieldRef');
            }
        }
        try {
            setSorting(localStorage.getItem("sorting"))
        } catch {
            setSorting("desc")
        }
    }, [])

    useEffect(() => {
        load()
    }, [currentPage, reset, isSearch, sorting, country, sb, demo]);

    function load() {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if (context && role == 5){
            history.push('/partner/stats');
            return
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setMobile(window.innerWidth <= 768);

        cancelToken = API.cancelToken();

        var field = ""
        var value = ""
        try {
            field = fieldRef.current.value;
            value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;
        } catch {
            console.log("Not able to save search data...")
        }

        if (field == "date" || field == "billing_date") {
            try {
                var individual_values = value.split(" - ")
                var store_string = `${individual_values[0].split("T")[0]} - ${individual_values[1].split("T")[0]}`
                localStorage.setItem('valueRef', store_string);
                localStorage.setItem('fieldRef', fieldRef.current.value);
            } catch {
                console.log("Not able to save search data...")
            }
        } else {
            localStorage.setItem('valueRef', value);
            localStorage.setItem('fieldRef', fieldRef.current.value);
        }

        setLoading(true)
        if (field !== "" && value != "") {
            API.getSearchListOfOrders(cancelToken, currentPage, field, value, country, sb, demo, sorting).then(res => {
                console.log(res);
                console.log(`total_pages: ${res.data.pagination.total_pages}`)
                setMaxPages(res.data.pagination.total_pages)
                res.data.orders ? setListOfOrder(res.data.orders) : setListOfOrder([]);
            }).catch(err => {
                setListOfOrder([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(
                f => {
                    setIsClicked(false);
                    setLoading(false)
                    setIsSearch(false)
                }
            );
        } else {
            setLoading(true)
            API.getListOfOrders(cancelToken, currentPage, sorting, country, sb, demo).then(res => {
                console.log(`total_pages: ${res.data.pagination.total_pages}`)
                setMaxPages(res.data.pagination.total_pages)
                res.data.orders ? setListOfOrder(res.data.orders) : setListOfOrder([]);
                setLoading(false)
            }).catch(err => {
                setListOfOrder([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(f => {
                // setLoading(false)
            });
        }

        return () => {
            API.cancel(cancelToken);
        }
    }

    useEffect(() => {
        let pages = []
        console.log(`maxPages: ${maxPages}`)
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])


    const doReset = () => {
        console.log("resetting")
        setReset(!reset)
    }

    const sortArrow = () => {
        if (sorting == "asc") {
            setSorting("desc")
            setCurrentPage(1)
            localStorage.setItem('sorting', "desc");
        } else {
            setSorting("asc")
            setCurrentPage(1)
            localStorage.setItem('sorting', "asc");
        }
    }

    const handleSearch = (e) => {
        e && e.preventDefault();

        let value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;
        let field = fieldRef.current.value;

        console.log(`VALUE: ${value}`)
        if (!value || value.trim() === '' || !field) {
            setError(true)
            return
        }
        if (currentPage === 1) {
            setIsSearch(true)
        } else {
            setCurrentPage(1)
        }
        setIsClicked(true);
    }

    const handleFilterChange = (filterParams) => {
        console.log(filterParams)
        if (filterParams.status === "BRA" || filterParams.status === "ARG" || filterParams.status === "") {
            setCountry(filterParams.status);
            localStorage.setItem('country', filterParams.status);
            setSb('');
            localStorage.setItem('sb', '');
        }
    };

    const handleFilterChange2 = (filterParams) => {
        console.log(filterParams);
        const status2 = filterParams.status;  // Change from status2 to status
        setSb(status2);
        localStorage.setItem('sb', status2);

    };

    const handleFilterChange3 = (filterParams) => {
        console.log(filterParams);
        console.log("Current demo:", demo, "Toggling demo.");
        setDemo(current => {
            const newDemo = !current;
            console.log("New demo:", newDemo);
            localStorage.setItem('demo', newDemo);
            return newDemo;
        });
    };

    const updateVisibleColumns = (columns) => {
        setVisibleColumns(columns);
        localStorage.setItem('visibleColumns', JSON.stringify(columns)); // Save the updated column visibility to local storage
    };

    const updateShowColumnFilter = () => {
        setShowColumnFilter(!showColumnFilter);
    };

    const showFilter = () => {
        setShowColumnFilter(true)
    };

    const getColumnOrder = () => {
        const savedOrder = JSON.parse(localStorage.getItem('columnOrder'));
        const defaultOrder = ['order_number', 'date', 'customer_number', 'customer_name', 'client_order_id', 'payment', 'status'];
        return savedOrder || defaultOrder;
    };

    const orderedColumns = getColumnOrder();

    const exportToExcel = () => {
        Swal.fire({
            title: 'Table Export',
            text: 'The data will be exported with all filtered and rearranged data.',
            confirmButtonColor: "#131921",
            confirmButtonText: 'OK',
            allowEscapeKey: false,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                const data = listOfOrder.map(order => {
                    const row = {};
                    orderedColumns.forEach(column => {
                        if (visibleColumns[column]) {
                            switch (column) {
                                case 'order_number':
                                    row['SKF Order Number'] = order.skf_order_id;
                                    break;
                                case 'date':
                                    row['Date'] = databaseToDate(order.created_at);
                                    break;
                                case 'customer_number':
                                    row['COH ID'] = order.customer.skf_id;
                                    break;
                                case 'customer_name':
                                    row['COH Short Name'] = order.customer.name;
                                    break;
                                case 'client_order_id':
                                    row['Client Order ID'] = order.client_order_id;
                                    break;
                                case 'payment':
                                    row['Payment Method'] = paymentMethod.get(order.payment_method);
                                    break;
                                case 'status':
                                    row['Order Status'] = replaceStatusString(order.status);
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
                    return row;
                });
        
                const ws = XLSX.utils.json_to_sheet(data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Orders');
                XLSX.writeFile(wb, 'Orders.xlsx');
            }
        });
    };

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            {
                showColumnFilter &&
                <TableOptions visibleColumns={visibleColumns} onApplyChanges={updateVisibleColumns} showColumnFilter={updateShowColumnFilter} />
            }

            <ScreenHeader
                title={translate('orderTitle')}
                link="../CreateOrder"
                buttonDescription={translate('AddOrderLabel')}
                svg="orders"
                hasButton="true" />

            <SearchBar
                searchFields={searchFields}
                valueRef={valueRef}
                fieldRef={fieldRef}
                fieldStatusRef={fieldStatusRef}
                onSearch={handleSearch}
                clicked={isClicked}
                dropDownValues={dropDownValues}
                error={error}
                reset={doReset} />

            <FilterMenu
                onFilterChange={handleFilterChange}
                onFilterTypeChange={handleFilterChange2}
                onFilterDemoChange={handleFilterChange3}
                onShowFilterMenu={showFilter}
                exportTable={exportToExcel} />

            {/* <button onClick={exportToExcel} className="mb-4 py-2 px-4 bg-green-500 text-white rounded">
                Export to Excel
            </button> */}

            <div className="overflow-auto">
                <table className="table-auto w-full">
                    <thead>
                        <tr className="bg-gray-200 text-xs lg:text-base font-bold">
                            {orderedColumns.map(column => (
                                visibleColumns[column] && (
                                    <th key={column} className={`text-center py-5  ${column === 'date' ? " flex items-center pr-5 pl-32": "pl-5"}`}>
                                    {translate(searchFields.find(field => field.fieldValue === column)?.fieldName)}
                                        {column === 'date' && (
                                            <span className="arrow-up" onClick={sortArrow}>
                                                {sorting == "asc" ? GlobalSVG.upArrow() : GlobalSVG.downArrow()}
                                            </span>
                                        )}
                                    </th>
                                )
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {!listOfOrder || loading ? (
                            <tr>
                                <td colSpan={12} className="loader-wrapper min-h-full min-w-full text-center">
                                    <Loader light={true} />
                                </td>
                            </tr>
                        ) : listOfOrder.length === 0 ? (
                            <tr>
                                <td colSpan={12} className="loader-wrapper min-h-full min-w-full p-5 text-center">
                                    No Data Found
                                </td>
                            </tr>
                        ) : (
                            listOfOrder.map((order, index) => (
                                <tr key={index} className="bg-blue border-b-2 border-gray-600 text-xs lg:text-base">
                                    {orderedColumns.map(column => (
                                        visibleColumns[column] && (
                                            <td key={column} className={`text-center py-5 pl-5 ${column === 'status' && "flex justify-center"}`}>
                                                <Link to={`./Order/${order.order_id}`}>
                                                    {column === 'order_number' && order.skf_order_id}
                                                    {column === 'date' && databaseToDate(order.created_at)}
                                                    {column === 'customer_number' && order.customer.skf_id}
                                                    {column === 'customer_name' && order.customer.name}
                                                    {column === 'client_order_id' && order.client_order_id}
                                                    {column === 'payment' && paymentMethod.get(order.payment_method)}
                                                    {column === 'status' && <Status
                                                        key={order.order_id + 'orderStatus'}
                                                        id={order.order_id}
                                                        className="text-center py-5 pl-5"
                                                        currentStatus={replaceStatusString(order.status)}
                                                        clickable="true"
                                                    />}
                                                </Link>
                                            </td>
                                        )
                                    ))}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {
                !loading && maxPages ?
                    <div className="page-selector flex-row-nowrap space-between">
                        <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                            {
                                !mobile && <p>{translate("PreviousLabel")}</p>
                            }
                        </div>
                        <div className="pages-section flex-row-nowrap">
                            {
                                pages
                            }
                        </div>
                        <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            {
                                !mobile && <p>{translate("NextLabel")}</p>
                            }
                            <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                        </div>
                    </div> :
                    null
            }
        </div>);
}

export default ListOfOrders;