import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import GlobalSVG from '../../utils/GlobalSVG';
import ReactGA from 'react-ga';

function ListOfSuggestions() {
    const [listOfSuggestions, setListOfSuggestions] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const fieldStatusRef = useRef();
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false)
    const [sorting, setSorting] = useState("desc")
    const valueRef = useRef();
    const fieldRef = useRef();

    const searchFields = [
    { fieldValue: "request_id", fieldName: "Request ID" },
    { fieldValue: "coh_customer_id", fieldName: "COH Customer ID" },
    { fieldValue: "customer_name", fieldName: "COH Short Name" }];


    const dropDownValues = {
        status: [{ status: "received", description: "Awaiting COH Registration" },
        { status: "processing", description: "Processing" },
        { status: "completed", description: "Completed" },
        { status: "payment", description: "Awaiting Payment" },
        { status: "cancelled", description: "Canceled" },
        { status: "expired", description: "Expired Payment" },
        { status: "denied", description: "Payment Not Authorized" },
        { status: "erro", description: "Erro" },
        ],
        payment: [{ status: "1", description: "Credit Limit" }, { status: "2", description: "Credit Card" }]
    };

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setMobile(window.innerWidth <= 768);

        cancelToken = API.cancelToken();

        setLoading(true)

        API.getListOfSuggestions(cancelToken).then(res => {
            res.data.suggestions ? setListOfSuggestions(res.data.suggestions) : setListOfSuggestions([]);
            setLoading(false)
        }).catch(err => {
            setListOfSuggestions([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });


        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, reset, isSearch, sorting]);


    useEffect(() => {
        let pages = []
        console.log(`maxPages: ${maxPages}`)
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])


    const doReset = () => {
        console.log("resetting")
        setReset(!reset)
    }

    const sortArrow = () => {
        if (sorting == "asc") {
            setSorting("desc")
            setCurrentPage(1)
            localStorage.setItem('sorting', "desc");
        } else {
            setSorting("asc")
            setCurrentPage(1)
            localStorage.setItem('sorting', "asc");
        }
    }

    function handleSearch(){

    }

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title="Customer's Request"
                link="../addmaintenance"
                buttonDescription="Add maintenance"
                svg="orders"
                hasButton="false" />

            <SearchBar
                searchFields={searchFields}
                valueRef={valueRef}
                fieldRef={fieldRef}
                fieldStatusRef={fieldStatusRef}
                onSearch={handleSearch}
                clicked={isClicked}
                dropDownValues={dropDownValues}
                error={error}
                reset={doReset} />


            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-2/12 text-center">Request ID</div>
                        <div className="w-2/12 text-center">COH Customer ID</div>
                        <div className="w-2/12 text-center">COH Customer Short Name</div>
                        <div className="w-2/12 text-center">Request Type</div>
                        <div className="w-2/12 text-center">Request Date</div>
                        <div className="w-2/12 text-center">Status</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!listOfSuggestions || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        listOfSuggestions.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            listOfSuggestions.map((suggestion, index) => {
                                return <Link to={`./sugestion/${suggestion.user_suggestion_id}`} key={index + 'link'}>
                                    <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                        <div className="w-2/12 text-center">{suggestion.user_suggestion_id}</div>
                                        <div className="w-2/12 text-center">{suggestion.skf_id}</div>
                                        <div className="w-2/12 text-center">{suggestion.name}</div>
                                        <div className="w-2/12 text-center">{suggestion.suggestion_type}</div>
                                        <div className="w-2/12 text-center">{suggestion.created_at}</div>
                                        <div className="w-2/12 text-center flex justify-center">
                                            <Status
                                                key={suggestion.user_suggestion_id + 'orderStatus'}
                                                id={suggestion.user_suggestion_id}
                                                currentStatus={suggestion.suggestion_status}
                                                clickable="true" />
                                        </div>
                                    </div>
                                </Link>;
                            })
                    }
                </div>
            </div>

        </div>);
}

export default ListOfSuggestions; 