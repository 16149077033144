import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import FilterMenu from "../../components/FilterMenu";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import GlobalSVG from '../../utils/GlobalSVG';
import ReactGA from 'react-ga';
// Importar
import { useLanguage } from '../../context/LanguageContext';

function SendEmails() {
    // Importar
    const { translate } = useLanguage();

    const [listOfEmails, setListOfEmails] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(true);
    const [isClicked, setIsClicked] = useState(false);
    const [country, setCountry] = useState(localStorage.getItem('country') ? localStorage.getItem('country') : '');
    const [sb, setSb] = useState(localStorage.getItem('sb') ? localStorage.getItem('sb') : '');
    const [demo, setDemo] = useState(localStorage.getItem('demo') ? localStorage.getItem('demo') : false);
    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false)
    const [sorting, setSorting] = useState("desc")


    // {"dispached_emails_id":1, email_name:"First Email", sender_name: "C9Apps", sender_email:"novelini@c9apps.com.br" ,"submitted_at":"2024-18-05", "status":"Sent"}

    const searchFields = [,
    { fieldValue: "email_name", fieldName: translate('sendEmailsH1') },
    { fieldValue: "sender_name", fieldName: translate('sendEmailsH2') },
    { fieldValue: "sender_email", fieldName: translate('sendEmailsH3') },
    { fieldValue: "submitted_at", fieldName: translate("sendEmailsH4") },
    { fieldValue: "status", fieldName: translate('sendEmailsH5') }];

    const statusDictionary = {
        "Pedido Concluido": "Completed",
        "Pedido Cancelado": "Canceled",
        "Pagamento Expirado": "Expired Payment",
        "Em processamento": "Processing",
        "Aguardando Registro COH": "Awaiting COH Registration",
        "Aguardando Pagamento": "Awaiting Payment",
        "Pagamento Não Autorizado": "Payment Not Authorized",
        "Erro": "Error",
    }

    function replaceStatusString(input) {
        let output = input;
        for (const key in statusDictionary) {
            const value = statusDictionary[key];
            const regex = new RegExp(key, 'gi');
            output = output.replace(regex, value);
        }
        return output;
    }

    const paymentMethod = new Map([[1, 'Credit Limit'], [2, 'Credit Card']])

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    useEffect(() => {
        API.getListOfEmails(cancelToken, currentPage).then(res => {
            console.log(res);
            res.data.emails ? setListOfEmails(res.data.emails) : setListOfEmails([]);
            setMaxPages(res.data.pagination.total_pages)
        }).catch(err => {
            setListOfEmails([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        // load()
    }, [currentPage, reset, isSearch, sorting, country, sb, demo]);



    useEffect(() => {
        let pages = []
        console.log(`maxPages: ${maxPages}`)
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])


    const doReset = () => {
        console.log("resetting")
        setReset(!reset)
    }


    const handleSearch = (e) => {
        e && e.preventDefault();

        console.log(`VALUE: ${value}`)
        if (!value || value.trim() === '' || !field) {
            setError(true)
            return
        }
        if (currentPage === 1) {
            setIsSearch(true)
        } else {
            setCurrentPage(1)
        }
        setIsClicked(true);
    }



    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title={translate('sendEmailsTitle')}
                link="../create-email"
                buttonDescription={translate('sendEmailsBtn')}
                svg="orders"
                hasButton="true" />

            <SearchBar
                searchFields={searchFields}
                valueRef={valueRef}
                fieldRef={fieldRef}
                fieldStatusRef={fieldStatusRef}
                onSearch={handleSearch}
                clicked={isClicked}
                dropDownValues={NaN}
                error={error}
                reset={doReset} />

            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-2/12 text-center">{translate('sendEmailsH1')}</div>
                        <div className="w-2/12 text-center">{translate('sendEmailsH2')}</div>
                        <div className="w-3/12 text-center">{translate('sendEmailsH3')}</div>
                        <div className="w-3/12 text-center">{translate('sendEmailsH4')}</div>
                        <div className="w-2/12 text-center">{translate('sendEmailsH5')}</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!listOfEmails || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        listOfEmails.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                {/* {"dispached_emails_id":1, email_name:"First Email", sender_name: "C9Apps", "submitted_at":"2024-18-05", "status":"Sent"} */}
                                No Data Found
                            </div> :
                            listOfEmails.map((email, index) => {
                                return <Link to={`./Order/${email.dispached_emails_id}`} key={index + 'link'}>
                                    <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                        <div className="w-2/12 text-center">{email.email_name}</div>
                                        <div className="w-2/12 text-center">{email.sender_name}</div>
                                        <div className="w-3/12 text-center">{email.sender_email}</div>
                                        <div className="w-3/12 text-center">{databaseToDate(email.submitted_at)}</div>
                                        <div className="w-2/12 text-center flex justify-center">
                                            <Status
                                                key={email.dispached_emails_id + 'orderStatus'}
                                                id={email.dispached_emails_id}
                                                currentStatus={replaceStatusString(email.status)}
                                                clickable="true" />
                                        </div>
                                    </div>
                                </Link>;
                            })
                    }
                </div>
            </div>
            {
                !loading && maxPages ?
                    <div className="page-selector flex-row-nowrap space-between">
                        <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                            {
                                !mobile && <p>{translate("PreviousLabel")}</p>
                            }
                        </div>
                        <div className="pages-section flex-row-nowrap">
                            {
                                pages
                            }
                        </div>
                        <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            {
                                !mobile && <p>{translate("NextLabel")}</p>
                            }
                            <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                        </div>
                    </div> :
                    null
            }
        </div>);
}

export default SendEmails; 