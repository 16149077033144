

export default function RadioButtonField(props) {
    return (
        <div class="flex-col w-full">
            <p>{props.label}</p>
            <div class="flex gap-2">
                {props.list && props.list.map((elem, i) => {
                    return (
                        < div class="inline-flex items-center" >
                            <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={i}>
                                <input name={props.name} type="radio"
                                    disabled={props.disabled}
                                    class={`before:content[''] peer relative h-5 w-5 cursor-pointer bg-[#0C1825] appearance-none rounded-full border border-[#FFF] text-gray-900 transition-all before:absolute before:top-2/4 ${props.error ? "border border-amber-900" : "border-0"} before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10`}
                                    value={elem}
                                    checked={props.value === elem}
                                    onChange={() => props.setValue(elem)}
                                />
                                <span
                                    class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                                        <circle cx="8" cy="8" r="8" fill="#80CAFF" />
                                        <circle cx="7.99996" cy="8.00005" r="3.2" fill="#0C1825" />
                                    </svg>
                                </span>
                            </label>
                            <label class="mt-px font-light text-[#FFFFFF] cursor-pointer select-none" htmlFor={i}>
                                {elem}
                            </label>
                        </div>
                    )
                })
                }
            </div >
        </div>
    )
}