import React, { useState, useRef, useEffect, useContext } from "react";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import './style.css'
import Status from "../../components/Status";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import GlobalSVG from '../../utils/GlobalSVG';
import * as XLSX from 'xlsx';


function Reports() {
    const [listOfReports, setListOfReports] = useState(null);
    const [initialListOfReports, setInitialListOfReports] = useState(null);
    const context = useContext(AppContext);
    const history = useHistory();
    let cancelToken = API.cancelToken();
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState("");
    const [field, setField] = useState("");
    const [reportLoading, setReportLoading] = useState(false);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [orders, setOrders] = useState([]);
    const [company, setCompany] = useState({ "companies": [], "users": [] });
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [editing, setEditing] = useState(null)
    const [force, setForce] = useState(true)
    const [editBody, setEditBody] = useState({ type: "", coh_id: "", country: "", reason: "", bi_exclusion_id: null })
    const [insertBody, setInsertBody] = useState([{ type: "", coh_id: "", country: "", reason: "" }])

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if (editing !== null) return
        setMobile(window.innerWidth <= 768);
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);

        setLoading(true)
        cancelToken = API.cancelToken();

        API.getReports(cancelToken, currentPage).then(res => {
            if (res.data.reports) {
                // setListOfReports(res.data.exclusions)
                setListOfReports(res.data.reports)
                setInitialListOfReports(res.data.reports)
                console.log(res.data.reports)
            } else {
                setListOfReports([]);
            }
            setMaxPages(res.data.pagination.total_pages)
        }).catch(err => {
            setListOfReports([]);
            setInitialListOfReports([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(
            f => {
                setLoading(false)
            });
        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, sort, field, editing, force]);



    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])


    const handleCreate = (e) => {
        const { name, value } = e.target;
        console.log(value)
        if (value != "") {
            const filteredArr = initialListOfReports.filter(item => item.name.includes(value));
            setListOfReports(filteredArr)
        } else {
            setListOfReports(initialListOfReports)
        }

    }
    const downloadReport = (report_id) => {
        setReportLoading(true)
        Swal.fire({
            title: 'Downloading Report...',
            allowOutsideClick: false,
            showConfirmButton: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });
        API.getReport(cancelToken, report_id).then(res => {
            if (res.data.report_data) {
                console.log(`There is data for ${report_id}`)
                if (report_id == "1") {
                    downloadFile(res.data.report_data.url)
                    Swal.fire({
                        title: 'Report Download',
                        text: 'Report Sucessfuly Downloaded.',
                        confirmButtonColor: "#131921",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                }
                if (report_id == "2") {
                    downloadFile(res.data.report_data.url)
                    Swal.fire({
                        title: 'Report Download',
                        text: 'Report Sucessfuly Downloaded.',
                        confirmButtonColor: "#131921",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                }
                setReportLoading(false)
            } else {
                Swal.fire({
                    title: 'No data',
                    text: 'No data available for this report',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
                setReportLoading(false)
            }
        }).catch(err => {
            Swal.fire({
                title: 'Error',
                text: 'Please try again later or contact support',
                confirmButtonColor: "#131921",
                icon: 'error',
                confirmButtonText: 'OK'
            });
            setReportLoading(false)
        })
    }

    function downloadFile(url) {
        const a = document.createElement('a');
        a.href = url;
        a.download = true; // This will prompt the file to download
        a.click(); // This will trigger a click event programmatically
    }

    useEffect(() => {
        if (orders.length > 0) {
            console.log("MAKING EXCEL")
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(orders);
            worksheet['!cols'] = [
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 }
            ];
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, "orders.xlsx");
        }
    }, [orders]);

    useEffect(() => {
        if (company.companies.length > 0) {
            console.log("MAKING EXCEL FOR COMPANY")
            var workbook = XLSX.utils.book_new();
            var company_arr = [['SKF ID', 'NAME', 'CNPJ', 'CUSTOMER TYPE', 'SEGMENT', 'SUB BUSINESS SEGMENT', 'ACCOUNT STATUS']]
            var user_arr = [['NAME', 'EMAIL', 'TELEPHONE', 'COH COH Customer ID', 'COH Customer Name', 'CNPJ', 'CUSTOMER TYPE', 'SEGMENT', 'SUB BUSINESS SEGMENT', 'ACCOUNT STATUS', 'ACCEPTED EMAIL MARKETING?']]
            company.companies.forEach(company => {
                company_arr.push([company['skf_id'], company['name'], company['cnpj'], company['type'], company['segment'], company['sub_business_segment'], company['status']])
            });
            company.users.forEach(user => {
                user_arr.push([user['name'],
                user['email'],
                user['phone_number'],
                user['skf_id'],
                user['company_name'],
                user['cnpj'],
                user['type'],
                user['segment'],
                user['sub_business_segment'],
                user['status'],
                user['is_marketing_allowed']
                ])
            });
            var worksheet_company = XLSX.utils.aoa_to_sheet(company_arr);
            var worksheet_user = XLSX.utils.aoa_to_sheet(user_arr);
            worksheet_company['!cols'] = [
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 }
            ];

            worksheet_user['!cols'] = [
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 }
            ];

            const boldStyle = { font: { bold: true } };

            // Assuming you'll have up to Z columns (adjust accordingly)
            const cols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
            for (let col of cols) {
                if (!worksheet_company[`${col}1`]) break;  // stop if the column is not used
                worksheet_company[`${col}1`].s = {
                    ...worksheet_company[`${col}1`].s,  // preserve other styles like background color
                    ...boldStyle
                };
            }
            XLSX.utils.book_append_sheet(workbook, worksheet_company, 'Companies');
            XLSX.utils.book_append_sheet(workbook, worksheet_user, 'Orders');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, "company.xlsx");
        }
    }, [company]);


    return <div className="flex-col md:p-10 p-5  customers-page height-limiter">
        <ScreenHeader
            title="Reports"
            link="../bulkexclusion"
            buttonDescription=""
            svg="ListOfCustomers"
            hasButton="false" />

        <form>
            <div id="searchBar" className="bg-gray-200 flex-row wrap-override h-30 my-6 p-6 rounded-md">
                <div className="w-full md:w-2/5 md:mr-2 mt-2 md:mt-0">
                    <input
                        onChange={handleCreate}
                        name="reason"
                        type="text"
                        className={`bg-blue col-span-3 md:text-base placeholder-white px-5 py-2 rounded-md  text-xs w-full box-border `}
                        placeholder="Type to search a report"
                        maxLength="100"
                    />
                </div>
            </div>
        </form>

        <div className="text-xs lg:text-base overflow-auto">
            <div id="resultTable" className="flex-col justify-between w-full">
                <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 pl-5 font-bold w-full">
                    <div className="w-1/3 pointer flex flex-row flex-nowrap align-center justify-center">
                        Report
                    </div>
                    <div className="w-1/3 pointer flex flex-row flex-nowrap align-center justify-center">
                        Description
                    </div>
                    <div className="w-1/3    pointer flex flex-row flex-nowrap align-center justify-center">
                        Request
                    </div>
                </div>
            </div>
            <div id="resultBody" className="w-full">
                {!listOfReports || loading === true ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div> :
                    listOfReports.length === 0 ?
                        <div className="loader-wrapper min-h-full min-w-full p-5">
                            No Data Found
                        </div> :
                        listOfReports.map((report, index) => {
                            return <div key={index + "exclusion"} id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 pl-5 w-full">
                                <p name="type" className="w-1/3 text-center bg-blue">
                                    {report.name}
                                </p>
                                <p name="type" className="w-1/3 text-center bg-blue">
                                    {report.description}
                                </p>
                                <div name="type" className="w-1/3 text-center bg-blue align-center justify-center flex flex-row flex-nowrap" onClick={() => downloadReport(report.report_id)}>
                                    {
                                        reportLoading ?
                                            <div className="loader-wrapper-sm">
                                                <Loader light={true} small={true} />
                                            </div> :
                                            GlobalSVG.download()
                                    }
                                </div>

                                {/* <input maxLength="30" onChange={handleEdit} name="reason" type="text" className="w-1/3 text-center bg-blue" defaultValue={exclusion.reason} disabled={editing !== index} />
                                {editing !== index && <div className="w-1/5 align-center justify-center flex flex-row flex-nowrap">
                                    <div className="mr-2 w-10 h-10 rounded-full bg-gray-700 p-2 flex flex-row flex-nowrap pointer" onClick={() => setEditing(index)}>
                                        {GlobalSVG.pencil()}
                                    </div>
                                    <div className="ml-2 w-10 h-10 rounded-full bg-gray-700 p-2 flex flex-row flex-nowrap pointer" onClick={() => handleDelete(exclusion.bi_exclusion_id, exclusion.coh_id)}>
                                        {GlobalSVG.garbage()}
                                    </div>
                                </div>} */}
                                {/* {editing === index && <div className="w-1/5 align-center justify-center flex flex-row flex-nowrap">
                                    <div className="mr-2 w-10 h-10 rounded-full bg-gray-700 p-2 flex flex-row flex-nowrap pointer" onClick={submitEdit}>
                                        {GlobalSVG.check()}
                                    </div>
                                    <div className="ml-2 w-10 h-10 rounded-full bg-gray-700 p-2 flex flex-row flex-nowrap pointer" onClick={() => setEditing(null)}>
                                        {GlobalSVG.close()}
                                    </div>
                                </div>} */}
                            </div>
                        })}
            </div>
        </div>
        {
            !loading && maxPages ?
                <div className="page-selector flex-row-nowrap space-between">
                    <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                        {
                            !mobile && <p>Previous</p>
                        }
                    </div>
                    <div className="pages-section flex-row-nowrap">
                        {
                            pages
                        }
                    </div>
                    <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        {
                            !mobile && <p>Next</p>
                        }
                        <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                    </div>
                </div> :
                null
        }

    </div>;
}

export default Reports; 