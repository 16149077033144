import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { saveAs } from 'file-saver';
import Status from "../../components/Status";
import Loader from "../../components/Loader";
import Swal from 'sweetalert2';
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import './style.css'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

function Order(props) {
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [downloadingNF, setDownloadingNF] = useState(false);
    const [orderLineDetails, setOrderLineDetails] = useState(null);
    const [nf, setNF] = useState(false);
    const context = useContext(AppContext);
    const history = useHistory();

    const statusRanking = new Map([
        ['RPI', [0, 'Processo de Faturamento']],
        ['PIC', [1, 'Em Separação']],
        ['ALL', [2, 'Processando Pagamento']],
        ['SHS', [3, 'Importação em Trânsito']],
        ['ENT', [4, 'Sem Previsão de Entrega']],//yellow
        ['BOK', [5, 'Entrega Programada']],
        ['RBK', [6, 'Entrega Reprogramada']], //yellow
        ['REJ', [7, 'Rejeitado']], //red
        ['INV', [8, 'Faturado']],
        ['CAN', [9, 'Cancelado']]
    ]);

    const statusDictionary = {
        "Pedido Concluido" : "Completed",
        "Pedido Cancelado" : "Canceled",
        "Pagamento Expirado" : "Expired Payment",
        "Em processamento" : "Processing",
        "Aguardando Registro COH" : "Awaiting COH Registration",
        "Aguardando Pagamento" : "Awaiting Payment",
        "Pagamento Não Autorizado" : "Payment Not Authorized",
        "Erro" : "Error",
    }


    function replaceStatusString(input) {
        let output = input;
        for (const key in statusDictionary) {
            const value = statusDictionary[key];
            const regex = new RegExp(key, 'gi');
            output = output.replace(regex, value);
        }
        return output;
    }


    function priceToReal(value) {
        if (value === null) return;
        console.log(value)
        try {
            value = parseFloat(value);
            let temp = value.toFixed(2).toString().replace('.', ',');
            return 'R$ ' + temp.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } catch (err) {
            return 'Not able to parse'
        }
    }

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    function getLineStatus(line){
        if(line.shipping_status != 'undefined' && line.shipping_status != null && line.shipping_status != '' && line.shipping_status != 'No status'){
            return line.shipping_status;
        }
        return statusRanking.get(line.status_code)[1]
    }

    let cancelToken = API.cancelToken();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getOrder(cancelToken, id).then(res => {
            res.data ? setOrderDetails(res.data) : setOrderDetails([]);
            res.data ? setOrderStatus(res.data.status) : setOrderStatus('NOT ABLE TO FETCH');
            res.data ? setOrderLineDetails(res.data.lines) : setOrderLineDetails([]);
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []
    );

    useEffect(() => {
        console.log(`NF: ${nf}`)
    }, [nf]);

    useEffect(() => {
        if (!orderLineDetails) return;
        if (orderLineDetails.length > 0) {
            orderLineDetails.forEach((e) =>{
                console.log(e)
                if(e.NF.nf_id){
                    setNF(true)
                }
            })
        }
    }, [orderLineDetails]);

    const getNF = (e) => {
        setDownloadingNF(true)
        const cancelToken = API.cancelToken();
        if (!downloadingNF){
            API.getOrderNF(id, cancelToken).then(response => {
                if(response.status != 200){
                    setDownloadingNF(false)
                    console.log(err)
                    Swal.fire({
                        title: 'Erro.',
                        text: 'Tente novamente mais tarde.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return;
                }
                response.blob().then(blob => {
                    console.log(blob)
                    saveAs(blob, `NF_ORDER_${id}.zip`)
                    setDownloadingNF(false)
                }).catch(err => {
                    setDownloadingNF(false)
                    console.log(err)
                    Swal.fire({
                        title: 'Erro.',
                        text: 'Tente novamente mais tarde.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                })
            }).catch(err => {
                setDownloadingNF(false)
                console.log(err)       
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }) 
            })
        }
    }

    const handleCancel = (e) => {
        e.preventDefault()
        let button = e.currentTarget
        button.disabled = true
        button.innerHTML = "Cancelling..."
        Swal.fire({
            text: 'Are you sure you want to cancel this order?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#131921",
            cancelButtonColor: "#880808",
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                const cancelToken = API.cancelToken();
                API.cancelOrder(cancelToken, orderDetails.order_id).then(res => {
                    setOrderStatus("Cancelled");
                }).catch(err => {
                    if (err.message === 'cancelling') return
                    if (err.response && err.response.status === 500) {
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                }).finally(f => {
                    button.disabled = false
                    button.innerHTML = "Cancel Order"
                })
                return () => {
                    API.cancel(cancelToken);
                }
            }
        });

    }

    return (
        <div className="flex-col md:p-10 p-5 height-limiter">
            <div className="flex-row justify-between">
                <h1 className="text-2xl">Order #{id} - {orderDetails && orderDetails.skf_order_id} </h1>
                <Status
                    currentStatus={orderStatus && replaceStatusString(orderStatus)}
                    clickable="false" />
            </div>
            <div className="mt-5 flex-row wrap-override justify-center text-xs lg:text-base text-white">
                <div id="orderInformation" className="flex-col justify-center max-w-sm m-auto align-center mx-5 mt-5 w-full md:w-1/3">
                    <p>Order Information</p>
                    <div className="rounded bg-gray-200 flex-column w-full p-5 h-60 md:h-80 mt-2">
                        {orderDetails ?
                            <div>
                                <div className="flex-row">
                                    <p className="lg:font-bold mr-2">AZPartes Order ID:</p>
                                    <p>{orderDetails && orderDetails.order_id ? orderDetails.order_id : "No data"}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">SKF Order ID:</p>
                                    <p>{orderDetails && orderDetails.skf_order_id ? orderDetails.skf_order_id : "No data"}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Customer Order Number:</p>
                                    <p>{orderDetails && orderDetails.client_order_id ? orderDetails.client_order_id : "No data"}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Order Date:</p>
                                    <p>{orderDetails && orderDetails.created_at ? databaseToDate(orderDetails.created_at) : "No data"}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Payment Method:</p>
                                    <p>{orderDetails && orderDetails.payment_method && orderDetails.payment_method.description ? orderDetails.payment_method.description : "No data"}</p>
                                </div>
                                {orderDetails && orderDetails.payment_method && orderDetails.payment_method.payment_method_id && orderDetails.payment_method.payment_method_id === 2 &&
                                    <div>
                                        <div className="flex-row mt-4" >
                                            <p className="lg:font-bold mr-2">Payment ID:</p>
                                            <p>{orderDetails.payment_method.payment_id ? orderDetails.payment_method.payment_id : "No data"}</p>
                                        </div>
                                        <div className="flex-row mt-4">
                                            <p className="lg:font-bold mr-2">Payment URL:</p>
                                            {orderDetails.payment_url ?
                                                <a href={orderDetails.payment_url} rel="noreferrer" target='_blank'>{orderDetails.payment_url}</a> :
                                                <p>No data</p>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Total Amount:</p>
                                    <p>{orderDetails && orderDetails.total_amount ? priceToReal(orderDetails.total_amount.toFixed(2)) : "No data"}</p>
                                </div>
                                {orderDetails && orderDetails.availability_request_id &&
                                    <div className="flex-row mt-4">
                                        <p className="lg:font-bold mr-2">Availibility ID:</p>
                                        <p>{orderDetails && orderDetails.availability_request_id}</p>
                                    </div>}
                            </div> :
                            <div className="loader-wrapper min-h-full min-w-full">
                                <Loader light={true} />
                            </div>
                        }
                    </div>
                </div>

                <div id="orderInformation" className="flex-col justify-center max-w-sm m-auto align-center mx-5 mt-5 w-full md:w-1/3">
                    <p>Customer Information</p>
                    <div className="rounded bg-gray-200 flex-column w-full p-5 h-60 md:h-80 mt-2">
                        {orderDetails ?
                            <div>
                                <div className="flex-row">
                                    <p className="lg:font-bold mr-2">Country:</p>
                                    <p>{orderDetails && orderDetails.country == "ARG" ? "Argentina" : "Brazil"}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">AZPartes COH Customer ID:</p>
                                    <p>{orderDetails && orderDetails.customer_id}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">COH COH Customer ID:</p>
                                    <p>{orderDetails && orderDetails.company_info && orderDetails.company_info.skf_id}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Customer Name:</p>
                                    <p>{orderDetails && orderDetails.company_info && orderDetails.company_info.name}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Customer Type:</p>
                                    <p>{orderDetails && orderDetails.company_info && orderDetails.company_info.type == "services" ? "Service" : orderDetails.company_info.type  }</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Business Segment:</p>
                                    <p>{orderDetails && orderDetails.company_info && orderDetails.company_info.sub_business_sector == "Service - BeaT" ? "Industrial" : orderDetails.company_info.sub_business_sector == "Service - Dynamics" ? "Industrial" : orderDetails.company_info.industry}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Sub Business Segment:</p>
                                    <p>{orderDetails && orderDetails.company_info && orderDetails.company_info.sub_business_sector}</p>
                                </div>
                            </div> :
                            <div className="loader-wrapper min-h-full min-w-full">
                                <Loader light={true} />
                            </div>}
                    </div>
                </div>

                <div id="orderInformation" className="flex-col justify-center align-center max-w-sm m-auto mx-5 mt-5 w-full md:w-1/3">
                    <p>Shipping Information</p>
                    <div className="rounded bg-gray-200 flex-column w-full p-5 h-60 md:h-80 mt-2">
                        {orderDetails ?
                            <div>

                                <div className="flex-row">
                                    <p className="lg:font-bold mr-2">Shipping Cost:</p>
                                    <p>{orderDetails && orderDetails.shipping_cost ? orderDetails.shipping_cost : "No data"}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Expected Shipping:</p>
                                    <p>{orderDetails && orderDetails.expected_shipping ? orderDetails.expected_shipping : "No data"}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Shipping Company:</p>
                                    <p>{orderDetails && orderDetails.shipping_company ? orderDetails.shipping_company : "No data"}</p>
                                </div>
                                <div className="flex-row mt-4">
                                    <p className="lg:font-bold mr-2">Shipping Address:</p>
                                    <p>{orderDetails &&
                                        orderDetails.delivery_address ?
                                        ((orderDetails.delivery_address.address === null ? "" : orderDetails.delivery_address.address) + " " +
                                            (orderDetails.delivery_address.address_2 === null ? "" : orderDetails.delivery_address.address_2) + " - " +
                                            (orderDetails.delivery_address.city === null ? "" : orderDetails.delivery_address.city)) : "No data"} </p>
                                </div>
                            </div> :
                            <div className="loader-wrapper min-h-full min-w-full">
                                <Loader light={true} />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div id="details" className="mt-5">
                {orderDetails && orderDetails.is_processed === 3 &&
                    <div className="flex-row-nowrap">
                        <Link to={`/createorder?q=${id}`} className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-4 mb-8" >Link Order</Link>
                        <button className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md m-4 mb-8" onClick={e => handleCancel(e)}>Cancel Order</button>
                    </div>}
                <div className="flex-row wrap-override">
                <p className="w-5/6 md:w-5/6 md:mr-2 mt-2 md:mt-0">Line/Split Details</p>
                { nf &&
                    <button className="h-10 w-1/2 md:w-1/6 bg-gray-700 rounded-md" onClick={(e) => getNF(e)} >{downloadingNF ? `DOWNLOADING NF...` : `DOWNLOAD NF-e`}</button>
                }
                </div>
                <div className="overflow-auto">
                    <div id="resultTable" className="flex-col justify-between mt-2">
                        <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                            <div className="w-1/12 text-center">Line</div>
                            <div className="w-1/12 text-center">Order Line Split</div>
                            <div className="w-1/12 text-center">SKU</div>
                            <div className="w-1/12 text-center">Quantity</div>
                            <div className="w-3/12 text-center">Invoice Number</div>
                            <div className="w-1/12 text-center">Billing Expected Date</div>
                            <div className="w-2/12 text-center">Billing Date</div>
                            <div className="w-2/12 text-center">Status</div>
                        </div>
                    </div>
                    <div id="resultBody">
                        {!orderDetails ?
                            <div className="loader-wrapper min-h-full min-w-full">
                                <Loader light={true} />
                            </div> :
                            (!orderLineDetails ?
                                <div className="loader-wrapper min-h-full min-w-full p-5">
                                    No Data Found
                                </div> :
                                orderLineDetails.map((order, index) => {
                                    return <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + "line"}>
                                        <div className="w-1/12 text-center">{order.line}</div>
                                        <div className="w-1/12 text-center">{order.split_id}</div>
                                        <div className="w-1/12 text-center">{order.SKU}</div>
                                        <div className="w-1/12 text-center">{order.quantity}</div>
                                        <div className="w-3/12 text-center truncate">{order.NF && order.NF.number}</div>
                                        <div className="w-1/12 text-center">{databaseToDate(order.billing_expected_date)}</div>
                                        <div className="w-2/12 text-center">{databaseToDate(order.billing_date)}</div>
                                        <div className="w-2/12 text-center flex justify-center">
                                            <Status
                                                key={index + "status"}
                                                currentStatus={getLineStatus(order)}
                                                type={orderDetails.company_info.type}
                                                country={orderDetails.country}
                                                clickable="false" />
                                        </div>
                                    </div>;
                                }))}
                    </div>
                </div>
            </div>
        </div>);
}

export default Order; 