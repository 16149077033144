import React, { useState } from 'react';
//import './style.css';
import { Link } from 'react-router-dom';

function DropDown(props) {

    const title = props.title;
    const children = props.children;
    const [toggle, setToggle] = useState({ display: "none" });

    function toggleClick() {
        if (toggle.display === 'none') {
            setToggle({ visibility: "visible" });
            return toggle;
        } else {
            setToggle({ display: "none" });
            return toggle;
        }
    }
    return (
        <div>
            <div onClick={toggleClick} className={`bg-blue cursor-pointer flex items-center justify-between rounded-tl-md rounded-tr-md p-2 ${toggle.display === 'none' && ' rounded-md'}`}>
                <h1>{title}</h1>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
            </div>
            <div style={toggle} className="absolute bg-blue rounded-bl-md rounded-br-md z-1">
                {
                    props.children.map((e, id) => (
                        e.href ?
                            <Link className="cursor-pointer hover:bg-gray-400" to={e.href} key={e.text + id}>
                                <p className="px-2 py-2">{e.text}</p>
                            </Link> :
                            <p key={e.text + id}
                                className="cursor-pointer px-2 py-2 text-neutral-400 hover:text-white"
                                onClick={() => {
                                    toggleClick();
                                    e.action(); 
                                }}  >{e.text}</p>
                    ))
                }
            </div>

        </div>
    )
}

export default DropDown;