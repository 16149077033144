import { useEffect, useState, useRef } from 'react';
import './style.css';
import API from "../../utils/API";
import Loader from "../../components/Loader";
import { Link, useLocation, useHistory } from 'react-router-dom';

function ChangePassword() {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [success, setSuccess] = useState(null);
    // const [strength, setStrength] = useState(null);
    const password = useRef();
    const confirmPassword = useRef();
    const location = useLocation();
    const history = useHistory();


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('comp') !== null) history.push('/')
        params.get('ref') ? setToken(params.get('ref')) : setToken(false);
    }, [])

    useEffect(() => {
        if (token === false) history.push('/')
    }, [token])
    

    // TODO
    // let strongPassword = new RegExp('(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[^A-Za-z0-9])(?=.{8,})')
    // let mediumPassword = new RegExp('((?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[^A-Za-z0-9])(?=.{6,}))|((?=.[a-z])(?=.[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

    // const StrengthChecker = () => {
    //     if (strongPassword.test(password.current.value)) {
    //         console.log('strong')
    //         setStrength("Forte");
    //     } else if (mediumPassword.test(password.current.value)) {
    //         console.log('medium')
    //         setStrength("Média");
    //     } else {
    //         setStrength("Fraca");
    //         console.log('weak')
    //     }
    // }

    const changePassword = (e) => {
        e.preventDefault();
        setError(null);
        if (!password.current.value) return;
        if (!confirmPassword.current.value) return;
        if (password.current.value !== confirmPassword.current.value) {
            setError('As senhas não coincidem');
            return;
        }
        setLoading(true);
        const cancelToken = API.cancelToken();
        API.changePassword(cancelToken, token, password.current.value).then(res => {
            setSuccess(true);
            history.push(location.pathname + location.search + '&comp')
            setLoading(false);
        }).catch(err => {
            if(err.message==='cancelling') return
            setError(err.response.message);
            console.log(err.message);
        });
        return () => {
            API.cancel(cancelToken);

        }
    }


    return (
        <div className="password-page">
            <div className="form-container">
                <Link className="reset" to={'/home'}>
                    <h3 className="margin-bottom-20">AZPartes.com</h3>
                </Link>
                {
                    success ?
                        <div>
                            <p className="success-message-white text-center margin-bottom-30">Senha alterada com sucesso!</p>
                            <Link className="reset" to="/login">
                                <div className="action-button">Log in</div>
                            </Link>
                        </div> :
                        <form>
                            <p className="label">Senha</p>
                            <input type="password" ref={password} />
                            {/* <input type="password" ref={password} onChange={StrengthChecker} /> */}
                            <p className="label">Confirmar Senha</p>
                            <input type="password" ref={confirmPassword} />
                            {error &&
                                <p className="error margin-bottom-20">
                                    {error}
                                </p>
                            }
                            {/* {
                                strength &&
                                <p className="strength margin-bottom-30">
                                    A sua senha está {strength}
                                </p>
                            } */}
                            {
                                loading ?
                                    <div className="loader-wrapper">
                                        <Loader light={true} />
                                    </div> :
                                    <input type="submit" className="action-button margin-bottom-20" onClick={(e) => changePassword(e)} value="Enviar" />

                            }
                            <h5>Precisa de ajuda?</h5>
                            <Link className="reset" to={'/'}>
                                <p>Outros problemas com o acesso</p>
                            </Link>
                        </form>
                }
            </div>
        </div>
    )
}

export default ChangePassword;