import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import UploadTranslation from "../../components/UploadTranslation";
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
import API from "../../utils/API";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import Loader from "../../components/Loader";

function BulkTranslation() {
    
    const [productDetails, setProductDetails] = useState([]);
    const [reportLoading, setReportLoading] = useState(false);
    let cancelToken = API.cancelToken();
    
    useEffect(() => {
        if(productDetails.length > 0){
            console.log("GET DATA")
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(productDetails);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Missing Translation');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, "Missing_Translation_Report.xlsx");
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
    }, [productDetails]);
    

    function getData(){
        setReportLoading(true)
        Swal.fire({
            title:'Creating report...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        API.getMissingTranslation(cancelToken).then(res => {
            if(res.data){
                var arr = [['SKU','Price List','Original', 'English', 'Spanish', 'Portuguese']]
                console.log(res.data)
                res.data.products.forEach(product => {
                    arr.push([product['skf_product_id'],product['price_list'],product['description'],product['english'],product['spanish'],product['portuguese']])
                });
                setProductDetails(arr)
                setReportLoading(false)
            }else{
                setProductDetails(null);
                setReportLoading(false)
            }
        })
    }

    return <div className="flex-col md:p-10 p-5 orders-page height-limiter">
        <div id="header" className="flex-row justify-between">
            <div className="text-2xl my-2">Bulk Translation</div>
            <div className="flex flex-col md:flex-row justify-end gap-x-4">
                <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2" style={{width:"240px"}} onClick={() => {getData()}}>
                {
                            reportLoading ?
                            <div className="loader-wrapper-sm">
                                <Loader light={true} small={true}/>
                            </div>
                            :
                            <p className="px-2">Download Pending List</p>
                }
                </div>
            </div>
        </div>
        <UploadTranslation
        title="Upload the translation information .xlsx template below" />


    </div>
}

export default BulkTranslation;