import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import UploadFile from "../../components/UploadFile";
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
import API from "../../utils/API";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

function ProductInfo() {


    
    
    const [productDetails, setProductDetails] = useState([]);
    let cancelToken = API.cancelToken();
    
    useEffect(() => {
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        if(productDetails.length > 0){
            console.log("GET DATA")
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(productDetails);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Missing Packs');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, "Missing_Pack_Report.xlsx");
        }
    }, [productDetails]);
    

    function getData(){
        // setMissingPack(true)
        Swal.fire({
            title:'Creating report...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        API.getPackInformation(cancelToken).then(res => {
            if(res.data){
                var arr = [['Price List','SKU', 'Pack']]
                console.log(res.data)
                res.data.products.forEach(product => {
                    arr.push([product['price_list'],product['skf_product_id'],''])
                    // arr.push([product['price_list']])
                });
                setProductDetails(arr)
            }else{
                setProductDetails(null);
            }
        })
    }

    return <div className="flex-col md:p-10 p-5 orders-page height-limiter">
        <div id="header" className="flex-row justify-between">
            <div className="text-2xl my-2">Product Information Registration</div>
            <div className="flex flex-col md:flex-row justify-end gap-x-4">
                <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2" onClick={() => {getData()}}>
                    <p className="px-2">Download Pending List</p>
                </div>
                
                <a href="https://skfbuy-public-assets.s3.amazonaws.com/product_pack_template.xlsx" rel="noreferrer" target="_blank">
                    <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2">
                        <p className="px-2">Download Template</p>
                    </div>
                </a>
            </div>
        </div>
        <UploadFile
        title="Upload the product information .xlsx template below" />


    </div>
}

export default ProductInfo;