import React, { useState, useEffect, useContext } from "react";
import Status from "../../components/Status";
import CatalogSearchBar from "../../components/CatalogSearchBar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import Dropdown from "../../components/DropDown";
import Swal from 'sweetalert2';
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import { useParams, useHistory, Link } from "react-router-dom";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';


function EditIndustrial() {

    const { id } = useParams();
    const history = useHistory();
    const [productDetails, setProductDetails] = useState(null);
    const [newValue, setNewValue] = useState(null);
    const context = useContext(AppContext);
    console.log(id);

    let cancelToken = API.cancelToken();

    function handleChange(event) {
        const { name, value } = event.target;
        setNewValue(value)
    }

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getListOfFromToProduct(cancelToken, id).then(res => {
            res.data ? setProductDetails(res.data.invalid_product) : setProductDetails([]);
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []
    );

    function saveFromTo(e){
        Swal.fire({
            title:'Saving from to...',
            showConfirmButton: false,    
        })
        console.log(e.target.id)
        let target = e.target.id
        var value = ""
        API.putFromToProduct(cancelToken, id, newValue).then(res => {
            console.log(res)
            if (res.data.body.status != "OK"){
                Swal.fire({
                    title: 'Oops!',
                    text: res.data.body.status,
                    confirmButtonColor: "#131921",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }else{
                Swal.fire({
                    title: 'Success',
                    text: 'From-to relation sucessfully saved.',
                    confirmButtonColor: "#131921",
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            }
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });

    }

    function inactivateProduct() {
            Swal.fire({
                title:'Working on it...',
                showConfirmButton: false,    
            })
            API.putFromToProduct(cancelToken, id, '',true).then(res => {
                console.log(res)
                if (res.data.body.status != "OK"){
                    Swal.fire({
                        title: 'Oops!',
                        text: res.data.body.status,
                        confirmButtonColor: "#131921",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }else{
                    Swal.fire({
                        title: 'Success',
                        text: 'From-to sucessfully inactivated.',
                        confirmButtonColor: "#131921",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                    history.push('/fromto');
                }
            }).catch(err => {
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            });
        }
    return (


        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <div className="flex-row mb-5">
                <div className="flex align-center w-1/2">
                    <h1 className="lg:text-xl text-xs ">From To Product</h1>
                </div>
                <div className="w-1/2">
                    <Dropdown title="Actions"
                        children={
                            [
                                { text: "Inactivate From To", action: ()=>inactivateProduct() }
                            ]
                        }
                    />
                </div>
            </div>

                
            { productDetails ?
                <div className="bg-gray-100 px-8 py-4 rounded-2xl">
                    <div className="p-1 mt-4 text-l">Product Information</div>
                    <div className="flex flex-col rounded-lg border-2 px-8 py-4">
                        <label>From</label>
                        <div className="flex flex-col gap-y-2 gap-x-4 my-2 md:flex-row">
                            <input disabled={true} className="px-2 h-10 w-full md:w-5/6 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" defaultValue = {productDetails && productDetails.product_from}/>
                            <div className="md:w-1/6"></div>
                        </div>
                        <label className="mt-2">To</label>
                        <div className="flex flex-col gap-y-2 gap-x-4 my-2 md:flex-row">
                            <input className="px-2 h-10 w-full md:w-5/6 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" name="description_en" onChange={handleChange} defaultValue = {productDetails && productDetails.product_to} />
                            <button className="h-10 w-1/2 md:w-1/6 bg-gray-700 rounded-md" id="description_en" onClick={saveFromTo}>Save</button>
                        </div>
                    </div>
                </div>:
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div>
            }
        </div>


    )
}

export default EditIndustrial;