import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import SortableTable from "../../components/SortableTable";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

function Capgemini() {
    const [listOfPending, setListOfPending] = useState(null);
    const [currentPagePending, setCurrentPagePending] = useState(1);
    const [maxPagesPending, setMaxPagesPending] = useState(null);
    const [pagesPending, setPagesPending] = useState([]);
    const [listOfDone, setListOfDone] = useState(null);
    const [currentPageDone, setCurrentPageDone] = useState(1);
    const [maxPagesDone, setMaxPagesDone] = useState(null);
    const [pagesDone, setPagesDone] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(false);
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [isSearch, setIsSearch] = useState(false);
    const [openHistoryPending, setOpenHistoryPending] = useState(null)
    const [openHistoryDone, setOpenHistoryDone] = useState(null)

    // const searchFields = [{ fieldValue: "date", fieldName: "Date" },
    // { fieldValue: "order_number", fieldName: "COH Order ID" },
    // { fieldValue: "customer_number", fieldName: "COH COH Customer ID" },
    // { fieldValue: "customer_name", fieldName: "COH Short Name" },
    // { fieldValue: "client_order_id", fieldName: "Customer Order Number" },
    // { fieldValue: "payment", fieldName: "Payment Method" },
    // { fieldValue: "status", fieldName: "Order Status" },
    // { fieldValue: "nf_number", fieldName: "Invoice Number" },
    // { fieldValue: "nf_key", fieldName: "Invoice Key Access" },
    // { fieldValue: "billing_date", fieldName: "Billing Date" }];

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    function priceToReal(value) {
        if (value === null) return;
        console.log(value)
        try {
            value = parseFloat(value);
            let temp = value.toFixed(2).toString().replace('.', ',');
            return 'R$ ' + temp.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } catch (err) {
            return 'Not able to parse'
        }
    }
    const paymentMethod = new Map([[1, 'Credit Limit'], [2, 'Cartão Credito']])

    const dropDownValues = {
        status: [{ status: "received", description: "Aguardando Registro COH" },
        { status: "processing", description: "Em Processamento" },
        { status: "completed", description: "Pedido Concluído" },
        { status: "payment", description: "Aguardando Pagamento" },
        { status: "cancelled", description: "Pedido Cancelado" },
        { status: "expired", description: "Pagamento Expirado" },
        { status: "denied", description: "Pagamento Não Autorizado" },],
        payment: [{ status: "1", description: "Company Credit" }, { status: "2", description: "Credit Card" }]
    };

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setMobile(window.innerWidth <= 768);
        setLoading(true)

        cancelToken = API.cancelToken();

        API.getListOfCapgemini(cancelToken, currentPagePending, 0).then(res => {
            setMaxPagesPending(res.data.pagination.total_pages)
            res.data.orders ? setListOfPending(res.data.orders) : setListOfPending([]);
        }).catch(err => {
            setListOfPending([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });
        API.getListOfCapgemini(cancelToken, currentPageDone, 1).then(res => {
            setMaxPagesDone(res.data.pagination.total_pages)
            res.data.orders ? setListOfDone(res.data.orders) : setListOfDone([]);
        }).catch(err => {
            setListOfDone([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });

        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPagePending, isSearch]);


    useEffect(() => {
        let pages = []
        console.log(`maxPagesPending: ${maxPagesPending}`)
        if (maxPagesPending <= 7) {
            for (let i = 1; i <= maxPagesPending; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPagePending === i && 'active'}`} onClick={() => setCurrentPagePending(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPagePending === 1 && 'active'}`} onClick={() => setCurrentPagePending(1)}>{1}</div>)
            if (currentPagePending - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPagesPending - currentPagePending >= 4) {
                    pages.push(<div key={'page' + currentPagePending - 1} className={`page-num`} onClick={() => setCurrentPagePending(currentPagePending - 1)}>{currentPagePending - 1}</div>)
                    pages.push(<div key={'page' + currentPagePending} className={`page-num active`} onClick={() => setCurrentPagePending(currentPagePending)}>{currentPagePending}</div>)
                    pages.push(<div key={'page' + currentPagePending + 1} className={`page-num`} onClick={() => setCurrentPagePending(currentPagePending + 1)}>{currentPagePending + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPagesPending - 4; i <= maxPagesPending - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPagePending === i && 'active'}`} onClick={() => setCurrentPagePending(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPagePending === 2 && 'active'}`} onClick={() => setCurrentPagePending(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPagePending === 3 && 'active'}`} onClick={() => setCurrentPagePending(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPagePending === 4 && 'active'}`} onClick={() => setCurrentPagePending(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPagePending === 5 && 'active'}`} onClick={() => setCurrentPagePending(5)}>{5}</div>)
                if (maxPagesPending - currentPagePending >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPagePending === maxPagesPending && 'active'}`} onClick={() => setCurrentPagePending(maxPagesPending)
            }> {maxPagesPending}</div >)
        }

        setPagesPending(pagesPending);
    }, [maxPagesPending, currentPagePending])

    useEffect(() => {
        let pages = []
        console.log(`maxPagesDone: ${maxPagesDone}`)
        if (maxPagesDone <= 7) {
            for (let i = 1; i <= maxPagesDone; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPageDone === i && 'active'}`} onClick={() => setCurrentPageDone(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPageDone === 1 && 'active'}`} onClick={() => setCurrentPageDone(1)}>{1}</div>)
            if (currentPageDone - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPagesDone - currentPageDone >= 4) {
                    pages.push(<div key={'page' + currentPageDone - 1} className={`page-num`} onClick={() => setCurrentPageDone(currentPageDone - 1)}>{currentPageDone - 1}</div>)
                    pages.push(<div key={'page' + currentPageDone} className={`page-num active`} onClick={() => setCurrentPageDone(currentPageDone)}>{currentPageDone}</div>)
                    pages.push(<div key={'page' + currentPageDone + 1} className={`page-num`} onClick={() => setCurrentPageDone(currentPageDone + 1)}>{currentPageDone + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPagesDone - 4; i <= maxPagesDone - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPageDone === i && 'active'}`} onClick={() => setCurrentPageDone(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPageDone === 2 && 'active'}`} onClick={() => setCurrentPageDone(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPageDone === 3 && 'active'}`} onClick={() => setCurrentPageDone(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPageDone === 4 && 'active'}`} onClick={() => setCurrentPageDone(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPageDone === 5 && 'active'}`} onClick={() => setCurrentPageDone(5)}>{5}</div>)
                if (maxPagesDone - currentPageDone >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPageDone === maxPagesDone && 'active'}`} onClick={() => setCurrentPageDone(maxPagesDone)
            }> {maxPagesDone}</div >)
        }

        setPagesDone(pagesDone);
    }, [maxPagesDone, currentPageDone])


    const handleSortPending = (sortValue) => {
        setListOfPending(null)
        setListOfPending(sortValue)

    }

    const handleSortDone = (sortValue) => {
        setListOfDone(null)
        setListOfDone(sortValue)

    }

    const handleShowHistoryPending = (value) => {
        if (openHistoryPending === value) setOpenHistoryPending(null)
        else setOpenHistoryPending(value)
    }

    const handleShowHistoryDone = (value) => {
        if (openHistoryDone === value) setOpenHistoryDone(null)
        else setOpenHistoryDone(value)
    }

    const handleSearch = (value) => {
        // if (openHistory === value) setOpenHistory(null)
        // else setOpenHistory(value)
    }


    const handleNextStatus = (orderId) => {
        console.log("******")
        console.log(orderId)
        Swal.fire({
            title: `Atenção!`,
            // html: "<hr><p class='mt-3'>Essa tarefa será convertida para [STATUS_VARIÁVEL]. Você confirma esta ação?</p>",
            html: "<hr><p class='mt-3'>Essa tarefa será marcada como concluída. Você confirma esta ação?</p>",
            showCancelButton: true,
            confirmButtonText: 'SIM',
            confirmButtonColor: "#289067",
            cancelButtonText: 'NÃO',
            cancelButtonColor: "#81263B",
            customClass: { container: 'capgemini-swal' }
        }).then((result) => {
            if (result.isConfirmed) {
                console.log("UPDATING")
                API.updateCapgeminiStatus(cancelToken, orderId).then(res => {
                    Swal.fire({
                        title: 'Parabéns!',
                        text: 'Tarefa concluída com sucesso',
                        confirmButtonColor: "#1e2b3c",
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        window.location.reload()
                    })
                }).catch(err => {
                    if (err.message === 'cancelling') return
                    if (err.response && err.response.status === 500) {
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            confirmButtonColor: "#1e2b3c",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                })
            }
        })
    }


    return (
        <div className="flex-col md:p-10 p-5 capgemini-page height-limiter">
            <ScreenHeader
                title="Capgemini"
                link="../CreateOrder"
                buttonDescription="Add Order"
                svg="orders"
                hasButton="false" />

            <div className="flex-row justify-between my-5">
                <div className="p-2 error-banner">
                    Somente enviar o arquivo .txt ao banco após todas as notas fiscais serem baixadas.
                </div>
                <div>
                    <form className="flex-row justify-center align-center" action="">
                        <input className="bg-transparent border-2 border-gray-500 rounded-l-lg p-3" type="text" name="search" placeholder="Type here" />
                        <button className="py-4 px-5 rounded-r-lg bg-slate-400" onClick={handleSearch}>
                            <img src={require('../../assets/images/magnifying_glass.png')} alt="previous" />
                        </button>
                    </form>
                </div>
            </div>
            <div className="relative mt-7">
                {openHistoryPending !== null &&
                    <div className="absolute right w-full h-full left-0 top-0 z-50 flex justify-center align-center" onClick={() => setOpenHistoryPending(null)}>
                        <div className="flex-col justify-center align-center custom-color w-fit">
                            <div className="flex flex-nowrap justify-between text-xs lg:text-base font-bold w-fit w-fit p-3">
                                <div className="col-width-force text-center">Status</div>
                                <div className="col-width-force text-center">Timestamp</div>
                                <div className="col-width-force text-center">User</div>
                            </div>
                            <div className="flex-col justify-center align-center custom-color w-fit max-h-36 overflow-y-auto">

                                {
                                    listOfPending[openHistoryPending].complete_tasks.map((e, index) => (

                                        <div key={"history" + index + e.status} className="flex flex-nowrap justify-between text-xs lg:text-base w-fit w-fit px-3 py-1">
                                            <div className="col-width-force text-center">{e.task_type}</div>
                                            <div className="col-width-force text-center" >{e.updated_at}</div>
                                            <div className="col-width-force text-center">{e.email}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
                <h3 className="mb-3">Pending</h3>
                <SortableTable headers={
                    [{ name: 'Registration Date', sorts: 'created_at' },
                    { name: 'COH Customer ID', sorts: 'skf_id' },
                    { name: 'COH Order ID', sorts: 'skf_order_id' },
                    { name: 'Status', sorts: '' },
                    { name: 'Total Paid', sorts: 'client_order_id' },
                    { name: 'Total Deposit', sorts: 'payment_method' },
                    { name: 'Deposit Timestamp', sorts: 'payment_method' },
                    { name: 'Amount Paid by Cost Center', sorts: 'payment_method' },
                    { name: 'Cost Center Timestamp', sorts: 'payment_method' },
                    { name: 'Total Chargeback', sorts: 'payment_method' },
                    { name: 'Chargeback Timestamp', sorts: 'payment_method' },
                    { name: 'Execution History', sorts: 'payment_method' },
                    { name: 'Checklist', sorts: 'status' }]
                }
                    data={
                        listOfPending ?
                            (listOfPending.length > 0 ?
                                listOfPending.map((order, index) => {
                                    return <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-nowrap py-5 text-xs lg:text-base pl-5 w-fit" key={index + 'order'}>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{databaseToDate(order.created_at)}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.skf_id}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.skf_order_id}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center flex justify-center">
                                            <Status
                                                key={order.order_finance_flow_id + 'orderStatus'}
                                                id={order.order_finance_flow_id}
                                                currentStatus={order.task_type}
                                                clickable="true" />
                                        </div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.total_amount === null ? "-" : priceToReal(order.total_amount)}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.total_deposited === null ? "-" : order.total_deposited}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.deposit_timestamp === null ? "-" : order.deposit_timestamp}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.amount_paid_by_cost_center === null ? "-" : order.amount_paid_by_cost_center}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.cost_center_timestamp === null ? "-" : order.cost_center_timestamp}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.total_chargeback === null ? "-" : order.total_chargeback}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.chargeback_timestamp === null ? "-" : order.chargeback_timestamp}</div>
                                        <div className={`col-width-force text-center ${openHistoryPending === index ? "rotate-180" : ""}`} onClick={() => handleShowHistoryPending(index)}><img src={require('../../assets/images/tooltip.png')} alt="previous" /></div>
                                        <div className="col-width-force text-center pointer" onClick={() => handleNextStatus(order.order_finance_flow_id)}>
                                            <input type="checkbox" name="" id="" />
                                        </div>
                                    </div>
                                })
                                :
                                <p className="m-5 w-full">No data found</p>
                            )
                            :
                            null
                    }
                    dataSet={listOfPending}
                    setDataSet={(value) => handleSortPending(value)}
                    loading={loading} />

                {
                    !loading && maxPagesPending && maxPagesPending > 1  ?
                        <div className="page-selector flex-row-nowrap space-between">
                            <div onClick={() => currentPagePending > 1 ? setCurrentPagePending(currentPagePending => currentPagePending - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                                <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                                {
                                    !mobile && <p>Previous</p>
                                }
                            </div>
                            <div className="pages-section flex-row-nowrap">
                                {
                                    pagesPending
                                }
                            </div>
                            <div onClick={() => currentPagePending < maxPagesPending ? setCurrentPagePending(currentPagePending => currentPagePending + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                                {
                                    !mobile && <p>Next</p>
                                }
                                <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                            </div>
                        </div> :
                        null
                }


                <h3 className="mb-3">Done</h3>
                <SortableTable headers={
                    [{ name: 'Registration Date', sorts: 'created_at' },
                    { name: 'COH Customer ID', sorts: 'skf_id' },
                    { name: 'COH Order ID', sorts: 'name' },
                    { name: 'Status', sorts: 'skf_order_id' },
                    { name: 'Total Paid', sorts: 'client_order_id' },
                    { name: 'Total Deposit', sorts: 'payment_method' },
                    { name: 'Deposit Timestamp', sorts: 'payment_method' },
                    { name: 'Amount Paid by Cost Center', sorts: 'payment_method' },
                    { name: 'Cost Center Timestamp', sorts: 'payment_method' },
                    { name: 'Total Chargeback', sorts: 'payment_method' },
                    { name: 'Chargeback Timestamp', sorts: 'payment_method' },
                    { name: 'Execution History', sorts: 'payment_method' }]
                }
                    data={
                        listOfDone ?
                            (listOfDone.length > 0 ?
                                listOfDone.map((order, index) => {
                                    return <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-nowrap py-5 text-xs lg:text-base pl-5 w-fit" key={index + 'order'}>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{databaseToDate(order.created_at)}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.skf_id}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.skf_order_id}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center flex justify-center">
                                            <Status
                                                key={order.order_finance_flow_id + 'orderStatus'}
                                                id={order.order_finance_flow_id}
                                                currentStatus={order.task_type}
                                                clickable="true" />
                                        </div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.total_amount === null ? "-" : priceToReal(order.total_amount)}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.total_deposited === null ? "-" : order.total_deposited}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.deposit_timestamp === null ? "-" : order.deposit_timestamp}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.amount_paid_by_cost_center === null ? "-" : order.amount_paid_by_cost_center}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.cost_center_timestamp === null ? "-" : order.cost_center_timestamp}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.total_chargeback === null ? "-" : order.total_chargeback}</div>
                                        <div onClick={() => history.push(`/capgeminitask/${order.order_finance_flow_id}`)} className="col-width-force text-center">{order.chargeback_timestamp === null ? "-" : order.chargeback_timestamp}</div>
                                        <div className={`col-width-force text-center ${openHistoryDone === index ? "rotate-180" : ""}`} onClick={() => handleShowHistoryDone(index)}><img src={require('../../assets/images/tooltip.png')} alt="previous" /></div>
                                    </div>
                                })
                                :
                                <p className="m-5">No data found</p>
                            )
                            :
                            null
                    }
                    dataSet={listOfDone}
                    setDataSet={(value) => handleSortDone(value)}
                    loading={loading} />
            </div>
            {
                    !loading && maxPagesDone && maxPagesDone > 1 ?
                        <div className="page-selector flex-row-nowrap space-between">
                            <div onClick={() => currentPageDone > 1 ? setCurrentPageDone(currentPageDone => currentPageDone - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                                <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                                {
                                    !mobile && <p>Previous</p>
                                }
                            </div>
                            <div className="pages-section flex-row-nowrap">
                                {
                                    pagesDone
                                }
                            </div>
                            <div onClick={() => currentPageDone < maxPagesDone ? setCurrentPageDone(currentPageDone => currentPageDone + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                                {
                                    !mobile && <p>Next</p>
                                }
                                <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                            </div>
                        </div> :
                        null
                }
        </div>);
}

export default Capgemini; 