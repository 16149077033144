import React, { useState } from "react";
import './App.css';

import Order from "./pages/Order";
import FromToProduct from "./pages/FromToProduct";
import CatalogTransition from "./pages/CatalogTransition";
import OutOfCatalog from "./pages/OutOfCatalog";
import CatalogOpportunity from "./pages/CatalogOpportuity";
import EditIndustrial from "./pages/EditIndustrial";
import ApiServices from "./pages/ApiServices";
import ApiService from "./pages/ApiService";
import PriceDetails from "./pages/PriceDetails";
import ProductDisplay from "./pages/ProductDisplay";
import FromToPage from "./pages/FromToPage";
import EditProduct from "./pages/EditProduct";
import ProductInfo from "./pages/ProductInfo";
import NotificationPanel from "./pages/NotificationPanel";
import DetailedNotification from "./pages/DetailedNotification";
import PriceCalculation from "./pages/PriceCalculation";
import CreateOrder from "./pages/CreateOrder";
import Recovery from "./pages/Recovery";
import ListOfOrders from "./pages/ListOfOrders";
import ListOfMaintenance from "./pages/ListOfMaintenance";
import ListOfCompanies from "./pages/ListOfCompanies";
import ExcludedIds from "./pages/ExcludedIds";
import BulkExcludedIds from "./pages/BulkExcludedIds";
import CustomerRegistration from "./pages/CustomerRegistration";
import ListOfCompanyUsers from "./pages/ListOfCompanyUsers";
import AddUser from "./pages/AddUser";
import AdminUser from "./pages/AdminUser";
import ApiTokenAccess from "./pages/ApiTokenAccess";
import AddServiceUser from "./pages/AddServiceUser";
import AddService from "./pages/AddService";
import UserAdmin from "./pages/UserAdmin";
import Customer from "./pages/Customer";
import ChangePassword from "./pages/ChangePassword";
import ListOfAvailibilies from "./pages/ListOfAvailibilities";
import CustomerUserRegistration from "./pages/CustomerUserRegistration";
import CustomerUser from "./pages/CustomerUser";
import Availibility from "./pages/Availibility";
import Login from "./pages/Login";
import Capgemini from "./pages/Capgemini";
import CapgeminiTask from "./pages/CapgeminiTask";
import ServiceBilling from "./pages/ServiceBilling";
import ServiceBillingPJ from "./pages/ServiceBillingPJ";
import servicelist from "./pages/ServicePJList";
import Orderbook from "./pages/Orderbook";
import Reports from "./pages/Reports";
import ContextProvider from "./utils/ContextProvider";
import Navigation from "./components/Navigation";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import BulkTranslation from "./pages/BulkTranslation";
import ReactGA from 'react-ga';
import ListOfTags from "./pages/ListOfTags";
import CreateTag from "./pages/CreateTag";
import GetTag from "./pages/GetTag";
import AddMaintenance from "./pages/AddMaintenance";
import ListOfSuggestions from "./pages/ListOfSuggestions";
import CreateSuggestion from "./pages/CreateSuggestion";
import PolicyUpdate from "./pages/PolicyUpdate";
import PolicyRegistration from "./pages/PolicyRegistration";
import Banners from "./pages/Banners";
import AddBanner from "./pages/AddBanner";
import ProductGuides from "./pages/ProductGuides";
import Campaigns from "./pages/Campaigns";
import AddProductGuide from "./pages/AddProductGuide";
import AddCampaign from "./pages/AddCampaign";
import { LanguageProvider } from "./context/LanguageContext";
import SendEmails from "./pages/SendEmail";
import CreateEmail from "./pages/CreateEmail";
import AddDistributor from "./pages/AddDistributer";
import ListOfDistributors from "./pages/ListOfServicePartnerDistributors";
import listOfPartners from "./pages/ListOfServicePartners";
import AddPartner from "./pages/AddPartner";
import PartnerDetails from "./pages/UpdatePartner";
import DistributorDetails from "./pages/UpdateDistributor";
import ServicePartnerStats from "./pages/ServicePartnerStats";

ReactGA.initialize('G-EN5T877H8F');

function App() {
  const [style, setStyle] = useState({ display: "visible" });

  function isClicked() {
    if (style.display === "none") {
      setStyle({ visibility: "visible" });
    } else {
      setStyle({ display: "none" });
    }
  }

  return (
    <Router>
      <ContextProvider>
        <div className="flex-row-nowrap">
          <LanguageProvider>


            <Sidebar clicked={style} onClick={isClicked} />

            <div className={`flex-1 min-h-screen relative pb-10 ${style.display === "visible" || style.visibility === "visible" ? 'w-10/12' : 'w-full'}`}>
              <Navigation onClick={isClicked} />
              <Switch>
                <Route path="/createorder" component={CreateOrder} />
                <Route path="/createtag" component={CreateTag} />
                <Route path="/listoforders" component={ListOfOrders} />
                <Route path="/tags" component={ListOfTags} />
                <Route path="/add-distributor" component={AddDistributor} />
                <Route path="/apiservices" component={ApiServices} />
                <Route path="/sendemails" component={SendEmails} />
                <Route path="/create-email" component={CreateEmail} />
                <Route path="/distributors" component={ListOfDistributors} />
                <Route path="/partners" component={listOfPartners} />
                <Route path="/partner/stats" component={ServicePartnerStats} />
                <Route path="/partner/:id" component={PartnerDetails} />
                <Route path="/distributor/:id" component={DistributorDetails} />
                <Route path="/addpartners" component={AddPartner} />
                <Route path="/apiservice/:id" component={ApiService} />
                <Route path="/fromto" component={FromToPage} />
                <Route path="/catalogtransition" component={CatalogTransition} />
                <Route path="/bulktranslation" component={BulkTranslation} />
                <Route path="/banners" component={Banners} />
                <Route path="/addbanner" component={AddBanner} />
                <Route path="/productguides" component={ProductGuides} />
                <Route path="/addproductguide" component={AddProductGuide} />
                <Route path="/campaigns" component={Campaigns} />
                <Route path="/addcampaign" component={AddCampaign} />
                <Route path="/outofcatalog" component={OutOfCatalog} />
                <Route path="/policy" component={PolicyUpdate} />
                <Route path="/policy-registration" component={PolicyRegistration} />
                <Route path="/catalogopportunity" component={CatalogOpportunity} />
                <Route path="/editindustrial/:id" component={EditIndustrial} />
                <Route path="/pricecalculation" component={PriceCalculation} />
                <Route path="/pricedetails/:id/:product" component={PriceDetails} />
                <Route path="/productdisplay" component={ProductDisplay} />
                <Route path="/editproduct/:id" component={EditProduct} />
                <Route path="/tag/:id" component={GetTag} />
                <Route path="/productinfo" component={ProductInfo} />
                <Route path="/service/user/add" component={AddServiceUser} />
                <Route path="/service/add" component={AddService} />
                <Route path="/notificationpanel" component={NotificationPanel} />
                <Route path="/notification/:id" component={DetailedNotification} />
                <Route path="/listofcustomers" component={ListOfCompanies} />
                <Route path="/sugestions" component={ListOfSuggestions} />
                <Route path="/sugestion/:user_suggestion_id" component={CreateSuggestion} />
                <Route path="/maintenance" component={ListOfMaintenance} />
                <Route path="/addmaintenance" component={AddMaintenance} />
                <Route path="/listofavailibilities" component={ListOfAvailibilies} />
                <Route path="/customerregistration" component={CustomerRegistration} />
                <Route path="/adduser" component={AddUser} />
                <Route path="/adminuser/:id" component={AdminUser} />
                <Route path="/excludedids" component={ExcludedIds} />
                <Route path="/bulkexclusion" component={BulkExcludedIds} />
                <Route path="/useradmin" component={UserAdmin} />
                <Route path="/customeruser/:id" component={CustomerUser} />
                <Route path="/companyusers" component={ListOfCompanyUsers} />
                <Route path="/customeruserregistration" component={CustomerUserRegistration} />
                <Route path="/order/:id" component={Order} />
                <Route path="/fromtoproduct/:id" component={FromToProduct} />
                <Route path="/customer/:id" component={Customer} />
                <Route path="/availibility/:id" component={Availibility} />
                <Route path="/login" component={Login} />
                <Route path="/orderbook" component={Orderbook} />
                <Route path="/reports" component={Reports} />
                <Route path="/recuperacao" component={Recovery} />
                <Route path="/alterarsenha" component={ChangePassword} />
                <Route path="/capgemini" component={Capgemini} />
                <Route path="/capgeminitask/:id" component={CapgeminiTask} />
                <Route path="/servicebilling" component={ServiceBilling} />
                <Route path="/servicebillingpj" component={ServiceBillingPJ} />
                <Route path="/servicelist" component={servicelist} />
                <Route path="/apitoken" component={ApiTokenAccess} />
                <Route path="/partner/stats" component={ServicePartnerStats} />
                <Route path="*" component={ListOfOrders} />
              </Switch>
              <Footer />
            </div>
          </LanguageProvider>
        </div>
      </ContextProvider>
    </Router>
  )
};

export default App; 
