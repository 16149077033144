import React, { useEffect, useState } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import Notification from "../../components/Notification";
import API from "../../utils/API";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import {useHistory} from "react-router-dom";
import Swal from 'sweetalert2';

function NotificationPanel() {

    // const notifications = API.getNotifications();
    let cancelToken = API.cancelToken();
    const [listOfNotifications, setListOfNotifications] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    useEffect(() => {
        setLoading(true)
        API.getNotifications(cancelToken).then(res => {
            setListOfNotifications(res.data.notifications)
            setLoading(false)
        }).catch(err => {
            setListOfNotifications([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(
            f => {
                setLoading(false)
            }
        );
    }, []);

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title="Notification Panel"
                link="" />
            <div className="bg-gray-100 px-8 py-8 rounded-sm my-4">

                {!listOfNotifications || loading ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div> :
                    listOfNotifications.map((n, index) => {
                        return (
                            <Link to={`/notification/${n.admin_notification_id}`}>
                                <Notification
                                    priority={n.priority}
                                    title={n.title}
                                    subtitle={n.subtitle}
                                    updated_at={n.updated_at} />
                            </Link>

                        )
                    })
                }

            </div>



        </div>);
}

export default NotificationPanel; 