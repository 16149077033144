import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../utils/ContextProvider";
import { NavLink } from "react-router-dom";
import './style.css'
import { useLanguage } from '../../context/LanguageContext';

function Sidebar(props) {

    const context = useContext(AppContext);
    const role = context.getRole;
    const [toggle, setToggle] = useState({ visibility: "visible" })
    const [isClicked, setIsClicked] = useState(false);
    const [newWidth, setNewWidth] = useState(window.innerWidth);
    const [external, setExternal] = useState(true)
    const { translate } = useLanguage();

    window.addEventListener('resize', function (event) {
        setNewWidth(window.innerWidth);
    });

    useEffect(() => {
        if (context.getLogin) {
            setExternal(false)
        }
    }, [context])


    useEffect(() => {

        setToggle(props.clicked);

    }, [props.clicked])


    return (
        <div>
            {!external && <div className="flex-row sidebar-width h-full">
                {
                    newWidth < 1080 &&
                    <div style={toggle} onClick={() => props.onClick()} className="fixed top-0 left-0 z-10 w-full h-full bg-darkblue opacity-50">
                    </div>
                }
                <div style={toggle} className={newWidth > 1080 ? "flex-row h-full" : "fixed z-10"}>
                    <div className="flex-row">
                        <div className={"bg-gray-200 flex-col p-6 " + (newWidth < 1080 ? "min-h-screen" : "min-h-screen")}>
                            <div className="font-bold flex items-center">
                                <h1>AZPartes.com</h1>

                                {
                                    newWidth < 1080 &&
                                    <div onClick={() => { props.onClick() }} className="flex-row ml-10 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x cursor-pointer" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </div>}
                            </div>
                            <div className="flex-col mt-14 mb-6">
                                <h1 className="font-medium text-lg faded">Functions</h1>
                            </div>

                            {
                                context && role == 5 ?
                                    <div className="pl-6">
                                        <div className="flex-col mt-14 mb-6">
                                            <h1 className=" text-lg font-medium faded" >{translate('ServicePartnerLabel')}</h1>
                                        </div>
                                        <div>
                                            <div className="pl-6 pr-12">
                                                <NavLink to="/partner/stats"><div className="py-2 font-light text-lg">Service Partner</div></NavLink>
                                                <NavLink to="/distributors"><div className="py-2 font-light text-lg">{translate('Distributors')}</div></NavLink>
                                                <NavLink to="/partners"><div className="py-2 font-light text-lg">{translate('Partners')}</div></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="pl-6">
                                {
                                    context && role == 1 &&
                                    <NavLink to="/useradmin"><div className="py-2 font-light text-lg">{translate('AccessDescription')}</div></NavLink>
                                }

                                <NavLink to="/listoforders"><div className="py-1 font-light text-lg">{translate('orderTitle')}</div></NavLink>

                                {
                                    context && role != 3 && role != 4 &&
                                    <div>
                                        <div id="pending-task-menu">
                                            <div className="py-1 font-light text-lg">{translate('RegistrationLabel')}</div>
                                            <div className="pl-8">
                                                <NavLink to="/listofcustomers"><div className="py-2 font-light text-lg">{translate('CompaniesLabel')}</div></NavLink>
                                            </div>
                                            <div className="pl-8">
                                                <NavLink to="/companyusers"><div className="py-2 font-light text-lg">{translate('UsersLabel')}</div></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    context && role != 3 && role != 4 &&
                                    <div>
                                        <NavLink to="/listofavailibilities"><div className="py-2 font-light text-lg">{translate('AvailabilityLabel')}</div></NavLink>
                                        <NavLink to="/apiservices"><div className="py-2 font-light text-lg">{translate('APIServicesLabel')}</div></NavLink>
                                        <NavLink to="/fromto"><div className="py-2 font-light text-lg">{translate('FromToLabel')}</div></NavLink>
                                        <NavLink to="/excludedids"><div className="py-2 font-light text-lg">{translate('QlikDeletedLabel')}</div></NavLink>
                                        <NavLink to="/orderbook"><div className="py-2 font-light text-lg">{translate('OrderbookLabel')}</div></NavLink>
                                    </div>

                                }

                                {
                                    context && (role == 1 || role == 2) &&
                                    <div id="pending-task-menu">
                                        <div className="py-1 font-light text-lg">{translate('DownloadPriceListLabel')}</div>
                                        <div className="pl-8">
                                            <NavLink to="/pricecalculation"><div className="py-2 font-light text-lg">B2B</div></NavLink>
                                        </div>
                                        <div className="pl-8">
                                            <NavLink to="/pricecalculation/B2E"><div className="py-2 font-light text-lg">B2E</div></NavLink>
                                        </div>
                                    </div>
                                }

                                {/* {
                                    context && role != 3 &&
                                    <NavLink to="/capgemini"><div className="py-2 font-light text-lg">Capgemini</div></NavLink>
                                } */}

                                {
                                    context && role == 1 &&
                                    <div id="pending-task-menu">
                                        <div className="py-1 font-light text-lg">{translate('PendingTasksLabel')}</div>
                                        <div className="pl-8">
                                            <NavLink to="/OutOfCatalog"><div className="py-2 font-light text-lg">{translate('OutOfCatalogLabel')}</div></NavLink>
                                        </div>

                                        <div className="pl-8">
                                            <NavLink to="/catalogtransition"><div className="py-2 font-light text-lg">{translate('CatalogTransitionLabel')}</div></NavLink>
                                        </div>
                                        <div className="pl-8">
                                            <NavLink to="/productdisplay"><div className="py-2 font-light text-lg">{translate('ProductDisplayLabel')}</div></NavLink>
                                        </div>
                                        <div className="pl-8">
                                            {/* <NavLink to="/pricecalculation"><div className="py-2 font-light text-lg">Price Calculation</div></NavLink> */}
                                        </div>
                                        <div className="pl-8">
                                            <NavLink to="/catalogopportunity"><div className="py-2 font-light text-lg">{translate('CatalogOportunityLabel')}</div></NavLink>
                                        </div>
                                    </div>
                                }
                                {
                                    context && role == 2 &&
                                    <div id="pending-task-menu">
                                        <div className="py-1 font-light text-lg">Pending Tasks</div>
                                        <div className="pl-8">
                                            <NavLink to="/productdisplay"><div className="py-2 font-light text-lg">{translate('ProductDisplayLabel')}</div></NavLink>
                                        </div>
                                        <div className="pl-8">
                                            {/* <NavLink to="/pricecalculation"><div className="py-2 font-light text-lg">Price Calculation</div></NavLink> */}
                                        </div>
                                        <div className="pl-8">
                                            <NavLink to="/catalogopportunity"><div className="py-2 font-light text-lg">{translate('CatalogOportunityLabel')}</div></NavLink>
                                        </div>
                                    </div>
                                }
                                {
                                    context && (role == 1 || role == 2) &&
                                    <NavLink to="/tags"><div className="py-2 font-light text-lg">Google {translate('TagManagerLabel')}</div></NavLink>
                                }
                                {
                                    context && (role == 1) &&
                                    <NavLink to="/policy"><div className="py-2 font-light text-lg">{translate('policyTitle')}</div></NavLink>
                                }
                                {
                                    context && (role == 1) &&
                                    <NavLink to="/sendEmails"><div className="py-2 font-light text-lg">{translate('emailTitle')}</div></NavLink>
                                }
                                {
                                    context && (role == 1) &&
                                    <NavLink to="/sugestions"><div className="py-2 font-light text-lg">{translate('suggestionsTitle')}</div></NavLink>
                                }
                                {
                                    context && role != 4 &&
                                    <div>
                                        <NavLink to="/servicebilling"><div className="py-2 font-light text-lg">{translate('ServiceBillingLabel')}</div></NavLink>
                                        <NavLink to="/reports"><div className="py-2 font-light text-lg">{translate('ReportsLabel')}</div></NavLink>
                                    </div>

                                }

                                {
                                    context && (role == 1) &&
                                    <div id="marketing-menu">
                                        <div className="py-1 font-light text-lg">{translate('MarketingTitle')}</div>
                                        <div className="pl-8">
                                            <NavLink to="/banners"><div className="py-2 font-light text-lg">{translate('BannersTitle')}</div></NavLink>
                                        </div>

                                        <div className="pl-8">
                                            <NavLink to="/productguides"><div className="py-2 font-light text-lg">{translate('ProductGuidesTitle')}</div></NavLink>
                                        </div>
                                        <div className="pl-8">
                                            <NavLink to="/campaigns"><div className="py-2 font-light text-lg">{translate('CampaignsTitle')}</div></NavLink>
                                        </div>
                                    </div>
                                }

                                {
                                    context && role == 4 &&
                                    <div>
                                        <div className="flex-col mt-14 mb-6">
                                            <h1 className=" text-lg font-medium faded" >{translate('ServiceBillingLabel')} PJ</h1>
                                        </div>
                                        <div className="pl-6">
                                            <NavLink to="/servicebillingpj"><div className="py-2 font-light text-lg">{translate('ServiceInvoicePjLabel')}</div></NavLink>
                                            <NavLink to="/servicelist"><div className="py-2 font-light text-lg">{translate('ServicePjOrders')}</div></NavLink>
                                        </div>
                                    </div>
                                }

                            </div>
                            }

                            


                            <div className="flex-col mt-14 mb-6">
                                <h1 className=" text-lg font-medium faded" >{translate('OthersLabel')}</h1>
                            </div>
                            <div className="pl-6">
                                <div className="py-1 font-light cursor-pointer text-lg" onClick={() => { context.logOut() }}>{translate('LogoutLabel')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Sidebar;