import React, { useEffect, useState, useRef, useContext } from "react";
import Status from "../../components/Status";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Loader from '../../components/Loader';
import { Link } from "react-router-dom";
import './style.css'
import { AppContext } from "../../utils/ContextProvider";
import { useHistory, useParams } from "react-router-dom";

function AdminUser() {

    const context = useContext(AppContext);
    const history = useHistory();
    const [user, setUser] = useState({ name: "", email: "", role_id: "1" });
    const [loading, setLoading] = useState(false);
    let cancelToken = API.cancelToken();

    const { id } = useParams();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        return () => {
            API.cancel(cancelToken);
        }
    }, [])

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }

        console.log('Something')
        setLoading(true)
        API.getAdminUser(cancelToken, id).then(res => {
            console.log(res.data.user);
            var tempuser = { name: res.data.user.name, email: res.data.user.email, role_id: res.data.user.role }
            console.log(tempuser);
            setUser(tempuser)
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []
    );


    return (
        <div className="py-10 px-8 shrink-0 lg:mx-20 lg:px-20 height-limiter">
            <div className="flex flex-nowrap flex-row justify-between gap-x-5">
                <h1 className="text-3xl w-full py-3 mb-5">Admin User</h1>
            </div>
            {
                loading ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div> :
                    <div>
                        <div className="mb-3">
                            <p>Admin user information:</p>
                        </div>
                        <div className="bg-gray-100 p-5 px-16">
                            <form>
                                {
                                    user &&
                                    <div className="flex-col my-5">
                                        <div className="flex-row align-center space-between gap-x-4 my-6">
                                            <label>Name:</label>
                                            <input name="name" value={user.name != "" ? user.name : ""} className="w-full h-10 px-3 mt-2 bg-gray-200" disabled />
                                        </div>
                                        <div className="flex-row align-center space-between gap-x-4 my-6">
                                            <label>Email:</label>
                                            <input name="email" value={user.email != "" ? user.email : ""} className="w-full h-10 px-3 mt-2 bg-gray-200" disabled />
                                        </div>
                                        <div className="flex-row align-center space-between gap-x-4 my-6">
                                            <label>Role:</label>
                                            <select name="role_id" value={user.role_id != "" ? user.role_id : ""} className="px-3 h-10 mt-2 w-full bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" disabled>
                                                <option value="1">Admin</option>
                                                <option value="2">Internal Sales</option>
                                                <option value="3">Service Billing</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>

            }
        </div>
    );
}

export default AdminUser;