import React, { useEffect, useState, useRef, useContext } from "react";
import Status from "../../components/Status";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Loader from '../../components/Loader';
import { Link } from "react-router-dom";
import './style.css'
import { AppContext } from "../../utils/ContextProvider";
import { useHistory, useParams } from "react-router-dom";

function AddService() {

    const context = useContext(AppContext);
    const history = useHistory();
    const [buttonText, setButtonText] = useState("Add Service");
    const [buttonState, setButtonState] = useState(false);
    const [addService, setaddService] = useState({ description: "", service_owner: ""});
    let cancelToken = API.cancelToken();


    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        return () => {
            API.cancel(cancelToken);
        }
    }, [])

    function handleUserChange(event) {
        const { name, value } = event.target;
        setaddService(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    };

    function validateEmail(mail){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
            return (true)
        }
            return (false)
    }

    function addEditSubmit(e) {
        
        e.preventDefault();
        setButtonText("Creating Service...")
        setButtonState("disabled")
        // Form Validation
        if(!validateEmail(addService.service_owner)){
            console.log('This email is not valid!')
            Swal.fire({
                title: 'Error.',
                text: 'Digite um email válido.',
                confirmButtonColor: "#131921",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            setButtonText("Add Service")
            setButtonState(false)
        }
        const payload = { data: addService }
        API.postService(cancelToken, addService.description, addService.service_owner).then(async res => {
            Swal.fire({
                title: 'Added!',
                text: 'Service sucessfully added!',
                // confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                history.push('/apiservices')
            })
            setButtonText("Service Sucessfully Created!")
        }).catch(err => {
            Swal.fire({
                title: 'Failed...',
                text: err.response && err.response.status === 400 ? 'Please complete all the required fields' : 'Internal error, please try again later',
                confirmButtonColor: "#131921",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            setButtonText("Add Service")
            setButtonState(false)
        }).finally(f => {
        });
        
    }

    return (
        <div className="py-10 px-8 shrink-0 lg:mx-20 lg:px-20 height-limiter">
            <div className="flex flex-nowrap flex-row justify-between gap-x-5">
                <h1 className="text-3xl w-full py-3 mb-5">Add Service</h1>
            </div>
            <div className="mb-3">
                <p>Fill the form below to register a new service:</p>
            </div>
            <div className="bg-gray-100 p-5 px-16">
                <form onSubmit={(e) => addEditSubmit(e)}>
                    <div className="flex-col my-5">
                        <div className="flex-row align-center space-between gap-x-4 my-6">
                            <label>Service Name:</label>
                            <input name="description" onChange={handleUserChange} className="w-full h-10 px-3 mt-2 bg-gray-200" required />
                        </div>
                        <div className="flex-row align-center space-between gap-x-4 my-6">
                            <label>Service Owner:</label>
                            <input name="service_owner" onChange={handleUserChange} className="w-full h-10 px-3 mt-2 bg-gray-200" required />
                        </div>
                        <div className="flex-row">
                            <button type="submit" className="hover:opacity-70 px-10 py-2 h-12 mt-4 w-full rounded-md bg-gray-200" disabled={buttonState} >{buttonText}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddService;