import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Dropdown from "../../components/DropDown";
import Status from "../../components/Status";
import Loader from "../../components/Loader";
import API from "../../utils/API";
import Swal from 'sweetalert2';
import { AppContext } from "../../utils/ContextProvider";
import './style.css'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';


function Customer(props) {
    const { id } = useParams();
    const [customerDetails, setCustomerDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    let root_url = process.env.NODE_ENV === "staging" ? "https://staging.azpartes.com" : process.env.NODE_ENV === "production" ? "https://azpartes.com" : ""

    function handleChange(event) {
        const { name, value } = event.target;
        setCustomerDetails(preValue => {
            return {
                ...preValue,
                [name]: value
            }
        });
    }


    function sendPassword() {
        Swal.fire({
            title: 'Sending email...',
            showConfirmButton: false,
        })
        API.sendRecover(customerDetails.email).then(res => {
            Swal.fire({
                title: 'Success',
                text: 'Recover email has been sent',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }).catch(err => {
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }

    function resetPassword() {
        Swal.fire({
            title: 'Reseting password...',
            showConfirmButton: false,
        })
        API.hardReset(customerDetails.user_id).then(res => {
            Swal.fire({
                title: 'Success',
                text: `User's password has been reset to ${res.data.generated}`,
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }).catch(err => {
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }
    function inactivateUser() {
        Swal.fire({
            title: 'Working on it...',
            showConfirmButton: false,
        })
        API.inactivateUser(customerDetails.user_id).then(res => {
            Swal.fire({
                title: 'Success',
                text: `This user is no longer active.`,
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setCustomerDetails(prev => {
                return {
                    ...prev,
                    status: "Inactive"
                }
            })
        }).catch(err => {
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }

    const reactivateUser = () => {
        Swal.fire({
            title: 'Working on it...',
            showConfirmButton: false,
        })
        API.reactivateUser(customerDetails.user_id, customerDetails.skf_id).then(res => {
            Swal.fire({
                title: 'Success',
                text: `This user is now active.`,
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setCustomerDetails(prev => {
                return {
                    ...prev,
                    status: "active"
                }
            })
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && (err.response.status === 412)) {
                let errorMessage = ""
                for (let item of err.response.data.error) {
                    errorMessage += (`<li>${item}</li>`)
                }
                Swal.fire({
                    title: 'Error.',
                    html: "<p>There are divergent fields in the company registration, please request correction as specified below.</p><ul>" + errorMessage + "</ul>",
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: err.response.data.error,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
    }


    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getCustomerDetails(cancelToken, id).then(res => {
            console.log(res);
            res.data ? setCustomerDetails(res.data) : setCustomerDetails([]);
        }).catch(err => {
            setCustomerDetails([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []
    );

    function saveContactInformation() {

        if (!customerDetails.contact_name || customerDetails.contact_name.trim() === '' || !customerDetails.email || customerDetails.email.trim() === '' || !customerDetails.phone || customerDetails.phone.trim() === '') {
            setError(true)
            return
        }

        cancelToken = API.cancelToken();
        setLoading(true);

        API.companyContact(id, customerDetails.contact_name, customerDetails.email, customerDetails.phone).then(res => {
            setLoading(false);
            Swal.fire({
                title: 'Success',
                text: 'Information updated!',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }).catch(err => {
            setLoading(false);
            if (err.message === 'cancelling') return
            if (err.response && (err.response.status === 500 || err.response.status === 400)) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }

    const unlink = (e, user_id) => {
        console.log(user_id)
        e.preventDefault()
        let button = e.currentTarget
        button.disabled = true
        button.innerHTML = "Unlinking..."
        let payload = {
            "data": {
                "user_id": 1,
                "coh_company": "CC0186"
            }
        }

        let cancelToken = API.cancelToken();
        API.unlinkUser(cancelToken, payload).then(res => {
            let authUsers = []
            for (let user of customerDetails.authorized_users) {
                if (user.user_id != user_id) authUsers.push(user)
            }
            let tempCustDets = {...customerDetails}
            tempCustDets.authorized_users = authUsers
            setCustomerDetails(tempCustDets)
        }).catch(err => {
            console.log(err)
            button.disabled = false
            button.innerHTML = "Unlink"
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Failed to unlink.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }

    useEffect(() => {
    }, [customerDetails])
    return <div className="flex-col md:p-10 p-5 height-limiter">
        <div className="flex-row">
            <div className="flex align-center w-1/2">
                <h1 className="lg:text-xl text-xs ">Company {customerDetails && (customerDetails.skf_id === null ? "" : customerDetails.skf_id)}</h1>
                <div className="ml-5">
                    {customerDetails && <Status
                        currentStatus={customerDetails && customerDetails.status}
                        clickable="false" />}
                </div>
            </div>
            <div className="w-1/2">
                <Dropdown title="Actions"
                    children={
                        [
                            // { text: "Send Recover Password Email", action: () => sendPassword() },
                            // { text: "Hard Reset Password", action: () => resetPassword() },
                            {
                                text: customerDetails && customerDetails.status !== "Inactive" ?
                                    "Inactivate Company" : "Reactivate Company",
                                action: () => customerDetails && customerDetails.status !== "Inactive" ? inactivateUser() : reactivateUser()
                            }
                        ]
                    }
                />
            </div>
        </div>

        {customerDetails && customerDetails.error_message && <div id="errorMessage"
            className="bg-blue h-32 w-full rounded-md text-lightWite mt-5 p-5 lg:text-base text-xs">
            <p className="align-top">{customerDetails.error_message}</p>
        </div>}
        {
            customerDetails ?
                <div className="bg-blue rounded mt-10 text-xs lg:text-base md:p-10 p-5">
                    <div id="firstRow" className="flex-row wrap-override items-center w-full">
                        <div className="flex-col w-full md:w-2/12 md:mr-2 mt-5 box-border">
                            <p>COH COH Customer ID</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.skf_id === null ? "" : customerDetails.skf_id)}</p>
                            </div>
                        </div>

                        <div className="flex-col w-full md:w-3/12 md:mr-2 mt-5 box-border">
                            <p>Company's Name</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && customerDetails.name}</p>
                            </div>
                        </div>

                        <div className="flex-col w-full md:w-3/12 md:mr-2 mt-5 box-border">
                            <p>CNPJ</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.cnpj === null ? "" : customerDetails.cnpj)}</p>
                            </div>
                        </div>

                        <div className="flex-col w-full md:w-3/12 md:mr-2 mt-5 box-border">
                            <p>Price List</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.price_list === null ? "" : customerDetails.price_list)}</p>
                            </div>
                        </div>

                        <div className="flex-col w-full md:w-3/12 mt-5 box-border">
                            <p>Business Model</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.type === null ? "" : customerDetails.type)}</p>
                            </div>
                        </div>
                    </div>

                    <div id="secondRow" className="flex-row wrap-override items-center pt-5">
                        <div className="flex-col w-full md:w-3/12 mt-5 md:mr-2 ">
                            <p>Business Segment</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.industry === null ? "" : customerDetails.industry)}</p>
                            </div>
                        </div>

                        <div className="flex-col w-full md:w-2/12 mt-5 md:mr-2 ">
                            <p>Sub Business Segment</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.sub_business_segment === null ? "" : customerDetails.sub_business_segment)}</p>
                            </div>
                        </div>

                        <div className="flex-col w-full md:w-2/12 mt-5 md:mr-2 ">
                            <p>SCD Status</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.scd_status === null ? "" : (customerDetails.scd_status === 1 ? "Active" : "Inactive"))}</p>
                            </div>
                        </div>

                        <div className="flex-col w-full md:w-2/12 mt-5 md:mr-2 ">
                            <p>Order Status</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.coh_status === null ? "" : (customerDetails.coh_status === 1 ? "Active" : "Inactive"))}</p>
                            </div>
                        </div>

                        <div className="flex-col w-full md:w-2/12 mt-5">
                            <p>AZPartes Status</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.status === null ? "" : customerDetails.status)}</p>
                            </div>
                        </div>
                    </div>

                    <div id="thirdRow" className="flex-row items-center pt-5">
                        <div className="flex-col w-full">
                            <p>Address</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.address === null ? "" : customerDetails.address)
                                    + " " + (customerDetails.number === null ? "" : customerDetails.number)
                                    + " " + (customerDetails.city === null ? "" : customerDetails.city)
                                    + " " + (customerDetails.uf === null ? "" : customerDetails.uf)
                                    + " " + (customerDetails.postal_code === null ? "" : customerDetails.postal_code)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex-row items-center pt-5">
                        <div className="flex-col w-full">
                            <p>SINTEGRA Information</p>
                            <div className="border-2 border-lightWhite rounded mt-1 p-5 flex-col">
                                <div className="flex-row wrap-override">
                                    <div className="w-full md:w-1/2 mt-5 md:mr-2">
                                        <p>Corporate Name</p>
                                        <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                            <p>{customerDetails && (customerDetails.sintegra_company_name === null ? "" : customerDetails.sintegra_company_name)}</p>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 mt-5">
                                        <p>Fantasy Name</p>
                                        <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                            <p>{customerDetails && (customerDetails.sintegra_fantase_name === null ? "" : customerDetails.sintegra_fantase_name)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-row pt-5">
                                    <div className="flex-col w-full">
                                        <p>Address</p>
                                        <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                            <p>{customerDetails && (customerDetails.sintegra_address === null ? "" : customerDetails.sintegra_address)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-row wrap-override">
                                    <div className="flex-col w-full md:w-1/2 mt-5 md:mr-2">
                                        <p>State Registration</p>
                                        <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                            <p>{customerDetails && (customerDetails.sintegra_state_registration === null ? "" : customerDetails.sintegra_state_registration)}</p>
                                        </div>
                                    </div>
                                    <div className="flex-col w-full md:w-1/2 mt-5">
                                        <p>Email</p>
                                        <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                            <p>{customerDetails && (customerDetails.sintegra_email === null ? "" : customerDetails.sintegra_email)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-row items-center pt-5">
                        <div className="flex-col w-full">
                            <p>Contact Information</p>
                            <div className="border-2 border-lightWhite rounded mt-1 p-5 flex-col">
                                {
                                    customerDetails && customerDetails.authorized_users && Array.isArray(customerDetails.authorized_users) ?
                                        (customerDetails.authorized_users.length > 0 ?
                                            customerDetails.authorized_users.map((e, index) => (
                                                <div key={e + index + "usercontact"} className="flex-row wrap-override">
                                                    <div className="flex-col w-full md:w-5/12 mt-5 md:mr-2 ">
                                                        <p>Contact Name</p>
                                                        <input
                                                            type="text"
                                                            name="contact_name"
                                                            value={e.name && e.name}
                                                            className={`w-full h-10 mt-1 bg-gray-200 rounded-md p-5`}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="flex-col w-full md:w-5/12 mt-5">
                                                        <p>Email</p>
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            value={e.email && e.email}
                                                            className={`w-full h-10 mt-1 bg-gray-200 rounded-md p-5`}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="flex mx-3 my-2 items-end pointer">
                                                        <button onClick={(event, user) => unlink(event, e.user_id)}>Unlink</button>
                                                    </div>
                                                </div>
                                            )) :
                                            <p>No authorized users found</p>
                                        ) :
                                        <p>{customerDetails.authorized_users && customerDetails.authorized_users}</p>
                                }
                            </div>
                        </div>
                    </div>


                    {/* <div id="lastRow" className="flex-row wrap-override items-center pt-5">
                        <div className="flex-col w-full md:w-1/2 mt-5 md:mr-2 ">
                            <p>Completed Registration</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.status === null ? "" : customerDetails.status)}</p>
                            </div>
                        </div>
                        <div className="flex-col w-full md:w-1/2 mt-5 md:mr-2 ">
                            <p>Registration URL</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.email && `${root_url}/registration?email=${customerDetails.email}`)}</p>
                            </div>
                        </div>
                        <div className="flex-col w-full md:w-1/2 mt-5 md:mr-2 ">
                            <p>Accept E-Mkt</p>
                            <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                <p>{customerDetails && (customerDetails.is_marketing_allowed === 0 ? "FALSE" : "TRUE")}</p>
                            </div>
                        </div>
                    </div> */}

                </div> :
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div>
        }
    </div>;
}

export default Customer;