import React, { useEffect, useState, useContext, useRef } from "react";
import LineSplitDetails from "../../components/LineSplitDetails";
import InputMask from 'react-input-mask';
import ScreenHeader from "../../components/ScreenHeader";
import API from "../../utils/API";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';

function CreateEmail() {
    const getCurrentFormattedDate = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
      
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      };
    const [doDisplay, setDoDisplay] = useState(false);
    const context = useContext(AppContext);
    const [emailObj, setEmailObj] = useState({
        email_name: "",
        sender_name: context.getUser,
        sender_email: context.getEmail,
        scheduled_date: getCurrentFormattedDate(),
        sub_business_segment: "",
        email_content:""
    });
    const [cco, setCco] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const [skuAlert, setSKUAlert] = useState(false);
    const [isAzCompany, setIsAzCompany] = useState(true);
    const [isClickedSubmit, setisClickedSubmit] = useState(false);
    const [orderCOH, setOrderCOH] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [disableInsert, setDisableInsert] = useState(false);
    let cancelToken = API.cancelToken();
    const history = useHistory();
    const [error, setError] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [emailContent, setEmailContent] = useState("");
    const [hideDesktop, setHideDesktop] = useState(false);
    const [hideMobile, setHideMobile] = useState(false);
    const [hideTablet, setHideTablet] = useState(false);
    const [checkAll, setCheckAll] = useState(true);

    const handleTextareaChange = (event) => {
        setEmailContent(event.target.value);
        setEmailObj(prevValue => {
            return {
                ...prevValue,
                ['email_content']: emailContent
            }
        })
    };
    
    const iframeRefDesktop = useRef(null);
    const iframeRefMobile = useRef(null);
    const iframeRefTablet = useRef(null);

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
    }, [])

    useEffect(() => {
        setEmailObj(prevValue => {
            return {
                ...prevValue,
                ['sender_email']: context.getEmail,
                ['sender_name']: context.getUser
            }
        })
    }, [context])

    const updateIframeContent = (iframeRef) => {
        const iframeDocument = iframeRef.current.contentDocument;
        iframeDocument.open();
        iframeDocument.write(emailContent);
        iframeDocument.close();
    };

    useEffect(() => {
        updateIframeContent(iframeRefDesktop);
        updateIframeContent(iframeRefMobile);
        updateIframeContent(iframeRefTablet);
    }, [emailContent]);

    useEffect(() => {
        if (skuAlert) {
            Swal.fire({
                html: `O pedido possui produto(s) fora da lista de preços do cliente e/ou do catálogo. Deseja continuar?`,
                confirmButtonColor: "#131921",
                cancelButtonColor: "#81263B",
                icon: 'warning',
                confirmButtonText: 'SIM',
                cancelButtonText: 'NÃO',
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            }).then((result) => {
                if (result.isConfirmed) {
                } else {
                    setOrderCOH([])
                    setOrderStatus([])
                    setDoDisplay(false);
                }
            })
        }
    }, [skuAlert])

    useEffect(() => {
        if (!isAzCompany.status && isAzCompany.cohId !== undefined) {
            setOrderCOH([])
            setOrderStatus([])
            setDoDisplay(false);
            Swal.fire({
                title: 'Ops.',
                html: `Cliente ${isAzCompany.cohId} - Não pertencea base de dados do AZPartes.com`,
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        }
    }, [isAzCompany])

    useEffect(() => {
        params.get('q') && params.get('q') !== "null" ? setCco(params.get('q')) : setCco(null);
    }, [location])

    function handleChange(event) {
        const newValue = event.target.value;
        setCohValue(newValue);
    }

    const triggerCheckBox = (e) => {
        const name = e.target.name;

        if(name === "all"){
            if (e.target.checked) {
                setCheckAll(true);
                setHideDesktop(false);
                setHideMobile(false);
                setHideTablet(false);
            } else {
                setCheckAll(false);
                setHideDesktop(true);
                setHideMobile(true);
                setHideTablet(true);
            }
        }

        if (name === "desktop") {
            if (hideDesktop) {
                setHideDesktop(false);
            } else {
                setHideDesktop(true);
            }
        }
        if (name === "mobile") {
            if (hideMobile) {
                setHideMobile(false);
            } else {
                setHideMobile(true);
            }
        }
        if (name === "tablet") {
            if (hideTablet) {
                setHideTablet(false);
            } else {
                setHideTablet(true);
            }
        }
    }

    function verifyOrder(e) {
        e.preventDefault();
        setDisableInsert(false);
        if (!cohValue || cohValue.trim() === "") {
            setError(true)
            return
        }

        setError(false)
        setOrderCOH(null);
        setOrderStatus(null);

        setIsClicked(true);
        setDoDisplay(false);


        cancelToken = API.cancelToken();

        API.getCohOrder(cancelToken, cohValue).then(res => {
            console.log(res);
            if (!res.data.is_az_company) {
                var is_az_company = res.data.is_az_company;
                var coh_id = res.data.lines[0].CUSTOMER_ID;
                setIsAzCompany({ status: is_az_company, cohId: coh_id });
                if (!is_az_company) {
                    setOrderCOH([])
                    setOrderStatus([])
                    setDoDisplay(false);
                    return
                }
            }

            res.data.lines.forEach((order) => {
                if (order.exists_az == false) {
                    setSKUAlert(true)
                }
                if ((!order.FINANCE && order.LINE_STATUS === "INV")) {
                    console.log(`Bugged order: ${order}`)
                    setDisableInsert(true);
                }
            })
            if (res.data.lines.length === 0) {
                setOrderCOH([])
                setOrderStatus([])
                Swal.fire({
                    title: 'Ops.',
                    html: "COH Order ID not found, please confirm order number. </br> If the COH Order ID is correct, request the manual inclusion of the order on AZPartes.com with the support team.",
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
            } else {
                setDoDisplay(true);
                setOrderCOH(res.data.lines)
                setOrderStatus(res.data.status)
                console.log("LINES")
            }
            if (res.data.is_az_order === true) {
                setOrderCOH([])
                setOrderStatus([])
                setDoDisplay(false);
                Swal.fire({
                    title: 'Ops.',
                    html: "Desculpe, o pedido já existe na base de dados do AZPartes.com",
                    confirmButtonColor: "#131921",
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                setDoDisplay(true);
                setOrderCOH(res.data.lines)
                setOrderStatus(res.data.status)
                console.log("LINES")
            }
        }).catch(err => {
            console.log(err.response.data)
            var error_message = "Unkonwn error. Please contact IT support."
            setOrderCOH([]);
            setOrderStatus([]);
            if (err.message === 'cancelling') return
            if (err.response.data.error != undefined && err.response.data.error != null && err.response.data.error != "") {
                error_message = err.response.data.error
            }
            Swal.fire({
                title: 'Error.',
                text: error_message,
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'OK'
            });

        }).finally(
            f => {
                setIsClicked(false);
            }
        );
    }

    function handleChange(e) {
        const { name, value } = event.target;
        setEmailObj(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    const validateEmailObj = (emailObj) => {
        // Destructure the emailObj to get the individual fields
        const { email_name, sender_name, sender_email, scheduled_date, sub_business_segment, email_content } = emailObj;
    
        // Check if any of the fields other than scheduled_date are empty or null
        if (!email_name || !sender_name || !sender_email || !sub_business_segment || !email_content) {
            return { isValid: false, message: "All fields except scheduled date must be filled." };
        }
    
        // If scheduled_date is provided, check if it is today or later
        if (scheduled_date) {
            // Regex to check if the date is in DD/MM/YYYY hh:mm format
            const dateTimeRegex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/;
            const match = scheduled_date.match(dateTimeRegex);
        
            if (!match) {
              return { isValid: false, message: "Scheduled date must be in the format DD/MM/YYYY hh:mm." };
            }
        
            const [_, day, month, year, hours, minutes] = match;
            const scheduledDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
        
            if (isNaN(scheduledDate.getTime())) {
              return { isValid: false, message: "Scheduled date is not a valid date." };
            }
        
            const now = new Date();
            if (scheduledDate < now) {
              return { isValid: false, message: "Scheduled date must be current date/time or later." };
            }
          }
    
        return { isValid: true, message: "Validation successful" };
    };
    

    function submitEmail(e) {
        e.preventDefault();
        if (isClickedSubmit){
            return
        }
        setisClickedSubmit(true);
        console.log(emailObj)
        var isValid = validateEmailObj(emailObj);
        console.log(isValid)

        if (!isValid.isValid) {
            Swal.fire({
                title: 'Error.',
                text: isValid.message,
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'OK'
            });
            setisClickedSubmit(false);
            return;
        }

        var sub_name = ""
        console.log(emailObj.sub_business_segment)
        if(emailObj.sub_business_segment === "CC"){
            sub_name = "Car Center"
        }
        if(emailObj.sub_business_segment === "VA"){
            sub_name = "Distributor VA"
        }
        if(emailObj.sub_business_segment === "2W"){
            sub_name = "Two Wheels (2W)"
        }

        Swal.fire({
            title: 'Attention!',
            text: `Do you want to send an email to the customers of ${sub_name}?`,
            confirmButtonColor: "#131921",
            icon: 'question',
            confirmButtonText: 'YES',
            showCancelButton: true,
            cancelButtonText: 'NO',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.isConfirmed) {
                API.sendEmail(emailObj).then(res => {
                    Swal.fire({
                        title: 'Success.',
                        text: 'Email Submission Sucessfully Created.',
                        confirmButtonColor: "#131921",
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push('/sendemails');
                        }
                    });
                }).catch(err => {
                    console.log(err.response && err.response)
                    setisClickedSubmit(false);
                    if (err.message === 'cancelling') return
                    if (err.response && err.response.status === 500) {
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        });
                    } else {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        });
                    }
                }).finally(
                    f => {
                        setIsClickedSubmit(false);
                    }
                );
            } else {
                setisClickedSubmit(false);
            }
        })

        
    }

    return (
        <div className="flex-col md:p-10 p-5 height-limiter">
            <ScreenHeader
                title='Send Email'
                hasButton="false" />

            <div className="pt-10 pb-6">
                Please fill the information below:
            </div>
            <div className="bg-blue h-34 rounded text-xs lg:text-base">
                <div className="flex-row justify-start items-center md:p-10 p-5">
                    <div className="flex-col md:mr-10 md:w-6/12 sm:w-full">
                        <div className="flex-col md:mr-10 w-full" >
                            <p className="mt-5 md:mt-0">Email Name</p>
                            <input type="text" className="h-10 mt-2 bg-gray-200 rounded-md text-white w-full flex align-center pl-2" name="email_name" value={emailObj.email_name} onChange={handleChange} />
                        </div>
                        <div className="flex-col md:mr-10 w-full mt-3" >
                            <p className="mt-5 md:mt-0">Requester Name</p>
                            <input type="text" className="h-10 mt-2 bg-gray-200 rounded-md text-[#c4c4c4] w-full flex align-center pl-2" name="sender_name" value={context.getUser} onChange={handleChange} />
                        </div>
                        <div className="flex-col md:mr-10 w-full mt-3" >
                            <p className="mt-5 md:mt-0">Requester Email</p>
                            <input type="text" className="h-10 mt-2 bg-gray-200 rounded-md text-[#c4c4c4] w-full flex align-center pl-2" name="sender_email" value={context.getEmail} readOnly={true} />
                        </div>

                        <div className="flex justify-start w-full">
                            <div className="flex-1 flex-col mt-3 mr-3">
                                <p className="mt-5 md:mt-0">Sending Date</p>
                                <InputMask mask="99/99/9999 99:99" placeholder="DD/MM/YYYY hh:mm" className="h-10 mt-2 bg-gray-200 rounded-md text-white w-full flex items-center pl-2" name="scheduled_date" value={emailObj.scheduled_date} onChange={handleChange} />
                                
                            </div>
                            <div className="flex-1 flex-col mt-3">
                                <p className="mt-5 md:mt-0">Sub Business Segment</p>
                                <select className="h-10 mt-2 bg-gray-200 rounded-md text-white w-full flex items-center pl-2" name="sub_business_segment" value={emailObj.sub_business_segment} onChange={handleChange}>
                                    <option value="">Select a Sub Business Segment</option>
                                    <option value="CC">Car Center</option>
                                    <option value="VA">Distributor VA</option>
                                    <option value="2W">Two Wheels (2W)</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex w-full space-x-4 p-4">
                            <div className="flex items-center w-1/4">
                                <input type="checkbox" name="all" checked={checkAll} id="checkbox1" className="mr-2" onChange={triggerCheckBox}/>
                                <label htmlFor="checkbox1">All Devices</label>
                            </div>
                            <div className="flex items-center w-1/4">
                                <input type="checkbox" name="desktop" id="checkbox2" checked={!hideDesktop} className="mr-2" onChange={triggerCheckBox}  />
                                <label htmlFor="checkbox1">Desktop</label>
                            </div>
                            <div className="flex items-center w-1/4">
                                <input type="checkbox" name="mobile" id="checkbox3" checked={!hideMobile} className="mr-2" onChange={triggerCheckBox}  />
                                <label htmlFor="checkbox1">Mobile</label>
                            </div>
                            <div className="flex items-center w-1/4">
                                <input type="checkbox" name="tablet" id="checkbox4" checked={!hideTablet} className="mr-2" onChange={triggerCheckBox} />
                                <label htmlFor="checkbox1">Tablet/iPad</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-col  md:flex-1 sm:w-full">
                        <textarea className="h-[340px] mt-5 placeholder:text-[#c4c4c4] bg-gray-200 p-3 rounded-md text-white w-full flex" name="cohCustomerId" placeholder="Email Content" value={emailContent}
                            onChange={handleTextareaChange} onBlur={handleTextareaChange} />
                    </div>
                </div>
                <div className="pt-10 pl-10 mb-3">
                    <h1 className="text-2xl">Preview</h1>
                </div>
                <div className="flex-col sm:w-full pl-10 pr-10 pb-5">
                    <p>Desktop (1024x768 a 1920x1080)</p>
                    <div className="flex space-x-4">
                        {
                            !hideDesktop &&
                            <iframe
                                className="w-full h-[500px] bg-white"
                                title="Desktop Preview"
                                ref={iframeRefDesktop}
                            ></iframe>
                        }
                    </div>
                </div>
                <div className="flex-col sm:w-full pl-10 pb-5 pr-10">
                    <p className="w-2/3">Tablet/iPad (601x962 a 1280x800)</p>
                    <div className="flex space-x-4">
                        {
                            !hideTablet &&
                            <>
                                <iframe
                                    className="w-2/3 h-[300px] bg-white"
                                    title="Tablet Preview"
                                    ref={iframeRefTablet}
                                ></iframe>
                            </>
                        }
                        {
                            !hideMobile &&
                            <iframe
                                className="w-1/3 h-[300px] bg-white"
                                title="Mobile Preview"
                                ref={iframeRefMobile}
                            ></iframe>
                        }
                    </div>
                </div>

            </div>
            <div className="flex justify-end">
                <button
                    id="Insert"
                    className={`bg-gray-200 text-xs lg:text-base mt-5 cursor-pointer rounded-md w-44 h-10 flex justify-center items-center ${disableInsert && "opacity-50 cursor-default"}`}
                    onClick={submitEmail}
                    disabled={disableInsert}>
                    <p>{isClickedSubmit ? "SENDING..." : "SEND"}</p>
                </button>
            </div>
        </div>
    );
}

export default CreateEmail; 