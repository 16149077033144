import React, { useState, useRef, useEffect } from 'react';
import { useLanguage } from '../../context/LanguageContext';

function DropdownMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { switchLanguage } = useLanguage();

  // Handle clicking outside of the dropdown to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  return (
    <div className="relative" ref={dropdownRef}>
      <button onMouseEnter={() => setIsOpen(!isOpen)}>
        <img src={require('../../assets/images/www.png')} alt="Menu" className="w-6 h-6 mt-1 ml-1 mr-1" />
      </button>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-[#283648] ring-1 ring-black ring-opacity-5 z-50" onMouseLeave={() => setIsOpen(!isOpen)}>
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a onClick={() => switchLanguage('en')} className="pointer block px-4 py-2 text-md text-white hover:text-[#E1DEDE]" role="menuitem">English</a>
            <a onClick={() => switchLanguage('pt')}className="pointer block px-4 py-2 text-md text-white hover:text-[#E1DEDE]" role="menuitem">Portuguese</a>
            <a onClick={() => switchLanguage('es')} className="pointer  block px-4 py-2 text-md text-white hover:text-[#E1DEDE]" role="menuitem">Spanish</a>
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;