export default function InputField(props) {
    return (
        <div className={`flex-col w-full md:w-full md:mr-2 md:mt-0 mt-4`}>
            <p>{props.label}</p>
            <input
                type="text"
                disabled={props.disabled}
                placeholder={props.placeholder}
                name={props.name || "input"}
                className={`h-10 mt-2 bg-[#0C1825] rounded-md placeholder-[#2D4560] text-white p-5 w-full ${props.error ? "border border-amber-900" : "border-0"}`}
                onChange={(e) => props.setValue(e.target.value)}
                value={props.value}
            />
        </div>
    )
}