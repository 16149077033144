import React, { useState, useEffect, useContext } from "react";
import Loader from "../../components/Loader";
import './style.css'
import InputField from "../../components/InputField";
import RadioButtonField from "../../components/RadioButtonField";
import SelectField from "../../components/SelectField";
import CheckBoxesField from "../../components/CheckBoxesField";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom";
import ReactDatePicker from "react-datepicker";
import InputNumberField from "../../components/InputNuberField";
import UploadFile from "../../components/UploadFile";
import UploadFileField from "../../components/UploadFileField";
import Swal from "sweetalert2";
import Status from "../../components/Status";


function AddProductGuide(props) {
    const [productGuideName, setProductGuideName] = useState()
    const [segment, setSegment] = useState()
    const [featuredGuide, setFeaturedGuide] = useState()
    const [sequenceSite, setSequenceSite] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [productGuideFile, setProductGuideFile] = useState(null)
    const [type, setType] = useState("add")
    const [errorForm, setErrorForm] = useState({
        resp: false,
        productGuideName: false,
        segment: false,
        featuredGuide: false,
        sequenceSite: false,
        startDate: false,
        endDate: false,
        productGuideFile: false
    })
    const { location } = props;

    useEffect(() => {

        Swal.fire({
            title: 'Attention',
            text: "You don't have permission to access this page.",
            confirmButtonColor: "#1e2b3c",
            icon: 'warning',
            confirmButtonText: 'OK',
            allowEscapeKey: false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                history.push('/banners')
            }
        });

        if (location.state && location.state.type && location.state.type !== "add") {
            setProductGuideName(location.state.name)
            setSegment(location.state.segment)
            setFeaturedGuide(location.state.featuredGuide)
            setSequenceSite(location.state.sequence)
            setStartDate(new Date(location.state.startDate))
            setEndDate(new Date(location.state.endDate))
            setProductGuideFile(location.state.productGuideFile)
        }
        if (location.state.type) {
            setType(location.state.type)
        }
    }, [])
    const history = useHistory()

    const downloadTemplate = () => {
        Swal.fire({
            title: 'Template Successfully Downloaded.',
            confirmButtonColor: "#131921",
            icon: 'success',
            confirmButtonText: 'Ok'
        }).then(() => {
            // Create a link element
            const link = document.createElement('a');
            // Set the URL of the file
            link.href = '/templates/bannerTemplate.xlsx'; // Update with your file's relative path
            // Set the download attribute to prompt a download
            link.setAttribute('download', 'bannerTemplate.xlsx'); // Update with the desired file name
            // Append the link to the body
            document.body.appendChild(link);
            // Programmatically click the link
            link.click();
            // Remove the link from the document
            link.remove();
        });
    }

    const validationForm = () => {
        let error = {
            resp: false,
            productGuideName: false,
            segment: false,
            featuredGuide: false,
            sequenceSite: false,
            startDate: false,
            endDate: false,
            productGuideFile: false
        }
        if (!productGuideName || productGuideName.length <= 0) {
            error.productGuideName = true
            error.resp = true
        }
        if (!segment || segment.length <= 0) {
            error.segment = true
            error.resp = true
        }
        if (!featuredGuide || featuredGuide.length <= 0) {
            error.featuredGuide = true
            error.resp = true
        }
        if (!sequenceSite || sequenceSite.length <= 0) {
            error.sequenceSite = true
            error.resp = true
        }
        if (!startDate || startDate.length <= 0) {
            error.startDate = true
            error.resp = true
        }
        if (!endDate || endDate.length <= 0) {
            error.endDate = true
            error.resp = true
        }
        if (!productGuideFile || productGuideFile.length <= 0) {
            error.productGuideFile = true
            error.resp = true
        }
        setErrorForm(error)
        return error.resp
    }

    const future = (date) => new Date().setDate(new Date().getDate()) < date;

    const handleCreate = () => {
        Swal.fire({
            title: type == "add" ? "Do you want create this guide?" : "Do you want edit this guide?"
            ,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: "#1e2b3c"
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Creating the guide...',
                    showConfirmButton: false,
                    allowOutsideClick: false
                })
                const error = validationForm()
                if (!error) {
                    Swal.fire({
                        title: 'Success.',
                        text: 'Your guide was successfully create.',
                        showCancelButton: true,
                        cancelButtonText: "BACK TO PRODUCT GUIDES",
                        confirmButtonColor: "#1e2b3c",
                        icon: 'success',
                        confirmButtonText: 'ADD ANOTHER GUIDE',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                            return;
                        } else {
                            history.push('/productguides')
                            return;
                        }
                    });
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please, fill the mandatory fields.',
                        confirmButtonColor: "#1e2b3c",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#1e2b3c",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        })
    }

    const handleChange = (range) => {
        const [startDate, endDate] = range;
        setStartDate(startDate);
        setEndDate(endDate);
        if (startDate != null && endDate != null) {
            console.log(`Setting selected date: ${startDate.toISOString()} - ${endDate.toISOString()}`)
            // setDate(`${startDate.toISOString()} - ${endDate.toISOString()}`)
        }
    };
    const handleProductGuideFileChange = (e) => {
        const file = e.target.files[0];
        setProductGuideFile(URL.createObjectURL(file));
    };


    const loading = true
    return <div className="flex-col md:p-10 p-5 height-limiter">
        <div className="flex-row">
            {type == "add" && <h1 className="lg:text-2xl ">New Guide</h1>}
            {type == "edit" && <h1 className="lg:text-2xl ">{"Edit Guide- " + productGuideName.toUpperCase()}</h1>}
            {type == "details" && <h1 className="lg:text-2xl ">{"PRODUCT GUIDE - DETAILS - " + productGuideName.toUpperCase()}</h1>}
        </div>
        {
            loading ? <>
                <div className="bg-gray-200 flex flex-col justify-center gap-6 items-end self-stretch rounded mt-10 text-xs lg:text-base md:p-10 p-8">
                    <div className="flex flex-start gap-10 self-stretch">
                        <div className="flex w-1/3 flex-col items-start gap-2">
                            <InputField setValue={setProductGuideName} value={productGuideName} error={errorForm.productGuideName} name="product-guide-name" disabled={type == "details" ? true : false} label="Product Guide Name" />
                        </div>
                        <div className="flex w-1/3 flex-col items-start gap-2">
                            <RadioButtonField name={"sub_business_segment"} label={"Sub Business Segment"} error={errorForm.segment} value={segment} disabled={type == "details" ? true : false} list={["SKF Center", "2W", "VA Distributor"]} setValue={setSegment} />
                        </div>
                        {type == "details" && <div className="flex w-1/3 flex-row items-center justify-end gap-2">
                            <p>Guide Status</p>
                            <Status
                                key={'Status'}
                                id={"Status"}
                                currentStatus={"active"}
                                clickable="true" />
                        </div>}
                    </div>
                    <div className="flex flex-start gap-6 self-stretch">
                        <div className="flex w-auto gap-2">
                            <RadioButtonField name={"featured_guide"} label={"Featured guide"} error={errorForm.featuredGuide} value={featuredGuide} disabled={type == "details" ? true : false} list={["Yes", "No"]} setValue={setFeaturedGuide} />
                        </div>
                        <div className="flex w-56 gap-2 border-1 border-red">
                            <SelectField label={"Sequence on site"} name={"sequence_on_site_select"} error={errorForm.sequenceSite} value={sequenceSite} disabled={type == "details" ? true : false} options={["1", "2", "3", "4", "5"]} setValue={setSequenceSite} />
                        </div>
                        <div className="flex w-56 gap-2">
                            <div class="flex-col w-full gap-2">
                                <p>Start Date   {"  >  "}  End Date </p>
                                <ReactDatePicker
                                    // selected={selectedDate ? new Date(selectedDate) : null}
                                    selected={startDate}
                                    // onChange={(value) => setDate(value.toISOString())}
                                    placeholderText="yyyy/MM/dd - yyyy/MM/dd"
                                    onChange={handleChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    filterDate={future}
                                    selectsRange
                                    monthsShown={2}
                                    disabled={type == "details" ? true : false}
                                    showYearDropdown
                                    dateFormat="yyyy/MM/dd"
                                    className={`bg-[#0C1825] w-full col-span-3 lg:text-base placeholder-[#2D4560] px-5 py-2 rounded-md mt-2 text-base box-border ${errorForm.startDate || setErrorForm.endDate ? "border border-amber-900" : "border-0"}`} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-start gap-6 self-stretch">
                        <div className="flex w-1/3 flex-col items-start py-10 gap-2">
                            <p className="font-bold text-lg leading-7">Select a file</p>
                            <UploadFileField setValue={handleProductGuideFileChange} error={errorForm.productGuideFile} disabled={type == "details" ? true : false} />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end mt-4 flex-row gap-4" >
                    <NavLink className="flex flex-row h-12 py-1 px-6 justify-center items-center gap-4 self-stretch rounded" to="/productguides">
                        <p>CANCEL</p>
                    </NavLink>
                    {type == "add" && <button className="flex flex-row h-12 py-1 px-6 justify-center items-center gap-4 self-stretch rounded bg-[#1E2B3C]" onClick={handleCreate}>
                        <p>ADD PRODUCT GUIDE</p>
                    </button>}
                    {type == "edit" && <button className="flex flex-row h-12 py-1 px-6 justify-center items-center gap-4 self-stretch rounded bg-[#1E2B3C]" onClick={handleCreate}>
                        <p>SAVE PRODUCT GUIDE</p>
                    </button>}
                </div>
            </>
                :
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div>
        }
    </div>;
}

export default AddProductGuide;