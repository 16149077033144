import React, { useState, useContext } from "react";
import Status from "../../components/Status";
import CatalogSearchBar from "../../components/CatalogSearchBar";
import ScreenHeader from "../../components/ScreenHeader";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import DatePicker from "react-datepicker";
import { AppContext } from "../../utils/ContextProvider";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import { useLanguage } from '../../context/LanguageContext';


function OutOfCatalog() {
    const { translate } = useLanguage();

    let cancelToken = API.cancelToken();
    const [dropDownChange, setDropDownChange] = useState("");
    const [selectedDate, setDate] = useState("")
    const [reset, setReset] = useState(false)
    const [searchInput, setSearchInput] = useState('');
    const [list, setList] = useState(null)
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [productDetails, setProductDetails] = useState([]);
    const [downloadType, setDownloadType] = useState('');
    const [loading, setLoading] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const [reportLoading2, setReportLoading2] = useState(false);
    const context = useContext(AppContext);
    const [currentPage, setCurrentPage] = useState(1);

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    useEffect(() => {
        setDate(null)
    }, [dropDownChange]);

    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])


    useEffect(() => {
        if (productDetails.length > 0) {
            console.log("GET DATA")
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(productDetails);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, `${downloadType}-Missing_Catalog_Products.xlsx`);
        }
    }, [productDetails]);

    function getData(industry) {
        if(industry=='industrial'){
            setReportLoading2(true)
        }else{
            setReportLoading(true)
        }
        Swal.fire({
            title: 'Creating report...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        API.getMissingCatalog(cancelToken, industry).then(res => {
            if (res.data) {
                var arr = [['SKU', 'Price List']]
                console.log(res.data)
                res.data.products.forEach(product => {
                    arr.push([product['skf_product_id'], product['price_list']])
                });
                setDownloadType(industry)
                setProductDetails(arr)
                if(industry=='industrial'){
                    setReportLoading2(false)
                }else{
                    setReportLoading(false)
                }
            } else {
                setProductDetails(null);
                if(industry=='industrial'){
                    setReportLoading2(false)
                }else{
                    setReportLoading(false)
                }
            }
        })
    }

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setLoading(true)

        API.getIndustrialCatalogInfo(cancelToken, currentPage, '').then(res => {
            setMaxPages(res.data.max_pages)
            res.data.products ? setList(res.data.products) : setList([]);
        }).catch(err => {
            setList([]);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            // setIsClicked(false);
            setLoading(false)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, reset]);

    function doSearch(e) {
        e.preventDefault();
        const search = e.target.search.value;
        setLoading(true)
        API.getIndustrialCatalogInfo(cancelToken, 1, search).then(res => {
            setMaxPages(res.data.max_pages)
            res.data.products ? setList(res.data.products) : setList([]);
        }).catch(err => {
            setList([]);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            // setIsClicked(false);
            setLoading(false)
        })
    }

    function handleChange(e) {
        setDropDownChange(e.target.value);
    }

    const doReset = () => {
        console.log("Do Reset")
        setSearchInput('')
        setReset(!reset)
    }

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    return (


        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <div className="flex flex-row mb-1 gap-x-2 justify-end">
                <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2" style={{width:"280px"}} onClick={() => { getData('automotive') }}>
                    {
                        reportLoading ?
                            <div className="loader-wrapper-sm">
                                <Loader light={true} small={true} />
                            </div>
                            :
                            <p className="px-2">{translate("DownloadAutoLabel")}</p>
                    }
                </div>
                <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2" style={{width:"280px"}} onClick={() => { getData('industrial') }}>
                    {
                        reportLoading2 ?
                            <div className="loader-wrapper-sm">
                                <Loader light={true} small={true} />
                            </div>
                            :
                            <p className="px-2">{translate("DownloadIndustrialLabel")}</p>
                    }
                </div>
            </div>
            <ScreenHeader
                title={translate("OutOfCatalogLabel")}
                subtitle={translate("AllProductsDescriptionLabel")}
                link=""
                hasButton="false" />
            <form onSubmit={(e) => doSearch(e)}>
                <div id="searchBar" className="bg-gray-200 flex-row wrap-override h-30 my-6 p-6 rounded-md">
                    <div className="w-full md:w-3/5 md:mr-2 mt-2 md:mt-0">

                        {
                            dropDownChange === "createdAt" || dropDownChange === "lastUpdate" ?
                                <div>
                                    <DatePicker
                                        selected={selectedDate ? new Date(selectedDate) : null}
                                        onChange={(value) => setDate(value.toISOString())}
                                        dateFormat="dd/MM/yyyy"
                                        className={`bg-blue col-span-3 lg:text-base placeholder-white px-5 py-2 rounded-md  text-xs w-full box-border`} />
                                    <input type="hidden" name="search" value={selectedDate} />
                                </div>
                                :
                                dropDownChange === "status" ?
                                    <select name="search" className="bg-blue col-span-3 lg:text-base placeholder-white px-5 py-2 rounded-md  text-xs w-full box-border">
                                        <option value="-1">Select</option>
                                        <option value="found">Found</option>
                                        <option value="not found">Not Found</option>
                                    </select>

                                    :
                                    <input
                                        name="search"
                                        type="text"
                                        className={`bg-blue col-span-3 lg:text-base placeholder-white px-5 py-2 rounded-md  text-xs w-full box-border `}
                                        placeholder={translate("SearchDescriptionLabel")}
                                        value={searchInput}
                                        onChange={handleInputChange}
                                    />

                        }
                    </div>
                    <div className="w-full md:w-1/5 md:mr-2 mt-2 md:mt-0">
                        <select onChange={(e) => { handleChange(e) }} name="category" className="bg-blue text-xs md:text-base cursor-pointer flex items-center justify-between w-full h-full rounded-md py-2 px-5">
                            <option value="sku">SKU</option>
                        </select>
                    </div>

                    <div className="w-full md:w-1/5 md:mr-2 mt-2 md:mt-0">
                        <button className="bg-blue cursor-pointer flex items-center justify-center lg:px-5 lg:text-base placeholder-white py-2 rounded-md text-xs w-full"
                            type="submit" >
                            {translate("search")}
                        </button>
                    </div>
                    <div className="w-full md:w-1/5 md:mr-2 mt-2 md:mt-0">
                        <button className="bg-blue cursor-pointer flex items-center justify-center lg:px-5 lg:text-base placeholder-white py-2 rounded-md text-xs w-full"
                            onClick={doReset} >
                            {translate("ClearLabel")}
                        </button>
                    </div>
                </div>
            </form>
            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-1/5 text-center">Price List</div>
                        <div className="w-1/5 text-center">SKU</div>
                        <div className="w-1/5 text-center">{translate("CreatedAtLabel")}</div>
                        <div className="w-1/5 text-center">{translate("LastUpdatesLabel")}</div>
                        <div className="w-1/5 text-center">Status</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!list || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        list.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            list.map((component, index) => {
                                return <div key={index + 'link'}>
                                    <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                        <div className="w-1/5 text-center">{component.price_list}</div>
                                        <div className="w-1/5 text-center">{component.skf_product_id}</div>
                                        <div className="w-1/5 text-center">{databaseToDate(component.created_at)}</div>
                                        <div className="w-1/5 text-center">{databaseToDate(component.updated_at)}</div>
                                        <div className="w-1/5 text-center flex justify-center">
                                            <Status
                                                key={component.skf_product_id + 'Status'}
                                                id={component.skf_product_id}
                                                currentStatus={component.status}
                                                // currentStatus='pending'
                                                clickable="false" />
                                        </div>
                                    </div>
                                </div>
                            })}
                </div>
            </div>
            {
                !loading && maxPages ?
                    <div className="page-selector flex-row-nowrap space-between">
                        <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                            {
                                !mobile && <p>{translate('PreviousLabel')}</p>
                            }
                        </div>
                        <div className="pages-section flex-row-nowrap">
                            {
                                pages
                            }
                        </div>
                        <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            {
                                !mobile && <p>{translate('NextLabel')}</p>
                            }
                            <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                        </div>
                    </div> :
                    null
            }
        </div>


    )
}

export default OutOfCatalog;