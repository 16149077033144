import React, { useState, useEffect } from "react";

function TableOptions({ visibleColumns, onApplyChanges, showColumnFilter }) {
    const [items, setItems] = useState(() => {
        const savedOrder = JSON.parse(localStorage.getItem('columnOrder'));
        const defaultItems = [
            { id: 'order_number', label: 'SKF Order Number', toggled: visibleColumns.order_number },
            { id: 'date', label: 'Date', toggled: visibleColumns.date },
            { id: 'customer_number', label: 'COH ID', toggled: visibleColumns.customer_number },
            { id: 'customer_name', label: 'COH Short Name', toggled: visibleColumns.customer_name },
            { id: 'client_order_id', label: 'Client Order ID', toggled: visibleColumns.client_order_id },
            { id: 'payment', label: 'Payment Method', toggled: visibleColumns.payment },
            { id: 'status', label: 'Order Status', toggled: visibleColumns.status },
        ];
        return savedOrder ? savedOrder.map(id => defaultItems.find(item => item.id === id)) : defaultItems;
    });

    useEffect(() => {
        setItems(items.map(item => ({ ...item, toggled: visibleColumns[item.id] })));
    }, [visibleColumns]);

    const [draggedItemIndex, setDraggedItemIndex] = useState(null);

    function toggleItem(id) {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, toggled: !item.toggled } : item
            )
        );
    }

    function handleDragStart(index) {
        setDraggedItemIndex(index);
    }

    function handleDragOver(index) {
        if (draggedItemIndex === index) return;

        const updatedItems = Array.from(items);
        const [draggedItem] = updatedItems.splice(draggedItemIndex, 1);
        updatedItems.splice(index, 0, draggedItem);

        setDraggedItemIndex(index);
        setItems(updatedItems);
    }

    function handleDrop() {
        setDraggedItemIndex(null);
    }

    function applyChanges() {
        const newVisibleColumns = items.reduce((acc, item) => {
            acc[item.id] = item.toggled;
            return acc;
        }, {});
        onApplyChanges(newVisibleColumns);

        // Save column order to local storage
        const newOrder = items.map(item => item.id);
        localStorage.setItem('columnOrder', JSON.stringify(newOrder));
    }

    function hidePanel() {
        showColumnFilter()
    }

    return (
        <div className="fixed top-0 right-0 h-full w-[400px] z-10 bg-slate-800 shadow-lg overflow-auto shadow-inner">
            <div className="flex flex-col px-6 pt-6 pb-4 mx-auto w-full shadow-sm bg-slate-800 max-w-[480px] mt-10">
                <div className="text-xl font-bold leading-7 text-white">Edit columns</div>
                {items.map((item, index) => (
                    <div
                        key={item.id}
                        className="flex gap-5 mt-3"
                        draggable
                        onDragStart={() => handleDragStart(index)}
                        onDragOver={() => handleDragOver(index)}
                        onDrop={handleDrop}
                    >
                        <div
                            className="flex flex-1 gap-3 my-auto"
                            onClick={() => toggleItem(item.id)}
                        >
                            <div className={`flex flex-col justify-center items-start py-0.5 pr-0.5 my-auto pointer ${item.toggled ? 'bg-emerald-600 pl-3.5' : 'bg-[#43454F] pr-3.5'} rounded-2xl`}>
                                <div className="shrink-0 w-3 h-3 bg-white rounded-2xl" />
                            </div>
                            <div className="text-base font-bold leading-5 text-center text-stone-50">
                                {item.label}
                            </div>
                        </div>
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f01b2d196c460e6c46a8c84cb5e1537db9543b75e7a28e462ebb058b8fe426f?"
                            className="shrink-0 w-6 aspect-square"
                        />
                    </div>
                ))}
                <div className="flex gap-2 justify-between pl-20 mt-10 text-base leading-5">
                    <div className="my-auto font-bold text-stone-50 pointer" onClick={hidePanel}>Cancel</div>
                    <div
                        className="justify-center py-2 pr-6 pl-5 font-medium text-white bg-emerald-600 rounded-md pointer"
                        onClick={applyChanges}
                    >
                        Apply changes
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableOptions;