import React, { useEffect, useState, useContext } from "react";
import LineSplitDetails from "../../components/LineSplitDetails";
import ScreenHeader from "../../components/ScreenHeader";
import API from "../../utils/API";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';

function CreateOrder() {
    const [doDisplay, setDoDisplay] = useState(false);
    const [cohValue, setCohValue] = useState("");
    const [cco, setCco] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const [skuAlert, setSKUAlert] = useState(false);
    const [isAzCompany, setIsAzCompany] = useState(true);
    const [isClickedInsert, setIsClickedInsert] = useState(false);
    const [orderCOH, setOrderCOH] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [disableInsert, setDisableInsert] = useState(false);
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [error, setError] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(()=>{
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
    }, [])

    useEffect(()=>{
        if (skuAlert) {
            Swal.fire({
                html: `O pedido possui produto(s) fora da lista de preços do cliente e/ou do catálogo. Deseja continuar?`,
                confirmButtonColor: "#131921",
                cancelButtonColor:  "#81263B",
                icon: 'warning',
                confirmButtonText: 'SIM',
                cancelButtonText: 'NÃO',
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            }).then((result) => {
                if (result.isConfirmed) {
                } else {
                    setOrderCOH([])
                    setOrderStatus([])
                    setDoDisplay(false);
                }
            })
        }
    }, [skuAlert])

    useEffect(()=>{
        if(!isAzCompany.status && isAzCompany.cohId !== undefined){
            setOrderCOH([])
            setOrderStatus([])
            setDoDisplay(false);
            Swal.fire({
                title: 'Ops.',
                html: `Cliente ${isAzCompany.cohId} - Não pertencea base de dados do AZPartes.com`,
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        }
    }, [isAzCompany])

    useEffect(()=>{
        params.get('q') && params.get('q') !== "null" ? setCco(params.get('q')) : setCco(null);
    },[location])

    function handleChange(event) {
        const newValue = event.target.value;
        setCohValue(newValue);
    }

    function verifyOrder(e) {
        e.preventDefault();
        setDisableInsert(false);
        if(!cohValue || cohValue.trim()===""){
            setError(true)
            return
        }

        setError(false)
        setOrderCOH(null);
        setOrderStatus(null);

        setIsClicked(true);
        setDoDisplay(false);
        

        cancelToken = API.cancelToken();

        API.getCohOrder(cancelToken, cohValue).then(res => {
            console.log(res);
            if(!res.data.is_az_company){
                var is_az_company = res.data.is_az_company;
                var coh_id = res.data.lines[0].CUSTOMER_ID;
                setIsAzCompany({status: is_az_company, cohId: coh_id});
                if (!is_az_company){
                    setOrderCOH([])
                    setOrderStatus([])
                    setDoDisplay(false);
                    return
                }
            }
            
            res.data.lines.forEach((order)=>{
                if(order.exists_az == false){
                    setSKUAlert(true)
                }
                if((!order.FINANCE && order.LINE_STATUS === "INV")){
                    console.log(`Bugged order: ${order}`)
                    setDisableInsert(true);
                }
            })
            if(res.data.lines.length === 0){
                setOrderCOH([])
                setOrderStatus([])
                Swal.fire({
                    title: 'Ops.',
                    html: "COH Order ID not found, please confirm order number. </br> If the COH Order ID is correct, request the manual inclusion of the order on AZPartes.com with the support team.",
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }else{
                setDoDisplay(true);
                setOrderCOH(res.data.lines)
                setOrderStatus(res.data.status)
                console.log("LINES")
            }
            if(res.data.is_az_order === true){
                setOrderCOH([])
                setOrderStatus([])
                setDoDisplay(false);
                Swal.fire({
                    title: 'Ops.',
                    html: "Desculpe, o pedido já existe na base de dados do AZPartes.com",
                    confirmButtonColor: "#131921",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }else{
                setDoDisplay(true);
                setOrderCOH(res.data.lines)
                setOrderStatus(res.data.status)
                console.log("LINES")
            }
        }).catch(err => {
            console.log(err.response.data)
            var error_message = "Unkonwn error. Please contact IT support."
            setOrderCOH([]);
            setOrderStatus([]);
            if (err.message === 'cancelling') return
            if (err.response.data.error != undefined && err.response.data.error != null && err.response.data.error != "") {
                error_message = err.response.data.error
            }
            Swal.fire({
                title: 'Error.',
                text: error_message,
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
            
        }).finally(
            f => {
                setIsClicked(false);
            }
        );
    }

    function insertOrder(e) {
        e.preventDefault();
        
        setIsClickedInsert(true);

        API.cancel(cancelToken);
        cancelToken = API.cancelToken();

        API.insertOrder(cancelToken, cohValue, cco).then(res => {
            Swal.fire({
                title: 'Success.',
                text: 'Order successfully inserted.',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            history.push(`/order/${res.data.id}`)
            
        }).catch(err => {
            console.log(err.response && err.response)
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: err.response.data.error,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(
            f => {
                setIsClickedInsert(false);
            }
        );
    }

    return (
        <div className="flex-col md:p-10 p-5 height-limiter">
            <ScreenHeader
                title={`${cco ? "Link COH " : "Insert "}order${cco ? " to credit card order #" + cco : ""}`}
                hasButton="false" />

            <div className="pt-10 pb-6">
                Please fill the information below:
            </div>
            <div className="bg-blue h-34 rounded text-xs lg:text-base">
                <div className="flex-row justify-start items-center md:p-10 p-5">
                    <div className="flex-col md:mr-10 w-full md:w-48">
                        <p className="">COH ORDER ID</p>
                        <input
                            type="text"
                            name="cohOrderId"
                            value={cohValue}
                            className={`h-10 mt-2 w-full bg-gray-200 rounded-md text-white p-5 focus:outline-none ${error ? "border border-amber-900" : "border-0"}`}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="flex-col md:mr-10 w-full md:w-48" style={{ display: doDisplay ? "" : "none" }}>
                        <p className="mt-5 md:mt-0">COH COH Customer ID</p>
                        <div className="h-10 mt-2 bg-gray-200 rounded-md text-lightWite w-full flex align-center pl-2" name="cohCustomerId">
                            <p>{orderCOH && orderCOH[0] && orderCOH[0].CUSTOMER_ID}</p>
                        </div>
                    </div>

                    <div className="flex-col md:mr-10 w-full md:w-48" style={{ display: doDisplay ? "" : "none" }}>
                        <p className="mt-5 md:mt-0">Customer Order Number</p>
                        <div className="h-10 mt-2 bg-gray-200 rounded-md text-lightWite w-full flex align-center pl-2" name="cohCustomerId">
                            <p>{orderCOH && orderCOH[0] && orderCOH[0].ORDER_CUSTOM_ID}</p>
                        </div>
                    </div>

                    <div className="flex-col md:mr-10 w-full md:w-48" style={{ display: doDisplay ? "" : "none" }}>
                        <p className="mt-5 md:mt-0">Status</p>
                        <div className="h-10 mt-2 bg-gray-200 rounded-md text-lightWite w-full flex align-center pl-2" name="status">
                            <p>{orderStatus}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    className="bg-gray-200 text-xs lg:text-base mt-5 cursor-pointer rounded-md w-44 h-10 flex justify-center items-center"
                    onClick={verifyOrder}
                    disabled={isClicked}>
                    <p>{isClicked ? "Verifying..." : "Verify Order"}</p>
                </button>
            </div>

            <LineSplitDetails
                doDisplay={doDisplay}
                orderDetails={orderCOH} />

            <div style={{ display: doDisplay ? "" : "none" }} className="flex justify-end">
                <button
                    id="Insert"
                    className={`bg-gray-200 text-xs lg:text-base mt-5 cursor-pointer rounded-md w-44 h-10 flex justify-center items-center ${disableInsert && "opacity-50 cursor-default"}`}
                    onClick={insertOrder}
                    disabled={disableInsert}>
                    <p>{isClickedInsert ? "Inserting..." : "Insert Order"}</p>
                </button>
            </div>
        </div>
    );
}

export default CreateOrder; 