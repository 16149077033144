import React, { useEffect, useState, useRef, useContext } from "react";
import Status from "../../components/Status";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Loader from '../../components/Loader';
import { Link } from "react-router-dom";
import './style.css'
import { AppContext } from "../../utils/ContextProvider";
import { useHistory, useParams } from "react-router-dom";
import { useLanguage } from '../../context/LanguageContext';

function AddUser() {

    const { translate } = useLanguage();
    const context = useContext(AppContext);
    const history = useHistory();
    const [buttonText, setButtonText] = useState(translate("CreateUserLabel"));
    const [buttonState, setButtonState] = useState(false);
    const [addUser, setAddUser] = useState({ name: "", email: "", role_id: "1", password:"", confirm_password:"" });
    let cancelToken = API.cancelToken();


    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        return () => {
            API.cancel(cancelToken);
        }
    }, [])

    function handleUserChange(event) {
        const { name, value } = event.target;
        setAddUser(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    };

    function validateEmail(mail){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
            return (true)
        }
            return (false)
    }

    function addEditSubmit(e) {
        e.preventDefault();
        setButtonText("Creating User...")
        setButtonState("disabled")
        // Form Validation
        if(!validateEmail(addUser.email)){
            console.log('This email is not valid!')
            Swal.fire({
                title: translate("ErrorLabel"),
                text: translate("ValidEmailLabel"),
                confirmButtonColor: "#131921",
                icon: translate("ErrorLabel"),
                confirmButtonText: 'Ok'
            })
            setButtonText(translate("CreateUserLabel"))
            setButtonState(false)
        }
        if(addUser.password !== addUser.confirm_password){
            console.log("Passwords don't match")
            Swal.fire({
                title: translate("ErrorLabel"),
                text: translate("PasswordMatchLabel"),
                confirmButtonColor: "#131921",
                icon: translate("ErrorLabel"),
                confirmButtonText: 'Ok'
            })
            setButtonText(translate("CreateUserLabel"))
            setButtonState(false)
            return;
        }
        const payload = { data: addUser }
        API.addSystemUser(payload).then(async res => {
            Swal.fire({
                title: translate("AddedLabel"),
                text: translate("UserSuccessfullyAddedLabel"),
                // confirmButtonColor: "#131921",
                icon: translate("SuccessLabel"),
                confirmButtonText: 'Ok'
            })
            setButtonText(translate("UserSuccessfullyCreatedLabel"))
        }).catch(err => {
            Swal.fire({
                title: translate("FailedLabel"),
                text: err.response && err.response.status === 400 ? translate("CompleteFieldsLabel") : translate("InternalErrorLabel"),
                confirmButtonColor: "#131921",
                icon: translate("ErrorLabel"),
                confirmButtonText: 'Ok'
            });
            setButtonText(translate("CreateUserLabel"))
            setButtonState(false)
        }).finally(f => {
        });
        
    }

    return (
        <div className="py-10 px-8 shrink-0 lg:mx-20 lg:px-20 height-limiter">
            <div className="flex flex-nowrap flex-row justify-between gap-x-5">
                <h1 className="text-3xl w-full py-3 mb-5">Add User</h1>
            </div>
            <div className="mb-3">
                <p>{translate("FillInUserLabel")}</p>
            </div>
            <div className="bg-gray-100 p-5 px-16">
                <form onSubmit={(e) => addEditSubmit(e)}>
                    <div className="flex-col my-5">
                        <div className="flex-row align-center space-between gap-x-4 my-6">
                            <label>{translate("NameLabel")}</label>
                            <input name="name" onChange={handleUserChange} className="w-full h-10 px-3 mt-2 bg-gray-200" required />
                        </div>
                        <div className="flex-row align-center space-between gap-x-4 my-6">
                            <label>Email:</label>
                            <input name="email" onChange={handleUserChange} className="w-full h-10 px-3 mt-2 bg-gray-200" required />
                        </div>
                        <div className="flex-row align-center space-between gap-x-4 my-6">
                            <label>{translate("RoleLabel")}:</label>
                            <select name="role_id" onChange={handleUserChange} className="px-3 h-10 mt-2 w-full bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" required>
                                <option value="1">Admin</option>
                                <option value="2">Internal Sales</option>
                                <option value="3">Service Billing</option>
                            </select>
                        </div>
                        <div className="flex-row align-center space-between gap-x-4 my-6">
                            <label>{translate('PasswordLabel')}</label>
                            <input type="password" name="password" onChange={handleUserChange} className="w-full h-10 px-3 mt-2 bg-gray-200" required/>
                        </div>
                        <div className="flex-row align-center space-between gap-x-4 my-6">
                            <label>{translate("ConfirmPasswordLabel")}</label>
                            <input type="password" name="confirm_password" onChange={handleUserChange} className="w-full h-10 px-3 mt-2 bg-gray-200" required/>
                        </div>
                        <div className="flex-row">
                            <button type="submit" className="hover:opacity-70 px-10 py-2 h-12 mt-4 w-full rounded-md bg-gray-200" disabled={buttonState} >{buttonText}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddUser;