import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import Swal from "sweetalert2";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import InputMask from 'react-input-mask';
import './style.css';
import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";
import { useParams, Link } from "react-router-dom/cjs/react-router-dom.min";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";

function AddMaintenance() {
    const iframeRefDesktop = useRef(null);
    const iframeRefMobile = useRef(null);
    const iframeRefTablet = useRef(null);
    const [maintenanceContent, setMaintenanceContent] = useState("");
    const [error, setError] = useState({});
    const [checkAll, setCheckAll] = useState(true);
    const [hideDesktop, setHideDesktop] = useState(false);
    const [hideMobile, setHideMobile] = useState(false);
    const [hideTablet, setHideTablet] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const maintenanceId = queryParams.get('id');
    const context = useContext(AppContext);
    const history = useHistory();
    const [maintenanceData, setMaintenanceData] = useState({
        "requester_name": context.getEmail,
        "maintenance_reason": "",
        "start_date": "",
        "end_date": "",
        "maintenance_content": "",
        "status": 1
    })
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    let cancelToken = API.cancelToken();



    function convertDateToDDMMYYYY(dateString) {
        // Check if the input is valid
        if (!dateString) {
            throw new Error("Invalid date string");
        }

        // Split the input string by '-'
        dateString = dateString.split(' ')[0];
        const [year, month, day] = dateString.split('-');

        // Return the formatted date string in 'dd/mm/yyyy' format
        return `${day}/${month}/${year}`;
    }

    useEffect(() => {
        console.log("HERE");
        console.log(maintenanceId);

        if (maintenanceId) {
            Swal.fire({
                title: 'Loading...',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            });
            API.getMaintenance(cancelToken, maintenanceId).then(async res => {
                console.log(res.data);
                Swal.close();
                const formattedData = {
                    ...res.data,
                    start_date: convertDateToDDMMYYYY(res.data.start_date),
                    end_date: convertDateToDDMMYYYY(res.data.end_date)
                };

                setMaintenanceData(formattedData);
                // Assuming res.data.maintenance_content is in HTML format
                const blocksFromHTML = convertFromHTML(res.data.maintenance_content);
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                setEditorState(EditorState.createWithContent(contentState));
            }).catch(err => {
                console.log(err);
                let errorMessage = 'An error occurred while trying to get the maintenance.';

                // Check if there is a custom error message from the backend
                if (err.response && err.response.data && err.response.data.error) {
                    errorMessage = err.response.data.error;
                }
                Swal.fire({
                    title: 'Error!',
                    text: errorMessage,
                    confirmButtonText: 'OK',
                    icon: 'error',
                    confirmButtonColor: "#131921"
                });
            });
        }
    }, [maintenanceId]);



    const onEditorStateChange = (newState) => {
        setEditorState(newState);
    };

    useEffect(() => {
        console.log(editorState.getCurrentContent())
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const htmlContent = draftToHtml(rawContentState);
        console.log(htmlContent); // Log the HTML content
        setMaintenanceContent(htmlContent);
        setMaintenanceData(prevValue => {
            return {
                ...prevValue,
                ['maintenance_content']: maintenanceContent
            }
        })
    }, [editorState]);

    const updateIframeContent = (iframeRef) => {
        const iframeDocument = iframeRef.current.contentDocument;
        iframeDocument.open();
        iframeDocument.write(maintenanceContent);
        iframeDocument.close();
    };

    useEffect(() => {
        updateIframeContent(iframeRefDesktop);
        updateIframeContent(iframeRefMobile);
        updateIframeContent(iframeRefTablet);
    }, [maintenanceContent]);

    const triggerCheckBox = (e) => {
        const name = e.target.name;

        if (name === "all") {
            if (e.target.checked) {
                setCheckAll(true);
                setHideDesktop(false);
                setHideMobile(false);
                setHideTablet(false);
            } else {
                setCheckAll(false);
                setHideDesktop(true);
                setHideMobile(true);
                setHideTablet(true);
            }
        }

        if (name === "desktop") {
            if (hideDesktop) {
                setHideDesktop(false);
            } else {
                setHideDesktop(true);
            }
        }
        if (name === "mobile") {
            if (hideMobile) {
                setHideMobile(false);
            } else {
                setHideMobile(true);
            }
        }
        if (name === "tablet") {
            if (hideTablet) {
                setHideTablet(false);
            } else {
                setHideTablet(true);
            }
        }
    }

    function handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        setError({})
        setMaintenanceData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    function validateDates(start_date, end_date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to start of the day for comparison

        const parseDate = (dateString) => {
            const [day, month, year] = dateString.split("/").map(Number);
            return new Date(year, month - 1, day);
        };

        const startDate = parseDate(start_date);
        const endDate = parseDate(end_date);

        // Check if start_date is valid and after today
        if (isNaN(startDate.getTime())) {
            return "Invalid start date.";
        } else if (startDate <= today) {
            return "Start date must be later than today.";
        }

        // Check if end_date is valid and after start_date
        if (isNaN(endDate.getTime())) {
            return "Invalid end date.";
        } else if (endDate <= startDate) {
            return "End date must be after the start date.";
        }

        // If all validations pass
        return false;
    }

    function submit(e) {
        e.preventDefault();
        setSubmitting(true);
        var text = 'Creating maintenance...'
        if(maintenanceId){
            text = "Updating maintenance..."
        }
        Swal.fire({
            title: text,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
        });
        setError({});
        var hasError = false

        console.log(maintenanceData)

        if (!maintenanceData.requester_name) {
            setError(prevValue => {
                return {
                    ...prevValue,
                    ['requester_name']: "Requester name is mandatory."
                }
            })
            hasError = true;
        }

        if (!maintenanceData.maintenance_reason) {
            setError(prevValue => {
                return {
                    ...prevValue,
                    ['maintenance_reason']: "Maintenance reason is mandatory."
                }
            })
            hasError = true;
        }

        if (!maintenanceData.start_date) {
            setError(prevValue => {
                return {
                    ...prevValue,
                    ['start_date']: "Start date is mandatory."
                }
            })
            hasError = true;
        }

        if (!maintenanceData.end_date) {
            setError(prevValue => {
                return {
                    ...prevValue,
                    ['end_date']: "End date is mandatory."
                }
            })
            hasError = true;
        }

        var errorDates = validateDates(maintenanceData.start_date, maintenanceData.end_date);
        if (errorDates) {
            setError(prevValue => {
                return {
                    ...prevValue,
                    ['start_date']: errorDates
                }
            })
            hasError = true;
        }

        if (!maintenanceData.maintenance_content) {
            setError(prevValue => {
                return {
                    ...prevValue,
                    ['maintenance_content']: "Maintenance content is mandatory."
                }
            })
            hasError = true;
        }


        if (hasError) {
            Swal.close();
            setSubmitting(false);
            return;
        }

        var payload = {
            "requester_name": maintenanceData.requester_name,
            "maintenance_reason": maintenanceData.maintenance_reason,
            "start_date": convertDateFormat(maintenanceData.start_date),
            "end_date": convertDateFormat(maintenanceData.end_date),
            "maintenance_content": maintenanceData.maintenance_content,
            "status":maintenanceData.status
        }

        if (maintenanceId) {
            API.updateMaintenance(cancelToken, maintenanceId, payload).then(async res => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Your maintenance has been changed successfully.',
                    confirmButtonText: 'BACK TO MAINTENANCE MODE',
                    icon: 'success',
                    confirmButtonColor: "#131921"
                }).then(() => {
                    history.push('/maintenance')
                });
            }).catch(err => {
                setSubmitting(false);
                let errorMessage = 'An error occurred while trying to create the maintenance.';

                // Check if there is a custom error message from the backend
                if (err.response && err.response.data && err.response.data.error) {
                    errorMessage = err.response.data.error;
                }

                Swal.fire({
                    title: 'Error!',
                    text: errorMessage,
                    confirmButtonText: 'OK',
                    icon: 'error',
                    confirmButtonColor: "#131921"
                });
            });
        } else {
            API.postMaintenance(cancelToken, payload).then(async res => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Your maintenance has been changed successfully.',
                    confirmButtonText: 'BACK TO MAINTENANCE MODE',
                    icon: 'success',
                    confirmButtonColor: "#131921"
                }).then(() => {
                    history.push('/maintenance')
                });
            }).catch(err => {
                setSubmitting(false);
                let errorMessage = 'An error occurred while trying to create the maintenance.';

                // Check if there is a custom error message from the backend
                if (err.response && err.response.data && err.response.data.error) {
                    errorMessage = err.response.data.error;
                }

                Swal.fire({
                    title: 'Error!',
                    text: errorMessage,
                    confirmButtonText: 'OK',
                    icon: 'error',
                    confirmButtonColor: "#131921"
                });
            });
        }
    }
    function convertDateFormat(dateString) {
        // Check if the input is a valid date string in the format 'DD/MM/YYYY'
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!datePattern.test(dateString)) {
            throw new Error("Invalid date format. Please use 'DD/MM/YYYY'.");
        }

        // Split the input string by '/'
        const [day, month, year] = dateString.split('/');

        // Check if the day, month, and year are valid numbers
        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            throw new Error("Invalid date components. Please provide a valid date.");
        }

        // Return the formatted date string in 'YYYY-MM-DD' format
        return `${year}-${month}-${day}`;
    }
    function updateStatus() {
        if (maintenanceData.status == 1) {
            Swal.fire({
                title: "Atention!",
                text: "This maintenance will be inactivate. Are you sure about this action?",
                showDenyButton: true,
                confirmButtonText: "YES",
                confirmButtonColor: "#1e2b3c",
                denyButtonColor: "#1e2b3c"
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Inactivating...",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })

                    var payload = {
                        "update_status": 0
                    }
                    console.log(maintenanceId)
                    console.log(payload)
                    API.updateMaintenance(cancelToken, maintenanceId, payload).then(async res => {
                        Swal.fire({
                            title: 'Success!',
                            text: 'Maintenance inactivated successfully.',
                            confirmButtonText: 'BACK TO MAINTENANCE MODE',
                            icon: 'success',
                            confirmButtonColor: "#131921"
                        }).then(() => {
                            history.push('/maintenance')
                        });
                    })
                }
            });
        }
        if (maintenanceData.status == 0) {
            Swal.fire({
                title: "Atention!",
                text: "This maintenance will be activated. Are you sure about this action?",
                showDenyButton: true,
                confirmButtonText: "YES",
                denyButtonText: `NO`,
                confirmButtonColor: "#1e2b3c",
                denyButtonColor: "#1e2b3c"
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {

                    Swal.fire({
                        title: "Activating...",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })

                    var payload = {
                        "update_status": 1
                    }
                    console.log(maintenanceId)
                    console.log(payload)
                    API.updateMaintenance(cancelToken, maintenanceId, payload).then(async res => {
                        Swal.fire({
                            title: "Maintenance activated successfully.",
                            confirmButtonText: "BACK TO MAINTENANCE MODE",
                            confirmButtonColor: "#1e2b3c",
                        }).then(() => {
                            history.push('/maintenance')
                        });
                    })




                    
                }
            });
        }
    }
    function getStatus(maintenanceObject) {
        const { status, end_date } = maintenanceObject;
        const today = new Date();
        const endDate = new Date(end_date);
        console.log(`maintenanceObject: ${maintenanceObject}`)
        console.log(`status: ${status}`)
        console.log(`end_date: ${end_date}`)

        // Rule 1: If status == 0 then return "inactive"
        if (status === 0) {
            console.log("Consider as inactive")
            return "inactive";
        }

        // Rule 2: if end_date > today's date then return "overdue"
        if (endDate < today) {
            return "overdue";
        }

        // Rule 3: if status == 1 then return 'active'
        if (status === 1) {
            return "active";
        }

        // Default case, if none of the above rules apply
        return "unknown";
    }

    const getButtonColor = (status) => {
        switch (status.toUpperCase()) {
            case "ACTIVE":
                return "#289067";
            case "INACTIVE":
                return "#373d45";
            case "OVERDUE":
                return "#81263B";
            default:
                return "#373d45";
        }
    };

    return (
        <div className="flex flex-col bg-slate-950 leading-[130%]">
            <div className="flex flex-col items-start px-5 pt-11 pb-16 w-full bg-slate-950 max-md:max-w-full">
                <div className="flex flex-row items-center justify-between pt-11 w-full bg-slate-950 max-md:max-w-full">
                    <div className="text-2xl font-medium leading-10 text-white max-md:max-w-full">
                        {maintenanceId ? `Maintenance #${maintenanceId}` : 'New Maintenance'}
                    </div>
                    <div>
                        {
                            maintenanceData && maintenanceId &&
                            <button style={{ backgroundColor: getButtonColor(getStatus(maintenanceData).toUpperCase()) }} className="text-white  font-medium py-4 px-4 rounded ml-2" onClick={(e) => updateStatus()}>
                                {
                                    (getStatus(maintenanceData).toUpperCase())
                                }

                            </button>
                        }
                    </div>
                </div>
                <div className="flex flex-col justify-center px-8 pt-8 pb-9 mt-4 w-full bg-slate-800 max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between text-base font-light text-white max-md:flex-wrap">
                        <div className="flex flex-col max-md:max-w-full w-1/2">
                            <div className="max-md:max-w-full">Requester</div>
                            <input className="shrink-0 mt-1 h-10 bg-gray-900 rounded max-md:max-w-full p-3" name="requester_name" value={maintenanceData.requester_name} onChange={handleChange} disabled={true} />
                            {error && error.requester_name && <p className="error">{error.requester_name}</p>}
                        </div>
                        <div className="flex flex-col max-md:max-w-full w-1/2">
                            <div className="max-md:max-w-full">Maintenance reason</div>
                            <input className="shrink-0 mt-1 h-10 bg-gray-900 rounded max-md:max-w-full p-3" name="maintenance_reason" value={maintenanceData.maintenance_reason} onChange={handleChange} />
                            {error && error.maintenance_reason && <p className="error">{error.maintenance_reason}</p>}
                        </div>
                    </div>
                    <div className="flex gap-5 justify-between text-base font-light text-white max-md:flex-wrap">
                        <div className="flex flex-col font-light text-white">
                            <div className="flex w-full space-x-4 mt-5 mb-5">
                                <div className="flex gap-1 justify-center text-base">
                                    <div className="flex flex-col">
                                        <div className="font-light text-white">Start Date</div>
                                        <InputMask mask="99/99/9999" name="start_date" onChange={handleChange} placeholder="DD/MM/YYYY" className="justify-center px-5 py-2 mt-1 whitespace-nowrap bg-gray-900 rounded p-3" value={maintenanceData.start_date} />
                                        {
                                            error && error.start_date && <p className="error">{error.start_date}</p>
                                        }
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="font-light text-white">End Date</div>
                                        <InputMask mask="99/99/9999" name="end_date" onChange={handleChange} placeholder="DD/MM/YYYY" className="justify-center px-5 py-2 mt-1 whitespace-nowrap bg-gray-900 rounded p-3" value={maintenanceData.end_date} />
                                        {
                                            error && error.end_date && <p className="error">{error.end_date}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-base">Devices</div>
                    <div className="flex gap-5 justify-between text-base font-light text-white max-md:flex-wrap">
                        <div className="flex items-center w-1/4">
                            <input type="checkbox" name="all" checked={checkAll} id="checkbox1" className="mr-2" onChange={triggerCheckBox} />
                            <label htmlFor="checkbox1">All Devices</label>
                        </div>
                        <div className="flex items-center w-1/4">
                            <input type="checkbox" name="desktop" id="checkbox2" checked={!hideDesktop} className="mr-2" onChange={triggerCheckBox} />
                            <label htmlFor="checkbox1">Desktop</label>
                        </div>
                        <div className="flex items-center w-1/4">
                            <input type="checkbox" name="mobile" id="checkbox3" checked={!hideMobile} className="mr-2" onChange={triggerCheckBox} />
                            <label htmlFor="checkbox1">Mobile</label>
                        </div>
                        <div className="flex items-center w-1/4">
                            <input type="checkbox" name="tablet" id="checkbox4" checked={!hideTablet} className="mr-2" onChange={triggerCheckBox} />
                            <label htmlFor="checkbox1">Tablet/iPad</label>
                        </div>
                    </div>
                    <Editor
                        wrapperClassName="shrink-0 mt-2 bg-gray-900 rounded h-[200px] max-md:max-w-full p-3"
                        editorClassName="editor"
                        toolbarClassName="toolbar"
                        onEditorStateChange={onEditorStateChange}
                        editorState={editorState}
                    />
                    {error && error.maintenance_content && <p className="error">{error.maintenance_content}</p>}

                    <div className="pt-10 pl-10 mb-3">
                        <h1 className="text-2xl">Preview</h1>
                    </div>
                    <div className="flex-col sm:w-full pl-10 pr-10 pb-5">
                        <p>Desktop (1024x768 a 1920x1080)</p>
                        <div className="flex space-x-4">
                            {
                                !hideDesktop &&
                                <iframe
                                    className="w-full h-[500px] bg-white"
                                    title="Desktop Preview"
                                    ref={iframeRefDesktop}
                                ></iframe>
                            }
                        </div>
                    </div>
                    <div className="flex-col sm:w-full pl-10 pb-5 pr-10">
                        <p className="w-2/3">Tablet/iPad (601x962 a 1280x800)</p>
                        <div className="flex space-x-4">
                            {
                                !hideTablet &&
                                <>
                                    <iframe
                                        className="w-2/3 h-[300px] bg-white"
                                        title="Tablet Preview"
                                        ref={iframeRefTablet}
                                    ></iframe>
                                </>
                            }
                            {
                                !hideMobile &&
                                <iframe
                                    className="w-1/3 h-[300px] bg-white"
                                    title="Mobile Preview"
                                    ref={iframeRefMobile}
                                ></iframe>
                            }
                        </div>
                    </div>
                </div>


                {/* <div className="flex gap-2.5 self-start mt-4 font-light">
                        <div className="text-base text-white">Message</div>
                        <div className="text-sm text-neutral-400">Viewed by user</div>
                    </div> */}
                {/* <textarea className="shrink-0 mt-2 bg-gray-900 rounded h-[200px] max-md:max-w-full p-3" /> */}
            </div>
            <div className="flex gap-4 self-stretch px-16 mb-32 text-base font-medium text-center max-md:flex-wrap max-md:px-5 max-md:mb-10 justify-end">
                <Link to={"./maintenance"} className="my-auto text-white pointer">
                    CANCEL
                </Link>
                <div className="justify-center px-6 py-4 text-white rounded-md bg-slate-800 max-md:px-5 pointer" onClick={(e) => submit(e)}>

                    {
                        maintenanceId ? submitting ? 'UPDATING...' : 'UPDATE MAINTENANCE' : submitting ? 'CREATING...' : 'ADD MAINTENANCE'
                    }
                </div>
            </div>
        </div>
    );
}

export default AddMaintenance;