import React, { useEffect, useState, useContext } from "react";
import API from "../../utils/API";
import { useParams } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import './style.css';
import Swal from "sweetalert2";

function CreateSuggestion() {
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const { user_suggestion_id } = useParams();
    const [suggestion, setSuggestion] = useState({
        user_suggestion_id: '',
        skf_id: '',
        name: '',
        suggestion_status: '',
        suggestion_type: '',
        suggestion_message: '',
        attached_file: null,
        created_at: '',
        updated_at: ''
    });
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const username = context.getEmail;

    useEffect(() => {
        Swal.fire({
            title: "Loading Customer's Request...",
            text: "Please wait...",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
        API.getSuggestion(cancelToken, user_suggestion_id).then((response) => {
            console.log(response.data);
            setSuggestion(response.data.suggestion);
            Swal.close();
        }).catch((error) => {
            console.error("Error fetching suggestion", error);
            Swal.close();
        });
        
        API.getSuggestionComments(cancelToken, user_suggestion_id).then((response) => {
            console.log(response.data);
            setComments(response.data.comments);
        }).catch((error) => {
            console.error("Error fetching comments", error);
        });
    }, [user_suggestion_id]);

    const handleStatusChange = (e) => {
        const newStatus = e.target.value;
        setSuggestion({ ...suggestion, suggestion_status: newStatus });

        const payload = { status: newStatus };
        API.putSuggestion(cancelToken, payload, suggestion.user_suggestion_id)
            .then((response) => {
                console.log("Status updated successfully", response);
            })
            .catch((error) => {
                console.error("Error updating status", error);
            });
    };

    const handleCommentSubmit = () => {
        if (!comment.trim()) return;

        Swal.fire({
            title: "Sending Comment...",
            text: "Please wait...",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        const payload = {
            user_suggestions_id: suggestion.user_suggestion_id,
            user_email: username,
            comment: comment
        };

        API.postSuggestionComment(cancelToken, payload)
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error adding comment", error);
                Swal.fire({
                    title: "Error",
                    text: "Failed to add comment. Please try again.",
                    icon: "error",
                });
            });
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Completed':
                return '#289067';
            case 'Rejected':
                return '#81263B';
            default:
                return '#BA983C';
        }
    };

    return (
        <div className="items-stretch bg-slate-950 flex flex-col tag-form mb-5">
            <div className="text-white text-2xl font-medium leading-10 whitespace-nowrap ml-11 mt-11 self-start max-md:ml-2.5 max-md:mt-10">
                Customer's Request
            </div>
            <div className="justify-center bg-slate-800 flex flex-col mt-4 mx-11 p-8 items-start m-5">
                <section className="flex flex-col justify-center self-stretch px-8 pt-8 pb-10 text-white bg-slate-800 max-md:px-5">
                    <div className="flex gap-5 justify-between max-md:flex-wrap">
                        <div className="flex flex-col text-base leading-5 w-full">
                            <p className="font-light w-full">COH Customer ID</p>
                            <input 
                                className="justify-center px-5 py-2 mt-1 whitespace-nowrap bg-gray-900 rounded w-full"
                                value={suggestion.skf_id}
                                readOnly
                            />
                        </div>
                        <div className="flex flex-col text-base leading-5 w-full">
                            <p className="font-light w-full">Short Name</p>
                            <input 
                                className="justify-center px-5 py-2 mt-1 bg-gray-900 rounded w-full"
                                value={suggestion.name}
                                readOnly
                            />
                        </div>
                        <div className="flex flex-col justify-center self-start whitespace-nowrap">
                            <p className="text-base font-light leading-5">Status</p>
                            <select 
                                id="requestType" 
                                name="requestType" 
                                className="justify-center px-2 py-2.5 mt-1 text-sm text-center font-bold tracking-wide rounded w-[150px]"
                                style={{ backgroundColor: getStatusColor(suggestion.suggestion_status) }}
                                value={suggestion.suggestion_status}
                                onChange={handleStatusChange}
                            >
                                <option value="Completed">Completed</option>
                                <option value="Analyzing">Analyzing</option>
                                <option value="Received">Received</option>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex gap-5 pr-20 mt-7 text-base leading-5 max-md:flex-wrap max-md:pr-5">
                        <div className="flex flex-col">
                            <p className="font-light">Request Type</p>
                            <input 
                                className="px-5 py-2 mt-1 whitespace-nowrap bg-gray-900 rounded w-[300px]"
                                value={suggestion.suggestion_type}
                                readOnly
                            />
                        </div>
                        <div className="flex flex-col">
                            <p className="font-light">Request Date</p>
                            <input 
                                className="px-5 py-2 mt-1 whitespace-nowrap bg-gray-900 rounded w-[200px]"
                                value={suggestion.created_at}
                                readOnly
                            />
                        </div>
                    </div>
                    <p className="mt-7 text-base font-light leading-5 w-full"> Message </p>
                    <textarea 
                        className="justify-center px-5 pt-2 pb-12 mt-1 text-base leading-5 bg-gray-900 h-[200px] rounded text-ellipsis w-full"
                        value={suggestion.suggestion_message}
                        readOnly
                    />
                    <p className="mt-6 text-base font-light leading-5 w-full"> Comments </p>
                    <div className="flex gap-1 px-5 py-2 mt-1 text-base leading-5 bg-gray-900 rounded max-md:flex-wrap">
                        <input 
                            className="flex-1 text-ellipsis w-full bg-gray-900" 
                            placeholder="Type to leave a comment... "
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <button
                            className="bg-emerald-600 px-4 py-2 rounded text-white"
                            onClick={handleCommentSubmit}
                        >
                            Send
                        </button>
                    </div>
                    <div className="mt-5">
                        {comments.map((comment, index) => (
                            <React.Fragment key={comment.user_suggestions_comments_id}>
                                <div className="bg-gray-800 p-4 rounded mb-2">
                                    <div className="flex justify-between items-center">
                                        <p className="font-bold">{comment.user_email}</p>
                                        <p className="text-sm">{new Date(comment.created_at).toLocaleString()}</p>
                                    </div>
                                    <p>{comment.comment}</p>
                                </div>
                                {index < comments.length - 1 && <hr className="my-4 border-gray-700" />}
                            </React.Fragment>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
}

export default CreateSuggestion;
