import React, { useState, useRef, useEffect, useContext } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import SearchBar from "../../components/Searchbar";
import Status from "../../components/Status";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'

function ListOfUsers() {
    const [listOfUsers, setlistOfUsers] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const role = context.getRole;
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [isSearch, setIsSearch] = useState(false);


    const searchFields = [{ fieldValue: "request_id", fieldName: "ID" },
    { fieldValue: "date", fieldName: "Date" },
    { fieldValue: "coh", fieldName: "COH COH Customer ID" },
    { fieldValue: "product", fieldName: "Product Designation" },
    { fieldValue: "quantity", fieldName: "Quantity" },
    { fieldValue: "status", fieldName: "Status" }];

    const dropDownValues = {
        status: [{ status: "-2", description: "Expired" },
        { status: "-1", description: "Rejected" },
        { status: "0", description: "Pending Review" },
        { status: "1", description: "Pending Approval" },
        { status: "2", description: "Approved" },
        { status: "3", description: "Purchase Complete" }]
    };

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }

        if (role != 1) {
            history.push('/listoforders');
            return;
        }

        setMobile(window.innerWidth <= 768);
        if (isSearch) {
            return
        }
        setLoading(true)
        API.getAdminUsers(cancelToken, currentPage).then(res => {
            console.log(res.data.users);
            res.data.users ? setlistOfUsers(res.data.users) : setlistOfUsers([]);
            setMaxPages(res.data.pagination.total_pages);
        }).catch(err => {
            setlistOfUsers([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage]
    );

    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])



    return <div className="flex-col md:p-10 p-5 availabilities-page height-limiter">
        <ScreenHeader
            title="Users"
            link="../adduser"
            buttonDescription="Add User"
            svg="ListOfCustomers"
            hasButton="true" />


        <div className="overflow-auto w-full mt-5">
            <div id="resultTable" className="flex-col justify-between">
                <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                    <div className="w-2/12 text-center">ID</div>
                    <div className="w-3/12 text-center">Name</div>
                    <div className="w-3/12 text-center">Email</div>
                    <div className="w-2/12 text-center">Role</div>
                    <div className="w-2/12 text-center">Status</div>
                </div>
            </div>
            <div id="resultBody">
                {!listOfUsers || loading ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div> :
                    listOfUsers.length === 0 ?
                        <div className="loader-wrapper min-h-full min-w-full p-5">
                            No Data Found
                        </div> :
                        listOfUsers.map((us, index) => {
                            return <Link to={`./adminuser/${us.admin_user_id}`} key={index + 'link'}>
                                <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5">
                                    <div className="w-2/12 text-center">{us.admin_user_id}</div>
                                    <div className="w-3/12 text-center">{us.name}</div>
                                    <div className="w-3/12 text-center">{us.email}</div>
                                    <div className="w-2/12 text-center">{us.role}</div>

                                    <div className="w-2/12 text-center flex justify-center">
                                        <Status
                                            key={'Status'}
                                            id={"Status"}
                                            currentStatus={"active"}
                                            clickable="true" />
                                    </div>
                                </div>
                            </Link>;
                        })}
            </div>
        </div>
        {
            !loading && maxPages &&
            <div className="page-selector flex-row-nowrap space-between">
                <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                    <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                    {
                        !mobile && <p>Previous</p>
                    }
                </div>
                <div className="pages-section flex-row-nowrap">
                    {
                        pages
                    }
                </div>
                <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                    {
                        !mobile && <p>Next</p>
                    }
                    <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                </div>
            </div>
        }
    </div>;
}

export default ListOfUsers; 