import { useState } from "react";

export default function CheckBoxesField(props) {

    const handleCheckboxChange = (checkboxName) => {
        let auxCheckboxState = { ...props.list }
        auxCheckboxState[checkboxName] = !auxCheckboxState[checkboxName]
        props.setValue(auxCheckboxState);
    };
    return (
        <div class="flex-col gap-2">
            <p>{props.label}</p>
            <div class={`flex gap-2 `}>
                {props.list && Object.keys(props.list).map((index, i) => {
                    return (<div class="inline-flex items-center">
                        <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={"check" + i}>
                            <input type="checkbox"
                                class={`before:content[''] peer relative h-5 w-5 cursor-pointer bg-[#0C1825] ${props.error ? "border border-amber-900" : "border-0"} appearance-none rounded-md border border-[#FFF] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#FFF] checked:bg-[#80CAFF] checked:before:bg-gray-900 hover:before:opacity-10`}
                                id={"check" + i}
                                disabled={props.disabled}
                                checked={props.list[index]}
                                onChange={() => handleCheckboxChange(index)} />
                            <span
                                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                    stroke="currentColor" stroke-width="1">
                                    <path fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </span>

                        </label>
                        <label class="mt-px font-light text-[#FFFFFF] cursor-pointer select-none" htmlFor={"check" + i}>
                            {index}
                        </label>
                    </div>
                    )
                })
                }
            </div>
        </div>
    )
}