import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import GlobalSVG from '../../utils/GlobalSVG';
import ReactGA from 'react-ga';

function ListOfTags() {
    const [listOfTags, setListOfTags] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [listOfChecked, setListOfChecked] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false)
    const [sorting, setSorting] = useState("desc")
    const [inactiveLabel, setInactiveLabel] = useState("INACTIVATE TAG")

    function handleSearch(e) {
        e && e.preventDefault();

        let value = valueRef.current.value;
        let field = fieldRef.current.value;

        setLoading(true);

        cancelToken = API.cancelToken();
        API.getListOfTags(cancelToken, currentPage, sorting, field, value).then((response) => {
            console.log(response.data)
            if (response.status === 200) {
                setListOfTags(response.data.tag_requests);
                setMaxPages(response.data.pagination.total_pages);
                setLoading(false);
            }
        })

    }

    function inactivateAction() {
        if (listOfChecked.length === 0) {
            return
        }
        Swal.fire({
            title: "Inactivation Request",
            text: "The selected TAGS will be inactivated. Do you want to proceed?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonColor: '#1e2b3c',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Please wait...',
                    html: 'We are processing your request.',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    willOpen: () => {
                        Swal.showLoading() // This will show a loading spinner
                    },
                });
                setInactiveLabel("INACTIVATING TAGS...")
                try {
                    Promise.all(listOfChecked.map(element => {
                        const cancelToken = API.cancelToken();
                        return API.removeTag(cancelToken, element);
                    })).then(responses => {
                        Swal.fire({
                            title: 'Inactivation Request',
                            text: "Inactivation request successfully sent!",
                            confirmButtonColor: '#1e2b3c',
                            allowOutsideClick: false,
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            icon: 'success',
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                        }).then(() => {
                            location.reload();
                        })
                    }).catch(error => {
                        Swal.fire({
                            title: 'Inactivation Request',
                            text: "Error on submiting request! Try again later or contact support.",
                            confirmButtonColor: '#1e2b3c',
                            allowOutsideClick: false,
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            icon: 'error',
                        });
                        console.error("An error occurred during the inactivation process:", error);
                        setInactiveLabel("INACTIVATE TAG")
                    });
                } catch (e) {
                    Swal.fire({
                        title: 'Inactivation Request',
                        text: "Error on submiting request! Try again later or contact support.",
                        confirmButtonColor: '#1e2b3c',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                        icon: 'error',
                    });
                    console.error("An exception occurred:", e);
                    setInactiveLabel("INACTIVATE TAG")
                }
            } else {

            }
        });

    }

    function doReset(e) {
        e && e.preventDefault();
        cancelToken = API.cancelToken();
        setLoading(true)
        API.getListOfTags(cancelToken, currentPage, sorting).then((response) => {
            console.log(response.data)
            if (response.status === 200) {
                setListOfTags(response.data.tag_requests);
                setMaxPages(response.data.pagination.total_pages);
                setLoading(false);
            }
        })
    }

    const changeCheckbox = (index, isChecked) => {
        console.log(index)
        if (isChecked) {
            // Add the tag to the list if checked, ensuring no duplicates
            setListOfChecked(prev => [...prev, index]);
        } else {
            // Remove the tag from the list if unchecked, assuming tag has a unique identifier
            setListOfChecked(prev => prev.filter(t => t !== index));
        }
    };

    function headerAction() {
        if (listOfChecked && listOfChecked.length > 0) {
            Swal.fire({
                title: 'Inactivation Request',
                text: "Request for TAG-28, TAG-29 Inactivation sucessfully sent!",
                confirmButtonColor: '#1e2b3c',
                allowOutsideClick: false,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                icon: 'success',
            })
        } else {
            history.push('/createtag')
        }
    }

    useEffect(() => {
        console.log(listOfChecked)
    }, [listOfChecked])

    useEffect(() => {
        cancelToken = API.cancelToken();
        API.getListOfTags(cancelToken, currentPage, sorting).then((response) => {
            console.log(response.data)
            if (response.status === 200) {
                setListOfTags(response.data.tag_requests);
                setMaxPages(response.data.pagination.total_pages);
                setLoading(false);
            }
        })
    }, [])

    const searchFields = [{ fieldValue: "tag_name", fieldName: "TAG Name" },
    { fieldValue: "jira_id", fieldName: "JIRA ID" },
    { fieldValue: "status", fieldName: "Status" },
    ];

    const dropDownValues = {
        status: [,
            { status: "ACTIVE", description: "ACTIVE" },
            { status: "INAVTIVE", description: "INACTIVE" }],
    };

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title="Tag Manager"
                buttonDescription="Add Tag"
                svg="orders"
                link="/createtag"
                // remove={listOfChecked && listOfChecked.length > 0 ? "true" : "false"}
                hasButton="true" />

            <SearchBar
                searchFields={searchFields}
                valueRef={valueRef}
                fieldRef={fieldRef}
                fieldStatusRef={fieldStatusRef}
                onSearch={handleSearch}
                clicked={isClicked}
                error={error}
                dropDownValues={dropDownValues}
                reset={doReset} />

            <div className="overflow-auto">
                <div id="resultTable" className="flex flex-col">
                    <div id="tableHeader" className="flex flex-row justify-between py-5 text-xs lg:text-base pl-5 font-bold bg-gray-200 px-10">
                        <div className="w-1/9 flex-row justify-center text-center"></div>
                        <div className="w-1/9 flex-row justify-center text-center">TAG Name</div>
                        <div className="w-1/9 flex-row justify-center text-center">JIRA ID</div>
                        <div className="w-1/9 flex-row justify-center text-center">JIRA Status</div>
                        <div className="w-1/9 flex-row justify-center text-center">User Name</div>
                        <div className="w-1/9 flex-row justify-center text-center">User Email</div>
                        <div className="w-1/9 flex-row justify-center text-center">Status</div>
                        <div className="w-1/9 flex-row justify-center text-center">Request Date</div>
                        <div className="w-1/9 flex-row justify-center text-center">Details</div>
                    </div>
                    <div id="resultBody" className="w-full">
                        {!listOfTags || loading ?
                            <div className="loader-wrapper min-h-full min-w-full">
                                <Loader light={true} />
                            </div> :
                            listOfTags.length === 0 ?
                                <div className="loader-wrapper min-h-full min-w-full p-5">
                                    No Data Found
                                </div> :
                                listOfTags && listOfTags.map((tag, index) => (
                                    // to={`/tag/${tag.request_id}`}
                                    <div className="flex flex-row justify-between py-5 text-xs lg:text-base pl-5 bg-blue px-10" key={index}>
                                        <div key={index} className="w-1/8 text-center flex-wrap break-words"><input type="checkbox" onChange={e => changeCheckbox(tag.request_id, e.target.checked)} /></div>
                                        <div className="w-1/9 text-center flex-wrap break-words">{tag.tag_name}</div>
                                        <div className="w-1/9 text-center flex-wrap break-words">{tag.jira_id}</div>
                                        <div className="w-1/9 text-center flex-wrap break-words">{tag.jira_status}</div>
                                        <div className="w-1/9 text-center flex-wrap break-words">{tag.requester_name}</div>
                                        <div className="w-1/9 text-center flex-wrap break-words">{tag.requester_email}</div>
                                        <div className="w-1/9 text-center flex-wrap break-words">
                                            <Status key={'orderStatus'} id={'123'} currentStatus={tag.status} clickable="true" />
                                        </div>
                                        <div className="w-1/9 flex-row justify-center">{tag.created_at && tag.created_at.split(" ")[0]}</div>
                                        <Link to={`/tag/${tag.request_id}`} className="w-1/9 flex-row justify-center">
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_240_1067)">
                                                    <path d="M13 17.5H5C4.45 17.5 4 17.95 4 18.5C4 19.05 4.45 19.5 5 19.5H13C13.55 19.5 14 19.05 14 18.5C14 17.95 13.55 17.5 13 17.5ZM19 9.5H5C4.45 9.5 4 9.95 4 10.5C4 11.05 4.45 11.5 5 11.5H19C19.55 11.5 20 11.05 20 10.5C20 9.95 19.55 9.5 19 9.5ZM5 15.5H19C19.55 15.5 20 15.05 20 14.5C20 13.95 19.55 13.5 19 13.5H5C4.45 13.5 4 13.95 4 14.5C4 15.05 4.45 15.5 5 15.5ZM4 6.5C4 7.05 4.45 7.5 5 7.5H19C19.55 7.5 20 7.05 20 6.5C20 5.95 19.55 5.5 19 5.5H5C4.45 5.5 4 5.95 4 6.5Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_240_1067">
                                                        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                        </Link>
                                    </div>
                                ))
                        }
                    </div>
                </div>
                {
                    listOfChecked && listOfChecked.length > 0 &&
                    <div className="flex justify-end items-stretch mt-6  max-md:flex-wrap max-md:px-5">
                        <div className="w-9/12">
                        </div>
                        <div className="w-3/12 text-white text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch bg-slate-800 grow px-4 py-3 max-w-[220px] rounded-md max-md:px-4 pointer" onClick={inactivateAction}>
                            {inactiveLabel}
                        </div>
                    </div>
                }
            </div>
            {/* {
                !loading && maxPages ?
                    <div className="page-selector flex-row-nowrap space-between">
                        <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                            {
                                !mobile && <p>Previous</p>
                            }
                        </div>
                        <div className="pages-section flex-row-nowrap">
                            {
                                pages
                            }
                        </div>
                        <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center">
                            {
                                !mobile && <p>Next</p>
                            }
                            <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                        </div>
                    </div> :
                    null
            } */}
        </div>);
}

export default ListOfTags; 