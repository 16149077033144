import React from "react";

function Status(props) {

    let clickable = props.clickable; 
    let neutral = props.neutral;
    let color = "#289067";
    if (props.currentStatus) {
        console.log(props.type)
        if (props.currentStatus.toLowerCase() === "faturada" 
            || props.currentStatus.toLowerCase() === "active"
            || props.currentStatus.toLowerCase()  === "complete" 
            || props.currentStatus.toLowerCase()  === "completed" 
            || props.currentStatus.toLowerCase() === "purchase completed"
            || props.currentStatus.toLowerCase() === "pedido concluído"
            || props.currentStatus.toLowerCase() === "approved"
            || props.currentStatus.toLowerCase() === "processed"
            || props.currentStatus === "NF-e baixada"
            ) {//green
            color = "#289067";
        }else if( (props.type == "services" || props.country == "ARG") && props.currentStatus.toLowerCase()  === "faturado"){
            color = "#289067"
        } else if (props.currentStatus.toLowerCase() === "aguardando pagamento" 
            || props.currentStatus.toLowerCase()  === "registration pending" 
            || props.currentStatus.toLowerCase()  === "processo de faturamento" 
            || props.currentStatus.toLowerCase()  === "pedido faturado" 
            || props.currentStatus.toLowerCase()  === "faturado" 
            || props.currentStatus.toLowerCase()  === "em separação" 
            || props.currentStatus.toLowerCase()  === "received" 
            || props.currentStatus.toLowerCase()  === "analyzing" 
            || props.currentStatus.toLowerCase()  === "processando pagamento" 
            || props.currentStatus.toLowerCase()  === "importação em trânsito" 
            || props.currentStatus.toLowerCase()  === "aguardando capigemini" 
            || props.currentStatus.toLowerCase()  === "sem previsão de entrega" 
            || props.currentStatus.toLowerCase()  === "entrega programada" 
            || props.currentStatus.toLowerCase()  === "entrega reprogramada" 
            || props.currentStatus.toLowerCase()  === "awaiting registration" 
            || props.currentStatus.toLowerCase()  === "awaiting payment" 
            || props.currentStatus.toLowerCase()  === "pending" 
            || props.currentStatus.toLowerCase()  === "pending client approval" 
            || props.currentStatus.toLowerCase()  === "aguardando crédito capgemini" 
            || props.currentStatus.toLowerCase() === "pending review"
            || props.currentStatus.toLowerCase() === "inactavion requested"
            || props.currentStatus.toLowerCase() === "pending approval"
            || props.currentStatus.toLowerCase() === "warning"
            || props.currentStatus.toLowerCase() === "approved"
            || props.currentStatus.toLowerCase() === "enviado"
            || props.currentStatus.toLowerCase() === "em processamento"
            || props.currentStatus.toLowerCase() === "processing"
            || props.currentStatus.toLowerCase() === "pagamento não autorizado"
            || props.currentStatus.toLowerCase() === "aguardando registro coh"
            || props.currentStatus.toLowerCase() === "crédito cliente débito provisionamento"
            || props.currentStatus.toLowerCase() === "linha sem previsão de entrega"
            || props.currentStatus.toLowerCase() === "credit_entry"
            || props.currentStatus.toLowerCase() === "awaiting confirmation"
            || props.currentStatus.toLowerCase() === "pending processing"
            || props.currentStatus.toLowerCase() === "processing"
            || props.currentStatus.toLowerCase() === "awaiting coh registration"
            || props.currentStatus === 0
            || props.currentStatus === "Baixar NF-e"
            || props.currentStatus.toLowerCase() === "linha com previsão de entrega reprogramada") {//yellow
            color = "#BA983C";
        } else if (props.currentStatus.toLowerCase()  === "registration error"
            || props.currentStatus.toLowerCase()  === "rejected and aborted"
            || props.currentStatus.toLowerCase()  === "rejected"
            || props.currentStatus.toLowerCase()  === "expired"
            || props.currentStatus.toLowerCase()  === "rejeitado"
            || props.currentStatus.toLowerCase()  === "urgent"
            || props.currentStatus.toLowerCase()  === "not found"
            || props.currentStatus.toLowerCase()  === "cancelado"
            || props.currentStatus.toLowerCase()  === "payment not authorized"
            || props.currentStatus.toLowerCase()  === "pedido cancelado"
            || props.currentStatus.toLowerCase()  === "canceled"
            || props.currentStatus.toLowerCase()  === "pagamento expirado"
            || props.currentStatus.toLowerCase()  === "expired payment"
            || props.currentStatus.toLowerCase()  === "pagamento não autorizado"
            || props.currentStatus.toLowerCase()  === "cancelled"
            || props.currentStatus.toLowerCase()  === "error"
            || props.currentStatus.toLowerCase()  === "erro"
            || props.currentStatus.toLowerCase()  === "overdue"
            || props.currentStatus.toLowerCase()  === "not able to fetch"
            || props.currentStatus.toLowerCase() === "linha rejeitada") {//red
            color = "#81263B";
        } else if (props.currentStatus.toLowerCase()  === "inactive") {//red
            color = "#868686";
        }else if (props.currentStatus.toLowerCase()  === "n/a" || props.currentStatus.toLowerCase()  === "invalid") {//Faded gray
            color = "#373d45";
        }else {
            color = "#289067";
        }
    }
    
    if (neutral && neutral == "true"){
        color = "#818181";
    }

    return (
        props.currentStatus &&
        <div
            className={`flex items-center justify-center py-1 rounded-md text-xs text-sm ${props.currentStatus.toLowerCase() === "not found" ? "px-2" : "px-3" }`}
            style={{ backgroundColor: color, cursor: clickable === "true"? "pointer" : "default" }}>
            <div>{props.currentStatus.toUpperCase()}</div>
        </div>);
}

export default Status;