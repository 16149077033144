import React, { useEffect, useState, useRef, useContext } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Loader from '../../components/Loader';
import { AppContext } from "../../utils/ContextProvider";
import { useHistory, useParams } from "react-router-dom";
import './style.css';

function PartnerDetails() {
    const context = useContext(AppContext);
    const history = useHistory();
    const [partner, setPartner] = useState({ name: "", email: "", cnpj: "", phone_number: "", address: "" });
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    let cancelToken = API.cancelToken();

    const { id } = useParams();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        return () => {
            API.cancel(cancelToken);
        }
    }, []);

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }

        setLoading(true);
        API.getPartner(cancelToken, id).then(res => {
            const partnerData = res.data.partner;
            setPartner({
                name: partnerData.name,
                email: partnerData.email,
                cnpj: partnerData.cnpj,
                phone_number: partnerData.phone_number,
                address: partnerData.address
            });
        }).catch(err => {
            if (err.message === 'cancelling') return;
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(() => {
            setLoading(false);
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPartner({
            ...partner,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        Swal.fire({
            title:'Processando',
            text: 'Por favor aguarde...',
            showConfirmButton: false,
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });
        API.updatePartner(partner)
            .then(res => {
                Swal.fire({
                    title: 'OK',
                    text: 'Partner editado com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    Swal.close()
                    setIsEditing(false);
                })
            })
            .catch(err => {
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to update partner details. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    setIsEditing(false);
                })
            })
            .finally(() => {
                
            });
    };

    return (
        <div className="py-10 px-8 shrink-0 lg:mx-20 lg:px-20 height-limiter">
            <div className="flex flex-nowrap flex-row justify-between gap-x-5">
                <h1 className="text-3xl w-full py-3 mb-5">Partner Details</h1>
            </div>
            {
                loading ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div> :
                    <div>
                        <div className="mb-3">
                            <p>Partner information:</p>
                        </div>
                        <div className="bg-gray-100 p-5 px-16">
                            <form onSubmit={handleSubmit}>
                                {
                                    partner &&
                                    <div className="flex-col my-5">
                                        <div className="flex-row align-center space-between gap-x-4 my-6">
                                            <label>Name:</label>
                                            <input 
                                                name="name" 
                                                value={partner.name || ""} 
                                                onChange={handleInputChange}
                                                className="w-full h-10 px-3 mt-2 bg-gray-200" 
                                            />
                                        </div>
                                        <div className="flex-row align-center space-between gap-x-4 my-6">
                                            <label>Email:</label>
                                            <input 
                                                name="email" 
                                                placeholder={partner.email || ""} 
                                                className="w-full h-10 px-3 mt-2 bg-gray-200" 
                                                disabled 
                                            />
                                        </div>
                                        <div className="flex-row align-center space-between gap-x-4 my-6">
                                            <label>CNPJ:</label>
                                            <input 
                                                name="cnpj" 
                                                placeholder={partner.cnpj || ""} 
                                                className="w-full h-10 px-3 mt-2 bg-gray-200" 
                                                disabled 
                                            />
                                        </div>
                                        <div className="flex-row align-center space-between gap-x-4 my-6">
                                            <label>Phone Number:</label>
                                            <input 
                                                name="phone_number" 
                                                value={partner.phone_number || ""} 
                                                onChange={handleInputChange}
                                                className="w-full h-10 px-3 mt-2 bg-gray-200" 
                                            />
                                        </div>
                                        <div className="flex-row align-center space-between gap-x-4 my-6">
                                            <label>Address:</label>
                                            <input 
                                                name="address" 
                                                value={partner.address || ""} 
                                                onChange={handleInputChange}
                                                className="w-full h-10 px-3 mt-2 bg-gray-200" 
                                            />
                                        </div>
                                        <div className="flex justify-end">
                                                <button 
                                                    type="submit" 
                                                    onClick={() => setIsEditing(true)} 
                                                    className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2"
                                                    disabled={loading}
                                                >
                                                    {"Editar Partner"}
                                                </button>
                                            </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
            }
        </div>
    );
}

export default PartnerDetails;
