import React from "react";
import { Link } from "react-router-dom";

function ScreenHeader(props) {
    const { svg, hasButton, action, external, link, buttonDescription } = props;

    function handleClick() {
        if (action) {
            action();
        }
    }

    // Button content with SVGs and description
    const buttonContent = (
        <div className="bg-gray-200 cursor-pointer flex-row items-center justify-around px-5 py-2 rounded-md my-2" onClick={handleClick}>
            <svg style={{ display: svg === "orders" ? "" : "none" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                {props.remove && props.remove === "true" ? (
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                ) : (
                    <>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </>
                )}
            </svg>
            <svg style={{ display: svg === "ListOfCustomers" ? "" : "none" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                <path d="M16 11h6m-3 -3v6"></path>
            </svg>
            <p className="px-2">{buttonDescription}</p>
        </div>
    );

    return (
        <div id="header" className="flex-row justify-between">
            <div className="text-2xl my-2 w-9/12">{props.title}</div>
            {hasButton === "true" && (
                external && link ? (
                    // External link
                    <a href={link} rel="noreferrer" target="_blank">
                        {buttonContent}
                    </a>
                ) : link ? (
                    // Internal link using React Router's Link component
                    <Link to={link}>
                        {buttonContent}
                    </Link>
                ) : (
                    // No link provided, render the button without Link or anchor
                    buttonContent
                )
            )}
            <div className="text-md w-11/12">{props.subtitle}</div>
            {/* Render other subtitles as needed */}
        </div>
    );
}

export default ScreenHeader;