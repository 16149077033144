import React, { useState, useEffect, useContext } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import CompanyLine from "../../components/CompanyLine"
import API from "../../utils/API";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import * as XLSX from 'xlsx'
import "./style.css"
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import pdfToText from 'react-pdftotext'

function PolicyRegistration() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [file, setFile] = useState(null);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);

    let cancelToken = API.cancelToken();

    let [policyRegistration, setPolicyRegistration] = useState({
        type: "EXCHANGE_POLICY",
        content: ""
    });
    const context = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);

    }, [])


    function handleChange(e) {
        let new_value = null
        const { name, value } = e.target;
        console.log(name, value)
        let newArr = policyRegistration
        newArr[name] = value;
        setPolicyRegistration(newArr);
    }

    const handleSubmit = () => {
        console.log(policyRegistration)

        Swal.fire({
            title: 'Submitting policy...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
        });

        Swal.fire({
            title: 'Policy Registration',
            text: 'You are trying to update the AZPartes.com policy. Are you sure?',
            showCancelButton: true,
            cancelButtonText: 'No',
            icon: 'warning',
            confirmButtonColor: "#131921",
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                API.addPolicy(policyRegistration, cancelToken).then((res) => {
                    Swal.fire({
                        title: 'Policy Registration',
                        text: 'Policy Updated',
                        icon: 'success',
                        confirmButtonColor: "#131921",
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push('/policy');
                        }
                    })
                }).catch((err) => {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Failed to update policy',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                })
                return
            }
        }
        )

        cancelToken = API.cancelToken();
        setLoading(true);
    }

    //upload file section 
    // on change states
    const [excelFileError, setExcelFileError] = useState(null);

    // handle File
    const fileType = ['application/pdf'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        processFile(selectedFile);
    }

    function processFile(selectedFile) {
        if (selectedFile) {
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);

                reader.onload = (e) => {
                    setExcelFileError(null);
                    if (e.target.result !== null) {
                        const workbook = XLSX.read(e.target.result, { type: 'buffer' });
                        const worksheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[worksheetName];
                        const data = XLSX.utils.sheet_to_json(worksheet);
                        setPolicyRegistration(data);
                        setMaxPages(Math.ceil(data.length / 10))

                    }
                }
            }
            else {
                setExcelFileError('Please select only excel file types');
            }
        }
        else {
            console.log('Please select a file');
        }
    }

    const onDrop = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
        let file = e.dataTransfer.files[0];
        console.log(file);
        if (file) {
            if (fileType.includes(file.type)) {
                pdfToText(file)
                    .then(text => {
                        console.log(text)
                        setFile(file.name)
                        let newArr = { ...policyRegistration, ['content']: text }
                        setPolicyRegistration(newArr);
                    })
                    .catch(error => console.error("Failed to extract text from pdf"))
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please select a pdf file',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        } else {
            copnsole.log('no file')
        }
        // processFile(file);
    }

    const onDragEnter = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = '#131930';
        e.currentTarget.style.border = '1px solid white';
    }
    const onDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
    }

    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])

    return (
        <div className="registration-page flex-col md:p-10 p-5 height-limiter">
            <ScreenHeader
                title="Policy Registration"
                link='https://skfbuy-public-assets.s3.amazonaws.com/insert_company.xlsx'
                external={true}
                buttonDescription="Download Template"
                hasButton="false" />

            <div id="sectionTwo">
                <div className="pt-10 pb-6">
                    Please fill the policy information below:
                </div>

                <div className="bg-blue h-full max-h-128 rounded text-xs lg:text-base md:p-7 p-3 flex-col justify-between items-center overflow-auto">
                    <p className="">Policy Type</p>
                    <select onChange={(e) => { handleChange(e) }} name="type" className="mt-5 bg-[#1e2b3c] text-xs md:text-base cursor-pointer flex items-center justify-between w-full h-full rounded-md py-2 px-5">
                        <option value="EXCHANGE_POLICY">Exchage Policy</option>
                        <option value="PRIVACY_POLICY">Privacy</option>
                        <option value="TERMS_OF_USE">Terms of Use</option>
                    </select>
                </div>
            </div>

            {!file && <div id="uploadFile">
                <div className="pt-10 pb-6">
                    <p>Drop your policy in a PDF format below:</p>
                </div>
                {excelFileError && <div className="py-2 text-red-600">{excelFileError}</div>}
                <div className="bg-blue flex justify-center align-center h-52 rounded text-xs lg:text-base">
                    <div
                        onClick={() => { document.getElementById('getFile').click() }}
                        onDrop={e => onDrop(e)}
                        onDragEnter={e => onDragEnter(e)}
                        onDragLeave={e => onDragLeave(e)}
                        onDragStart={e => e.preventDefault()}
                        onDragEnd={e => e.preventDefault()}
                        onDragOver={e => onDragEnter(e)}
                        onDrag={e => e.preventDefault()}
                        className="flex w-40 h-40 text-center border-white border border-dashed rounded-md justify-center items-center cursor-pointer">
                        <input type='file' name="getFile" id="getFile" style={{ display: "none" }} onChange={handleFile} />
                        <div>
                            <h1>DROP HERE</h1>
                            <h1>OR</h1>
                            <h1>SELECT A FILE</h1>
                        </div>
                    </div>
                </div>
            </div>
            }
            {
                file ?
                    <div className="p-10 bg-[#1e2b3c]">
                        {file}
                    </div> :
                    null
            }
            <div className="flex justify-end">
                <button
                    className="bg-gray-200 text-xs lg:text-base mt-5 cursor-pointer rounded-md w-44 h-10 flex justify-center items-center"
                    onClick={handleSubmit}>
                    <p>{"Update Policy"}</p>
                </button>
            </div>
        </div>);
}

export default PolicyRegistration;