import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "./style.css"
import "react-datepicker/dist/react-datepicker.css";

function AddField(props) {

    const listOfFields = props.listOfFields;
    const availibilityDetails = props.availabilityDetails;
    const availability = props.availability;
    const [field, setField] = useState({ product_id: '', quantity: '', pack: '', date: '', dpc: '', request_id: props.request_id });
    const [dd, setDd] = useState([])
    const [details, setDetails] = useState(false);
    const nbOfFields = listOfFields.length;

    useEffect(() => {
        props.field ? setField(props.field) : null
        let temp_arr = availibilityDetails.map(e => e.product_id)
        setDd(temp_arr)
    }, [props])


    function handleChange(e) {
        const { name, value } = e.target;
        props.selectedId({ ...field, [name]: value }, props.rowId, 'fields');
    };

    function showDetails() {
        let chevron = document.getElementsByClassName("chevron")[props.rowId];
        console.log(chevron);
        if (details === false) {
            chevron.style.transform = "rotate(90deg)";
            setDetails(true);
        } else {
            chevron.style.transform = "rotate(0deg)";
            setDetails(false);
        }
    }


    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }


    function selectAvailbility(i, api) {
        let rows = Array.from(document.getElementsByClassName("tableRow"));
        rows.forEach(row => {
            return row.style.backgroundColor = "#232F3E";
        })
        if (api === "global") {
            let row = document.getElementsByClassName("tableRow")[i];
            row.style.backgroundColor = "#465363";
            let selectedGlobal = availability.global_availability.items[i];
            console.log(selectedGlobal.availabilityInfos[0].deliveryDateActual)
            setField({ product_id: field.product_id, quantity: selectedGlobal.quantity, pack: selectedGlobal.pack, date: selectedGlobal.availabilityInfos[0].deliveryDateActual, dpc: selectedGlobal.dpc, request_id: props.request_id })
            props.selectedId({ product_id: field.product_id, quantity: selectedGlobal.quantity, pack: selectedGlobal.pack, date: selectedGlobal.availabilityInfos[0].deliveryDateActual, dpc: selectedGlobal.dpc, request_id: props.request_id }, props.rowId, 'fields');

        } else {
            let length = availability.global_availability.hasOwnProperty('error') ? 0 : availability.global_availability.items.length ;
            let row = document.getElementsByClassName("tableRow")[i + length];
            row.style.backgroundColor = "#465363";
            let selected = availability.snowflake_availability[i];

            let date = databaseToDate(selected.date);
            setField({ product_id: field.product_id, quantity: '', dpc: '', pack: '', request_id: '', date: '' })
            props.selectedId({ product_id: field.product_id, quantity: selected.quantity, dpc: selected.dpc, pack: selected.pack, request_id: props.request_id, date: date }, props.rowId, 'fields')
            setField({ product_id: field.product_id, quantity: selected.quantity, dpc: selected.dpc, pack: selected.pack, request_id: props.request_id, date: date })
        }
    }

    function plusButton() {
        props.addRow(props.rowId, field.product_id, 'fields')
        if (field && field.date && field.quantity && field.product_id && field.dpc) {
            showDetails()
        }
    }

    return <div className="flex flex-row" style={{ display: props.visibilityAvailibility === true ? "" : "none" }}>
        <div className="wholeField">
            {
                !props.readOnly &&
                <div className="arrow-width">
                    <svg onClick={() => showDetails()} xmlns="http://www.w3.org/2000/svg" className="chevron cursor-pointer icon icon-tabler icon-tabler-chevron-right" width="40" height="40" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 6l6 6l-6 6"></path>
                    </svg>
                </div>
            }
            <div className="line-width">
                <div className="w-full py-2 lg:w-2/12 flex flex-col">
                    <p className="py-2">Date</p>
                    <DatePicker
                        selected={field && field.date ? new Date(field.date) : null}
                        onChange={(value) => handleChange({
                            target: {
                                name: "date", value: value.toISOString()
                            }
                        })}
                        dateFormat="yyyy-MM-dd"
                        defaultValue=""
                        readOnly={props.readOnly}
                        placeholderText="Date"
                        className={`w-full h-10 bg-gray-200 rounded-md p-5 placeholder-neutral-400 ${props.error && field && !field.date ? "border border-amber-900" : "border-0"}`} />
                </div>

                <div className="w-full py-2 lg:w-3/12">
                    <p className="py-2">SKU</p>
                    {props.readOnly ?
                        <input
                            type="text"
                            name="product_id"
                            value={field && field.product_id !== "" ? field.product_id : props.product_id}
                            defaultValue={props.product_id && props.product_id}
                            onChange={handleChange}
                            className={`w-full h-10 bg-gray-200 rounded-md p-5 placeholder-neutral-400 ${field && field.wrongProd ? "border border-amber-900" : "border-0"}`}
                            placeholder="Product ID"
                            disabled
                        />
                        :
                        <input
                            type="text"
                            name="product_id"
                            value={field ? field.product_id : ''}
                            className={`w-full h-10 bg-gray-200 rounded-md p-5 placeholder-neutral-400`}
                            onChange={handleChange}
                            placeholder="Product ID"
                            disabled
                        />
                    }

                </div>
                <div className="w-full py-2 lg:w-2/12">
                    <p className="py-2">Quantity</p>
                    <input
                        type="text"
                        name="quantity"
                        value={field ? field.quantity : ''}
                        className={`w-full h-10 bg-gray-200 rounded-md p-5 placeholder-neutral-400 ${props.error && field && (!field.quantity || field.quantity === '') ? "border border-amber-900" : "border-0"}`}
                        onChange={handleChange}
                        placeholder="Quantity"
                        disabled={props.readOnly}
                    />
                </div>
                <div className="w-full py-2 lg:w-2/12">
                    <p className="py-2">Pack</p>
                    <input
                        type="text"
                        name="pack"
                        value={field ? field.pack : ''}
                        className={`w-full h-10 bg-gray-200 rounded-md p-5 placeholder-neutral-400 ${props.error && field && (!field.pack || field.pack === '') ? "border border-amber-900" : "border-0"}`}
                        onChange={handleChange}
                        placeholder="Pack"
                        disabled={props.readOnly}
                    />
                </div>
                <div className="w-full py-2 lg:w-2/12">
                    <p className="py-2">DPC</p>
                    <input
                        type="text"
                        name="dpc"
                        value={field ? field.dpc && field.dpc.toUpperCase() : ''}
                        className={`w-full h-10 bg-gray-200 rounded-md p-5 placeholder-neutral-400 ${props.error && field && (!field.dpc || field.dpc === '') ? "border border-amber-900" : "border-0"}`}
                        onChange={handleChange}
                        placeholder="DPC"
                        disabled={props.readOnly}
                    />
                </div>
                {!props.readOnly && <div className='w-full lg:w-1/12 flex-row-nowrap align-bottom py-2'>

                    {
                        (props.rowId !== nbOfFields - 1) &&
                        <button
                            className="bg-gray-200 rounded flex-row h-10 w-10  align-center justify-center"
                            onClick={() => { props.listOfFields.length > 1 ? props.deleteRow(props.rowId, 'fields') : null }}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-minus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                        </button>
                    }
                    {
                        (props.rowId + 1 === nbOfFields) &&
                        <button
                            className="bg-gray-200 rounded flex-row h-10 w-10  align-center justify-center"
                            onClick={() => plusButton()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                        </button>
                    }
                </div>}
            </div>
        </div>
        {
            details &&
            <div className="flex flex-col lg:flex-row justify-center p-2 lg:p-12 w-full">
                <div className="API API-global">
                    <p>Global Availability</p>
                    <div className="table">
                        <div className="tableHeader">
                            <div className="w-1/5 text-center">DPC</div>
                            <div className="w-1/5 text-center">PACK</div>
                            <div className="w-1/5 text-center">Quantity</div>
                            <div className="w-2/5 text-center">Delivery Date</div>
                        </div>
                        <div className="tableBody">
                            {availability.global_availability.hasOwnProperty('error') ?
                                <div className="w-full break-all text-center">{availability.global_availability.error}</div>
                                :
                                availability.global_availability.items.map((item, index) => {
                                    return <div key={index + "global"} onClick={() => { selectAvailbility(index, "global") }} className="tableRow">
                                        <div className="w-1/5 text-center">{item.dpc}</div>
                                        <div className="w-1/5 text-center">{item.pack}</div>
                                        <div className="w-1/5 text-center">{item.quantity}</div>
                                        <div className="w-2/5 text-center">{item.availabilityInfos[0].deliveryDateActual}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="API">
                    <p>Snowflake</p>
                    <div className="table">
                        <div className="tableHeader snowflake">
                            <div className="w-1/3 text-center">Warehouse</div>
                            <div className="w-1/3 text-center">PACK</div>
                            <div className="w-1/3 text-center">Quantity</div>
                        </div>
                        <div className="tableBody snowflake">
                            {availability.snowflake_availability.hasOwnProperty('error') ?
                                <div className="w-full break-all text-center">{availability.snowflake_availability.error}</div>
                                :
                                availability.snowflake_availability.map((item, index) => {
                                    return <div key={index + "snowflake"} onClick={() => { selectAvailbility(index, "snowflake") }} className="tableRow">
                                        <div className="w-1/3 text-center">{item.warehouse}</div>
                                        <div className="w-1/3 text-center">{item.pack}</div>
                                        <div className="w-1/3 text-center">{item.quantity}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>;
}

export default AddField; 