import React, { useState, useRef, useEffect, useContext } from "react";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import './style.css'
import Status from "../../components/Status";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import { useLanguage } from '../../context/LanguageContext';

function ListOfCompanies() {
    const { translate } = useLanguage();
    const [listOfCustomers, setListOfCustomers] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    const context = useContext(AppContext);
    const history = useHistory();
    let cancelToken = API.cancelToken();
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [isSearch, setIsSearch] = useState(false);
    const [reset, setReset] = useState(false)
    const [error, setError] = useState(false);

    const searchFields = [{ fieldValue: "coh_id", fieldName: "COH COH Customer ID" },
    { fieldValue: "name", fieldName: "COH Short Name" },
    { fieldValue: "cnpj", fieldName: "CNPJ" },
    { fieldValue: "accept", fieldName: "Accept E-Mkt" },
    { fieldValue: "status", fieldName: "Status" }];

    const dropDownValues = {
        status: [{ status: "-1", description: "Error" },
        { status: "0", description: "Inactive" },
        { status: "1", description: "Active" },
        { status: "3", description: "Awaiting Registration" },],
        accept: [{ status: "0", description: "FALSE" },
        { status: "1", description: "TRUE" }],
    };


    
    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        setMobile(window.innerWidth <= 768);
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);

        setLoading(true)
        cancelToken = API.cancelToken();

        let field = fieldRef.current.value;
        let value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;
        if (field !== "" && value != "") {
            API.getSearchListOfCustomers(cancelToken, currentPage, field, value).then(res => {
                res.data.companies ? setListOfCustomers(res.data.companies) : setListOfCustomers([]);
                setMaxPages(res.data.pagination.total_pages)
            }).catch(err => {
                setListOfCustomers([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(
                f => {
                    setIsClicked(false);
                    setLoading(false)
                    setIsSearch(false);
                });
        }
        else {
            API.getListOfCustomers(cancelToken, currentPage).then(res => {
                console.log(res);
                res.data.companies ? setListOfCustomers(res.data.companies) : setListOfCustomers([]);
                setMaxPages(res.data.pagination.total_pages)
            }).catch(err => {
                setListOfCustomers([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(f => {
                setLoading(false)
            });
        }

        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, reset, isSearch]);

    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])

    const doReset = () => {
        setReset(!reset)
    }

    const handleSearch = (e) => {
        e && e.preventDefault();
        let value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;
        let field = fieldRef.current.value;


        if (!value || value.trim() === '' || !field) {
            setError(true)
            return
        }

        if (currentPage === 1) {
            setIsSearch(true)
        } else {
            setCurrentPage(1)
        }

        setIsClicked(true);
    }


    return <div className="flex-col md:p-10 p-5  customers-page height-limiter">
        <ScreenHeader
            title={translate("ListOfCustomersLabel")}
            link="../customerregistration"
            buttonDescription={translate( "AddCustomerLabel")}
            svg="ListOfCustomers"
            hasButton="true" />

        <SearchBar
            searchFields={searchFields}
            valueRef={valueRef}
            fieldRef={fieldRef}
            fieldStatusRef={fieldStatusRef}
            onSearch={handleSearch}
            clicked={isClicked}
            dropDownValues={dropDownValues}
            error={error}
            reset={doReset} />

        <div className="text-xs lg:text-base overflow-auto">
            <div id="resultTable" className="flex-col justify-between w-full">
                <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 pl-5 font-bold w-full">
                    <div className="w-2/12 text-center">{translate("CohId")}</div>
                    <div className="w-3/12 text-center">{translate("CohShort")}</div>
                    <div className="w-3/12 text-center">CNPJ</div>
                    <div className="w-2/12 text-center">{translate("AcceptMktLabel")}</div>
                    <div className="w-2/12 text-center">Status</div>
                </div>
            </div>
            <div id="resultBody" className="w-full">
                {!listOfCustomers || loading ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div> :
                    listOfCustomers.length === 0 ?
                        <div className="loader-wrapper min-h-full min-w-full p-5">
                            No Data Found
                        </div> :
                        listOfCustomers.map((customer, index) => {
                            return <Link to={`./customer/${customer.user_id}`} key={index + 'link'}>
                                <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 pl-5 w-full">
                                    <div className="w-2/12 text-center">{customer.skf_id}</div>
                                    <div className="w-3/12 text-center">{customer.name}</div>
                                    <div className="w-3/12 text-center">{customer.cnpj}</div>
                                    <div className="w-2/12 text-center">{customer.is_marketing_allowed === 0 ? "FALSE" : "TRUE"}</div>
                                    <div className="w-2/12 text-center flex justify-center">
                                        <Status
                                            key={index + 'status'}
                                            currentStatus={customer.status}
                                            clickable="true" />
                                    </div>
                                </div>
                            </Link>;
                        })}
            </div>
        </div>
        {
            !loading && maxPages ?
                <div className="page-selector flex-row-nowrap space-between">
                    <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                        {
                            !mobile && <p>{translate('PreviousLabel')}</p>
                        }
                    </div>
                    <div className="pages-section flex-row-nowrap">
                        {
                            pages
                        }
                    </div>
                    <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        {
                            !mobile && <p>{translate('NextLabel')}</p>
                        }
                        <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                    </div>
                </div> :
                null
        }

    </div>;
}

export default ListOfCompanies; 