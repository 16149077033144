import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import './Style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLanguage } from '../../context/LanguageContext';

function SearchBar(props) {
    const [dropDownChange, setDropDownChange] = useState("");
    const [selectedDate, setDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const { translate } = useLanguage();
   
    const past = (date) => new Date().setDate(new Date().getDate()) > date ;

    useEffect(() => {
        setDate(null)
        // Clear search bar
        if(dropDownChange != "payment" && dropDownChange != "status"){
            props.valueRef.current.value = ""
            props.valueRef.current.innerHTML = ""
        }
    }, [dropDownChange])

    useEffect(() => {
        selectedDate ? props.valueRef.current.value = selectedDate : props.valueRef.current.value = null
    }, [selectedDate])

    const handleChange = (range) => {
        const [startDate, endDate] = range;
        setStartDate(startDate);
        setEndDate(endDate);
        if(startDate != null && endDate != null){
            console.log(`Setting selected date: ${startDate.toISOString()} - ${endDate.toISOString()}`)
            setDate(`${startDate.toISOString()} - ${endDate.toISOString()}`)
        }
      };

    const handleReset = (e) => {
    
        e.preventDefault();
        props.reset()
        setDropDownChange("")
        if(props.valueRef.current != undefined){
            props.fieldRef.current.value = ""
            if(props.fieldStatusRef.current) props.fieldStatusRef.current.value = ""
            props.valueRef.current.value = ""
            props.valueRef.current.innerHTML = ""
        }
    
    }

    return (
        <form onSubmit={(e) => {
            props.onSearch(e);
        }}>
            <div id="searchBar" className="bg-gray-200 flex-row wrap-override h-30 my-6 p-6 rounded-md">
                <div className={`w-full md:w-2/6 md:mr-2 mt-2 md:mt-0 ${props.hideField == true ? 'hidden' : ''}`}>
                    <select name="searchFields"
                        className={`appearance-none bg-blue col-span-2 lg:text-base placeholder-white px-5 py-2 rounded-md text-xs w-full box-border ${props.error && props.fieldRef.current && !props.fieldRef.current.value ? "border border-amber-900" : "border-0"}`}
                        ref={props.fieldRef}
                        onChange={() => setDropDownChange(props.fieldRef.current.value)}>
                        <option value="">{translate('fieldSelect')}</option>
                        {
                            props.searchFields.map((e, index) => {
                                return <option value={e.fieldValue} key={index + 'field'}>{e.fieldName}</option>;
                            })
                        }
                    </select>
                </div>
                { dropDownChange && props.dropDownValues[dropDownChange] ?
                    <div className="w-full md:w-3/6 md:mr-2 mt-2 md:mt-0">
                        <select name="values"
                            className={`appearance-none bg-blue col-span-2 lg:text-base placeholder-white px-5 py-2 rounded-md text-xs w-full box-border ${dropDownChange && props.error && props.fieldStatusRef.current && !props.fieldStatusRef.current.value ? "border border-amber-900" : "border-0"}`}
                            ref={props.fieldStatusRef}                    >
                            <option value="">Select a value</option>
                            {
                                props.dropDownValues[dropDownChange].map((e, index) => {
                                    return <option value={e.status} key={index + 'status'}>{e.description}</option>;
                                })
                            }
                        </select>
                    </div> :
                    <div className="w-full md:w-3/6 md:mr-2 mt-2 md:mt-0">
                        {dropDownChange && (dropDownChange === "date" || dropDownChange === "billing_date") &&
                            <DatePicker
                                // selected={selectedDate ? new Date(selectedDate) : null}
                                selected={startDate}
                                // onChange={(value) => setDate(value.toISOString())}
                                onChange={handleChange}
                                startDate={startDate}
                                endDate={endDate}
                                filterDate={past}
                                selectsRange
                                monthsShown={2}
                                showYearDropdown
                                dateFormat="dd/MM/yyyy"
                                className={`bg-blue col-span-3 lg:text-base placeholder-white px-5 py-2 rounded-md  text-xs w-full box-border ${props.error && props.valueRef.current && !props.valueRef.current.value ? "border border-amber-900" : "border-0"}`} />
                        }
                        <input type="text"
                            className={`bg-blue col-span-3 lg:text-base placeholder-white px-5 py-2 rounded-md  text-xs w-full box-border ${props.error && props.valueRef.current && !props.valueRef.current.value ? "border border-amber-900" : "border-0"}`}
                            placeholder={translate('searchLabel')}
                            ref={props.valueRef}
                            // value={selectedDate ? selectedDate : null}
                            hidden={(dropDownChange === "date" || dropDownChange === "billing_date")}
                        />
                    </div>
                }

                <div className="w-full md:w-1/6 md:mr-2 mt-2 md:mt-0">
                    <button className="bg-blue cursor-pointer flex items-center justify-center lg:px-5 lg:text-base placeholder-white py-2 rounded-md text-xs w-full"
                        disabled={props.clicked}
                        type="submit" >
                        {props.clicked ? translate('searchingLabel') : translate('search')}
                    </button>
                </div>
                <div className="w-full md:w-1/6 mt-2 md:mt-0">
                    <button className="bg-blue cursor-pointer flex items-center justify-center lg:px-5 lg:text-base placeholder-white py-2 rounded-md text-xs w-full"
                        disabled={props.clicked}
                        onClick={(e) => handleReset(e)} >
                        {translate('ClearLabel')}
                    </button>
                </div>
            </div>
        </form>
    )
}

export default SearchBar; 