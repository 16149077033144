import React, { useEffect, useState } from "react";
import Status from "../../components/Status";
import Loader from "../../components/Loader";
import {useHistory, useParams} from "react-router-dom";
import API from "../../utils/API";
import Swal from 'sweetalert2';

function DetailedNotification() {

    const {id} = useParams();
    const history = useHistory();

    let cancelToken = API.cancelToken();
    const [notification, setNotification] = useState(null);
    const [priority, setPriority] = useState(null);
    const [loading, setLoading] = useState(false);

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    useEffect(() => {
        console.log(`Priority ${priority}`)
    }, [priority]);

    useEffect(() => {
        setLoading(true)
        API.getNotification(cancelToken, id).then(res => {
            console.log(res.data.notification)
            setNotification(res.data.notification)
            switch(res.data.notification.priority) {
                case 1:
                    setPriority('urgent')
                case 2:
                    setPriority('urgent')
                  break;
                case 3:
                    setPriority('warning')
                case 4:
                    setPriority('warning')
                  break;
                default:
                    setPriority('urgent-def')
              }
            setLoading(false)
        }).catch(err => {
            setNotification([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(
            f => {
                setLoading(false)
            }
        );
    }, []);

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">   
            <div id="header" className="flex-row justify-between my-4">
                <div className="flex-col">
                    <div className="text-2xl ">Notification #{id}</div>
                    <p className="text-neutral-400 p-1">{notification && notification.created_at ? databaseToDate(notification.created_at) : "..."}</p>
                </div>
                <div className="h-10 w-1/6 md:w-1/12">
                {
                    priority &&
                    <Status
                        currentStatus={priority ? priority : 'urgent'}
                        clickable="false" />

                }
                </div>
            </div>
            <div className="bg-gray-100 p-10">
                {!notification  &&
                            <div className="loader-wrapper min-h-full min-w-full">
                                <Loader light={true} />
                            </div>
                    }             
                <h1 className="text-2xl mb-6">{notification && notification.title ? notification.title : ""}</h1>
                <p className="font-thin text-neutral-400 mb-3">{notification && notification.subtitle ? notification.subtitle : ""}</p>
                <p className="font-thin text-neutral-400">{notification && notification.text ? notification.text : ""}</p>
            </div>
        </div>)
}

export default DetailedNotification;