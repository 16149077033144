import './style.css';

function Loader(props) {

    return (
        <div className="flex-wrapper flex-row-nowrap align-center flex-center">
            <div className={`${props.small===true? 'loader-small' : 'loader'} ${props.light===true? 'loader-light' : 'loader-bold'}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader;