import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

function FromToPage() {
    const [listOfOrder, setListOfOrder] = useState(null);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const valueRef = useRef();
    const fieldRef = useRef();
    const fieldStatusRef = useRef();
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false)

    const searchFields = [{ fieldValue: "product_from", fieldName: "Product From" },
    { fieldValue: "product_to", fieldName: "Product To" },
    { fieldValue: "status", fieldName: "Status" }];

    const dropDownValues = {
        status: [{ status: "pending", description: "Pending" },
        { status: "completed", description: "Completed" },
        { status: "error", description: "Error" },
        { status: "invalid", description: "Invalid" },]
    };

    useEffect(() => {
        let pages = []
        console.log(`maxPages: ${maxPages}`)
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setMobile(window.innerWidth <= 768);
        setLoading(true)

        let field = fieldRef.current.value;
        let value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;

        cancelToken = API.cancelToken();

        if (field !== "" && value != "") {
            API.getListOfFromTo(cancelToken,field, value).then(res => {
                console.log(res);
                console.log(`max_pages: ${res.data.max_pages}`)
                setMaxPages(res.data.max_pages)
                res.data.products ? setListOfOrder(res.data.products) : setListOfOrder([]);
            }).catch(err => {
                console.log(err)
                setListOfOrder([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(f => {
                setIsClicked(false);
                setLoading(false)
                setIsSearch(false)
            });
            
            return () => {
                API.cancel(cancelToken);
            }
        }else{
            API.getListOfFromTo(cancelToken).then(res => {
                console.log(res);
                console.log(`max_pages: ${res.data.max_pages}`)
                setMaxPages(res.data.max_pages)
                res.data.products ? setListOfOrder(res.data.products) : setListOfOrder([]);
            }).catch(err => {
                console.log(err)
                setListOfOrder([]);
                if (err.message === 'cancelling') return
                if (err.response && err.response.status === 500) {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }).finally(f => {
                setLoading(false)
            });
            
            return () => {
                API.cancel(cancelToken);
            }
        }
        
    }, [reset, isSearch]);





    const doReset = () => {
        setReset(!reset)
    }

    const handleSearch = (e) => {
        e && e.preventDefault();

        let value = fieldRef.current.value in dropDownValues ? fieldStatusRef.current.value : valueRef.current.value;
        let field = fieldRef.current.value;

        if (!value || value.trim() === '' || !field) {
            setError(true)
            return
        }
        setIsClicked(true);
        setIsSearch(true)
    }

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title="From To"
                svg="orders"
                hasButton="false" />

            
            <SearchBar
                searchFields={searchFields}
                valueRef={valueRef}
                fieldRef={fieldRef}
                fieldStatusRef={fieldStatusRef}
                onSearch={handleSearch}
                clicked={isClicked}
                dropDownValues={dropDownValues}
                error={error}
                reset={doReset} />  

            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-4/12 text-center">FROM</div>
                        <div className="w-4/12 text-center">TO</div>
                        <div className="w-4/12 text-center">Status</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!listOfOrder || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        listOfOrder.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            listOfOrder.map((product, index) => {
                                return <Link to={`./fromtoproduct/${product.from_to_product_id}`} key={index + 'link'}>
                                    <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 justify-around text-xs lg:text-base pl-5" key={index + 'order'}>
                                        <div className="w-4/12 text-center">{product.product_from}</div>
                                        <div className="w-4/12 text-center">{product.product_to}</div>
                                        <div className="w-4/12 text-center flex justify-center">
                                            <Status
                                                key={product.order_id + 'orderStatus'}
                                                id={product.order_id}
                                                currentStatus={product.status}
                                                clickable="true" />
                                        </div>
                                    </div>
                                </Link>;
                            })}
                </div>
            </div>
        </div>);
}

export default FromToPage; 