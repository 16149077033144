import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Dropdown from "../../components/DropDown";
import Status from "../../components/Status";
import Loader from "../../components/Loader";
import API from "../../utils/API";
import Swal from 'sweetalert2';
import { AppContext } from "../../utils/ContextProvider";
import './style.css'
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import * as XLSX from 'xlsx'

function Customer(props) {
    const { id } = useParams();
    const [serviceLinesDetails, setServiceLinesDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [serviceDetails, setServiceDetails] = useState(null)
    const [companies, setCompanies] = useState(null);
    let root_url = process.env.NODE_ENV === "staging" ? "https://staging.azpartes.com" : process.env.NODE_ENV === "production" ? "https://azpartes.com" : ""

    function handleChange(event) {
        const { name, value } = event.target;
        setServiceDetails(preValue => {
            return {
                ...preValue,
                [name]: value
            }
        });
    }

    function getData() {
        Swal.fire({
            title: 'Creating report...',
            showConfirmButton: false,
            allowOutsideClick: false
        })
        API.getServiceReport(cancelToken, id).then(res => {
            if (res.data) {
                var arr = [['Accessed By (Name)', 'Accessed By (Email)', 'Accessed By (Tag)', 'Service Description', 'Access Date']]
                console.log(res.data)
                res.data.forEach(line => {
                    arr.push([line['accessed_by_name'], line['accessed_by_email'], line['accessed_by_tag'], line['service_description'], line['accessed_date']])
                });
                setServiceLinesDetails(arr)
            } else {
                setServiceLinesDetails(null);
            }
        })
    }

    useEffect(() => {
        if (serviceLinesDetails && serviceLinesDetails.length > 0) {
            console.log("GET DATA")
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(serviceLinesDetails);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
            Swal.fire({
                title: 'Success.',
                text: 'Report sucessfully created.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, `service-id-${serviceDetails && serviceDetails.service_id}-access-report.xlsx`);
        }
    }, [serviceLinesDetails]);

    function sendPassword() {
        Swal.fire({
            title: 'Sending email...',
            showConfirmButton: false,
        })
        API.sendRecover(serviceDetails.email).then(res => {
            Swal.fire({
                title: 'Success',
                text: 'Recover email has been sent',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }).catch(err => {
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }

    function resetPassword() {
        Swal.fire({
            title: 'Reseting password...',
            showConfirmButton: false,
        })
        API.hardReset(serviceDetails.user_id).then(res => {
            Swal.fire({
                title: 'Success',
                text: `User's password has been reset to ${res.data.generated}`,
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }).catch(err => {
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }
    function inactivateUser() {
        Swal.fire({
            title: 'Working on it...',
            showConfirmButton: false,
        })
        API.inactivateCustomerUser(serviceDetails.user_id).then(res => {
            Swal.fire({
                title: 'Success',
                text: `This user is no longer active.`,
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setServiceDetails(prev => {
                return {
                    ...prev,
                    status: "Inactive"
                }
            })
        }).catch(err => {
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }

    const reactivateUser = () => {
        Swal.fire({
            title: 'Working on it...',
            showConfirmButton: false,
        })
        API.activateCustomerUser(serviceDetails.user_id).then(res => {
            Swal.fire({
                title: 'Success',
                text: `This user is now active.`,
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setServiceDetails(prev => {
                return {
                    ...prev,
                    status: "active"
                }
            })
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && (err.response.status === 412)) {
                let errorMessage = ""
                for (let item of err.response.data.error) {
                    errorMessage += (`<li>${item}</li>`)
                }
                Swal.fire({
                    title: 'Error.',
                    html: "<p>There are divergent fields in the company registration, please request correction as specified below.</p><ul>" + errorMessage + "</ul>",
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: err.response.data.error,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
    }


    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getAPIService(cancelToken, id).then(res => {
            console.log(res);
            res.data ? setServiceDetails(res.data.service) : setServiceDetails([]);
        }).catch(err => {
            setServiceDetails([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []
    );

    function saveCompanies() {
        if (companies === null) return
        setLoading(true);
        let payload = {
            "data": [
                {
                    "coh_companies": companies.split(","),
                    "user_id": id
                }
            ]
        }
        API.putCohIds(payload).then(res => {
            setLoading(false);
            Swal.fire({
                title: 'Success',
                text: 'Information updated!',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }).catch(err => {
            setLoading(false);
            if (err.message === 'cancelling') return
            if (err.response && (err.response.status === 404)) {
                Swal.fire({
                    title: 'Error.',
                    text: `Companies not found: ${err.response.data.failed_companies.join(",")}`,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else if (err.response && (err.response.status === 500 || err.response.status === 400)) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }

    function createNewToken(e, user_email, tag, current_token) {
        Swal.fire({
            title: `Creating new token for user ${user_email}...`,
            showConfirmButton: false,
            allowOutsideClick: false
        })
        API.generateServiceToken(API.cancelToken(), id, user_email, tag, current_token)
            .then(response => {
                console.log(response)
                if (response.status != 200) {
                    throw new Error(response.data.error)
                }
                location.reload()
            })
            .catch(error => {
                if (error.response) {
                    Swal.fire({
                        title: 'Error',
                        text: `${error.response.data.error}`,
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                }
            })
    }


    function revokeToken(e, service_token_id) {
        let cancelToken = API.cancelToken();
        Swal.fire({
            title: 'Revoke Access',
            text: 'Are you sure you want to cancel this token?',
            showCancelButton: true,
            confirmButtonText: 'YES',
            confirmButtonColor: "#131921",
            cancelButtonText: `NO`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Revoking token...',
                    showConfirmButton: false,
                    allowOutsideClick: false
                })
                API.revokeTokenAcces(cancelToken, service_token_id).then(res => {
                    Swal.fire({
                        title: 'Success',
                        text: 'Token revoked!',
                        confirmButtonColor: "#131921",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                }).catch(err => {
                    setLoading(false);
                    if (err.message === 'cancelling') return
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                });
            }
        })

    }

    function addUserToService() {
        let cancelToken = API.cancelToken();
        Swal.fire({
            title: 'Type the email and description of the user you want to add to the service',
            html:
                '<h2 class="text-left">Email</h2>' +
                '<input id="swal-input1" class="swal2-input">' +
                '<h2 class="mt-3 text-left">Tag</h2>' +
                '<input id="swal-input2" class="swal2-input">',
            showCancelButton: true,
            confirmButtonText: 'Generate Token',
            confirmButtonColor: "#010518",
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return new Promise(function (resolve) {
                    resolve({
                        email: document.getElementById("swal-input1").value,
                        tag: document.getElementById("swal-input2").value
                    })
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            let user_email = result.value.email.trim()
            let user_tag = result.value.tag.trim()
            if (user_email == "" || user_tag == "") {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please complete both fields.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Generating Token...',
                    showConfirmButton: false,
                    allowOutsideClick: false
                })
                API.generateServiceToken(API.cancelToken(), id, user_email, user_tag, null)
                    .then(response => {
                        console.log(response)
                        if (response.status != 200) {
                            Swal.fire({
                                title: 'Error.',
                                text: `${response.data.error}`,
                                confirmButtonColor: "#131921",
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            });
                        }
                        return location.reload()
                    })
                    .catch(error => {
                        if (error.response) {
                            Swal.fire({
                                title: 'Error.',
                                text: `${error.response.data.error}`,
                                confirmButtonColor: "#131921",
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            });
                        }
                    })
            }
        })
    }
    useEffect(() => {
        console.log(serviceDetails)
    }, [serviceDetails])

    return <div className="flex-col md:p-10 p-5 height-limiter">
        <div className="flex-row">
            <div className="flex align-center w-1/2">
                <h1 className="lg:text-xl text-xs ">API Service</h1>
                {serviceDetails && <div className="ml-5">
                    <Status
                        currentStatus={serviceDetails.service === "0" ? "Inactive" : (serviceDetails.service_status === "1" ? "Active" : "Unkown Status")}
                        clickable="false" />
                </div>}
            </div>
            <div className="w-1/2">
                <Dropdown title="Actions"
                    children={
                        [
                            { text: "Add token for user", action: () => addUserToService() },
                            { text: "Download Access Report", action: () => getData() }
                        ]
                    }
                />
            </div>
        </div>

        {serviceDetails && serviceDetails.error_message && <div id="errorMessage"
            className="bg-blue h-32 w-full rounded-md text-lightWite mt-5 p-5 lg:text-base text-xs">
            <p className="align-top">{serviceDetails.error_message}</p>
        </div>}
        {
            serviceDetails ?
                <div className="bg-blue rounded mt-10 text-xs lg:text-base md:p-10 p-5">
                    <div id="firstRow" className="flex-row wrap-override items-center w-full">
                        <div className="flex-row wrap-override items-center w-full">
                            <div className="flex-col w-full md:w-3/12 md:mr-2 mt-5 box-border">
                                <p>Service ID</p>
                                <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                    <p>{serviceDetails && (serviceDetails.service_id === null ? "" : serviceDetails.service_id)}</p>
                                </div>
                            </div>
                            <div className="flex-col w-full md:w-5/12 md:mr-2 mt-5 box-border">
                                <p>Service Description</p>
                                <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                    <p>{serviceDetails && (serviceDetails.service_description === null ? "" : serviceDetails.service_description)}</p>
                                </div>
                            </div>
                            <div className="flex-col w-full md:w-5/12 md:mr-2 mt-5 box-border">
                                <p>Service Owner</p>
                                <div className="w-full h-10 mt-1 bg-gray-200 rounded-md text-lightWite flex align-center pl-2">
                                    <p>{serviceDetails && (serviceDetails.service_owner === null ? "" : serviceDetails.service_owner)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-row items-center pt-5">
                        <div className="flex-col w-full">
                            <div className="flex-row w-full mb-4">
                                <p>Service Users</p>
                            </div>
                            <div className="border-2 border-lightWhite rounded mt-1 flex-col overflow-x-scroll ">
                                <div className="flex-row wrap-override bg-gray-200 p-5 ">
                                    <div className="flex-col w-full md:w-1/12 mt-1 text-center">
                                        <p>ID</p>
                                    </div>
                                    <div className="flex-col w-full md:w-4/12 mt-1 text-center">
                                        <p>Email</p>
                                    </div>
                                    <div className="flex-col w-full md:w-4/12 mt-1 text-center">
                                        <p>Token Creation Date</p>
                                    </div>
                                    <div className="flex-col w-full md:w-4/12 mt-1 text-center">
                                        <p>Tag</p>
                                    </div>
                                    <div className="flex-col w-full md:w-4/12 mt-1 text-center">
                                        <p>Number of Requests</p>
                                    </div>
                                    <div className="flex-col w-full md:w-4/12 mt-1 text-center">
                                        <p>Last Used</p>
                                    </div>
                                    <div className="flex-col w-full md:w-2/12 mt-1 text-center">
                                        <p></p>
                                    </div>
                                    <div className="flex-col w-full md:w-2/12 mt-1 text-center">
                                        <p></p>
                                    </div>

                                </div>
                                {
                                    serviceDetails && serviceDetails.service_users && Array.isArray(serviceDetails.service_users) ?
                                        (serviceDetails.service_users.length > 0 ?
                                            serviceDetails.service_users.map((e, index) => (
                                                <div className="flex-row wrap-override  overflow-y-scroll py-2 px-3">
                                                    <div className="flex-col w-full md:w-1/12 mt-5 text-center">
                                                        <p>{e.service_token_id && e.service_token_id}</p>
                                                    </div>
                                                    {/* <div className="flex-col w-full md:w-3/12 mt-5 md:mr-2 ">
                                                        <p>{e.name && e.name}</p>
                                                    </div> */}
                                                    <div className="flex-col w-full md:w-4/12 mt-5 text-center">
                                                        <p>{e.email && e.email}</p>
                                                    </div>
                                                    <div className="flex-col w-full md:w-4/12 mt-5 text-center">
                                                        <p>{e.created_at && e.created_at}</p>
                                                    </div>
                                                    <div className="flex-col w-full md:w-4/12 mt-5 text-center">
                                                        <p>{e.tag && e.tag}</p>
                                                    </div>
                                                    <div className="flex-col w-full md:w-4/12 mt-5 text-center">
                                                        <p>{e.number_of_requests && e.number_of_requests}</p>
                                                    </div>
                                                    <div className="flex-col w-full md:w-4/12 mt-5 text-center">
                                                        <p>{e.last_used && e.last_used}</p>
                                                    </div>
                                                    <div className="flex-col w-full my-2 md:w-2/12 pointer text-center justify-center">
                                                        <button className="bg-gray-200 text-xs lg:text-base cursor-pointer rounded-md p-1 h-10 flex justify-center items-center bg-red" onClick={(event, user) => revokeToken(event, e.service_token_id)}>Revoke Access</button>
                                                    </div>
                                                    <div className="flex-col my-2 md:w-2/12 pointer">
                                                        <button className="bg-gray-200 text-xs lg:text-base cursor-pointer rounded-md w-full h-10 flex justify-center items-center" onClick={(event, user) => createNewToken(event, e.email, e.tag, e.service_token_id)}>Reset Token</button>
                                                    </div>
                                                </div>
                                            )) :
                                            <p>No authorized users found</p>
                                        ) :
                                        <p>{serviceDetails.service_users && serviceDetails.service_users}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div>
        }
    </div>;
}

export default Customer;