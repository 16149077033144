import React, { useState, useEffect } from "react";
import Data from "../Data";
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
import API from "../../utils/API";

function UploadFile(props) {

    // on change states
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);
    let cancelToken = API.cancelToken();

    // submit
    const [excelData, setExcelData] = useState(null);

    useEffect(() => {
        console.log(excelData)
        if(excelData != undefined){
            var data_array = []
            excelData.forEach(line => {
                if (
                    typeof line[0] == undefined ||
                    line[0] == null ||
                    line[1] == undefined ||
                    line[1] == null ||
                    line[2] == undefined ||
                    line[2] == null ||
                    line[0] == '' || 
                    line[2] == '' || 
                    !Number.isInteger(line[2])
                ){
                    return;
                }
                data_array.push(
                    {"price_list":line[0],
                     "skf_product_id":line[1],
                     "pack":line[2],    
                    }
                )
            });

            if (data_array.length == 0){
                Swal.fire({
                    title:'No valid line on template.',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#1e2b3c",
                    icon: 'error',
                })
                return;
            }

            const data = {"packs":data_array}
            Swal.fire({
                title: `Do you want update ${data_array.length} packs?`,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: "#1e2b3c"
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Swal.fire({
                        title:'Updating packs...',
                        showConfirmButton: false,
                        allowOutsideClick: false
                    })
                    API.putProductPack(cancelToken, data).then(res => {
                        Swal.fire({
                            title: 'Success.',
                            text: 'Packs sucessfully updated.',
                            confirmButtonColor: "#1e2b3c",
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                    }).catch(err => {
                        if (err.message === 'cancelling') return
                        if (err.response && err.response.status === 500) {
                            Swal.fire({
                                title: 'Error.',
                                text: 'Please try again later.',
                                confirmButtonColor: "#1e2b3c",
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            });
                        }
                    })
                } else if (result.isDenied) {
                  Swal.fire('Changes are not saved', '', 'info')
                }
              })
        }
    }, [excelData])

    // handle File
    const processFile = async (file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const binaryString = evt.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });
            /* Get first worksheet */
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });
            /* Update state */
            data.splice(0, 1)
            setExcelData(data);
        };
        reader.readAsBinaryString(file);
    }

    const onDrop = (e) => {
        e.preventDefault();
        let file = e.dataTransfer.files[0];
        processFile(file);
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
    }

    const onSelect = (e) => {
        e.preventDefault();
        let file = e.currentTarget.files[0];
        processFile(file);
    }

    const onDragEnter = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = '#131930';
        e.currentTarget.style.border = '1px solid white';
    }
    const onDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed white';
    }

    return <div id="uploadFile">
        <div className="pt-10 pb-6">
            {props.title}
        </div>
        {excelFileError && <div className="py-2 text-red-600">{excelFileError}</div>}
        <div className="bg-blue flex justify-center align-center h-96 rounded text-xs lg:text-base">
            <div
                onClick={() => { document.getElementById('getFile').click() }}
                onDrop={e => onDrop(e)}
                onDragEnter={e => onDragEnter(e)}
                onDragLeave={e => onDragLeave(e)}
                onDragStart={e => e.preventDefault()}
                onDragEnd={e => e.preventDefault()}
                onDragOver={e => onDragEnter(e)}
                onDrag={e => e.preventDefault()}
                className="flex w-40 h-40 text-center border-white border border-dashed rounded-md justify-center items-center cursor-pointer">
                <input type='file' name="getFile" id="getFile" style={{ display: "none" }}  onChange={(e) => onSelect(e)} />
                <div>
                    <h1>DROP HERE</h1>
                    <h1>OR</h1>
                    <h1>SELECT A FILE</h1>
                </div>
            </div>
        </div>
    </div>
}

export default UploadFile; 