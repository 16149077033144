import React, { useEffect, useRef, useState, useContext } from "react";
import Status from "../../components/Status";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { hotjar } from 'react-hotjar';
import GlobalSVG from '../../utils/GlobalSVG';
import ReactGA from 'react-ga';

function ListOfMaintenance() {
    const [listOfMaintenance, setListOfMaintenance] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [loading, setLoading] = useState(true);
    const [isClicked, setIsClicked] = useState(false);
    const fieldStatusRef = useRef();
    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false)
    const [sorting, setSorting] = useState("desc")
    const username = context.getEmail;
    const searchFields = [{ fieldValue: "date", fieldName: "Date" },
    { fieldValue: "order_number", fieldName: "COH Order ID" },
    { fieldValue: "customer_number", fieldName: "COH COH Customer ID" },
    { fieldValue: "customer_name", fieldName: "COH Short Name" },
    { fieldValue: "client_order_id", fieldName: "Customer Order Number" },
    { fieldValue: "payment", fieldName: "Payment Method" },
    { fieldValue: "status", fieldName: "Order Status" },
    { fieldValue: "nf_number", fieldName: "Invoice Number" },
    { fieldValue: "nf_key", fieldName: "Invoice Key Access" },
    { fieldValue: "billing_date", fieldName: "Billing Date" }];

    const statusDictionary = {
        "Pedido Concluido": "Completed",
        "Pedido Cancelado": "Canceled",
        "Pagamento Expirado": "Expired Payment",
        "Em processamento": "Processing",
        "Aguardando Registro COH": "Awaiting COH Registration",
        "Aguardando Pagamento": "Awaiting Payment",
        "Pagamento Não Autorizado": "Payment Not Authorized",
        "Erro": "Error",
    }

    const maintenanceList = [
        { "maintenance_id": "1", "requester_name": "John Doe", "start_date": "2024-01-01", "end_date": "2024-01-05", "status": "overdue" },
        { "maintenance_id": "2", "requester_name": "John Doe", "start_date": "2024-02-01", "end_date": "2024-02-05", "status": "overdue" },
        { "maintenance_id": "3", "requester_name": "John Doe", "start_date": "2024-03-01", "end_date": "2024-03-05", "status": "overdue" },
        { "maintenance_id": "4", "requester_name": "John Doe", "start_date": "2024-04-01", "end_date": "2024-04-05", "status": "active" },
    ]


    function replaceStatusString(input) {
        let output = input;
        for (const key in statusDictionary) {
            const value = statusDictionary[key];
            const regex = new RegExp(key, 'gi');
            output = output.replace(regex, value);
        }
        return output;
    }

    const dropDownValues = {
        status: [{ status: "received", description: "Awaiting COH Registration" },
        { status: "processing", description: "Processing" },
        { status: "completed", description: "Completed" },
        { status: "payment", description: "Awaiting Payment" },
        { status: "cancelled", description: "Canceled" },
        { status: "expired", description: "Expired Payment" },
        { status: "denied", description: "Payment Not Authorized" },
        { status: "erro", description: "Erro" },
        ],
        payment: [{ status: "1", description: "Credit Limit" }, { status: "2", description: "Credit Card" }]
    };

    const paymentMethod = new Map([[1, 'Credit Limit'], [2, 'Credit Card']])

    function databaseToDate(value) {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[0]}-${date[1]}-${date[2]}`;
    }


    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setMobile(window.innerWidth <= 768);

        cancelToken = API.cancelToken();

        setLoading(true)

        setLoading(true)
        API.listAllMaintenances(cancelToken, currentPage, sorting).then(res => {
            console.log(res.data)
            res.data ? setListOfMaintenance(res.data) : setListOfMaintenance([]);
            setLoading(false)
        }).catch(err => {
            setListOfMaintenance([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            // setLoading(false)
        });


        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, reset, isSearch, sorting]);

    function getStatus(maintenanceObject) {
        const { status, end_date } = maintenanceObject;
        const today = new Date();
        const endDate = new Date(end_date);
        console.log(`maintenanceObject: ${maintenanceObject}`)
        console.log(`status: ${status}`)
        console.log(`end_date: ${end_date}`)

        // Rule 1: If status == 0 then return "inactive"
        if (status === 0) {
            return "inactive";
        }

        // Rule 2: if end_date > today's date then return "overdue"
        if (endDate < today) {
            return "overdue";
        }

        // Rule 3: if status == 1 then return 'active'
        if (status === 1) {
            return "active";
        }

        // Default case, if none of the above rules apply
        return "unknown";
    }


    useEffect(() => {
        let pages = []
        console.log(`maxPages: ${maxPages}`)
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])


    const doReset = () => {
        console.log("resetting")
        setReset(!reset)
    }

    const sortArrow = () => {
        if (sorting == "asc") {
            setSorting("desc")
            setCurrentPage(1)
            localStorage.setItem('sorting', "desc");
        } else {
            setSorting("asc")
            setCurrentPage(1)
            localStorage.setItem('sorting', "asc");
        }
    }

    function extractDate(dateTimeString) {
        // Check if the input is the special case of "0000-00-00 00:00:00"
        if (dateTimeString === "0000-00-00 00:00:00") {
            return "Invalid Date";
        }
        
        // Split the date and time parts
        const [datePart, timePart] = dateTimeString.split(" ");
    
        // Return just the date part
        return datePart;
    }

    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title="Maintenance"
                link="../addmaintenance"
                buttonDescription="Add maintenance"
                svg="orders"
                hasButton="true" />


            <div className="overflow-auto">
                <div id="resultTable" className="flex-col justify-between">
                    <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 text-xs lg:text-base pl-5 font-bold">
                        <div className="w-2/12 text-center">Maintenance ID</div>
                        <div className="w-3/12 text-center">Requester</div>
                        <div className="w-2/12 text-center">Start Date</div>
                        <div className="w-2/12 text-center">End Date</div>
                        <div className="w-3/12 text-center">Status</div>
                    </div>
                </div>
                <div id="resultBody">
                    {!listOfMaintenance || loading ?
                        <div className="loader-wrapper min-h-full min-w-full">
                            <Loader light={true} />
                        </div> :
                        listOfMaintenance.length === 0 ?
                            <div className="loader-wrapper min-h-full min-w-full p-5">
                                No Data Found
                            </div> :
                            listOfMaintenance.map((order, index) => {
                                return <Link to={`./AddMaintenance?id=${order.maintenance_id}`} key={index + 'link'}>
                                    <div id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 text-xs lg:text-base pl-5" key={index + 'order'}>
                                        <div className="w-2/12 text-center">{order.maintenance_id}</div>
                                        <div className="w-3/12 text-center">{order.requester_name}</div>
                                        <div className="w-2/12 text-center">{extractDate(order.start_date)}</div>
                                        <div className="w-2/12 text-center">{extractDate(order.end_date)}</div>
                                        <div className="w-3/12 text-center flex justify-center">
                                            <Status
                                                key={order.order_id + 'orderStatus'}
                                                id={order.order_id}
                                                currentStatus={getStatus(order)}
                                                clickable="true" />
                                        </div>
                                    </div>
                                </Link>;
                            })
                    }
                </div>
            </div>

        </div>);
}

export default ListOfMaintenance; 