export default function SelectField(props) {
    return (
        <div class="flex-col w-full">
            <p>{props.label}</p>
            <select name={props.name || "select"}
                onChange={(e) => props.setValue(e.target.value)}
                defaultValue={""}
                disabled={props.disabled}
                value={props.value || ""}
                className={`appearance-none bg-[#0C1825] p-5 text-[#FFF] h-10 mt-2 col-span-2 lg:text-base text-[#FFF] placeholder-[#2D4560] ${props.error ? "border border-amber-900" : "border-0"} px-5 py-2 rounded-md text-xs w-full box-border "border-0"}`}>
                <option className="text-[#2D4560]" disabled={true} value= {""}>SELECT</option>
                {props.options && props.options.map((elem) => {
                    return <option className="text-[#2D4560]" selected={false} disabled={false} value={elem}>{elem}</option>
                })}
            </select>
        </div>
    )
}