import React, { useEffect, useState, useContext } from "react";
import LineSplitDetails from "../../components/LineSplitDetails";
import ScreenHeader from "../../components/ScreenHeader";
import API from "../../utils/API";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import './style.css'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { set } from "react-ga";

function CreateTag() {

    let cancelToken = API.cancelToken();
    const context = useContext(AppContext);
    const history = useHistory();
    const email = context.getEmail;
    const username = context.getUser;
    const [pages, setPages] = useState([]);
    const location = useLocation();
    const [sendButtonText, setSendButtonText] = useState("SEND");
    const [fileNames, setFileNames] = useState("");
    const [tagHeader, setTagHeader] = useState("");
    const [tagBody, setTagBody] = useState("");
    const [tagName, setTagName] = useState("");
    const [tagAttachments, setTagAttachments] = useState([]);


    function addPage() {
        setPages([...pages, ""]);
    }
    
    function removePage(indexToRemove) {
        console.log(`Removing page ${indexToRemove}`)
        setPages(currentPages => currentPages.filter((_, index) => index !== indexToRemove));
    }
    function handleSubmit() {
        if (sendButtonText !== "SEND") return;
        setSendButtonText("SENDING...");
        // Check if all required fields are filled
        if (!tagName.trim() || pages.length === 0 || !tagHeader.trim() || !tagBody.trim()) {
            // Identify which fields are missing
            let missingFields = [];
            if (!tagName.trim()) missingFields.push("Tag Name");
            if (pages.length === 0) missingFields.push("At least one page");
            if (!tagHeader.trim()) missingFields.push("Tag Head");
            if (!tagBody.trim()) missingFields.push("Tag Body");
    
            // Show alert with missing fields
            Swal.fire({
                title: 'Error',
                text: `Please fill in the following fields: ${missingFields.join(', ')}`,
                confirmButtonColor: '#1e2b3c',
                icon: 'error',
                confirmButtonText: 'OK',
            });
            setSendButtonText("SEND");
            return; // Stop the function from proceeding
        }
    
        // Proceed with form submission logic if all fields are filled
        console.log("Submitting form with data:", { tagName, pages, tagHeader, tagBody });
        // Example: Send data to server here
    
        const cancelToken = API.cancelToken();
        const body = {
            "tag_name":tagName,
            "request_name": username,
            "request_email": email,
            "tag_header": tagHeader,
            "tag_body": tagBody,
            "pages": pages.join(","),
        }
        API.postTag(cancelToken, body).then((response) => {
            Swal.fire({
                title: 'Request successfully sent!',
                icon: 'success',
                confirmButtonText: 'Back to TAG Manager',
                cancelButtonText: 'Add Another Tag',
                showCancelButton: true,
                cancelButtonColor: '#1e2b3c',
                confirmButtonColor: '#373d45',
            }).then((result) => {
                if (result.isConfirmed) {
                    history.push('/tags'); // Navigate back to TAG Manager
                } else{
                    window.location.reload()
                }
            });
        }).catch((error) => {
            Swal.fire({
                title: 'Error',
                text: `An error occurred while sending the request. Please try again later or contact I.T support.`,
                confirmButtonColor: '#1e2b3c',
                icon: 'error',
                confirmButtonText: 'OK',
            });
            setSendButtonText("SEND");
        })
    }

    function editName(index) {
        return (e) => {
            console.log(`Editing page ${index} to ${e.target.value}`)
            let newPages = [...pages];
            newPages[index] = e.target.value;
            setPages(newPages);
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if (name === 'tagHead') {
            setTagHeader(value);
        }
        if (name === 'tagBody') {
            setTagBody(value);
        }
        if (name === 'tagName') {
            setTagName(value);
        }

    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to Array
        const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
    
        // Filter files that exceed the size limit and collect their names
        const exceededFiles = files.filter(file => file.size > maxFileSize).map(file => file.name);
    
        // Filter files that are within the size limit
        const validFiles = files.filter(file => file.size <= maxFileSize);
    
        // Update your state with the valid file names
        const fileNames = validFiles.map(file => file.name).join(", ");
        setFileNames(fileNames);
    
        // Notify the user about the files that exceeded the limit
        if (exceededFiles.length > 0) {
            const exceededFileNames = exceededFiles.join(", ");
            alert(`The following files were removed because they exceed the 2MB size limit: ${exceededFileNames}`);
        }
    
        // Optional: Handle valid files as needed
    };
    function handleUploadClick() {
        // Trigger file upload logic here
        // This might involve preparing FormData and sending it to a server
        console.log("Upload button clicked");
    }


    return (
        <div className="items-stretch bg-slate-950 flex flex-col tag-form mb-5">
            <div className="text-white text-2xl font-medium leading-10 whitespace-nowrap ml-11 mt-11 self-start max-md:ml-2.5 max-md:mt-10">
                TAG MANAGER - ADD TAG
            </div>
            <div className="justify-center bg-slate-800 flex flex-col mt-4 mx-11 p-8 items-start max-md:max-w-full max-md:mr-2.5 max-md:px-5">
                <div className="text-white text-base font-light leading-5">
                    Tag name
                </div>
                <input className="items-start rounded bg-gray-900 flex w-[300px] shrink-0 max-w-full h-10 flex-col mt-1 pl-3" name="tagName" onChange={handleChange}  />
                <div className="items-stretch self-stretch flex justify-between gap-4 mt-4 max-md:max-w-full max-md:flex-wrap">
                    <div className="items-stretch flex flex-col flex-1 max-md:max-w-full">
                        <div className="text-white text-base font-light leading-5 max-md:max-w-full">
                            Request name
                        </div>
                        <input className="items-start rounded bg-gray-900 flex shrink-0 h-10 flex-col mt-1 max-md:max-w-full" value={username} />
                    </div>
                    <div className="items-stretch flex flex-col flex-1 max-md:max-w-full">
                        <div className="text-white text-base font-light leading-5 max-md:max-w-full">
                            Request e-mail
                        </div>
                        <input className="items-start rounded bg-gray-900 flex shrink-0 h-10 flex-col mt-1 max-md:max-w-full" value={email} />
                    </div>
                </div>
                <div className="text-white text-base font-light leading-5 mt-4">
                    Tag head
                </div>
                <input className="items-start rounded bg-gray-900 flex w-[400px] shrink-0 max-w-full h-10 flex-col mt-2" name="tagHead" onChange={handleChange} />
                <div className="self-stretch mt-4 max-md:max-w-full">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[67%] max-md:w-full max-md:ml-0">
                            <div className="items-stretch flex grow flex-col max-md:max-w-full max-md:mt-4">
                                <div className="text-white text-base font-light leading-5 max-md:max-w-full">
                                    Tag body
                                </div>
                                <textarea className="items-start rounded bg-gray-900 flex shrink-0 h-[200px] flex-col mt-2 max-md:max-w-full" name="tagBody" onChange={handleChange}  />
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch flex flex-col max-md:max-w-full max-md:mt-4">
                                <div className="text-white text-base font-light leading-5 max-md:max-w-full">
                                    Pages
                                </div>
                                {
                                    pages && pages.length > 0 &&
                                    pages.map((page, index) => {
                                        return (
                                            <div className="items-stretch flex justify-between gap-3 mt-2 max-md:max-w-full max-md:flex-wrap">
                                                <div className="text-white text-base my-auto">
                                                    {index + 1}.
                                                </div>
                                                <input className="text-white text-base leading-5 items-stretch rounded bg-gray-900 grow justify-center" onChange={editName(index)} />
                                                <div className="w-1/11 justify-center items-stretch bg-slate-950 flex gap-1 px-5 py-3.5 rounded-md self-end " onClick={() => removePage(index)}>
                                                    <button className="text-white text-base leading-5 grow whitespace-nowrap self-start pointer">
                                                        -
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="justify-center items-stretch bg-slate-950 flex gap-1 mt-2 px-5 py-2 rounded-md self-end w-full" onClick={addPage}>
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d2ee012fceedcab7975cae153da30ec1866da2573dce9f35de2e2aa001c521f?"
                                        className="aspect-square object-contain object-center w-6 shrink-0"
                                    />
                                    <button className="text-white text-base leading-5 grow whitespace-nowrap self-start pointer">
                                        Add page
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-white text-xl font-bold leading-7 mt-6">
                    Attachments
                </div>
                <div className="flex items-stretch w-[418px] max-w-full gap-0 mt-4">
                    <label className="bg-gray-900 text-slate-700 text-base leading-5 whitespace-nowrap grow justify-center pl-5 pr-16 py-2.5 rounded items-start max-md:pr-5 cursor-pointer">
                        {fileNames || "Select file to upload"}
                        <input type="file" onChange={handleFileChange} style={{ display: 'none' }} multiple />
                    </label>
                    {/* <button className="text-white text-base leading-5 whitespace-nowrap justify-center items-stretch bg-slate-950 px-5 py-2.5 rounded-none" onClick={handleUploadClick}>
                        Upload files
                    </button> */}
                </div>
            </div>
            <div className="flex justify-end items-stretch gap-4 mt-6 px-16 max-w-full max-md:flex-wrap max-md:px-5">
                <Link to="./tags" className="text-white text-center text-base font-medium leading-5 grow my-auto pointer">
                    CANCEL
                </Link>
                <div className="text-white text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch bg-slate-800 grow px-6 py-4 rounded-md max-md:px-5 pointer" onClick={handleSubmit}>
                    {sendButtonText}
                </div>
            </div>
        </div>
    );
}

export default CreateTag; 