import React, { useState, useEffect, useContext } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import { Link, useHistory, useParams } from "react-router-dom";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';



function EditProduct() {

    const history = useHistory();
    const {id} = useParams();
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState(false);
    const [pk, setPk] = useState(false);
    let cancelToken = API.cancelToken();


    function handleChange(event) {
        const pk = event.target.value;
        setPk(pk)
    }

    //putProductPack

    function insertPack(e) {
        e && e.preventDefault();
        Swal.fire({
            title:'Updating pack...',
            showConfirmButton: false,    
        })
        const data = {"packs":[{
            "skf_product_id":product.skf_product_id,
            "pack":pk,
            "price_list":product.price_list
        }]}
        API.putProductPack(cancelToken, data).then(res => {
            Swal.fire({
                title: 'Success.',
                text: 'Pack has been updated.',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }).catch(err => {
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        })
    }
    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        const params = new URLSearchParams(location.search);
        var type = ''
        var pricelist = ''
        if(params.get('t')){
            type = params.get('t')
        }
        if(params.get('pricelist')){
            pricelist = params.get('pricelist')
        }
        setLoading(true)
        console.log(type)
        API.getProductDisplay(cancelToken, 1, id.trim(), type, pricelist, 0, 0, 0, 0 ,0).then(res => {
            res.data.products ? setProduct(res.data.products[0]) : setList([]);
        }).catch(err => {
            setProduct(false);
            console.log("Error")
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(f => {
            setLoading(false)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []);
    
    return (
        <div className="flex-col md:p-10 p-5 orders-page height-limiter">
            <ScreenHeader
                title={decodeURIComponent(id)}
                link="" />
            {!product || loading ?
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div> 
                :
            <div className="bg-gray-100 px-8 py-4 rounded-2xl">
                <div className="flex flex-col gap-x-4 md:flex-row flex-nowrap">
                    <div className="flex flex-col w-full md:w-1/5 my-2">
                        <label className="py-1">Price List</label>
                        <input name="priceList" placeholder="" value={product.price_list ? product.price_list.toUpperCase() : ''} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                    </div>
                    <div className="flex flex-col w-full md:w-1/5 my-2">
                        <label className="py-1">SKU</label>
                        <input name="sku" placeholder="" value={product.skf_product_id ? product.skf_product_id : ''} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                    </div>
                    <div className="flex flex-col w-full md:w-1/5 my-2">
                        <label className="py-1">Dimensions</label>
                        <input name="dimensions" placeholder="" value={product.dimension ? product.dimension : ''} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                    </div>
                    <div className="flex flex-col w-full md:w-1/5 my-2">
                        <label className="py-1">Catalogue information</label>
                        <input name="catalogInfo" placeholder="" value={product.origin ? product.origin : ''} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                    </div>
                    <div className="flex flex-col w-full md:w-1/5 my-2">
                        <label className="py-1">Availability</label>
                        <input name="availability" placeholder="" value={product.stock ? product.stock : ''} className="px-2 h-10 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" />
                    </div>
                </div>
                <div className="p-1 mt-4 text-l">Update Product Information</div>
                <div className="flex flex-col rounded-lg border-2 px-8 py-8 mb-6">
                    <label>Pack</label>
                    <div className="flex flex-col md:flex-row gap-4">
                        <input disabled={product.pk == 'N/A' ? true : false}  type="text" name="pk" onChange={handleChange} className="px-2 h-10 w-full lg:w-1/3 bg-gray-200 rounded-md placeholder-neutral-400 cursor-pointer" defaultValue={product.pk ? product.pk == 'not found' ? '' :  product.pk : ''}/>
                        <button disabled={product.pk == 'N/A' ? true : false} onClick={(e) => insertPack(e)} className="w-full lg:w-1/3 h-10 bg-gray-700 rounded-md">SAVE PRODUCT INFORMATION</button>
                    </div>
                </div>
            </div>
            
                }

        </div>);
}

export default EditProduct; 