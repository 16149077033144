import React, { useState, useRef, useEffect, useContext } from "react";
import SearchBar from "../../components/Searchbar";
import ScreenHeader from "../../components/ScreenHeader";
import './style.css'
import Status from "../../components/Status";
import Loader from "../../components/Loader";
import { Link, useHistory } from "react-router-dom";
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from 'sweetalert2';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import GlobalSVG from '../../utils/GlobalSVG';


function ExcludedIds() {
    const [listOfExclusions, setListOfExclusions] = useState(null);
    const context = useContext(AppContext);
    const history = useHistory();
    let cancelToken = API.cancelToken();
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState("");
    const [field, setField] = useState("");
    const [maxPages, setMaxPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [editing, setEditing] = useState(null)
    const [force, setForce] = useState(true)
    const [editBody, setEditBody] = useState({ type: "", coh_id: "", country: "", reason: "", bi_exclusion_id: null })
    const [insertBody, setInsertBody] = useState([{ type: "", coh_id: "", country: "", reason: "" }])

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if (editing !== null) return
        setMobile(window.innerWidth <= 768);
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);

        setLoading(true)
        cancelToken = API.cancelToken();

        API.getExclusion(cancelToken, currentPage, field, sort).then(res => {
            if (res.data.exclusions) {
                setListOfExclusions(res.data.exclusions)
            } else {
                setListOfExclusions([]);
            }
            setMaxPages(res.data.pagination.total_pages)
        }).catch(err => {
            setListOfExclusions([]);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(
            f => {
                setLoading(false)
            });
        return () => {
            API.cancel(cancelToken);
        }
    }, [currentPage, sort, field, editing, force]);


    const handleSort = (e) => {
        if (e === field) {
            sort === "ASC" ? setSort("DESC") : setSort("ASC")
        } else {
            setField(e)
            setSort("ASC")
        }
    }

    useEffect(() => {
        let pages = []
        if (maxPages <= 7) {
            for (let i = 1; i <= maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${currentPage === 1 && 'active'}`} onClick={() => setCurrentPage(1)}>{1}</div>)
            if (currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'page' + currentPage - 1} className={`page-num`} onClick={() => setCurrentPage(currentPage - 1)}>{currentPage - 1}</div>)
                    pages.push(<div key={'page' + currentPage} className={`page-num active`} onClick={() => setCurrentPage(currentPage)}>{currentPage}</div>)
                    pages.push(<div key={'page' + currentPage + 1} className={`page-num`} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = maxPages - 4; i <= maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${currentPage === i && 'active'}`} onClick={() => setCurrentPage(i)}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${currentPage === 2 && 'active'}`} onClick={() => setCurrentPage(2)}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${currentPage === 3 && 'active'}`} onClick={() => setCurrentPage(3)}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${currentPage === 4 && 'active'}`} onClick={() => setCurrentPage(4)}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${currentPage === 5 && 'active'}`} onClick={() => setCurrentPage(5)}>{5}</div>)
                if (maxPages - currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${currentPage === maxPages && 'active'}`} onClick={() => setCurrentPage(maxPages)
            }> {maxPages}</div >)
        }

        setPages(pages);
    }, [maxPages, currentPage])

    useEffect(() => {
        if (!listOfExclusions || editing === null) return
        let tempBody = listOfExclusions[editing]
        setEditBody({ ...tempBody })
    }, [editing])

    const handleEdit = (e) => {
        const { name, value } = e.target;
        setEditBody(current => { return { ...current, [name]: value.trim() } })
    }

    const handleCreate = (e) => {
        const { name, value } = e.target;
        setInsertBody(current => { return [{ ...current[0], [name]: value.trim() }] })
    }

    useEffect(() => {
        console.log(insertBody)
    }, [insertBody])

    const submitEdit = () => {
        API.cancel(cancelToken);
        cancelToken = API.cancelToken();
        setLoading(true)
        API.editExlusion(cancelToken, editBody).then(res => {
            Swal.fire({
                title: 'Success.',
                text: 'Order successfully updated.',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setEditing(null)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err.response && err.response)
            if (err.message === 'cancelling') return
            if (err.response && err.response.status >= 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: err.response.data.error,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        })
    }

    const submitInsert = (e) => {
        e.preventDefault()
        let button = e.currentTarget
        button.disabled = true
        button.innerHTML = "Adding..."
        cancelToken = API.cancelToken();
        setLoading(true)
        API.createExlusion(cancelToken, insertBody).then(res => {
            console.log(res)
            let error = ""
            if (res.data.errors !== null){
                error = res.data.errors
            }
            if (res.data.existent !== null){
                error = res.data.existent
            }
            Swal.fire({
                title: error ? "Warning." : 'Success.',
                text: error ? error : 'Order successfully inserted.',
                confirmButtonColor: "#131921",
                icon: error ? "warning" : 'success',
                confirmButtonText: 'Ok'
            });
            setLoading(false)
            button.disabled = false
            button.innerHTML = "Add"
            setForce(!force)
        }).catch(err => {
            setLoading(false)
            button.disabled = false
            button.innerHTML = "Add"
            console.log(err.response && err.response)
            if (err.message === 'cancelling') return
            if (err.response && err.response.status >= 500) {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: err.response.data.error,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        })
    }


    const handleDelete = (id, coh_id) => {
        let cancelToken = API.cancelToken();
        Swal.fire({
            title: 'Delete ID',
            text: `Are you sure you want to delete ${coh_id}?`,
            showCancelButton: true,
            confirmButtonText: 'YES',
            confirmButtonColor: "#131921",
            cancelButtonText: `NO`,
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                API.deleteExlusion(cancelToken, id).then(res => {
                    setLoading(false)
                    setForce(!force)
                }).catch(err => {
                    setLoading(false)
                    console.log(err.response && err.response)
                    if (err.message === 'cancelling') return
                    if (err.response && err.response.status >= 500) {
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    } else {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                })
            }
        })
    }

    return <div className="flex-col md:p-10 p-5  customers-page height-limiter">
        <ScreenHeader
            title="Qlikview Deleted IDs"
            link="../bulkexclusion"
            buttonDescription="Bulk Add ID's"
            svg="ListOfCustomers"
            hasButton="true" />

        <form>
            <div id="searchBar" className="bg-gray-200 flex-row wrap-override h-30 my-6 p-6 rounded-md">
                <div className="w-full md:w-1/5 md:mr-2 mt-2 md:mt-0">
                    <select onChange={handleCreate} name="country" className="bg-blue cursor-pointer flex items-center justify-between w-full h-full rounded-md py-2 px-5 text-xs md:text-base">
                        <option value="" >Country</option>
                        <option value="Brazil" >Brazil</option>
                        <option value="Argentina" >Argentina</option>
                    </select>
                </div>
                <div className="w-full md:w-1/5 md:mr-2 mt-2 md:mt-0">
                    <select onChange={handleCreate} name="type" className="bg-blue cursor-pointer flex items-center justify-between w-full h-full rounded-md py-2 px-5 text-xs md:text-base">
                        <option value="" >Type</option>
                        <option value="order" >Order</option>
                        <option value="customer" >Customer</option>
                    </select>
                </div>
                <div className="w-full md:w-2/5 md:mr-2 mt-2 md:mt-0">
                    <input
                        onChange={handleCreate}
                        name="coh_id"
                        maxLength={insertBody && insertBody[0].type == "order" ? 10 : 8}
                        type="text"
                        className={`bg-blue col-span-3 md:text-base placeholder-white px-5 py-2 rounded-md  text-xs w-full box-border `}
                        placeholder="COH COH Customer ID"
                    />
                </div>
                <div className="w-full md:w-2/5 md:mr-2 mt-2 md:mt-0">
                    <input
                        onChange={handleCreate}
                        name="reason"
                        type="text"
                        className={`bg-blue col-span-3 md:text-base placeholder-white px-5 py-2 rounded-md  text-xs w-full box-border `}
                        placeholder="Reason"
                        maxLength="30"
                    />
                </div>

                <div className="w-full md:w-1/5 md:mr-2 mt-2 md:mt-0">
                    <button onClick={submitInsert} className="bg-blue cursor-pointer flex items-center justify-center md:px-5 md:text-base placeholder-white py-2 rounded-md text-xs w-full"
                        type="submit" >
                        Add
                    </button>
                </div>
            </div>
        </form>

        <div className="text-xs lg:text-base overflow-auto">
            <div id="resultTable" className="flex-col justify-between w-full">
                <div id="tableHeader" className="bg-gray-200 flex-row justify-around py-5 pl-5 font-bold w-full">
                    <div className="w-1/5 pointer flex flex-row flex-nowrap align-center justify-center" onClick={() => handleSort("type")}>
                        Type
                        <div className="ml-2">
                            {field === "type" ? (sort === "ASC" ? GlobalSVG.sortArrowAsc() : GlobalSVG.sortArrowDesc()) : ""}
                        </div>
                    </div>
                    <div className="w-1/5 pointer flex flex-row flex-nowrap align-center justify-center" onClick={() => handleSort("coh_id")}>
                        COH COH Customer ID
                        <div className="ml-2">
                            {field === "coh_id" ? (sort === "ASC" ? GlobalSVG.sortArrowAsc() : GlobalSVG.sortArrowDesc()) : ""}
                        </div>
                    </div>
                    <div className="w-1/5 pointer flex flex-row flex-nowrap align-center justify-center" onClick={() => handleSort("country")}>
                        Country
                        <div className="ml-2">
                            {field === "country" ? (sort === "ASC" ? GlobalSVG.sortArrowAsc() : GlobalSVG.sortArrowDesc()) : ""}
                        </div>
                    </div>
                    <div className="w-1/5 pointer flex flex-row flex-nowrap align-center justify-center" onClick={() => handleSort("reason")}>
                        Reason
                        <div className="ml-2">
                            {field === "reason" ? (sort === "ASC" ? GlobalSVG.sortArrowAsc() : GlobalSVG.sortArrowDesc()) : ""}
                        </div>
                    </div>
                    <div className="w-1/5"></div>
                </div>
            </div>
            <div id="resultBody" className="w-full">
                {!listOfExclusions || loading === true ?
                    <div className="loader-wrapper min-h-full min-w-full">
                        <Loader light={true} />
                    </div> :
                    listOfExclusions.length === 0 ?
                        <div className="loader-wrapper min-h-full min-w-full p-5">
                            No Data Found
                        </div> :
                        listOfExclusions.map((exclusion, index) => {
                            return <div key={index + "exclusion"} id="rowDetail" className="bg-blue border-b-2 border-gray-600 flex flex-row py-5 pl-5 w-full">
                                <select onChange={handleEdit} name="type" className="w-1/5 text-center bg-blue" defaultValue={exclusion.type} disabled={editing !== index}>
                                    <option value="order" >Order</option>
                                    <option value="customer" >Customer</option>
                                </select>
                                <input maxLength={editBody.type === "order" ? 10 : 8} onChange={handleEdit} name="coh_id" type="text" className="w-1/5 text-center bg-blue" defaultValue={exclusion.coh_id} disabled={editing !== index} />
                                <select onChange={handleEdit} name="country" className="w-1/5 text-center bg-blue" defaultValue={exclusion.country} disabled={editing !== index} >
                                    <option value="BRA" >Brazil</option>
                                    <option value="ARG" >Argentina</option>
                                </select>
                                <input maxLength="30" onChange={handleEdit} name="reason" type="text" className="w-1/5 text-center bg-blue" defaultValue={exclusion.reason} disabled={editing !== index} />
                                {editing !== index && <div className="w-1/5 align-center justify-center flex flex-row flex-nowrap">
                                    <div className="mr-2 w-10 h-10 rounded-full bg-gray-700 p-2 flex flex-row flex-nowrap pointer" onClick={() => setEditing(index)}>
                                        {GlobalSVG.pencil()}
                                    </div>
                                    <div className="ml-2 w-10 h-10 rounded-full bg-gray-700 p-2 flex flex-row flex-nowrap pointer" onClick={() => handleDelete(exclusion.bi_exclusion_id, exclusion.coh_id)}>
                                        {GlobalSVG.garbage()}
                                    </div>
                                </div>}
                                {editing === index && <div className="w-1/5 align-center justify-center flex flex-row flex-nowrap">
                                    <div className="mr-2 w-10 h-10 rounded-full bg-gray-700 p-2 flex flex-row flex-nowrap pointer" onClick={submitEdit}>
                                        {GlobalSVG.check()}
                                    </div>
                                    <div className="ml-2 w-10 h-10 rounded-full bg-gray-700 p-2 flex flex-row flex-nowrap pointer" onClick={() => setEditing(null)}>
                                        {GlobalSVG.close()}
                                    </div>
                                </div>}
                            </div>
                        })}
            </div>
        </div>
        {
            !loading && maxPages ?
                <div className="page-selector flex-row-nowrap space-between">
                    <div onClick={() => currentPage > 1 ? setCurrentPage(currentPage => currentPage - 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        <img src={require('../../assets/images/leftBlue.png')} alt="previous" />
                        {
                            !mobile && <p>Previous</p>
                        }
                    </div>
                    <div className="pages-section flex-row-nowrap">
                        {
                            pages
                        }
                    </div>
                    <div onClick={() => currentPage < maxPages ? setCurrentPage(currentPage => currentPage + 1) : null} className="control-buttons flex-row-nowrap space-between align-center cursor-pointer">
                        {
                            !mobile && <p>Next</p>
                        }
                        <img src={require('../../assets/images/rightBlue.png')} alt="next" />
                    </div>
                </div> :
                null
        }

    </div>;
}

export default ExcludedIds; 