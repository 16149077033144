import React, { useState } from 'react';
import Modal from 'react-modal';

// Ensure to set appElement to suppress modal-related accessibility warnings.
Modal.setAppElement('#root');

export default function ImageModal(props) {
    return (
        <Modal
            isOpen={props.showModal}
            className="absolute w-[700px] h-[230px] inset-y-1/3 right-80" // Adjust the position using Tailwind classes
            overlayClassName="fixed inset-0 bg-gray-500 opacity-90"
            onRequestClose={props.closeModal}
        >
            <img className='opacity-1' src={props.imageUrl} alt={props.altText} width={700} height={250} />
        </Modal>
    );
};