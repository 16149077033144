import React, { useEffect, useRef, useState, useContext } from "react";
import Loader from "../../components/Loader";
import './style.css'
import { Link, useHistory } from "react-router-dom";
import Status from "../../components/Status";

function SortableTable(props) {

    const [sortField, setSortField] = useState(null)
    const [sortAsc, setSortAsc] = useState(true)
    const handleSort = (value) => {
        if (sortField == value) {
            setSortAsc(!sortAsc)
        }
        else {
            setSortAsc(true)
        }
        setSortField(value)
    }

    useEffect(() => {
        if (sortField === null || sortAsc === null || !props.dataSet) return
        let tempArr = props.dataSet
        if (sortAsc) tempArr.sort(function (a, b) {
            console.log("Sorting ascending")
            if (a[sortField] < b[sortField]) return -1
            if (a[sortField] > b[sortField]) return 1
            return 0
        });
        if (!sortAsc) tempArr.sort(function (a, b) {
            console.log("Sorting descending")
            if (a[sortField] > b[sortField]) return -1
            if (a[sortField] < b[sortField]) return 1
            return 0
        });
        props.setDataSet(tempArr)
    }, [sortField, sortAsc])

    return (
        <div className="sortable-table flex-row p-1 w-full">
            {!props.data || props.loading ?
                <div className="loader-wrapper min-h-full min-w-full">
                    <Loader light={true} />
                </div> :
                <div id="resultTable" className="flex-col justify-center overflow-x-scroll">
                    <div id="tableHeader" className="bg-gray-200 flex flex-nowrap justify-between py-5 text-xs lg:text-base pl-5 font-bold w-fit">
                        {
                            props && props.headers.map((e, index) => (
                                <div key={index + e + "customtable"} className="pointer col-width-force text-center" onClick={() => handleSort(e.sorts)}>{e.name}</div>
                            ))
                        }
                    </div>
                    <div id="resultBody" className="table-height-limiter overflow-y-scroll w-fit">
                        {
                            props.data
                        }
                    </div>
                </div>
            }
        </div>
    )
}
export default SortableTable
